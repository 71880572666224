import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT } from '../utils/LocalStorageConstants';
import { CREATE_TRANSFERED_STAFF_OLD_DATA, STAFF_COLLEGEID_UPDATE, USERS_COLLEGEID_UPDATE } from '../utils/InfoUploadingApiConstants';
import { EMPLOYEE } from '../utils/apiConstants';

function ModalTransferCollege({ setLoading, data, flag, setFlag }) {

  const [clg, setClg] = useState("");

  const selectedStaffId = data;

  console.log("modal data", selectedStaffId);

  const [employeeData, setEmployeeData] = useState([]);
//   const [transfereddate, setTransferedDate] = useState([]);
  const [transfereddate, setTransferedDate] = useState(new Date().toISOString().slice(0, 10));

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const handleDepartmentChange = (selectedOption) => {
    console.log("Selected Department:", selectedOption);
    setSelectedDepartment(selectedOption);
  };

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), clg]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const getData = () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setEmployeeData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  },[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setTransferedDate((prev) => ({
    //   ...prev,
    //   [name]: value,
    // }));
    setTransferedDate(value);
  };

  console.log("transfereddate -", transfereddate);

  const handleUpdate = async (id) => {

    if(!clg || !selectedDepartment?.value) {
        return toast.error("Please select college and department to be transfered");
    }
    // if(clg || !selectedDepartment?.value) {
    //     return toast.error("Please select department also to be transfered");
    // }

    setLoading(1);

    const staffId = (employeeData?.find((s) => s.id == id)?.user_id)
    console.log("staffId -", staffId);

    // Fetch previous values
    const prevValues = employeeData?.find((s) => s.id == id);

    console.log("prevValues.id -", prevValues.id);
    console.log("prevValues.college_id -", prevValues.college_id);
    console.log("prevValues.department_id -", prevValues.department_id);

    const config = {
      method: "put",
      url: `${STAFF_COLLEGEID_UPDATE}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        college_id: clg,
        department_id: selectedDepartment?.value,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success("Successfully Transfered Staff");
        // getData();
        setFlag((flag)=>!flag)
      })
      .catch((err) => {
        console.log(err);
      });

      const config2 = {
        method: "put",
        url: `${USERS_COLLEGEID_UPDATE}/${staffId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          college_id: clg,
        },
      };
  
      await axios(config2)
        .then((res) => {
        //   toast.success("Successfully Transfered Staff");
          setFlag((flag)=>!flag)
        })
        .catch((err) => {
          console.log(err);
        });

        const config1 = {
            method: "post",
            url: CREATE_TRANSFERED_STAFF_OLD_DATA,
            headers: {
              "Content-Type": "application/json",
              //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
            },
            data: {
              emp_id: prevValues.id,
              college_id: prevValues.college_id,
              department_id: prevValues.department_id,
              date_of_joining: prevValues.date_of_joining,
              transfered_date: transfereddate,
            },
          };
      
        axios(config1)
            .then((res) => {
              console.log(res);
            //   toast.success("Succesfully Uploaded Details");
              // clearData();
            })
            .catch((err) => {
              console.log(err);
            });

      setLoading(0);
    };

    // const handleSubmit = async () => {

    //     setLoading(1);
    //     const config1 = {
    //       method: "post",
    //       url: CREATE_TRANSFERED_STAFF_OLD_DATA,
    //       headers: {
    //         "Content-Type": "application/json",
    //         //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
    //       },
    //       data: {
    //         emp_id: "",
    //         college_id: "",
    //         department_id: "",
    //         date_of_joining: "",
    //         transfered_date: transfereddate,
    //       },
    //     };
    
    //     axios(config1)
    //       .then((res) => {
    //         console.log(res);
    //         toast.success("Succesfully Uploaded Details");
    //         // clearData();
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       });
    
    //     setLoading(0);
    //   };

  return (
    <div className='ModalTransferCollege'>

        <div
          className="modal fade"
          id="ModalTransferCollege"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
            <div
              className="modal-dialog modal-dialog-centered mw-100 w-75"
              role="document"
            >
                <div className="modal-content">

                    <div className="modal-header">
                        <h3 className="text-primary">TRANSFER STAFF TO</h3>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                    </div>

                    <div className="modal-body">
                        <div className="row">

                            <div className="col-md-5">
                              <div className="form-group">
                                <label htmlFor="validationCustom01">
                                  Faculty <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  name="clg"
                                  id="faculty"
                                  className="form-control"
                                  value={clg}
                                  onChange={(e) => {
                                    setClg(e.target.value);
                                    setSelectedDepartment(null);
                                  }}
                                >
                                <option value=""> Select Faculty </option>
                                  {collegeOpt?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.name}
                                    </option>
                                  ))}
                                </select>
                              </div>  
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="validationCustom01">
                                  Department <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  name="selectedDepartment"
                                  id="section"
                                  value={selectedDepartment}
                                  onChange={handleDepartmentChange}
                                  options={
                                    department
                                      ?.filter((s) => s.college_id == clg)
                                      ?.map((i) => ({
                                        label: i.name,
                                        value: i.id,
                                      }))                                
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label htmlFor="">Date</label>
                                <input type="date"
                                  placeholder="Enter the Transfering Date"
                                  className="form-control"
                                  name="transfereddate"
                                  value={transfereddate}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                />
                              </div>
                            </div>
                         
                        </div>
                        <br/>
                        <div className="row float-right ml-4">
                            <div className="col-md-8">
                              <button
                                className="btn btn-nex btn-md"
                                type="submit"
                                name="submit"
                                value="collect"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => handleUpdate(selectedStaffId)}
                              >
                                Transfer
                              </button>
                            </div>  
                        </div>
                    </div>

                </div>
            </div>
        </div>
      
    </div>
  )
}

export default ModalTransferCollege;
