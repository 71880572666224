import React from 'react'
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RESEARCHANDPUBLICATION_UPLOAD } from '../../utils/InfoUploadingApiConstants';
import { ASSET_PUBLICATION } from '../../utils/AssetsReferenceTypes';
import { getFileUrl } from '../../Helpers/Helpers';
import { ROUTES } from "../../Router/routerConfig"
import { useNavigate } from 'react-router-dom';

function ResearchandPublication({ setLoading }) {
  const fileref = useRef(null);
  const navigate = useNavigate();
  let role = sessionStorage.getItem("role");
  const [info, setInfo] = useState({
    title: "",
    researcher: "",
    publishdate: "",
    attachments: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      title: "",
      researcher: "",
      publishdate: "",
      attachments: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.title || !info?.researcher || !info?.publishdate || !info?.attachments ) {
      toast.error("Please Enter all the required Details");
      return;
    }

    setLoading(1);
    const config = {
      method: "post",
      url: RESEARCHANDPUBLICATION_UPLOAD,
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: info,
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_PUBLICATION,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <div className="container-fluid mt-5">
      <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Research and Publication</h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Home</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            <a href="javascript:void(0)">Research and Publication</a>
                          </li>
                          
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input type="text"
                    placeholder="Enter the Research Title"
                    className="form-control"
                    name="title"
                    value={info?.title}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="">Name of Researcher </label>
                  <input type="text"
                    placeholder="Enter the Researcher Name"
                    className="form-control"
                    name="researcher"
                    value={info?.researcher}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="">Date of Publication</label>
                  <input
                  required
                    type="date"
                    className="form-control"
                    name="publishdate"
                    placeholder="Enter Title Of the Subject"
                    value={info?.date}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                  <div className="form-group">
                    <lable>Attachmet</lable>
                    <input
                    
                      type="file"
                      className="form-control"
                      name="attachments"
                      placeholder="Enter Title Of the Subject"
                      ref={fileref}
                      onChange={(e) => handleChange1(e)}
                    />
                  </div>
                </div>
              <div className="col-md-12">
                <button className="btn btn-success float-right" id="submit" onClick={handleSubmit}>Submit</button>
              </div>
             
            </div>
          </div>
        </div>
      </div>

    </div>

  )
}


export default ResearchandPublication;
