import React, { useState, useRef, useEffect } from "react";
import "./../Leave.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { EMPLOYEEALL, LEAVE_ENTITLEMENT } from "../../../../utils/apiConstants";
import { LOCAL_COLLEGE } from "../../../../utils/LocalStorageConstants";
import Nodata from "../../../../Components/NoData/Nodata";
import useEmployee from "../../../../Hooks/Employee/useEmployee";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function LeaveEntitlementReport({ setLoading, collegeId }) {
  const [data, setData] = useState([]);
  const [employee] = useEmployee(collegeId);
  const [empData, setEmpData] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${LEAVE_ENTITLEMENT}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        // toast.success("success")
        setLoading(0);
        setData(res.data.data);
        console.log("data -", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });

    const config2 = {
      method: "get",
      url: EMPLOYEEALL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setEmpData(res.data.data);
        console.log("empData -", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Leave Entitlement Report List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div className="LeaveEntitlementReport Leave-Report">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Entitlement Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Leave</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Entitlement Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row d-flex justify-content-end p-3">
                    {/* <button className="btn btn-rounded btn-success btn-outline px-4">
                      Export &uarr;
                    </button> */}
                    <button
                      className="btn btn-primary rounded-pill ml-1 float-right"
                      onClick={onDownload}
                    >
                      Export Excel
                    </button>
                    <button
                      className="btn btn-primary rounded-pill ml-2"
                      onClick={handlePrint}
                    >
                      Export PDF
                    </button>
                  </div>

                  <div>
                    {data && data.length !== 0 ? (
                      data?.map((i, key) => {
                        const employeeData = employee?.find(
                          (j) => j.id == i?.employee_id
                        );
                        const firstName = employeeData?.first_name;
                        const lastName = employeeData?.last_name;

                        if (!firstName || !lastName) {
                          // Skip rendering this entry if either first name or last name is undefined
                          return null;
                        }

                        return (
                          <div
                            className="row my-3 mx-2 p-3 border rounded shadow report-div cursor-normal"
                            key={key}
                          >
                            <div className="col-12 row">
                              <div className="report-title col-12">
                                {/* {employee &&
                                employee?.find((j) => j.id === i.employee_id)
                                  ?.first_name} */}
                                {
                                  empData?.find((j) => j.id == i?.employee_id)
                                    ?.first_name
                                }
                              </div>
                              <div className="row col-12  role-parts">
                                <div className="col-sm-3 col-12 role-part-left">
                                  <button className="btn btn-primary btn-rounded">
                                    {i.leave_type}
                                  </button>
                                  <div className="d-flex my-2 text-danger">
                                    {i.to_date.split("T")[0]}
                                  </div>
                                </div>
                                <div className="col-sm-9 col-12  row role-part-right">
                                  <div className={`col-6 report-items `}>
                                    <div className="report-item-title">
                                      Balance
                                    </div>
                                    <div className="report-item-value btn btn-danger p-1">
                                      {i?.balance}
                                    </div>
                                  </div>
                                  <div className={`col-6 report-items `}>
                                    <div className="report-item-title">
                                      Earned
                                    </div>
                                    <div className="report-item-value ">
                                      {i?.earned}
                                    </div>
                                  </div>
                                  <div className={`col-6 report-items `}>
                                    <div className="report-item-title">
                                      Entitlement
                                    </div>
                                    <div className="report-item-value ">
                                      {i.entitled_days}
                                    </div>
                                  </div>
                                  <div className={`col-6 report-items `}>
                                    <div className="report-item-title">
                                      Taken
                                    </div>
                                    <div className="report-item-value ">
                                      {i.taken_days ? i.taken_days : 0}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <Nodata />
                    )}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <table
                id="table_id"
                ref={tableRef}
                className="display table table-bordered  nowrap table-hover "
                style={{
                  borderCollapse: "collapse",
                  borderSpacing: 0,
                  width: "100%",
                  display: "none",
                }}
              >
                <div className="col-12 text-center">
                  {/* {data.length > 0 && (
    <div>
      <h4 className="text-center">
      Leave Entitlement Report List -
        {data[0]?.college_id && collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
      </h4>
     <span> <p className="text-right float-right"> Printed On - {" " + getCurrentDate()} </p></span>
    </div>
)} */}
                  <thead>
                    <tr>
                      <td colSpan={10}>
                        <div>
                          <h4 className="text-center">
                            Leave Entitlement Report List -{" "}
                            {data?.length > 0 &&
                              data[0]?.college_id &&
                              collegeOpt?.find(
                                (s) => s.id === data[0]?.college_id
                              )?.name}
                          </h4>
                          <span>
                            <p className="text-right float-right">
                              Printed On - {getCurrentDate()}
                            </p>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Sl.No.</th>
                      <th>Employee Name</th>
                      <th>Leave Type</th>
                      <th>Entitled Days</th>
                      <th>Year</th>
                      <th>From Date</th>
                      <th>To Date</th>
                      <th>Balance</th>
                      <th>Earned</th>
                      <th>Taken</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data?.length == 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <Nodata />
                        </td>
                      </tr>
                    ) : (
                      data?.map((i, key) => {
                        const employeeData = employee?.find(
                          (j) => j.id == i?.employee_id
                        );
                        const firstName = employeeData?.first_name;
                        const lastName = employeeData?.last_name;

                        if (!firstName || !lastName) {
                          // Skip rendering this entry if either first name or last name is undefined
                          return null;
                        }
                        return (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {/* {empData?.find((j) => j.id == i?.employee_id)
                                ?.first_name +
                                " " +
                                empData?.find((j) => j.id == i?.employee_id)
                                  ?.last_name} */}
                              {`${firstName} ${lastName}`}
                            </td>
                            <td>{i?.leave_type}</td>
                            <td>{i?.entitled_days}</td>
                            <td>{i?.year}</td>
                            <td>
                              {/* {formatDate(i?.from_date.split("T")[0])} */}
                              {
                                new Date(i?.from_date)
                                  .toISOString()
                                  .split("T")[0]
                              }
                            </td>
                            <td>
                              {/* {formatDate(i?.to_date.split("T")[0])} */}
                              {new Date(i?.to_date).toISOString().split("T")[0]}
                            </td>
                            <td>{i?.balance}</td>
                            <td>{i?.earned}</td>
                            <td>{i?.taken_days}</td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </div>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeaveEntitlementReport;
