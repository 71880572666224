import React from "react";
import { useEffect } from "react";

function DocumentsModal({ title, img, setLink }) {
  let extension = img.slice(img.length - 3);
  // console.log('img - ', img);
  return (
    <div
      className="modal fade text-left"
      id="DocumentsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ zIndex: 1060 }} // Adjust the z-index for DocumentsModal
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => {
                  setLink("");
                }}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body text-center">
            {extension == "pdf" ? (
              <object
                data={img}
                type="application/pdf"
                width="100%"
                height="600px"
              ></object>
            ) : (
              <img src={img} width={500} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentsModal;
