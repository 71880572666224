import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef } from "react";
import { HOMEO_EVENT_UPLOAD } from "../../utils/InfoUploadingApiConstants";
import { getFileUrl } from "../../Helpers/Helpers";
import { ASSET_HOMEOMEDIA } from "../../utils/AssetsReferenceTypes";
import { EMPLOYEE_ALL } from "../../utils/apiConstants";

const HomeoInfo = ({ setLoading, college_id }) => {
  const fileref = useRef(null);

  const [attachments, setAttachments] = useState([]);
  const [info, setInfo] = useState({
    title: "",
    date: "",
    description: "",
  });

  const clearData = () => {
    setInfo({
      title: "",
      date: "",
      description: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.date || !info?.title) {
      toast.error("Please Enter all the required Details");
      return;
    }
    setLoading(1);

    // Retrieve college_id from session storage
    const college_id = sessionStorage.getItem("college_id")

    const config = {
      method: "post",
      url: HOMEO_EVENT_UPLOAD,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
        attachments,
        //  college_id,
        college_id: college_id, // Add college_id to the data
      },
    };

    axios(config)
      .then((res) => {
        // console.log("college_id"+res);
        toast.success("Succesfully Updated Details");
        clearData();
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });


    const config1 = {
      method: "get",
      url: EMPLOYEE_ALL,
      // url: EMPLOYEE_ALL? `${EMPLOYEE_ALL}/${"college_id"}`:null,
      headers: {
        "Content-Type": "application/json",
      },

    };

    await axios(config1).then((res) => {
      console.log(res.data.data)
      // setData1(res.data.data)
    }).catch((err) => {
      console.log(err)
    })


  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const Change = async (e) => {
    let files = [];
    for (let i of e.target.files) {
      let empId = Math.floor(Math.random() * 100);
      try {
        const d = await getFileUrl(
          ASSET_HOMEOMEDIA,
          "Homoeopathy_Assets/Events",
          i?.name.split(".")[1],
          setLoading,
          i
        );
        console.log("abcd" + d);
        files.push(d);
      } catch (error) {
        console.log(error);
      }
    }
    setAttachments(files);
  };
  return (
    <div>
      <div
        className="col-xl-12 p-0 col-lg-9 d-flex col-md-12 col-sm-12"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="card h-100 w-25" style={{ marginTop: "2rem" }}>
          <div className="card-body">
            <div className="row-gutters mt-4" style={{ textAlign: "center" }}>
              <h4>
                <u>Upload Homeo Event Details</u>
              </h4>
              <div style={{ marginTop: "3rem" }}>
                <h5>Title</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Title Of the Subject"
                      name="title"
                      value={info?.title}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "3rem" }}>
                <h5>Description</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      rows={5}
                      cols={10}
                      className="form-control"
                      placeholder="Enter Title Of the Subject"
                      name="description"
                      value={info?.description}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "3rem" }}>
                <h5>Date</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      type="date"
                      className="form-control"
                      name="date"
                      placeholder="Enter Title Of the Subject"
                      value={info?.date}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "3rem" }}>
                <h5>Attachments</h5>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      multiple="multiple"
                      type="file"
                      className="form-control"
                      name="attachment"
                      placeholder="Enter Title Of the Subject"
                      ref={fileref}
                      onChange={(e) => Change(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group" style={{ marginTop: "3rem" }}>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeoInfo;
