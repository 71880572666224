import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import { ROUTES } from "../../../Router/routerConfig";
import { STUDENT_SEM_DATA } from "../../../utils/apiConstants";
import { ACADEMICS_ADD_CLASS } from "../../../utils/Academics.apiConst";
import { FEE_DETAILS, FEE_STUDENT_DETAILS, FEE_STUDENT_DETAILS_BY_NAME } from "../../../utils/fees.apiConst";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import Select from "react-select";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";

function FeeCollectionCollectFee({ collegeId, setLoading }) {
  const getDepartmentData = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch {
      return "";
    }
  }

  const getProgramtData = () => {
    return localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;
  };

  const [session, setSession] = useState(
    sessionOpt?.find((s) => s.status == "ACTIVE").id
  );

  const [userId, setUserId] = useState("");

  const [data, setData] = useState([]);

  const [role, setRole] = useState(sessionStorage.getItem(SESSION_ROLE));

  const [departmentOpt, setDepartmentOpt] = useState(getDepartmentData());

  const [programOpt, setProgramOpt] = useState(getProgramtData());

  const [departmentId, setDepartmentId] = useState("");

  const [classId, setClassId] = useState("");

  const [classOpt, setClassOpt] = useState([]);

  const [student, setStudent] = useState([]);

  const [searchName, setSearchName] = useState("");

  const [fee1, setFee1] = useState([]);
  const [fee2, setFee2] = useState([]);
  const [numberType, setNumberType] = useState("EN");

  useState(() => {
    setDepartmentOpt(getDepartmentData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  useState(() => {
    setProgramOpt(getProgramtData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  const navigate = useNavigate();

  const changeDir = (dir, id) => {
    navigate(`${dir}/${id}`);
  };

  const getAllDropData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        toast.error("Sonething went wrong");
      });
    setLoading(0);
  };

  const handleSearch = async (id, f) => {
    if (!id) return;

    const config = {
      method: "get",
      url: `${FEE_DETAILS}?college_id=${collegeId}&student_id=${userId}&student_semester_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("x - ", res.data.data);
        if (f == 1) setFee1(res.data.data);
        else setFee2(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSearch1 = async () => {
    if (!session) return toast.error("Please Select Session");
    if (!userId) return toast.error("Please Enter Admission number");
    const config = {
      method: "get",
      url: `${FEE_STUDENT_DETAILS}?type=${numberType}&session_id=${session}&student_id=${userId}`,
      headers: {
        Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("student sem data - ", res.data.data);
        setStudent(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const getStudentsByName = async (x) => {
    if (!session) return toast.error("Please Select Session");
    if (!searchName) {
      toast.error("Please enter name of the student");
      return;
    }

    const config = {
      method: "get",
      url: `${FEE_STUDENT_DETAILS_BY_NAME}?name=${searchName}&session_id=${session}`,
      headers: {
        Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("student sem data - ", res.data.data);
        setStudent(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    setRole(sessionStorage.getItem(SESSION_ROLE));
  }, [sessionStorage.getItem(SESSION_ROLE)]);

  useEffect(() => {
    getAllDropData();
  }, []);
  return (
    <div className="FeeCollectionCollegeFee">
      {/* <ModalFeeBulkUpload setLoading={setLoading} collegeId={collegeId} /> */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            {/* <div className="row">
              <div className="col-12 ">
                <a
                  className="btn btn-success btn-sm btn-rounded float-right ml-1"
                  href={studentCollectFeeTemplate}
                  download=""
                >
                  <i className="fa fa-download" aria-hidden="true" /> Download
                  Documents
                </a>{" "}
                &nbsp;&nbsp;
                <button
                  className="btn btn-primary btn-sm btn-rounded float-right"
                  data-toggle="modal"
                  data-target="#exampleModalLong"
                  type="button"
                  name="submit"
                >
                  <i className="fa fa-upload" aria-hidden="true" /> Upload
                  Documents
                </button>
              
              </div>
              <div className="col-12 mt-3">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Collect Fee</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Fee Collection</a>
                      </li>
                      <li className="breadcrumb-item active">Collect Fees</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div> */}
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select criteria</h2>
                    <br />
                    <div className="row">
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name=""
                            id=""
                            className="form-control"
                            value={departmentId}
                            onChange={(e) => { setDepartmentId(e.target.value) }}
                          >
                            <option value="">Select Department</option>
                            {
                              departmentOpt?.filter(s => s?.college_id == collegeId)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}, {programOpt?.find(s => s.id == i?.program_id)?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          {/* <select
                            name=""
                            id=""
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                          >
                            <option value="">Select Session</option>
                            {SessionOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select> */}

                          <Select
                            options={sessionOpt.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            value={
                              session
                                ? {
                                    value: session,
                                    label:
                                      sessionOpt.find((i) => i.id === session)
                                        ?.name || "",
                                  }
                                : null
                            }
                            onChange={(selectedOption) =>
                              setSession(selectedOption.value)
                            }
                            placeholder="Select Session"
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name=""
                            id=""
                            className="form-control"
                            value={classId}
                            onChange={(e) => { setClassId(e.target.value) }}
                          >
                            <option value="">Select Class</option>
                            {
                              classOpt?.filter(s=>s?.department_id==departmentId)?.map((i, key) => (
                                <option value={i.id} key={key}>{i.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">{numberType == "EN" ? `Admission Number` : numberType == "UN" ? `University Number` :  `Student Name`}</label>
                          {/* <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Admission Number"
                            value={userId}
                            onChange={(e) => {
                              setUserId(e.target.value);
                            }}
                          /> */}
                        
                      <div className="input-group">
                      <select
                        onChange={(e) => {
                          setNumberType(e.target.value);
                          setUserId("");
                          setSearchName("");
                        } }
                      >
                        <option value="EN">Admission No.</option>
                        <option value="UN">University No.</option>
                        <option value="NAME">Student Name</option>
                      </select>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={numberType == "EN" ? `Enter Student Admission No.` : numberType == "UN" ? `Enter Student University No.` :  `Enter Student Name`}
                        value={numberType == "NAME" ? searchName : userId}
                        onChange={(e) => {
                          if(numberType == "NAME") {
                            setSearchName(e.target.value);
                          }
                          else {
                            setUserId(e.target.value);
                          }
                        }}
                      />
                    </div>
                    </div>
                    </div>
                    <div className="col-md-4">
                      <button
                        className="btn btn-nex mt-4"
                        type="submit"
                        name="submit"
                        value="collect"
                        onClick={() => {
                          if(numberType == "NAME") {
                            getStudentsByName();
                          }
                          else {
                            handleSearch1();
                          }
                        }}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                    </div>
                    <div className="row float-right mr-5">
                      
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Students Details</h4>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <a href="#">
                            <i
                              className="fa fa-file-pdf-o "
                              aria-hidden="true"
                            />
                          </a>{" "}
                          &nbsp;{" "}
                          <a href="#">
                            <i
                              className="fa fa-file-excel-o"
                              aria-hidden="true"
                            />
                          </a>{" "}
                        </span>
                      </div>
                    </div>
                    <hr />
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover no-footer"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr role="row">
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "100px" }}
                            aria-sort="ascending"
                            aria-label="Addmision Number: activate to sort column descending"
                          >
                            Admission No.
                          </th>
                          <th
                            className="sorting_asc"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "100px" }}
                            aria-sort="ascending"
                            aria-label="Addmision Number: activate to sort column descending"
                          >
                            University No.
                          </th>
                          {/* <th
                                    className="sorting"
                                    tabIndex={0}
                                    aria-controls="datatable"
                                    rowSpan={1}
                                    colSpan={1}
                                    style={{ width: "99.0046px" }}
                                    aria-label="Roll  Number: activate to sort column ascending"
                                  >
                                    Roll Number
                                  </th> */}
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "116.005px" }}
                            aria-label="Student Name: activate to sort column ascending"
                          >
                            Student Name
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "116.005px" }}
                            aria-label="Student Name: activate to sort column ascending"
                          >
                            Semester
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "116.005px" }}
                            aria-label="Student Name: activate to sort column ascending"
                          >
                            Category
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "116.005px" }}
                            aria-label="Student Name: activate to sort column ascending"
                          >
                            Caste
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "116.005px" }}
                            aria-label="Student Name: activate to sort column ascending"
                          >
                            Freeship card
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "67.0046px" }}
                            aria-label="Amount: activate to sort column ascending"
                          >
                            Amount
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "63.0046px" }}
                            aria-label="Deposit: activate to sort column ascending"
                          >
                            Deposit
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "81.0046px" }}
                            aria-label="Discounts: activate to sort column ascending"
                          >
                            Trust Grant
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "35.0046px" }}
                            aria-label="Fine: activate to sort column ascending"
                          >
                            Fine
                          </th>
                          <th
                            className="sorting"
                            tabIndex={0}
                            aria-controls="datatable"
                            rowSpan={1}
                            colSpan={1}
                            style={{ width: "67.0046px" }}
                            aria-label="Balance: activate to sort column ascending"
                          >
                            Balance
                          </th>
                          {role != "ADMIN" &&
                          sessionStorage.getItem("employee_id") != 337 &&
                          role != "AD-CON" ? (
                            <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="datatable"
                              rowSpan={1}
                              colSpan={1}
                              style={{ width: "54.0046px" }}
                              aria-label="Action: activate to sort column ascending"
                            >
                              Action
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {student
                          ?.filter((s) => s.amount)
                          ?.map((i, key) => (
                            <tr key={key}>
                              <td>{i?.user_id}</td>
                              <td>{i?.university_number}</td>
                              <td>{i?.name}</td>
                              <td>{i?.sem_name}</td>
                              <td>{i?.category}</td>
                                <td>{i?.caste}</td>
                                <td>
                                  {i?.freeshipcard}
                                </td>
                              {/* <td>{formatDate(i?.feeData?.due_date?.split('T')[0])}</td> */}
                              <td>
                                {parseInt(i?.amount)?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {parseInt(i?.paid_amount)?.toLocaleString(
                                  "en-IN",
                                  {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  }
                                )}
                              </td>
                              <td>
                                {parseInt(i?.discount)?.toLocaleString(
                                  "en-IN",
                                  {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  }
                                )}
                              </td>
                              <td>
                                {parseInt(i?.fine)?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {(
                                  i?.amount -
                                  i?.paid_amount -
                                  i?.discount
                                )?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              {role != "ADMIN" &&
                              i?.student_semester_id &&
                              role != "AD-CON" &&
                              sessionStorage.getItem("employee_id") != 337 ? (
                                <td>
                                  <button
                                    onClick={() => {
                                      role == "SUACC" || role == "ACC"
                                        ? changeDir(
                                            ROUTES.Accountant.AddFee,
                                            i?.student_semester_id
                                          )
                                        : role == "SUPERADMIN"
                                        ? changeDir(
                                            ROUTES.Registar.Accounts
                                              .FeeCollection.AddFee,
                                            i?.student_semester_id
                                          )
                                        : role == "ADMIN"
                                        ? changeDir(
                                            ROUTES.Principal.Accounts
                                              .FeeCollection.AddFee,
                                            i?.student_semester_id
                                          )
                                        : role == "CASHIER"
                                        ? changeDir(
                                            ROUTES.Cashier.AddFee,
                                            i?.student_semester_id
                                          )
                                        : changeDir("/");
                                    }}
                                    className="btn btn-nex btn-sm btn-rounded"
                                    type="button"
                                  >
                                    Collect
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
          {/* End Page-content */}
        </div>
        {/* end main content*/}
      </div>
    </div>
  );
}

export default FeeCollectionCollectFee;
