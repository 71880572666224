import React from 'react'
import Calender from '../../../../Components/Calender/Calender'

function Planner() {
  return (
    <div className='Planner'>
        <div className="container">
            
        <Calender/>
        </div>
    </div>
  )
}

export default Planner