import axios from "axios";
import React from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import { SessionOpt } from "../../../Data/student/sessionData";
import {
  HOSTEL,
  HOSTEL_FLOORS,
  HOSTEL_ROOMS,
  HOSTEL_ROOMS_SQL,
  HOSTEL_ROOMS_SQL_2,
  HOSTEL_TYPE,
} from "../../../utils/Hostel.apiConst";
import { EMPLOYEE_ALL2 } from "../../../utils/apiConstants";
import ModalHostelRooms from "../../../modals/ModalHostelRooms";
import { Button, Popover } from "antd";

function HostelRoomsReport({ setLoading, collegeId }) {
  const [user, setUser] = useState({
    room_name_number: "",
    room_hostel_id: "",
    hostel_room_type_id: "",
    hostel_no_bed: "",
    floor_number: "",
  });

  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [year, setYear] = useState("");

  const [edit, setEdit] = useState(0);
  const [count, setCount] = useState(0);
  const [beds, setBeds] = useState([]);
  const [flag, setFlag] = useState(false);
  const [editId, setEditId] = useState();
  const [editBed_no, setBed_No] = useState();
  const [hostelData, setHostelData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [floorData, setFloorData] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [show2, setShow2] = useState([]);
  const [status, setStatus] = useState("ACTIVE");

  const [filterHostel, setFilterHostel] = useState("");
  const [filterFloor, setFilterFloor] = useState("");
  const [filterType, setFilterType] = useState("");

  const clearData = () => {
    setUser({
      room_name_number: "",
      room_hostel_id: "",
      hostel_room_type_id: "",
      hostel_no_bed: "",
      floor_number: "",
    });
    setBeds([]);
    setCount(0);
  };

  const [show, setShow] = useState([]);

  const tableRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Hostel Rooms Report ",
    sheet: "Users",
  });

  const role = sessionStorage.getItem("role");
  const emp_id = sessionStorage.getItem("employee_id");

  let auth = sessionStorage.getItem("UMS_auth");
  // console.log("role", role);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const getData1 = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({ ...config, url: `${HOSTEL_FLOORS}` })
      .then((res) => {
        // setLoading(0);
        setFloorData(res.data.data); //This line to check if floorData is being updated
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong", err);
      });

    await axios({ ...config, url: `${HOSTEL}` })
      .then((res) => {
        // setLoading(0);
        setHostelData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong", err);
      });

    await axios({ ...config, url: `${HOSTEL_TYPE}?college_id=${collegeId}` })
      .then((res) => {
        // setLoading(0);
        setRoomData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
      });
    setLoading(0);
  };

  const handleSubmit = () => {
    if (!user.room_hostel_id) return toast.error("Please select Hostel");
    if (user.floor_number != 0) {
      if (!user.floor_number) return toast.error("Please Select Floor");
    }
    if (!user.hostel_room_type_id)
      return toast.error("Please Select Room Type");
    if (!user.room_name_number) return toast.error("Please Enter Room Number");

    if (beds.length <= 0) {
      return toast.error("Please Enter Atleast one bed");
    }

    for (let i of beds) {
      if (!i?.name) {
        return toast.error("Bed name cannot be null");
      }
    }

    const config = {
      method: "post",
      url: `${HOSTEL_ROOMS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        beds,
      },
    };

    console.log("data to backend - ", {
      ...user,
      beds,
    });

    setLoading(1);
    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Success");
        clearData();
        getdata();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const handleEdit = () => {
    if (!user?.room_name_number) return toast.error("Room Number is required");
    let Array1 = [];
    if (JSON.parse(sessionStorage.getItem("beds"))?.length > 0) {
      Array1 = JSON.parse(sessionStorage.getItem("beds"));
    }
    let x = [];

    if (Array1.length > 0) {
      x = Array1.map((item) => Object.values(item)[0]);
    }

    if (Object.keys(obj).length > 0) {
      const value = Object.values(obj)[0];
      x.push(value);
    }

    let bed_numbers = x?.map((item, key) => item?.bed_no);

    for (let i of data) {
      if (
        i.hostelRooms.room_hostel_id == user.room_hostel_id &&
        bed_numbers.includes(i.hostelRooms.hostel_no_bed)
      ) {
        return toast.error("Bed Number is already added");
      }
    }

    if (x.length == 0) {
      //  return toast.error("Please Add Bed Number");
    }

    setLoading(1);
    const config = {
      method: "put",
      url: `${HOSTEL_ROOMS}/${editId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        x,
      },
    };

    console.log("user", user);
    console.log("x", x);

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        clearData();
        toast.success("Success");
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong", err);
      });
  };

  let obj = {};

  const handleChange1 = (e, key) => {
    setBed_No("");
    const { name, value } = e.target;
    if (!obj[key]) {
      obj[key] = {
        bed_no: "",
      };
    }
    obj[key][name] = value;
  };

  const getdata = async () => {
    if (!year) return toast.error("Please Select Year");
    const config = {
      method: "get",
      url: `${HOSTEL_ROOMS_SQL_2}?year=${year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("hostel beds - ", res.data.data);
        // setData(res.data.data);
        // Group by room_name_number
        const groupedData = res.data.data?.reduce((acc, current) => {
          const roomNameNumber = current.room_name_number;
          if (!acc[roomNameNumber]) {
            acc[roomNameNumber] = {
              room_name_number: roomNameNumber,
              beds: [],
              number_of_students: 0,
              bed_sheet: 0,
              ghoda: 0,
              pillow: 0,
              chair: 0,
              table: 0,
              dustbin: 0,
              vault_4: 0,
              vault_6: 0,
              vault: 0,
            };
          }
          if (current.hostel_fee_id) {
            acc[roomNameNumber].number_of_students += 1;
            acc[roomNameNumber].bed_sheet += current.bed_sheet || 0;
            acc[roomNameNumber].ghoda += current.ghoda || 0;
            acc[roomNameNumber].pillow += current.pillow || 0;
            acc[roomNameNumber].chair += current.chair || 0;
            acc[roomNameNumber].table += current.table || 0;
            acc[roomNameNumber].dustbin += current.dustbin || 0;
            acc[roomNameNumber].vault_4 += current.vault_4 || 0;
            acc[roomNameNumber].vault_6 += current.vault_6 || 0;
            acc[roomNameNumber].vault += current.vault || 0;
          }
          acc[roomNameNumber].beds.push(current);
          return acc;
        }, {});

        // Convert grouped object to array
        const groupedArray = Object.values(groupedData);

        console.log("grouped data - ", groupedArray);
        setData(groupedArray);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong", err);
      });
  };

  const handleUpdateStatus = (id, status) => {
    const config = {
      method: "put",
      url: `${HOSTEL_ROOMS}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: status,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        getdata();
        toast.success("Success");
      })
      .catch((err) => {
        setLoading(0);
        getdata();
        toast.error("Something Went Wrong", err);
      });
  };

  const getEmpData = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE_ALL2}?employee_id=${emp_id}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        console.log("emp data - ", res.data.data);
        setEmployee(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong", err);
      });
  };

  const handleBedNumberChange = (e) => {
    if (e.target.value > 26 || e.target.value < 0) {
      return toast.error("No. of Beds can be between 1 and 26");
    }
    setCount(e.target.value);
    if (!user?.room_name_number) {
      return toast.error("Please Enter Room number before adding beds");
    }

    const tempBeds = [];

    for (let i = 1; i <= e.target.value; i++) {
      tempBeds.push({
        id: i,
        name: user?.room_name_number + "-" + String.fromCharCode(64 + i),
      });
    }
    setBeds(tempBeds);
  };

  useEffect(() => {
    const hostelfloorsValues = [];

    for (const item of floorData) {
      const value = item.hostelfloors;
      hostelfloorsValues.push(value);
    }

    let x =
      hostelfloorsValues?.filter((s) => s.hostelname == user?.room_hostel_id)[0]
        ?.floors + 1;
    let arr = [];
    let cnt = 0;
    while (x) {
      arr.push(cnt);
      cnt++;
      x--;
    }
    setShow(arr);
  }, [user?.room_hostel_id]);

  useEffect(() => {
    const hostelfloorsValues = [];

    for (const item of floorData) {
      const value = item.hostelfloors;
      hostelfloorsValues.push(value);
    }

    let x =
      hostelfloorsValues?.filter((s) => s.hostelname == filterHostel)[0]
        ?.floors + 1;
    let arr = [];
    let cnt = 0;
    while (x) {
      arr.push(cnt);
      cnt++;
      x--;
    }
    setShow2(arr);
  }, [filterHostel]);

  useEffect(() => {
    console.log("beds - ", beds);
  }, [beds]);

  useEffect(() => {
    console.log("row data - ", rowData);
  }, [rowData]);

  useEffect(() => {
    getEmpData();
    // getdata();
    getData1();
  }, []);

  return (
    <div ClassName="HostelRoom">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div>
              <ModalHostelRooms setLoading={setLoading} vacantData={rowData} />
            </div>
            {/* Followup */}
            {/* start page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            Select Year
                            <span style={{ color: "red" }}>*</span>
                          </label>{" "}
                          <select
                            className="form-control"
                            name="selectedYear"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                          >
                            <option value="">Select Year</option>
                            {SessionOpt?.map((i, key) => (
                              <option value={i?.id}>
                                {i?.name.substring(0, 4)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-2">
                        <button
                          className="btn btn-nex mt-3"
                          type="submit"
                          name="submit"
                          value="collect"
                          onClick={getdata}
                        >
                          <i className="fa fa-search mr-2" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                    <div className="row float-right"></div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Hostel Rooms Report</h4>
                  <h4 className="mb-0">
                    {role == "WARDEN" &&
                      employee &&
                      hostelData.find((s) => s.id == employee[0]?.hostel_id)
                        ?.hostel_name}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Hostel</a>
                      </li>
                      <li className="breadcrumb-item active">Hostel Room</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <>
              {/* container-fluid */}
              {/* Fetch Data / Display Data List */}
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      {/* <div className="row">
                        <div className="col-md-6">
                          <h4 className="card-title">Hostel List</h4>
                        </div>
                        <div className="col-md-6"></div>
                      </div> */}
                      <div className="row mb-3">
                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                          <div className="card-title text-uppercase">
                            {" "}
                            <h6>Hostel Rooms List</h6>
                          </div>
                          <span>
                            <button
                              className="btn btn-primary rounded-pill mr-2"
                              onClick={handlePrint}
                            >
                              Export PDF
                            </button>
                            <button
                              className="btn btn-primary rounded-pill"
                              onClick={onDownload}
                            >
                              Export Excel
                            </button>
                          </span>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">Hostel</label>
                            <select
                              className="form-control"
                              value={filterHostel}
                              onChange={(e) => {
                                setFilterHostel(e.target.value);
                                setFilterFloor("");
                                setFilterType("");
                              }}
                            >
                              {role != "WARDEN" ? (
                                <option value="">All</option>
                              ) : (
                                <></>
                              )}
                              {role == "WARDEN"
                                ? hostelData
                                    ?.filter(
                                      (s) => s.id == employee[0]?.hostel_id
                                    )
                                    .map((i, key) => (
                                      <option value={i.id} key={key}>
                                        {i.hostel_name}
                                      </option>
                                    ))
                                : hostelData?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.hostel_name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Room Type
                            </label>
                            <select
                              className="form-control"
                              value={filterType}
                              onChange={(e) => setFilterType(e.target.value)}
                            >
                              <option value="">All</option>
                              {roomData
                                ?.filter((s) => s.hostel_id == filterHostel)
                                ?.map((i, key) => (
                                  <option value={i.id} key={key}>
                                    {i.room_type}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">Floor</label>
                            <select
                              className="form-control"
                              value={filterFloor}
                              onChange={(e) => setFilterFloor(e.target.value)}
                            >
                              <option value="">All</option>
                              {show2 &&
                                show2?.map((item, key) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Status
                              </label>
                              <select
                                className="form-control"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="">All</option>
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </select>
                            </div>
                          </div> */}
                      </div>
                      <hr />
                      <table
                        id="datatable"
                        className="table table-bordered dt-responsive nowrap"
                        ref={tableRef}
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl. No.</th>
                            <th>Hostel</th>
                            <th>Floor Number</th>
                            <th>Room Type</th>
                            <th>Room Number</th>
                            <th>
                              No. of <br />
                              Students
                            </th>
                            <th>No. of Beds</th>

                            <th>Bed Sheet</th>
                            <th>Pillow</th>
                            <th>Ghoda</th>

                            <th>Chair</th>
                            <th>Table</th>
                            <th>Dustbin</th>
                            <th>Vault 4</th>
                            <th>Vault 6</th>
                            <th>Vault</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        {role == "WARDEN" && employee[0]?.hostel_id ? (
                          <tbody>
                            {data &&
                              data
                                ?.filter(
                                  (s) =>
                                    s?.beds[0]?.room_hostel_id ==
                                      employee[0]?.hostel_id &&
                                    (!filterHostel ||
                                      s?.beds[0]?.room_hostel_id ==
                                        filterHostel) &&
                                    (!filterFloor ||
                                      s?.beds[0]?.floor_number ==
                                        filterFloor) &&
                                    (!filterType ||
                                      s?.beds[0]?.hostel_room_type_id ==
                                        filterType) &&
                                    (!status ||
                                      (status == "ACTIVE" &&
                                        s?.beds[0]?.status != "INACTIVE") ||
                                      (status == "INACTIVE" &&
                                        s?.beds[0]?.status == "INACTIVE"))
                                )
                                ?.map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td> {data?.beds[0]?.hostel_name}</td>
                                      <td>{data?.beds[0]?.floor_number}</td>
                                      <td> {data?.beds[0]?.room_type}</td>
                                      <td>{data?.room_name_number}</td>
                                      <td>{data?.number_of_students}</td>
                                      <td>
                                        <Popover
                                          content={
                                            <div>
                                              {data?.beds?.map((i, k) => {
                                                return (
                                                  <>
                                                    {i?.hostel_no_bed}
                                                    &nbsp;&nbsp;
                                                    {k != 0 && k % 4 == 0 ? (
                                                      <br />
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          }
                                        >
                                          {data?.beds?.length}
                                        </Popover>
                                      </td>

                                      <td>{data?.bed_sheet}</td>
                                      <td>{data?.pillow}</td>
                                      <td>{data?.ghoda}</td>

                                      <td>{data?.chair}</td>
                                      <td>{data?.table}</td>
                                      <td>{data?.dustbin}</td>
                                      <td>{data?.vault_4}</td>
                                      <td>{data?.vault_6}</td>
                                      <td>{data?.vault}</td>

                                      {role == "WARDEN" ? (
                                        <td>
                                          {" "}
                                          {data?.status !== "INACTIVE" ? (
                                            <span
                                              className="badge badge-soft-success"
                                              data-toggle="modal"
                                              data-target="#ModalHostelRooms"
                                              onClick={() => setRowData(data)}
                                            >
                                              ACTIVE
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-danger">
                                              INACTIVE
                                            </span>
                                          )}
                                        </td>
                                      ) : (
                                        <td>
                                          {" "}
                                          {data?.status !== "INACTIVE" ? (
                                            <span className="badge badge-soft-success">
                                              ACTIVE
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-danger">
                                              INACTIVE
                                            </span>
                                          )}
                                        </td>
                                      )}

                                      <td>
                                        <span
                                          className="badge badge-light text-dark mr-3"
                                          data-toggle="tooltip"
                                          title="Edit"
                                          onClick={() => {
                                            setUser({
                                              room_name_number:
                                                data?.room_name_number,
                                              room_hostel_id: data?.id,
                                              hostel_room_type_id: data?.id,
                                              hostel_desc: data?.hostel_desc,
                                              floor_number: data?.floor_number,
                                              // hostel_no_bed:
                                              //   data?.hostelRooms.hostel_no_bed,
                                            });
                                            setBed_No(data?.hostel_no_bed);
                                            setEdit(1);
                                            setEditId(data?.id);
                                          }}
                                        >
                                          {" "}
                                          <i
                                            class="fa fa-edit "
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        {data?.status == "INACTIVE" ? (
                                          <span
                                            className="badge badge-light text-success mr-3"
                                            data-toggle="tooltip"
                                            title="Activate"
                                            onClick={() =>
                                              handleUpdateStatus(
                                                data?.id,
                                                "ACTIVE"
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i
                                              class="fa fa-thumbs-up"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        ) : (
                                          <span
                                            className="badge badge-light text-danger mr-3"
                                            data-toggle="tooltip"
                                            title="Delete"
                                            onClick={() =>
                                              handleUpdateStatus(
                                                data?.id,
                                                "INACTIVE"
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <i
                                              class="fa fa-thumbs-down"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        ) : (
                          <tbody>
                            {data &&
                              data
                                ?.filter(
                                  (s) =>
                                    (!filterHostel ||
                                      s?.beds[0]?.room_hostel_id ==
                                        filterHostel) &&
                                    (!filterFloor ||
                                      s?.beds[0]?.floor_number ==
                                        filterFloor) &&
                                    (!filterType ||
                                      s?.beds[0]?.hostel_room_type_id ==
                                        filterType) &&
                                    (!status ||
                                      (status == "ACTIVE" &&
                                        s?.beds[0]?.status != "INACTIVE") ||
                                      (status == "INACTIVE" &&
                                        s?.beds[0]?.status == "INACTIVE"))
                                )
                                ?.map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td> {data?.beds[0]?.hostel_name}</td>
                                      <td>{data?.beds[0]?.floor_number}</td>
                                      <td> {data?.beds[0]?.room_type}</td>
                                      <td>{data?.room_name_number}</td>
                                      <td>{data?.number_of_students}</td>
                                      <td style={{ cursor: "pointer" }}>
                                        <Popover
                                          content={
                                            <div>
                                              {data?.beds?.map((i, k) => {
                                                return (
                                                  <>
                                                    {i?.hostel_no_bed}
                                                    &nbsp;&nbsp;
                                                    {k != 0 && k % 4 == 0 ? (
                                                      <br />
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </>
                                                );
                                              })}
                                            </div>
                                          }
                                        >
                                          {data?.beds?.length}
                                        </Popover>
                                      </td>

                                      <td>{data?.bed_sheet}</td>
                                      <td>{data?.pillow}</td>
                                      <td>{data?.ghoda}</td>

                                      <td>{data?.chair}</td>
                                      <td>{data?.table}</td>
                                      <td>{data?.dustbin}</td>
                                      <td>{data?.vault_4}</td>
                                      <td>{data?.vault_6}</td>
                                      <td>{data?.vault}</td>
                                      {/* { role == "WARDEN" ? (
                                  
                                  <td>  {data?.status == "VACANT" ? <span className="badge badge-soft-success"
                                    data-toggle="modal"
                                    data-target="#ModalHostelRooms" onClick={()=>setRowData(data)}>VACANT</span> 
                                  : data?.status == "OCCUPIED" ? <span className="badge badge-soft-warning">OCCUPIED</span> : null}</td>

                                  ) : (
                                    <td>  {data?.status == "VACANT" ? <span className="badge badge-soft-success">VACANT</span> 
                                  : data?.status == "OCCUPIED" ? <span className="badge badge-soft-warning">OCCUPIED</span> : null}</td>
                                  )
                                  
                                  } */}

                                      {role == "WARDEN" ? (
                                        <td>
                                          {" "}
                                          {data?.status !== "INACTIVE" ? (
                                            <span
                                              className="badge badge-soft-success"
                                              data-toggle="modal"
                                              data-target="#ModalHostelRooms"
                                              onClick={() => setRowData(data)}
                                            >
                                              ACTIVE
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-danger">
                                              INACTIVE
                                            </span>
                                          )}
                                        </td>
                                      ) : (
                                        <td>
                                          {" "}
                                          {data?.status !== "INACTIVE" ? (
                                            <span className="badge badge-soft-success">
                                              ACTIVE
                                            </span>
                                          ) : (
                                            <span className="badge badge-soft-danger">
                                              INACTIVE
                                            </span>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>{" "}
                {/* end col */}
              </div>{" "}
              {/* end row */}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostelRoomsReport;
