import React from 'react'

function AttendanceFieldCheckIn() {
  return (
    <div className='AttendanceFieldCheckIn'>
        
    </div>
  )
}

export default AttendanceFieldCheckIn