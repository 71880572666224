import React, { useState, useEffect } from "react";
import axios from "axios";
import NoData from "../../../../Components/NoData/Nodata";
import { useNavigate } from "react-router-dom";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import { EMPLOYEE_ATTENDANCE_TIME_REPORT } from "../../../../utils/Reports.apiConst";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../../utils/LocalStorageConstants";
import "../../../../modals/HR/Leave/TableScroll.css";
import { EMPLOYEE_BIO_IDS } from "../../../../utils/apiConstants";

function AttendanceTimeReport({ setLoading }) {
  const navigate = useNavigate();

  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "22",
  ];

  const collegeid = sessionStorage.getItem("college_id");
  console.log("collegeid -", collegeid);

  const getCurrentMonthFirstDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const firstDate = `01`;
    return `${year}-${month}-${firstDate}`;
  };

  const getCurrentMonthLastDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const lastDate = new Date(year, month, 0).getDate();
    return `${year}-${month}-${lastDate}`;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [user, setUser] = useState({
    from_date: getCurrentMonthFirstDate(),
    to_date: getCurrentMonthLastDate(),
    department_id: "",
    college_id: "",
  });

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(
    () => {
      setDepartmentOpt(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
          // (item) => item.college_id == user.college_id
          (item) => item.college_id == collegeid
        )
      );
      setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
      user.department_id = "";
    },
    // [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);
    [localStorage.getItem(LOCAL_DEPARTMENT), collegeid]
  );

  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const tableRef = useRef();
  const [currMonthDays, setCurrMonthDays] = useState(0);

  const [dateArray, setDateArray] = useState([]);

  const months = [
    { key: "01", name: "JANUARY" },
    { key: "02", name: "FEBRUARY" },
    { key: "03", name: "MARCH" },
    { key: "04", name: "APRIL" },
    { key: "05", name: "MAY" },
    { key: "06", name: "JUNE" },
    { key: "07", name: "JULY" },
    { key: "08", name: "AUGUST" },
    { key: "09", name: "SEPTEMBER" },
    { key: "10", name: "OCTOBER" },
    { key: "11", name: "NOVEMBER" },
    { key: "12", name: "DECEMBER" },
  ];

  const getlocalStorage = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  // const [attendanceData, setAttendanceData] = useState([]);
  const [jobRoleOpt, setJobRoleOpt] = useState(getlocalStorage);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const getData = async () => {
    console.log(user);
    if (!user?.from_date) {
      return toast.error("Please select From Date");
    }
    if (!user?.to_date) {
      return toast.error("Please select To Date");
    }

    const config = {
      method: "get",
      // url: `${EMPLOYEE_ATTENDANCE_TIME_REPORT}?from_date=${user?.from_date}&to_date=${user?.to_date}&college_id=${user?.college_id}&department_id=${user?.department_id}`,
      url: `${EMPLOYEE_ATTENDANCE_TIME_REPORT}?from_date=${user?.from_date}&to_date=${user?.to_date}&college_id=${collegeid}&department_id=${user?.department_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);

        const groupedData = res.data.data?.reduce((acc, curr) => {
          const { id, first_name, last_name, date, status } = curr;
          if (!acc[id]) {
            acc[id] = {
              id,
              first_name,
              last_name,
              attendance: [],
              p: 0,
              a: 0,
              wo: 0,
              h: 0,
            };
          }

          const isHoliday = res.data.holidays?.some((holiday) => {
            const holidayDate = new Date(holiday.from_date);
            const [day, month, year] = date.split("/");
            const formattedDate = new Date(`${year}-${month}-${day}`);
            return holidayDate.toDateString() === formattedDate.toDateString();
          });

          if (isHoliday) {
            acc[id].attendance.push({ date, status: "H" });
            acc[id].h = acc[id].h + 1;
          } else {
            acc[id].attendance.push({ date, status });
            if (status == "P") acc[id].p = acc[id].p + 1;
            if (status == "A") acc[id].a = acc[id].a + 1;
            if (status == "WO") acc[id].wo = acc[id].wo + 1;
          }
          return acc;
        }, {});
        const sortedEmployees = Object.values(groupedData).sort((a, b) => {
          const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setData(sortedEmployees);
        setDisplayData(sortedEmployees);
        // setData(Object.values(groupedData));
        console.log("monthly report data - ", sortedEmployees);
        console.log("holidays - ", res.data.holidays);

        const start = new Date(user?.from_date);
        const end = new Date(user?.to_date);
        const differenceInTime = end.getTime() - start.getTime();
        const diff = differenceInTime / (1000 * 3600 * 24);
        console.log("number of days - ", diff + 1);
        setCurrMonthDays(diff + 1);

        const tempArr = [];
        while (start <= end) {
          const day =
            start.getDate() < 10 ? `0${start.getDate()}` : start.getDate();
          const month =
            start.getMonth() + 1 < 10
              ? `0${start.getMonth() + 1}`
              : start.getMonth() + 1;
          const year = start.getFullYear();
          const date = `${day}/${month}/${year}`;
          tempArr.push(date);
          start.setDate(start.getDate() + 1);
        }
        setDateArray(tempArr);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${months.find((s) => s.key == user?.month1)?.name}-${
      user?.year1
    } Employee Attendance`,
    sheet: "Users",
  });

  useEffect(() => {
    const filteredData = data?.filter(
      (item) =>
        item?.first_name?.toLowerCase()?.includes(searchName?.toLowerCase()) ||
        item?.last_name?.toLowerCase()?.includes(searchName?.toLowerCase()) ||
        `${item?.first_name} ${item?.last_name}`
          ?.toLowerCase()
          ?.includes(searchName?.toLowerCase())
    );
    setDisplayData(filteredData);
  }, [searchName]);

  useEffect(() => {
    setData([]);
    setDisplayData([]);
    setCurrMonthDays(0);
    setDateArray([]);
  }, [user?.from_date, user?.to_date, user?.department_id]);

  return (
    <div className="AttendanceTimeClockReport">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">STAFF ATTENDANCE REPORT</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title">Select Criteria</div>
                    <div className="row">
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="college_id">
                            College <span className="text-danger">*</span>
                          </label>
                          <select 
                            name="college_id" id="college_id" required="" className="form-control"
                            value={user.college_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {collegeOpt?.map((data, key) => {
                              return <option value={data.id}>{data.name}</option>
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="department_id"
                            id="class"
                            value={user.department_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {departmentOpt
                              ?.filter((s) => s.college_id == collegeid)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name},{" "}
                                  {
                                    programOpt.find(
                                      (item) => item.id == i.program_id
                                    )?.name
                                  }
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="month">
                            Month <span className="text-danger">*</span>
                          </label>
                          <select name="month1" id="month1" required="" className="form-control" value={user?.month1} onChange={handleChange}>
                            <option value="">Select Month</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                          </select>
                        </div>
                      </div> */}
                      {/* <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="year">
                            Year <span className="text-danger">*</span>
                          </label>
                          <select name="year1" id="year1" required="" className="form-control" value={user?.year1} onChange={handleChange}>
                            <option value="">Select Year</option>
                            {sessionOpt &&
                              sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>{item?.name.substring(0,4)}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            From Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            name="from_date"
                            onChange={handleChange}
                            className="form-control"
                            value={user?.from_date}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="">
                            To Date <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="date"
                            disabled={!user?.from_date}
                            min={user?.from_date}
                            name="to_date"
                            onChange={handleChange}
                            className="form-control"
                            value={user?.to_date}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 my-4">
                        <button
                          className="btn btn-nex"
                          name="submit"
                          type="submit"
                          value="staff"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <br />
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                      <div></div>
                      <div>
                        <button
                          className="btn btn-nex rounded-pill mr-2"
                          onClick={onDownload}
                        >
                          Excel
                        </button>
                        <button
                          className="btn btn-nex rounded-pill"
                          onClick={handlePrint}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive fixTableHead">
                          <table
                            className="table table-bordered"
                            ref={tableRef}
                          >
                            {/* <thead> */}
                            <tr>
                              <th style={{ display: "none" }} colSpan={7}>
                                KALOL INSTITUTE AND RESEARCH CENTER
                              </th>
                            </tr>
                            {/* </thead> */}
                            <thead>
                              <tr>
                                <th
                                  className="text-uppercase"
                                  colSpan={currMonthDays ? 2 : 1}
                                >
                                  Employees - {data ? data.length : 0}
                                </th>
                                <th
                                  className="text-uppercase"
                                  colSpan={currMonthDays ? 5 : 1}
                                >
                                  FROM : {formatDate(user?.from_date)}
                                </th>
                                <th
                                  className="text-uppercase"
                                  colSpan={
                                    currMonthDays ? currMonthDays - 1 : 1
                                  }
                                >
                                  TO : {formatDate(user?.to_date)}
                                </th>
                              </tr>
                              <tr>
                                <th>Sl.No</th>
                                <th>Name</th>
                                {currMonthDays ? (
                                  <>
                                    {dateArray.map((date, index) => (
                                      <th>{date?.split("/")[0]}</th>
                                    ))}
                                    <th>P</th>
                                    <th>A</th>
                                    <th>WO</th>
                                    <th>H</th>
                                  </>
                                ) : (
                                  <>
                                    <th></th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {displayData && displayData.length ? (
                                displayData?.map((employee, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {employee?.first_name}{" "}
                                        {employee?.last_name}
                                      </td>
                                      {dateArray.map((date, index) => (
                                        <td
                                          key={index}
                                          className={`text-sm text-${
                                            employee?.attendance?.find(
                                              (s) => s.date === date
                                            )?.status === "P"
                                              ? "success"
                                              : employee?.attendance?.find(
                                                  (s) => s.date === date
                                                )?.status === "A"
                                              ? "danger"
                                              : employee?.attendance?.find(
                                                  (s) => s.date === date
                                                )?.status === "H"
                                              ? "primary"
                                              : "secondary"
                                          }`}
                                        >
                                          {employee?.attendance?.find(
                                            (s) => s.date === date
                                          )?.status || "-"}
                                        </td>
                                      ))}
                                      <td>
                                        <b>{employee?.p}</b>
                                      </td>
                                      <td>
                                        <b>{employee?.a}</b>
                                      </td>
                                      <td>
                                        <b>{employee?.wo}</b>
                                      </td>
                                      <td>
                                        <b>{employee?.h}</b>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    colSpan={
                                      currMonthDays ? currMonthDays + 6 : 3
                                    }
                                  >
                                    <NoData />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceTimeReport;
