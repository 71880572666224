import React, { useState, useEffect } from "react";
import Toggler from "../../../Components/Toggler/Toggler";
import { LEAVE_TYPE_PRIORITY } from "../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../../Components/Loader/Loader";
import { Button, Input, Select } from "antd";
const { TextArea } = Input;

function ModalLeavePriority({leaveTypes, type, edit, setLoading, collegeId, getLeavePriorities }) {

  const [leaves, setLeaves] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if(!user?.title) {
      return toast.error("Please enter title");
    }

    const config = {
      method: type === "edit" ? "put" : "post",
      url: type === "edit" ? `${LEAVE_TYPE_PRIORITY}/${user?.id}` : `${LEAVE_TYPE_PRIORITY}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        ...user, 
        college_id: collegeId
      }
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success(res.data.message);
        getLeavePriorities();
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error(err.response.data.message);
      });
  }

  const clearData = () => {
    setUser({});
  }

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string, value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser(edit);
  },[edit]);

  useEffect(() => {
    if (user) {
      const selectedLeaveIds = [];

      for (let i = 1; i <= 6; i++) {
        const priority = user[`priority_${i}`];
        if (priority) {
          selectedLeaveIds.push(priority);
        }
      }
      let tempData = leaveTypes.filter((leave) => !selectedLeaveIds.includes(leave.id));

      let arr = [{value: "", label: "Select Priority"}];
      for(let i of tempData) {
        arr.push({value: i?.id, label: i?.id});
      }
      console.log(arr);
      setLeaves(arr);
    }
  }, [user, leaveTypes]);

  return (
    <div className="ModalLeavePriority">
      <div
        className="modal fade"
        id="ModalLeavePriority"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Leave Type Priority
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <label>
                      Title <span style={{ color: "red" }}>*</span>
                    </label>
                    <br />
                    <Input
                      type="text"
                      name="title"
                      value={user?.title}
                      onChange={handleChange}
                    />
                </div>
                <div className="col-md-12 mt-3">
                    <label>
                      Description <span style={{ color: "red" }}>*</span>
                    </label>
                    <TextArea
                      type="text"
                      name="description"
                      value={user?.description}
                      onChange={handleChange}
                    />
                </div>
                <div className="col-md-12 mt-4">
                  <label>Priority 1 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 1"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_1: value });
                    }}
                    value={user?.priority_1}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div>
                <br />
                <div className="col-md-12 mt-4">
                  <label>Priority 2 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 2"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_2: value });
                    }}
                    value={user?.priority_2}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="col-md-12 mt-4">
                  <label>Priority 3 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 3"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_3: value });
                    }}
                    value={user?.priority_3}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="col-md-12 mt-4">
                  <label>Priority 4 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 4"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_4: value });
                    }}
                    value={user?.priority_4}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="col-md-12 mt-4">
                  <label>Priority 5 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 5"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_5: value });
                    }}
                    value={user?.priority_5}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div>
                <div className="col-md-12 mt-4">
                  <label>Priority 6 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 6"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_6: value });
                    }}
                    value={user?.priority_6}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div>
                {/* <div className="col-md-12 mt-4">
                  <label>Priority 7 : </label>
                  <Select
                    className="ml-2"
                    showSearch
                    placeholder="Select Priority 7"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setUser({ ...user, priority_7: value });
                    }}
                    value={user?.priority_7}
                    filterOption={filterOption}
                    options={leaves}
                    style={{ width: "40%" }}
                  />
                </div> */}
              </div>
              <div className="row d-flex justify-content-between px-2 mt-3">
                <Button
                  type="primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ background: 'red', borderColor: 'red' }}
                  onClick={clearData}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ background: 'green', borderColor: 'green' }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalLeavePriority;
