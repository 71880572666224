import React from "react";
import "./Dashboard.scss";
import ReactApexChart from "react-apexcharts";
import Calender from "../../Components/Calender/Calender";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  DASHBOARD_HR1,
  DASHBOARD_HR2,
  LEAVE_APPLICATION,
} from "../../utils/apiConstants";
import { DASHBOARD_REGISTRAR } from "../../utils/apiConstants";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";
import { LOCAL_COLLEGE } from "../../utils/LocalStorageConstants";
import { REPORT_STAFF_ATTENDANCE } from "../../utils/Reports.apiConst";
import { EMPLOYEE_BIO_IDS } from "../../utils/apiConstants";

function DashboardRegistrar({ setLoading, collegeId }) {
  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  //States
  const [data, setData] = useState();
  const [pending, setPending] = useState(0);
  const [upcoming, setUpcoming] = useState(0);

  const [data1, setData1] = useState();
  const [data2, setData2] = useState();

  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [clgPresent, setClgPresent] = useState(0);
  const [clgAbsent, setClgAbsent] = useState(0);

  const [collegeData, setCollegeData] = useState(getCollegeData());

  console.log(data?.college_staff?.map((s) => s?.count));
  console.log(data?.college_staff?.map((s) => String(s.college_id)));

  //Get Data
  const getData = async () => {
    let count = 0,
      count1 = 0;
    if (!collegeId || collegeId == undefined) return;
    setLoading(1);
    const config = {
      method: "get",
      url:
        DASHBOARD_REGISTRAR +
        "?role=" +
        sessionStorage.getItem(SESSION_ROLE) +
        "&college_id=" +
        collegeId,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        console.log("clg data - ", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });

    const config1 = {
      url: LEAVE_APPLICATION,
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const date = new Date();

    await axios(config1)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        try {
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].status == "PENDING") {
              count++;
            }
            const dateComp = new Date(res.data.data[i].from_date.split("T")[0]);
            if (
              date.getTime() < dateComp.getTime() &&
              res.data.data[i].status == "APPROVED"
            ) {
              count1++;
            }
          }
          setPending(count);
          setUpcoming(count1);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getData1 = () => {
    setLoading(1);
    const config = {
      method: "get",
      // url: DASHBOARD_HR + "?role=" + sessionStorage.getItem(SESSION_ROLE),
      url: DASHBOARD_HR1,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setData1(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getData2 = () => {
    setLoading(1);
    const config = {
      method: "get",
      url: DASHBOARD_HR2,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setData2(res.data.data);
        console.log("data2", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getEmpAttendance = async () => {
    setLoading(1);
    const user = {
      from_date: getCurrentDate(),
      to_date: getCurrentDate(),
    };

    let dateParts = user.from_date.split("-");
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];
    let from_date = `${day}/${month}/${year}`;
    let to_date = from_date;
    const config1 = {
      method: "get",
      url: `${EMPLOYEE_BIO_IDS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        let bioIds = res.data.data;
        console.log("all emp ids - ", bioIds);
        let bioIds2;

        const config = {
          method: "get",
          url: `${REPORT_STAFF_ATTENDANCE}?from_date=${from_date}&to_date=${to_date}`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        const config2 = {
          method: "get",
          url: `${EMPLOYEE_BIO_IDS}?college_id=${collegeId}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config2)
          .then((res) => {
            setLoading(0);
            bioIds2 = res.data.data;
            console.log("clg emp ids - ", bioIds2);
          })
          .catch((err) => {
            setLoading(0);
            toast.error("Something went wrong");
            console.log(err);
          });

        await axios(config)
          .then((res) => {
            console.log("attendance data length - ", res.data.data.length);
            let countsByEmpcode = res.data.data;
            let countsByEmpcode2 = countsByEmpcode;
            countsByEmpcode = countsByEmpcode.filter(
              (s) => s?.Empcode && bioIds.includes(s.Empcode)
            );

            let p = 0,
              a = 0;
            countsByEmpcode.map((i, k) => {
              if (i.Status == "P") {
                p += 1;
              } else {
                a += 1;
              }
            });
            setTotalPresent(p);
            setTotalAbsent(a);
            countsByEmpcode2 = res.data.data;
            countsByEmpcode2 = countsByEmpcode2.filter(
              (s) => s?.Empcode && bioIds2?.includes(s.Empcode)
            );

            console.log("countsByEmpcode2 -", countsByEmpcode2);

            let p2 = 0,
              a2 = 0;
            countsByEmpcode2.map((i, k) => {
              if (i.Status == "P") {
                p2 += 1;
              } else {
                a2 += 1;
              }
            });
            setClgPresent(p2);
            setClgAbsent(a2);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  console.log("clgPresent -", clgPresent);

  useEffect(() => {
    getData1();
    getData2();
    getEmpAttendance();
  }, []);

  const overviewChartOptions = {
    series: [
      {
        name: "Students",
        data: data?.studentsCount?.map((s) => s.total) || [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },

      xaxis: {
        categories: data?.studentsCount?.map((s) => s.college_code),
        position: "top",
        display: false,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    },
  };

  const overviewChartOptions1 = {
    series: [
      {
        name: "Active",
        data: data?.studentsCount?.map((s) => s.total) || [],
        color: "#28a745",
      },
      {
        name: "Inactive",
        data: data?.studentsInactive?.map((s) => s.total) || [],
        color: "#ff0000",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 300,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: -1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: data?.studentsCount?.map((s) => s.college_name),
      },
    },
  };

  //Chart Data 2
  const overviewChartOptions2 = {
    series: [
      {
        name: "Male",
        data: data?.studentsCount?.map((s) => s.male),
      },
      {
        name: "Female",
        data: data?.studentsCount?.map((s) => s.female),
      },
      // {
      //     name: 'Not Updated',
      //     data: data?.studentsCount?.map(s => s.other)
      // }
    ],
    // options: {
    //     chart: {
    //         type: 'bar',
    //         height: 430
    //     },
    //     plotOptions: {
    //         bar: {
    //             horizontal: true,
    //             dataLabels: {
    //                 position: 'top',
    //             },
    //         }
    //     },
    //     dataLabels: {
    //         enabled: true,
    //         offsetX: -6,
    //         style: {
    //             fontSize: '12px',
    //             colors: ['#fff']
    //         }
    //     },
    //     stroke: {
    //         show: true,
    //         width: -2,
    //         colors: ['#fff']
    //     },
    //     tooltip: {
    //         shared: true,
    //         intersect: false
    //     },
    //     xaxis: {
    //         categories: data?.studentsCount?.map(s => s.college_name),
    //     },
    // },
    options: {
      chart: {
        type: "bar",
        height: 300,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: -1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: data?.studentsCount?.map((s) => s.college_name),
      },
    },
  };

  useEffect(() => {
    if (collegeId) {
      getData();
      getEmpAttendance();
    }
  }, [sessionStorage.getItem(SESSION_ROLE), collegeId]);

  useState(() => {
    setCollegeData(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    console.log("clg present - ", clgPresent);
  }, [clgPresent]);

  return (
    <div className="DashboardHR">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    Hello , {sessionStorage.getItem(SESSION_ROLE)}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-3 mb-2">
              <h6>Employee Overview</h6>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Staff
                          </p>
                          <h4 className="mb-0">
                            {collegeId == 1111001
                              ? data?.number_of_staff - 1 || 0
                              : data?.number_of_staff || 0}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Today Present Staff
                          </p>
                          <h4 className="mb-0">{clgPresent}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-heart-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {data2 &&
                              data2.filter(
                                (employee) =>
                                  employee.employee_category ==
                                    "TEACHING STAFF" &&
                                  employee.college_id ==
                                    sessionStorage.getItem("college_id")
                              ).length}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-2-fill font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Non Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {collegeId == 1111001
                              ? data2 &&
                                data2.filter(
                                  (employee) =>
                                    employee.employee_category ==
                                      "NON-TEACHING STAFF" &&
                                    employee.college_id ==
                                      sessionStorage.getItem("college_id")
                                ).length - 1
                              : data2 &&
                                data2.filter(
                                  (employee) =>
                                    employee.employee_category ==
                                      "NON-TEACHING STAFF" &&
                                    employee.college_id ==
                                      sessionStorage.getItem("college_id")
                                ).length}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-follow-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2"> Overall Employee</p>
                          <h4 className="mb-0">
                            {/* {data1?.number_of_staff-1 || 0} */}
                            {data2?.length - 1}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2">
                            {" "}
                            Overall Today Present Staff
                          </p>
                          <h4 className="mb-0">{totalPresent}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-heart-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2">
                            {" "}
                            Overall Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {data2 &&
                              data2.filter(
                                (employee) =>
                                  employee.employee_category == "TEACHING STAFF"
                              ).length}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-2-fill font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2">
                            {" "}
                            Overall Non Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {data2 &&
                              data2.filter(
                                (employee) =>
                                  employee.employee_category ==
                                  "NON-TEACHING STAFF"
                              ).length - 1}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-follow-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row px-3 mb-2">
                  <h6>Employee Leave Overview</h6>
                </div>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="card ">
                        <div className="card-body ">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Pending Leave Approvals
                          </p>
                          <div className="row">
                            <h4 className="pl-3">{pending}</h4>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="card ">
                        <div className="card-body ">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Upcoming Employee on Leaves
                          </p>
                          <div className="row">
                            <h4 className="pl-3">{upcoming}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-3 mb-2 mt-3  ">
              <h6>Overall University Fees Overview</h6>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Today Fee Collection
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.today
                              ? String(data?.fee?.today)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Yesterday Fee Collection
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.yesterday
                              ? String(data?.fee?.yesterday)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Fee Collected in last 7 days
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.past10
                              ? String(data?.fee?.past10)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Fee Collected in last 30 days
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.past30
                              ? String(data?.fee?.past30)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-3 mb-2 mt-3  ">
              <h6>
                {collegeData?.find((s) => s.id == collegeId)?.name} Fees
                Overview
              </h6>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Today Fee Collection
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.todayCol
                              ? String(data?.fee?.todayCol)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Yesterday Fee Collection
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.yesterdayCol
                              ? String(data?.fee?.yesterdayCol)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Fee Collected in last 7 days
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.past10Col
                              ? String(data?.fee?.past10Col)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Fee Collected in last 30 days
                          </p>
                          <h4 className="mb-0">
                            ₹{" "}
                            {data?.fee?.past30Col
                              ? String(data?.fee?.past30Col)?.replace(
                                  /(\d)(?=(\d\d)+\d$)/g,
                                  "$1,"
                                )
                              : "0"}
                          </h4>
                        </div>
                        <div className="text-primary font-size-24">₹</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 row d-flex">
                  <div className="col-12">
                    <div className="card ">
                      <div className="card-body p-5">
                        <h4 className="card-title mb-4">Student Overview</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions1.options}
                            series={overviewChartOptions1.series}
                            type="bar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card ">
                      <div className="card-body">
                        <h4 className="card-title mb-4">Male Female Ratio</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions2.options}
                            series={overviewChartOptions2.series}
                            type="bar"
                            height={500}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="card p-3 w-100">
                    <h3 className="my-3">Event Calendar</h3>
                    <Calender setLoading={setLoading} collegeId={collegeId} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardRegistrar;
