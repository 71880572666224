import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SESSION_ROLE } from '../utils/sessionStorageContants';
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT } from '../utils/LocalStorageConstants';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SECTION, ACADEMICS_ADD_SEMESTER } from '../utils/Academics.apiConst';
import { EMPLOYEE_ALL, STUDENT_ADMISSION, STUDENT_ADVANCE_PAY, STUDENT_DETAILS, STUDENT_DETAILS1, STUDENT_SESSION_UPDATE } from '../utils/apiConstants';
import { STUDENT_GATE_PASS_PUT1, STUDENT_GATE_PASS_UPLOAD, STUDENT_INFORM_GET, STUDENT_SESSION_GET } from '../utils/InfoUploadingApiConstants';
import { HOSTEL, HOSTEL_ROOMS } from '../utils/Hostel.apiConst';
import { ADDHOSTELFEE } from '../utils/fees.apiConst';
import { format } from 'prettier';
import { ROUTES } from '../Router/routerConfig';


function HostelModalStudentGatePass({data, getData, setLoading, flag, setFlag}) {

    let role = sessionStorage.getItem(SESSION_ROLE);
    const collegeId = sessionStorage.getItem("college_id");
    const locate = useLocation();
  
    const [adv, setAdv] = useState([]);
    // const [data, setData] = useState([]);  
    var emp_id = sessionStorage.getItem("employee_id");  
    const navigate = useNavigate();  
    const [x, setX] = useState(false); 
    const getCollegeData = () => {
      return localStorage.getItem(LOCAL_COLLEGE)
        ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
        : null;
    };  
    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());  
    const [department, setDepartment] = useState(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
    ); 
    useEffect(() => {
      setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
    }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);
  

    const [classOpt, setClassOpt] = useState([]);  
    const [sectionOpt, setSectionOpt] = useState([]); 
    const [semesterOpt, setSemesterOpt] = useState([]); 
    const [faculty, setFaculty] = useState("");  
    const [currentclass, setCurrentClass] = useState("");  
    const [currentSemester, setCurrentSemester] = useState("");  
    const [currentSection, setCurrentSection] = useState("");  
    const [session, setSession] = useState("");  
    const [sessions, setSessions] = useState("");
    const [hostel, setHostel] = useState("");
    const [room, setRoom] = useState("");  
    const [employee, setEmployee] = useState("");  
    const [emp, setEmp] = useState("");  
    const [flag1, setFlag1] = useState(false);
    const [hostelFeeData, setHostelFeeData] = useState([]);
    const [studentfloor, setStudentFloor] = useState("");
    const [studentData, setStudentData] = useState([]);

  
    const getAllData = async () => {
      const config = {
        method: "get",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      };
  
      setLoading(1);
      const [data1, data2, data3, data4] = await Promise.all([
        await axios({
          ...config,
          url:
            emp_id != 316
              ? `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`
              : `${ACADEMICS_ADD_CLASS}`,
        })
          .then((res) => {
            setClassOpt(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          }),
  
        await axios({
          ...config,
          url:
            emp_id != 316
              ? `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`
              : `${ACADEMICS_ADD_SEMESTER}`,
        })
          .then((res) => {
            setSemesterOpt(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          }),
  
        await axios({
          ...config,
          url:
            emp_id != 316
              ? `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`
              : `${ACADEMICS_ADD_SECTION}`,
        })
          .then((res) => {
            setSectionOpt(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          }),
  
        await axios({
          ...config,
          url: STUDENT_ADVANCE_PAY,
        })
          .then((res) => {
            setLoading(0);
            setAdv(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          }),
      ]);
      const config1 = {
          method: "get",
          url: EMPLOYEE_ALL,
          headers: {
            "Content-Type": "application/json",
          },
        };
    
        await axios(config1)
          .then((res) => {
            setEmployee(res.data.data);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
  
          const config2 = {
              method: "get",
              url: STUDENT_SESSION_GET,
              headers: {
                "Content-Type": "application/json",
              },
            };
        
            await axios(config2)
              .then((res) => {
                setSessions(res.data.data);
                // console.log("session data -",res.data.data);
                setLoading(0);
              })
              .catch((err) => {
                setLoading(0);
                console.log(err);
              });
  
              const config3 = {
                  method: "get",
                  url: HOSTEL,
                  headers: {
                    "Content-Type": "application/json",
                  },
                };
            
                await axios(config3)
                  .then((res) => {
                    setHostel(res.data.data);
                    // console.log("hostel data -",res.data.data);
                    setLoading(0);
                  })
                  .catch((err) => {
                    setLoading(0);
                    console.log(err);
                  }); 
                  
                  const config4 = {
                      method: "get",
                      url: `${HOSTEL_ROOMS}?status=OCCUPIED`,
                      headers: {
                        "Content-Type": "application/json",
                      },
                    };
                
                    await axios(config4)
                      .then((res) => {
                        setRoom(res.data.data);
                        // console.log("room data -",res.data.data);
                        setLoading(0);
                      })
                      .catch((err) => {
                        setLoading(0);
                        console.log(err);
                      });
  
    };   
  
    useEffect(() => {
      if (locate?.state) {
        setCurrentClass(locate.state?.class);
        setCurrentSection(locate.state?.section);
        setCurrentSemester(locate.state?.sem);
        setSession(locate.state?.session);
        setFaculty(locate.state?.depart);
      }
    }, [locate?.state]);

    const handleChange = async (sid, h, t) => {
        setX(true);
        const config = {
          method: "put",
          url: `${STUDENT_SESSION_UPDATE}/${sid}`,
          data: {
            hostel: h,
            transport: t,
            session_id: session,
          },
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        await axios(config)
          .then((res) => {
            // console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const userId = data.student_id;
      // console.log("userid -",userId);/

      const getStudentData = async () => {
        setLoading(1);
      
        const config = {
          method: "get",
          url: `${STUDENT_ADMISSION}/${userId}`,
          data: {
            user_id: userId,
          },
          headers: {
            Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
      
        const config1 = {
          method: "get",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
      
        try {
          const res = await axios(config);
          // console.log("student data - ", data);
          let temp = res?.data;
          
          // Filter only "ACTIVE" sessions
          let activeSession = temp?.session?.filter((s) => s?.status === "ACTIVE");
      
          // Check if there are any active sessions
          if (activeSession.length > 0) {
            setFaculty(res?.data?.college_id);
            setCurrentClass(activeSession[0]?.class_id);
            setSession(activeSession[0]?.session_id);
            let obj = {};
            // obj["id"] = temp?.data?.id;
            obj["id"] =
              temp?.semester[temp?.semester.length - 1]?.student_session_id;
            obj["class_name"] = temp?.session[temp?.session.length - 1]?.class_name;
            obj["department_id"] = temp?.data?.department_id;
            obj["class_id"] = activeSession[0]?.class_id;
            obj["session_id"] = activeSession[0]?.session_id;
            obj["semester_id"] =
              temp?.semester[temp?.semester.length - 1]?.semester_name;
            obj["section_id"] =
              temp?.semester[temp?.semester.length - 1]?.section_id;
            obj["user_id"] = temp?.data?.user_id;
            obj["name"] = temp?.data?.name;
            obj["email"] = temp?.data?.email;
            obj["phone"] = temp?.data?.phone;
            obj["dob"] = temp?.data?.dob?.substring(0, 10);
            obj["gender"] = temp?.data?.gender;
            obj["category"] = temp?.data?.category;
            obj["is_hostel"] = activeSession[0]?.is_hostel;
            obj["is_transport"] = activeSession[0]?.is_transport;
            obj["status"] = temp?.data?.status;
            obj["college_name"] = collegeOpt.find(
              (s) => s.id == temp?.data?.college_id
            ).name;
            obj["department_name"] = department.find(
              (s) => s.id == temp?.data?.department_id
            )?.name;
            // console.log("obj - ", obj);
            setStudentData(obj);
            let targetDiv = document.getElementById("datatable");
            targetDiv.scrollIntoView({ behavior: "smooth" });
          } else {
            // Handle the case when there are no active sessions
            // You might want to set default values or display a message
            // console.log("No active sessions found");
          }
      
          setLoading(0);
        } catch (err) {
          setLoading(0);
          console.log(err);
        }
      
        try {
          const res = await axios({
            ...config1,
            url: STUDENT_ADVANCE_PAY,
          });
          // console.log("adv - ", res.data.data);
          setAdv(res.data.data);
        } catch (err) {
          console.log(err);
        }
      };

      useEffect(() => {
        // console.log("userId:", userId);
        getStudentData();
      },[userId]);

      // console.log("studentId",studentId);
  
    
    useEffect(() => {
      getAllData();
    }, []);
  
    useEffect(() => {
        // Fetch hostel fee data
        const fetchHostelFeeData = async () => {
          const config = {
            method: "get",
            url: ADDHOSTELFEE,
            headers: {
              "Content-Type": "application/json",
            },
          };
      
          try {
            const response = await axios(config);       
            setHostelFeeData(response.data.data);
            // console.log("hostelfeedata -",response.data.data);
          } catch (error) {
            console.error(error);
          }
        };
      
        fetchHostelFeeData();
      }, []);
  
    const [user, setUser] = useState({
      purpose_type: data?.purpose_type || "",
      out_from_date: data?.out_from_date || "",
      out_to_date: data?.out_to_date || "",
      no_of_days: data?. no_of_days || "",
      out_going_purpose: data?.out_going_purpose || "",
      request_remark: data?.request_remark || "",
      approved_by_1: data?.approved_by_1 || "",
      approved_date_1: data?.approved_date_1 || "",
      approval_status_1: data?.approval_status_1 || "",
      approved_by_2: data?.approved_by_2 || "",
      approved_date_2: data?.approved_date_2 || "",
      approval_status_2: data?.approval_status_2 || "",
    });

    // Update state when data prop changes
   useEffect(() => {
    setUser({
        purpose_type: data?.purpose_type || "",
        out_from_date: data?.out_from_date || "",
        out_to_date: data?.out_to_date || "",
        no_of_days: data?. no_of_days || "",
        out_going_purpose: data?.out_going_purpose || "",
        request_remark: data?.request_remark || "",
        approved_by_1: data?.approved_by_1 || "",
        approved_date_1: data?.approved_date_1 || "",
        approval_status_1: data?.approval_status_1 || "",
        approved_by_2: data?.approved_by_2 || "",
        approved_date_2: data?.approved_date_2 || "",
        approval_status_2: data?.approval_status_2 || "",
    });
  }, [data]);

  // console.log("data --",data);

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
    const clearData = () => {
      setUser({
      purpose_type: "",
      out_from_date: "",
      out_to_date: "",
      no_of_days:"",
      out_going_purpose: "",
      request_remark: "",
      approved_by_1: "",
      approved_date_1: "",
      approval_status_1: "",
      approved_by_2: "",
      approved_date_2: "",
      approval_status_2: "",
      });
      data.purpose_type = ""
      data.out_from_date = ""
      data.out_to_date = ""
      data.no_of_days = ""
      data.out_going_purpose = ""
      data.request_remark = ""
      data.approved_by_1 = ""
      data.approved_date_1 = ""
      data.approval_status_1 = ""
      data.approved_by_2 = ""
      data.approved_date_2 = ""
      data.approval_status_2 = ""
    };
  
    const employee_id = sessionStorage.getItem("employee_id");

    // console.log("data.student_id -",data.student_id);
  
    const handleSubmit = async () => {
        setLoading(1);
    
        const dataToSend = {
          ...user,
          no_of_days: days,
        };
    
        const config = {
          method: "put",
          url: `${STUDENT_GATE_PASS_PUT1}/${data.id}`,
          headers: {
            "Content-Type": "application/json",
            //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
          },
          data: dataToSend,
        };
    
        axios(config)
          .then((res) => {
            toast.success("Succesfully Uploaded Details");
            clearData();
            setFlag((flag)=>!flag)
            getData()
          })
          .catch((err) => {
            console.log(err);
          });
    
        setLoading(0);
      };
  
    const calculateDays = () => {
      const fromDate = new Date(user.out_from_date);
      const toDate = new Date(user.out_to_date);
      if (!user.out_from_date || !user.out_to_date || fromDate > toDate) {
        return 0;
      }
      fromDate.setHours(0, 0, 0, 0);
      toDate.setHours(23, 59, 59, 999);
      const timeDifference = toDate.getTime() - fromDate.getTime();
      const daysDifference = timeDifference / (1000 * 3600 * 24);
      const numberOfDays = Math.round(daysDifference);    
      return numberOfDays >= 0 ? numberOfDays : 0;
    };
  
    const days = calculateDays();

    const role1 = sessionStorage.getItem("role");

    console.log("studentdata.user_id",studentData.user_id);  

    const statusMessage = () => {
        if (role1 == "WARDEN") {
          if (data.approval_status_1 == "CANCELLED") {
            return "STUDENT IS " + data.approval_status_1 + " THE APPLICATION";
          } else if (data.approval_status_1 == "PENDING") {
            return "PROCTOR APPROVAL IS " + data.approval_status_1;
          } else if (data.approval_status_1 == "DECLINED") {
            return "PROCTOR " + data.approval_status_1 + " THE APPROVAL";
          }
        }
        return "student is Approved By Proctor and Hostel Warden";
      };

  return (
    <div className="StudentGatePass">

        <div
          className="modal fade"
          id="HostelStudentGatePass"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true" 
        >
            <div
              className="modal-dialog modal-dialog-centered mw-100 w-75"
              role="document"
            >
                <div className="modal-content">

                <div className="modal-body">
                    <br/>                    

                    {/* { role1 == "WARDEN" && data.approval_status_1 == "APPROVED" && data.approval_status_2 != "APPROVED"
                      ?                        */}
                    <div className="row">
                        <div className="col-12">
                            <div className="modal-header">
                                <h5 className="text-primary">EDIT STUDENT GATE PASS</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                      <span aria-hidden="true" className="mr-4">×</span>
                                    </button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    
                                <br />

                                <div className="table-responsive">
                                 <h6 className="ml-2 text-primary">STUDENT DETAILS</h6>
                                <table
                                  id="datatable"
                                  className="table table-bordered  nowrap table-hover"
                                  style={{
                                    borderSpacing: 0,
                                    width: "100%",
                                  }}
                                >
                                    <thead>
                                    <br/>
                                      <tr>
                                        <th>Enrollment No </th>
                                        <td>{studentData?.user_id}</td>
                                        <th>Name </th>
                                        <td>{studentData?.name}</td>                                     
                                        <th>Faculty </th>
                                        <td>{studentData?.college_name}</td>
                                      </tr>
                                      <tr>  
                                        <th>Department </th>
                                        <td>{studentData?.department_name}</td>
                                        <th>Class </th>
                                        <td>{studentData?.class_name}</td>    
                                        <th>Semester </th>
                                        <td>
                                          {studentData?.semester_id}
                                        </td> 
                                      </tr>
                                      <tr>
                                        <th>Session </th>
                                        <td>
                                          {studentData?.session_id}
                                        </td>
                                        <th>Hostel </th>
                                        <td>
                                          {hostelFeeData
                                            .filter((fee) => fee.student_id == studentData.id)
                                            .map((fee) => {
                                              const matchingRoom = room?.find((r) => {
                                                return r.hostelRooms.hostel_no_bed == fee.bed_no;
                                            });
                                            return matchingRoom ? matchingRoom.hostel.hostel_name : null;
                                          })}
                                        </td>
                                        <th>Floor No </th>
                                        <td>
                                          {hostelFeeData
                                            .filter((fee) => fee.student_id == studentData.id)
                                            .map((fee) => {     
                                              const matchingRoom = room?.find((r) => { 
                                                return r.hostelRooms.hostel_no_bed == fee.bed_no;
                                              });
                                              return matchingRoom ? matchingRoom.hostelRooms.floor_number : null;
                                          })}
                                        </td>                                        
                                      </tr>
                                      <tr>
                                        <th>Room Type </th>
                                        <td>
                                          {hostelFeeData
                                            .filter((fee) => fee.student_id == studentData.id)
                                            .map((fee) => {
                                              const matchingRoom = room?.find((r) => {
                                                return r.hostelRooms.hostel_no_bed == fee.bed_no;
                                              });
                                              return matchingRoom ? matchingRoom.roomType.room_type : null;
                                          })}
                                        </td>  
                                        <th>Room No </th>
                                        <td>
                                          {hostelFeeData
                                            .filter((fee) => fee.student_id == studentData.id)
                                            .map((fee) => fee.room_id)
                                          }
                                        </td>
                                        <th>Bed No</th>
                                        <td>
                                          {hostelFeeData
                                            .filter((fee) => fee.student_id == studentData.id)
                                            .map((fee) => fee.bed_no)
                                          } 
                                        </td>
                                      </tr>
                                      <tr>
                                        <th><p className="mt-3">status</p></th>
                                        <td> 
                                          <p className={`mt-3 badge ${data?.approval_status_1 == 'APPROVED' ? 'badge-soft-success' : 
                                          data?.approval_status_1 == 'PENDING' ? 'badge-soft-info' :
                                          data?.approval_status_1 == 'DECLINED' ? 'badge-soft-warning' : 
                                          data?.approval_status_1 == 'CANCELLED' ? 'badge-soft-danger' : ''}`}
                                          style={{
                                            width: '90px',
                                            height: '30px', 
                                            padding: '10px', 
                                            textAlign: 'center', 
                                            lineHeight: '1.5', 
                                          }}
                                          >
                                          {data?.approval_status_1}
                                          </p>
                                        </td>
                                      </tr>
                                    </thead>
                                </table>
                                </div>

                                </div>
                            </div>
                        </div>
                        { role1 == "WARDEN" && data.approval_status_1 == "APPROVED" && data.approval_status_2 != "APPROVED"
                         ?            
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">

                                    <h2 className="card-title text-info"><b>Request Detail</b></h2>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Out Going Purpose Type
                                                </label>
                                                <select
                                                    name="purpose_type"
                                                    id="class"
                                                    className="form-control"
                                                    value={user?.purpose_type ? user?.purpose_type : data.purpose_type}
                                                    // onChange={handleChange1}
                                                    onChange={(e) => {
                                                        handleChange1(e);
                                                    }}
                                                >
                                                    <option value="">Select Purpose Type</option>
                                                    <option value="LEAVE">Leave</option>
                                                    <option value="GENERAL">General</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Out Going Purpose
                                                </label>
                                                <input
                                                    type="text"
                                                    name="out_going_purpose"
                                                    className="form-control"
                                                    placeholder="Enter Out Going Purpose"
                                                    value={user?.out_going_purpose ? user?.out_going_purpose : data.out_going_purpose}
                                                    // onChange={handleChange1}
                                                    onChange={(e) => {
                                                        handleChange1(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Request Remark
                                                </label>
                                                <input
                                                    type="text"
                                                    name="request_remark"
                                                    className="form-control"
                                                    placeholder="Enter Request Remark"
                                                    value={user?.request_remark ? user?.request_remark : data.request_remark}
                                                    // onChange={handleChange1}
                                                    onChange={(e) => {
                                                        handleChange1(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Out Going Period
                                                </label>
                                                <div className="row">
                                                  <div className="col-md-5">
                                                    <label htmlFor="validationCustom02">
                                                      From Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="out_from_date"
                                                        className="form-control"
                                                        placeholder="From Date"
                                                        value={user?.out_from_date ? user?.out_from_date : data.out_from_date}
                                                        // onChange={handleChange1}
                                                        onChange={(e) => {
                                                            handleChange1(e);
                                                        }}
                                                    />
                                                  </div>
                                                  <div className="col-md-5">
                                                    <label htmlFor="validationCustom02">
                                                      To Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        name="out_to_date"
                                                        className="form-control"
                                                        placeholder="To Date"
                                                        value={user?.out_to_date ? user?.out_to_date : data.out_to_date}
                                                        // onChange={handleChange1}
                                                        onChange={(e) => {
                                                            handleChange1(e);
                                                        }}
                                                    />
                                                  </div>
                                                  <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label htmlFor="validationCustom02">No of Days</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={calculateDays()}
                                                            readOnly
                                                        />
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3"></div>    
                                    </div>
                                    <hr/>
                                    <h2 className="card-title text-info"><b>Approval Detail</b></h2>
                                    <br/>
                                   
                                    <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="validationCustom02">
                                              Approved By
                                            </label>
                                            <select
                                              name="approved_by_2"
                                              id="faculty"
                                              className="form-control"
                                              value={user?.approved_by_2 ? user?.approved_by_2 : data.approved_by_2}
                                              onChange={(e) => {
                                                setUser((prevUser) => ({
                                                  ...prevUser,
                                                  approved_by_2: e.target.value,
                                                }));
                                              }}
                                            >
                                            <option value="">Select</option>
                                              {employee &&
                                                employee
                                                ?.filter((s) => s.college_id == collegeId)
                                                ?.map((i, key) => (
                                                  <option value={i.id} key={key}>
                                                    {i.first_name + i?.last_name}
                                                  </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="validationCustom02">
                                              Approved Date
                                            </label>
                                            <input
                                                type="date"
                                                name="approved_date_2"
                                                className="form-control"
                                                placeholder="Request Date"
                                                value={user?.approved_date_2 ? user?.approved_date_2 : data.approved_date_2}
                                                onChange={(e) => {
                                                    handleChange1(e);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="validationCustom02">
                                              Approval Status
                                            </label>
                                            <select
                                                name="approval_status_2"
                                                id="class"
                                                className="form-control"
                                                value={user?.approval_status_2 ? user?.approval_status_2 : data.approval_status_2}
                                                onChange={(e) => {
                                                    handleChange1(e);
                                                }}
                                            >
                                                <option value="">Select Approval Status</option>
                                                <option value="APPROVED">Approved</option>
                                                <option value="PENDING">Pending</option>
                                                <option value="DECLINED">Declined</option>
                                                <option value="CANCELLED">Cancelled</option>
                                            </select>
                                        </div>
                                    </div>
                                    </div>
                                   
                                    <br/>
                                    <br/>
                                    <div className="row float-right mr-3">
                                    <button
                                      className="btn btn-success float-right"
                                      id="submit"
                                      aria-label="Close"
                                      type="button"
                                        data-dismiss="modal"

                                      onClick={() => {
                                        handleSubmit();
                                      }}
                                    >
                                      Submit
                                    </button>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                          : <></> 
                        } 

                    </div>
                     

                    </div>

                </div>                      

                </div>
            </div>
        </div>  
    
  )
}

export default HostelModalStudentGatePass;
