import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/routerConfig";
import { TICKET } from "../../utils/ticket.apiConst";
import { toast } from "react-toastify";

function DevelopersCentre({ setLoading }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  let role = sessionStorage.getItem("role");
  const [x, setX] = useState();

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: role == "IT" ? `${TICKET}?whom=IT` : `${TICKET}?whom=ERP`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log('tickets - ', res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const next = (data) => {
    console.log('state data - ', data);
    sessionStorage.setItem("conversation_by", 100);
    navigate(`${ROUTES.developersConversation}/${data.ticket_no}`, {
      state: { data: data },
    });
  };

  console.log("data.ticket_no -",data?.ticket_no);

  useEffect(() => {
    getData();
  }, []);

  // const updateStatus = async (id) => {
  //   setX("WORKING");
  //   setLoading(1);
  //   const config = {
  //     method: "put",
  //     url: `${TICKET}/${id}`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: {
  //       status: "WORKING",
  //     },
  //   };

  //   await axios(config)
  //     .then((res) => {
  //       toast.success("Successfully Updated Ticket Status");
  //       getData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setLoading(0);
  // };

  const updateStatus = async (id) => {
    setLoading(1);
    setX("WORKING");
    const config = {
      method: "put",
      url: `${TICKET}/${id}`, // Use the id parameter directly
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        status: "WORKING",
      },
    };
  
    try {
      await axios(config);
      toast.success("Successfully Updated Ticket Status");
      getData();
    } catch (err) {
      console.error(err);
      // Handle error, show error message, etc.
    } finally {
      setLoading(0);
    };
  };

  return (
    <div>
      {" "}
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h5>Pending Tickets List - {role == "DEVELOPERS" ? "ERP" : "IT"}</h5>
              </div>
              <div className="col-12 mt-3">
                <div className="card">
                  <div className="card-body">
                    <table
                      className="table table-bordered dt-responsive nowrap table-hover"
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Sl.No</th>
                          <th>Ticket No</th>
                          <th>Ticket Received From</th>
                          <th>Title</th>
                          <th>Module</th>
                          <th>Description</th>
                          <th>Priority</th>
                          <th>Date</th>
                          <th>Attachment</th>
                          <th>Status</th>
                          <th>Ticket Conversation</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data?.map((item, key) => {
                            if (item.status != "COMPLETED") {
                              return (
                                <>
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{item?.ticket_no}</td>
                                    <td>{item?.employeeName}</td>
                                    <td>{item?.title}</td>
                                    <td>{item?.module}</td>
                                    <td>
                                      {item?.description.length > 30
                                        ? item?.description.slice(0, 10) +
                                          "   ...."
                                        : item?.description}
                                    </td>
                                    <td>{item?.priority}</td>
                                    <td>{item?.created_on.split("T")[0]}</td>
                                    <td></td>
                                    <td> 
                                      <span
                                        className={`badge badge-soft-${
                                          item?.status == "COMPLETED"
                                          ? "success"
                                          : item?.status == "WORKING"
                                          ? "primary"
                                          : item?.status == "UNDER REVIEW"
                                          ? "info"
                                          : item?.status == "RESOLVED"
                                          ? "success"
                                          : item?.status == "NEED INFORMATION"
                                          ? "danger"
                                          : item?.status == "ACTIVE"
                                          ? "danger"
                                          : "danger"
                                          }`}
                                      >
                                        {/* {item?.status} */}
                                        {item?.status === "ACTIVE" ? "OPEN" : item?.status}
                                      </span> 
                                    </td>
                                    <td className="">
                                      <span className="mr-2 ">
                                        {item?.attachment ? (
                                          <a
                                            title="View Attachments"
                                            className="badge badge-primary text-light p-2"
                                            href={item?.attachment}
                                            target="_blank"
                                          >
                                            <i
                                              className="fa fa-link"
                                              aria-hidden="true"
                                            />
                                          </a>
                                        ) : (
                                          " "
                                        )}
                                      </span>
                                      <a
                                        onClick={() => next(item)}
                                        className="badge badge-success p-2 text-light"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i
                                          className="fa fa-eye pl-3"
                                          aria-hidden="true"
                                        />
                                        View
                                      </a>
                                    </td>
                                    { item?.status != "WORKING" 
                                    ?
                                    <td>
                                      <button 
                                        className="badge badge-primary text-light p-2" 
                                        onClick={() => updateStatus(item?.id)}
                                      >
                                        WORKING 
                                      </button>
                                    </td>
                                    :
                                    <></> 
                                    }                                    
                                  </tr>
                                </>
                              );
                            }
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevelopersCentre;
