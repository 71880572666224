import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { JOB_OPENINGS, JOB_APPLICATIONS } from "../../../utils/apiConstants";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_DOCUMENT } from "../../../utils/AssetsReferenceTypes";
import DocumentsModal from "../../../modals/Students/DocumentsModal";
import { international } from "../../../Data/Countries/international";
import {
  useParams, useNavigate
} from "react-router-dom";
import { Select, Input, DatePicker, Form, Button, FormInstance} from "antd";
const { TextArea } = Input;

function JobApplicationProfile({ collegeId, setLoading }) {

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      console.log("error while formating date :: ", dateString);
      return dateString;
    }
  }

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const navigate = useNavigate();

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const [data, setData] = useState([]);

  const id = useParams().id;

  const getJobApplication = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${JOB_APPLICATIONS}?id=${id}`,
    };

    await axios(config)
      .then((res) => {
        console.log("application data - ", res.data.data);
        setData(res.data.data[0]);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getJobApplication();
  },[]);

  return (
    <div>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center ">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-1 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">Applicant Profile</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="col-12">
                  <div className="card">
                    <div className="box p-2 text-center">
                      <h6 className="mt-3 text-primary"><b>Applicant Profile</b></h6>
                      <img
                        className="profile-user-img img-responsive rounded-circle mx-auto d-block mt-4"
                        src={data?.photo}
                        alt="Profile photo"
                        width="50%"
                        style={{ aspectRatio: "1/1", border: "2px solid black" }}
                      />
                      <h5 className="mt-3">{data?.first_name?.toUpperCase()} {data?.last_name?.toUpperCase()}</h5>
                      <table style={{ borderCollapse: 'collapse' }} width="100%" className="mt-4 p-2">
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Email</th>
                            <td width="70%" className="text-left"><b>:</b> {data?.email}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Phone</th>
                            <td width="70%" className="text-left" ><b>:</b> {data?.phone}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Date of Birth</th>
                            <td width="70%"className="text-left"><b>:</b> {formatDate(data?.dob?.split("T")[0])}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Age</th>
                            <td width="70%"className="text-left"><b>:</b> {calculateAge(data?.dob)}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Gender</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.gender}</td>
                          </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="col-12">
                  <div className="card">
                      <div className="box p-4">
                        <h6 className="text-primary"><b>Applicant Details</b></h6>
                        <table style={{ borderCollapse: 'collapse' }} width="100%" className="mt-4 p-2">
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Full name</th>
                            <td width="70%" className="text-left"><b>:</b> {data?.first_name} {data?.last_name}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Date of Birth</th>
                            <td width="70%"className="text-left"><b>:</b> {formatDate(data?.dob?.split("T")[0])}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Nationality</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.nationality}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Country</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.country}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >State</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.state}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >City</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.city}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Current Address</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.current_address}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Permanent Address</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.permanent_address}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Father Name</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.father_name}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Mother Name</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.mother_name}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Marital Status</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.marital_status}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Qualification</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.qualification}</td>
                          </tr>
                          <tr>
                            <th width="30%" className="text-left px-1 py-1" >Work Experience</th>
                            <td width="70%"className="text-left"><b>:</b> {data?.work_experience}</td>
                          </tr>
                      </table>
                      <button 
                        className="mt-3 ml-1 btn btn-nex btn-sm"
                        data-toggle="modal"
                        data-target="#DocumentsModal"
                        onClick={() => {
                          setLink(data?.resume);
                          setTitle("Applicant Resume");
                        }}
                      > 
                        View Resume
                      </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobApplicationProfile;
