//Sessio or Year
export const SessionOpt = [
  {
    name: '2030-31',
    id: '2030'
},
{
    name: '2029-30',
    id: '2029'
},
{
    name: '2028-29',
    id: '2028'
},
{
    name: '2027-28',
    id: '2027'
},
{
    name: '2026-27',
    id: '2026'
},
{
    name: '2025-26',
    id: '2025'
},
{
    name: '2024-25',
    id: '2024'
},
{
    name: '2023-24',
    id: '2023'
},
{
    name: '2022-23',
    id: '2022'
},
{
    name: '2021-22',
    id: '2021'
},
{
    name: '2020-21',
    id: '2020'
},
]