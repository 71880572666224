import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HOSTEL } from "../utils/Hostel.apiConst";

function HostelDetails({ setLoading }) {
  const [hostelData, setHostelData] = useState([]);

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Hostel Details</h4>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Select Criteria</h2>
              <br />

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="validationCustom02">
                      Hostel<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      //   name="hostel"
                      id="hostel"
                      // value={user.hostel}
                      // onChange={handleChange}
                    >
                      <option value="">Select Hostel</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row float-right mr-3">
                <div className="col-md-6">
                  <button
                    className="btn btn-nex  btn-primary"
                    type="submit"
                    name="submit"
                    value="collect"
                    // onClick={getData}
                  >
                    {/* <i className="fa fa-search" aria-hidden="true" />  */}
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 table-responsive">
                  <table className="table table-borders">
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Hostel Name</th>
                        <th>Room No</th>
                        <th>No Of Students</th>
                        <th>Bed</th>
                        <th>Bed Sheet</th>
                        <th>Ghoda</th>
                        <th>Pillow</th>
                        <th>Chair</th>
                        <th>Table</th>
                        <th>Dustbin</th>
                        <th>Vault-4</th>
                        <th>Vault-6</th>
                        <th>Vault</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostelDetails;
