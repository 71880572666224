import React, { useState, useRef, useEffect } from 'react'
import { COLLEGES, COLLEGE_TYPE } from '../../../../utils/apiConstants';
import './../Employer.scss'
import axios from 'axios'
import ModalSpecialization from '../../../../modals/HR/Employer/ModalSpecialization';
import ModalCollege from '../../../../modals/HR/Employer/ModalCollege';
import { ALL_DATA } from '../../../../utils/LocalStorageConstants';
import Nodata from '../../../../Components/NoData/Nodata';
import { toast } from 'react-toastify';
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";

function EmployeeCollege({setLoading}) {
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [type, setType] = useState()
    const [edit, setEdit] = useState();
    const [ctData, setCtData] = useState(JSON.parse(localStorage.getItem(ALL_DATA)).collegeType)


    console.log()

    const getData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: COLLEGES,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
        };

        await axios(config)
            .then((res) => {
                setLoading(0)
                console.log(res.data.data);
                setData(res.data.data);
            })
            
            .catch(err => {
                console.log(err)
                setLoading(0)
            })

            const config1 = {
                method: 'get',
                url: COLLEGE_TYPE,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
                },
            };
    
            await axios(config1)
                .then((res) => {
                    setLoading(0)
                    console.log(res.data.data);
                    setData1(res.data.data)
                })
                .catch(err => {
                    setLoading(0)
                    console.log(err)})

    }

    useEffect(() => {
        getData();
    }, [])

    const [collegeData, setCollegeData] = useState([]);

    const selectCollegeType = (id) => {
        console.log(id)
        data.filter(item => console.log(item))
        setCollegeData(data.filter(item => item.college_type_id == id))
    }

    const rowRef = useRef();

    const { onDownload } = useDownloadExcel({
      currentTableRef: rowRef.current,
      filename: " Colleges List ",
      sheet: "Users",
    });

    return (
        <div className='EmployeeCollege'>
            <div className="main-content">
                <ModalCollege reloadData={getData} type={type} data={edit} setLoading={setLoading}/>


                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Colleges</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Employer</li>
                                            <li className="breadcrumb-item active">College</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* 
                        <div className="row ">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="card-title">Select Criteria</h2>
                                        <br />
                                        <div className="row d-flex ">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="validationCustom01">
                                                        College Type<span style={{ color: "red" }}>*</span>
                                                    </label>
                                                    <select
                                                        id="role"
                                                        name="role"
                                                        className="form-control"
                                                        autoComplete="off"
                                                        onChange={(e) => selectCollegeType(e.target.value)}
                                                    >
                                                        <option value="" selected>Select</option>
                                                        {ctData?.map((i, key) => {
                                                            return <option value={i.id} key={key}>{i.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row ">
                                            <div className="col-md-12 d-flex justify-content-between">
                                                <button
                                                    className="btn btn-nex btn-rounded "
                                                    type="submit"
                                                    name="submit"
                                                >
                                                    <i className="fa fa-search" aria-hidden="true" /> Search
                                                </button>


                                                <button
                                                    onClick={() => { setType("add"); setEdit() }}
                                                    className="btn btn-rounded btn-success btn-outline px-4"
                                                    data-toggle="modal"
                                                    data-target="#ModalSpecialization"
                                                >
                                                    Add +
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> */}


                        <div>
                            <div className="card">
                                <div className="card-body">

                                <div className=" d-flex ml-auto justify-content-end">
                                            <button
                                            onClick={() => { setType("add"); setEdit() }}
                                            className="btn btn-rounded btn-success btn-outline px-4"
                                            data-toggle="modal"
                                            data-target="#ModalSpecialization"
                                        >
                                            Add +
                                        </button>
                                        <span>
                                          <button
                                            className="btn btn-primary rounded-pill ml-1"
                                            onClick={onDownload}
                                          >
                                            Export Excel
                                          </button>
                                        </span>
                                        </div>

                                        <table
                                      id="table_id"
                                      ref={rowRef}
                                      className="display table table-bordered  nowrap table-hover "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%", display: 'none' }}
                                    >
                                        <div className="col-12 text-center"><h4> Colleges List </h4>
                                        <thead>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th> Colege Name </th>
                                                <th> College Code </th>
                                                <th> College Type </th>
                                                <th> Location </th>
                                                <th> Phone </th>
                                                <th> Email </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ?
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                          :
                                          data
                                          .map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{i.name}</td>
                                              <td>{i.code}</td>
                                              {/* <td>{i.college_type_id}</td> */}
                                              <td>{data1?.find(s=>s?.id==i?.college_type_id)?.name}</td>
                                              <td>{i.location}</td>
                                              <td>{i.phone}</td>
                                              <td>{i.email}</td>
                                              <td></td>
                                              </tr>
                                          })
                                        }
                                        </tbody>
                                        </div>
                                    </table>

                                    <div className="row">
                                        
                                        {data && data.length !== 0 ? data.map((i, key) => (
                                            <div
                                                className="col-md-3"
                                                onClick={() => { setType("edit"); setEdit(i); }}
                                                data-toggle="modal"
                                                data-target="#ModalSpecialization"
                                            >
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="col-12" key={key} >
                                                           <div className='d-flex align-items-center '>
                                                           
                                                                <div><h5>{i.name}</h5>
                                                              <p className='text-info'> Code : {i.code}</p>
                                                              </div>
                                                         
                                                            <div className=" d-flex justify-content-end">
                                                            {'>'}
                                                        </div>
                                                           </div>
                                                       
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                        <div className='mt-3'>
                                            <Nodata titleTop={'No data available for your search'}/>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeCollege;