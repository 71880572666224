import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import {
  HOSTEL_SUMMARY_REPORT,
  HOSTEL, // add HOSTEL API endpoint
} from "../../../utils/Hostel.apiConst";

import * as XLSX from "xlsx/xlsx.mjs";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { toast } from "react-toastify";
import { college_title } from "../../../Data/main";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import Select from "react-select";

const HostelSummaryReport = ({ setLoading, collegeId }) => {
  let role = sessionStorage.getItem("role");

  const tableRef = useRef();

  const [data, setData] = useState([]);
  const [dates, setDates] = useState([]);
  const [department, setDepartment] = useState("All");
  const [session, setSession] = useState(
    sessionOpt?.find((s) => s.status == "ACTIVE")?.id
  );
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedHostel, setSelectedHostel] = useState("ALL");
  const [gender, setGender] = useState("ALL");

  const collegeOpt = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));
  const [college, setCollege] = useState([]);
  const [hostelData, setHostelData] = useState([]);

  const getDatas = async () => {
    const config = {
      method: "get",
      url: HOSTEL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.sort((a, b) => b.id - a.id);
        setHostelData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDatas();
  }, []);

  const [studentSet, setStudentSet] = useState([]);
  const navigate = useNavigate();

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [name, setName] = useState("");

  const getData = async () => {
    if (!session) {
      return toast.error("Please Select Academic Session");
    }
    if (!college) {
      return toast.error("Please Select College");
    }
    if (!selectedHostel) {
      return toast.error("Please Select Hostel");
    }
    if (!gender) {
      return toast.error("Please Select Gender");
    }

    setLoading(1);
    let apiUrl = `${HOSTEL_SUMMARY_REPORT}?year=${session}&college_id=${college}`;

    if (selectedHostel !== "ALL") {
      apiUrl += `&hostel_id=${selectedHostel}`;
    }
    if (gender !== "ALL") {
      apiUrl += `&gender=${gender}`;
    }

    try {
      setLoading(1);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      });

      setLoading(0);
      console.log("data - ", response.data.data);

      const reducedData = response.data.data?.reduce((acc, curr) => {
        const departmentId = curr.department_id;
        const departmentName = curr.department_name;
        const amount = parseInt(curr.amount);
        const paidAmount = parseInt(curr.paid_amount);

        if (!acc[departmentId]) {
          acc[departmentId] = {
            department_name: departmentName,
            department_id: departmentId,
            total_amount: 0,
            amount1: 0,
            amount2: 0,
            term1: 0,
            term2: 0,
            count: 0,
          };
        }

        acc[departmentId].total_amount += amount;
        acc[departmentId].amount1 += parseInt(amount / 2);
        acc[departmentId].amount2 += parseInt(amount / 2);
        acc[departmentId].count += 1;

        if (paidAmount > parseInt(amount / 2)) {
          acc[departmentId].term1 += parseInt(amount / 2);
          acc[departmentId].term2 += paidAmount - parseInt(amount / 2);
        } else {
          acc[departmentId].term1 += paidAmount;
          acc[departmentId].term2 += 0;
        }

        return acc;
      }, {});

      const groupedTransactions = Object.values(reducedData);
      console.log("grouped data - ", groupedTransactions);
      setData(groupedTransactions);
    } catch (error) {
      setLoading(0);
      console.log(error);
    }

    setLoading(0);
  };

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Fee Summary Report" + name,
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const handleCollegeChange = (selectedOption) => {
    setCollege(selectedOption?.value);
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">HOSTEL FEE SUMMARY REPORT</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">
                        Year <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        name="session_id"
                        id="fee_grp"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={
                          sessionOpt &&
                          sessionOpt.map((data) => ({
                            value: data.id,
                            label: data.id,
                          }))
                        }
                        value={
                          session
                            ? {
                                value: session,
                                label: sessionOpt.find(
                                  (data) => data.id == session
                                )?.id,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setSession(selectedOption.value);
                        }}
                        placeholder="Select Session"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="college_id">
                        College <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={collegeOpt?.map((i) => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        value={{
                          value: college,
                          label: collegeOpt?.find((s) => s.id == college)?.name,
                        }}
                        onChange={handleCollegeChange}
                        placeholder="Select College"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="college_id">
                        Hostel <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={[
                          { value: "ALL", label: "ALL" },
                          ...hostelData?.map((i) => ({
                            value: i.id,
                            label: i.hostel_name,
                          })),
                        ]}
                        value={{
                          value: selectedHostel,
                          label:
                            selectedHostel == "ALL"
                              ? "ALL"
                              : hostelData?.find((s) => s.id == selectedHostel)
                                  ?.hostel_name,
                        }}
                        onChange={(selectedOption) => {
                          setSelectedHostel(selectedOption.value);
                        }}
                        placeholder="Select Hostel"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="gender">
                        Gender <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        options={[
                          { value: "ALL", label: "ALL" },
                          { value: "MALE", label: "MALE" },
                          { value: "FEMALE", label: "FEMALE" },
                        ]}
                        value={gender ? { value: gender, label: gender } : null}
                        onChange={(selectedOption) => {
                          setGender(selectedOption.value);
                        }}
                        placeholder="Select Gender"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex float-right">
                      <button className="btn btn-nex mt-4" onClick={getData}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">Hostel Fee Summary Report</div>
                    <button
                      className="btn btn-nex rounded-pill"
                      onClick={onDownload}
                    >
                      Excel
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive fixTableHeadPage">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <tr>
                            <th>Department</th>
                            <th>No. of Students</th>
                            <th>Total Fee</th>
                            <th>1st Term Fee</th>
                            <th>Fee Paid</th>
                            <th>2nd Term Fee</th>
                            <th>Fee Paid</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((i, k) => (
                            <tr key={k}>
                              <td>{i?.department_name}</td>
                              <td>{i?.count}</td>
                              <td>
                                {i?.total_amount?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {i?.amount1?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {i?.term1?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {i?.amount2?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                              <td>
                                {i?.term2?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <th>Total</th>
                            <th>
                              {data?.reduce(
                                (acc, curr) => acc + curr?.count,
                                0
                              )}
                            </th>
                            <th>
                              {data
                                ?.reduce(
                                  (acc, curr) => acc + curr?.total_amount,
                                  0
                                )
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {data
                                ?.reduce((acc, curr) => acc + curr?.amount1, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {data
                                ?.reduce((acc, curr) => acc + curr?.term1, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {data
                                ?.reduce((acc, curr) => acc + curr?.amount2, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {data
                                ?.reduce((acc, curr) => acc + curr?.term2, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelSummaryReport;
