import React, { useEffect, useRef, useState } from "react";
import { Http } from "../../../../Services/Services";
import { LEAVE_ENTITLEMENT_REPORT } from "../../../../utils/apiConstants";
import { toast } from "react-toastify";
import { LOCAL_COLLEGE } from "../../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

const EntitlementDetaildReport = ({ collegeId, setLoading }) => {
  const collegeList = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);

  const calculateDifference = (a1, a2) => {
    var Difference_In_Time = new Date(a2).getTime() - new Date(a1).getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 60 * 60 * 24);
    return Difference_In_Days;
  };

  const calculateMonthDifference = (a1, a2, type) => {
    const date1 = new Date(a1);
    const date2 = new Date(a2);

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    const month1 = date1.getMonth();
    const month2 = date2.getMonth();

    const yearDifference = year2 - year1;
    const monthDifference = month2 - month1;

    let x = type
      ? yearDifference * 12 + monthDifference + 1
      : yearDifference * 12 + monthDifference;
    return x;
  };

  const getData = async () => {
    setLoading(true);
    let response = await Http.get(
      `${LEAVE_ENTITLEMENT_REPORT}?college_id=${collegeId}`
    ).catch((error) => {
      console.log(error);
      toast.error(error.message || "Error while fetching reports");
    });
    if (response) {
      const empSet = new Set();
      const leaveSet = new Set();
      for (const i of response.data.data) {
        if (i?.earning_policy == "DAILY") {
          var earning = (
            (calculateDifference(i?.from_date, new Date()) * 15) /
            365
          ).toFixed(2);

          if (earning < 0) {
            earning = 0;
          }
          // console.log(earning)
          i.earned = parseFloat(earning);
          i.balance = parseFloat((earning - i?.taken_days).toFixed(2));
          i.taken_days = parseFloat(i.taken_days);
        } else if (i?.earning_policy == "Starting of the month") {
          let earning = (
            (calculateMonthDifference(i?.from_date, new Date(), 1) *
              i?.entitled_days) /
            12
          ).toFixed(2);

          if (earning < 0) {
            earning = 0;
          }
          i.earned = parseFloat(earning);
          i.balance = parseFloat((earning - i.taken_days).toFixed(2));
          i.taken_days = i?.taken_days
            ? parseFloat(i.taken_days)
            : i.taken_days;
        } else if (i?.earning_policy == "End of the Month") {
          let earning = (
            (calculateMonthDifference(i?.from_date, new Date(), 0) *
              i?.entitled_days) /
            12
          ).toFixed(2);

          if (earning < 0) {
            earning = 0;
          }
          i.earned = earning;
          i.balance = (earning - i.taken_days).toFixed(2);
          i.taken_days = parseFloat(i.taken_days);
        }
        empSet.add(i.employee_id);
        leaveSet.add(i.leave_type);
      }

      setData(response.data.data);
      setEmployees(Array.from(empSet));
      console.log("emp - ", Array.from(empSet));
      console.log(Array.from(leaveSet));
      setLeaveTypes(Array.from(leaveSet));
    }
    console.log("data - ", response.data.data);
    setLoading(false);
  };

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      "Entitlement Detailed Report-" +
      new Date().getFullYear() +
      "-" +
      collegeList?.find((s) => s.id == collegeId)?.name,
    sheet: "Entitlement",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h6 className="card-header">Entitlement Detailed Report</h6>
              </div>
            </div>
            <div className="mt-3 row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex mb-3">
                          <button
                            onClick={onDownload}
                            className="btn btn-success ml-auto"
                          >
                            Export Excel
                          </button>
                          <button
                            className="btn btn-primary ml-2"
                            onClick={handlePrint}
                          >
                            Export PDF
                          </button>
                        </div>
                        <div className="table-responsive">
                          <table
                            ref={tableRef}
                            className="table table-bordered"
                          >
                            <tr>
                              <th colSpan={Number(leaveTypes.length) * 3 + 7}>
                                College Name:{" "}
                                {
                                  collegeList?.find((s) => s.id == collegeId)
                                    ?.name
                                }
                              </th>
                            </tr>
                            <tr>
                              <th rowSpan={2}>Sl.Name</th>
                              <th rowSpan={2}>Employee Name</th>
                              {leaveTypes?.map((item, index) => (
                                <th colSpan={4} key={index}>
                                  {item}
                                </th>
                              ))}
                              <th colSpan={4}>Total</th>
                            </tr>
                            <tr>
                              {leaveTypes?.map((item, index) => (
                                <>
                                  <th>Entitled</th>
                                  <th>Earned</th>
                                  <th>Taken</th>
                                  <th>Balance</th>
                                </>
                              ))}
                              <th>Entitled</th>
                              <th>Earned</th>
                              <th>Taken</th>
                              <th>Balance</th>
                            </tr>
                            {employees?.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {
                                    data?.find((s) => s.employee_id == item)
                                      ?.first_name
                                  }{" "}
                                  {
                                    data?.find((s) => s.employee_id == item)
                                      ?.last_name
                                  }
                                </td>
                                {leaveTypes?.map((leave, index2) => (
                                  <>
                                    <td>
                                      {data
                                        ?.filter(
                                          (s) =>
                                            s.employee_id == item &&
                                            s.leave_type == leave
                                        )
                                        ?.reduce(
                                          (a, b) => a + b.entitled_days,
                                          0
                                        )
                                        ?.toFixed(2) || "0"}
                                    </td>
                                    <td>
                                      {data
                                        ?.filter(
                                          (s) =>
                                            s.employee_id == item &&
                                            s.leave_type == leave
                                        )
                                        ?.reduce((a, b) => a + b.earned, 0)
                                        ?.toFixed(2) || "0"}
                                    </td>
                                    <td>
                                      {data
                                        ?.filter(
                                          (s) =>
                                            s.employee_id == item &&
                                            s.leave_type == leave
                                        )
                                        ?.reduce((a, b) => a + b.taken_days, 0)
                                        ?.toFixed(2) || "0"}
                                    </td>
                                    <td>
                                      {data
                                        ?.filter(
                                          (s) =>
                                            s.employee_id == item &&
                                            s.leave_type == leave
                                        )
                                        ?.reduce((a, b) => a + b.balance, 0)
                                        ?.toFixed(2) || "0"}
                                    </td>
                                  </>
                                ))}
                                <td>
                                  {data
                                    ?.filter((s) => s.employee_id == item)
                                    ?.reduce((a, b) => a + b.entitled_days, 0)
                                    ?.toFixed(2) || "0"}
                                </td>
                                <td>
                                  {data
                                    ?.filter((s) => s.employee_id == item)
                                    ?.reduce((a, b) => a + b.earned, 0)
                                    ?.toFixed(2) || "0"}
                                </td>
                                <td>
                                  {data
                                    ?.filter((s) => s.employee_id == item)
                                    ?.reduce((a, b) => a + b.taken_days, 0)
                                    ?.toFixed(2) || "0"}
                                </td>
                                <td>
                                  {data
                                    ?.filter((s) => s.employee_id == item)
                                    ?.reduce((a, b) => a + b.balance, 0)
                                    ?.toFixed(2) || "0"}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan={2}>Total</td>
                              {leaveTypes?.map((leave, index2) => (
                                <>
                                  <td>
                                    {data
                                      ?.filter((s) => s.leave_type == leave)
                                      ?.reduce((a, b) => a + b.entitled_days, 0)
                                      ?.toFixed(2) || "0"}
                                  </td>
                                  <td>
                                    {data
                                      ?.filter((s) => s.leave_type == leave)
                                      ?.reduce((a, b) => a + b.earned, 0)
                                      ?.toFixed(2) || "0"}
                                  </td>
                                  <td>
                                    {data
                                      ?.filter((s) => s.leave_type == leave)
                                      ?.reduce((a, b) => a + b.taken_days, 0)
                                      ?.toFixed(2) || "0"}
                                  </td>
                                  <td>
                                    {data
                                      ?.filter((s) => s.leave_type == leave)
                                      ?.reduce((a, b) => a + b.balance, 0)
                                      ?.toFixed(2) || "0"}
                                  </td>
                                </>
                              ))}
                              <td>
                                {data
                                  ?.reduce((a, b) => a + b.entitled_days, 0)
                                  ?.toFixed(2) || "0"}
                              </td>
                              <td>
                                {data
                                  ?.reduce((a, b) => a + b.earned, 0)
                                  ?.toFixed(2) || "0"}
                              </td>
                              <td>
                                {data
                                  ?.reduce((a, b) => a + b.taken_days, 0)
                                  ?.toFixed(2) || "0"}
                              </td>
                              <td>
                                {data
                                  ?.reduce((a, b) => a + b.balance, 0)
                                  ?.toFixed(2) || "0"}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntitlementDetaildReport;
