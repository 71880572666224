import React, { useState, useRef, useEffect } from 'react'
// import ModalEarningPolicy from '../../../../modals/HR/Leave/ModalEarningPolicy'
// import { LEAVE_EARNING_POLICY } from '../../../../utils/apiConstants';
import axios from 'axios';
import { toast } from "react-toastify";
// import Nodata from '../../../../Components/NoData/Nodata';
import { useDownloadExcel } from "react-export-table-to-excel";
import { LOCAL_COLLEGE } from '../../../utils/LocalStorageConstants';
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import { Flex, Input } from 'antd';
import Swal from "sweetalert2";

import {EMPLOYEE, GET_RESIGNATION_REQUESTS, UPDATE_RESIGNATION_REQUESTS} from "../../../utils/apiConstants"

function ResignationRequests({collegeId, setLoading}) {


  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    catch(err) {
      console.log(err);
      return dateString;
    }
  }

  let emp_id = sessionStorage.getItem("employee_id");

    const [empData, setEmpData] = useState([]);
    const [data, setData] = useState([]);
    const [displayRequests, setDisplayRequests] = useState([]);
    const [type, setType] = useState("All");

    const getEmployeeDetails = async () => {
      setLoading(1);
      const config = {
        method: "get",
        url: EMPLOYEE + "/" + emp_id,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      };
      await axios(config)
      .then((res) => {
          setLoading(0);
          console.log('emp data - ', res.data.data);
        //   setResignationText()
          setEmpData(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        });
    }

    const getResignationRequests = async () => {
        setLoading(1);
        const config = {
          method: "get",
          url: GET_RESIGNATION_REQUESTS + "/" + emp_id,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
        await axios(config)
        .then((res) => {
            setLoading(0);
            console.log('requests data - ', res.data.data);
            setData(res.data.data);
            setDisplayRequests(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
        setLoading(0);
      }

    const handleSubmit = async (id, status) => {
      if (!emp_id) {
        return toast.error("Unable to get Employee id");
      }
      const config = {
        method: "put",
        url: `${UPDATE_RESIGNATION_REQUESTS}/${id}?status=${status}&employee_id=${emp_id}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        }
      };
      Swal.fire({
        title: `Are you sure you want to ${status == "ACCEPTED" ? "Accept" : "Reject"} this request?`,
        showCancelButton: true,
        confirmButtonText: "Ok",
      }).then(async (s) => {
        if (s.isConfirmed) {
          setLoading(1);
          await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Submitted Resignation Letter");
            getResignationRequests();
            console.log(res);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
        }
        setLoading(0);
      });
};

useEffect(() => {
    if(type == "Pending") {
        let tempData = data.filter((i) => i.status == "PENDING");
        setDisplayRequests(tempData);
    }
    else if(type == "Accepted") {
        let tempData = data.filter((i) => i.status == "ACCEPTED");
        setDisplayRequests(tempData);
    }
    else if(type == "Rejected") {
        let tempData = data.filter((i) => i.status == "REJECTED");
        setDisplayRequests(tempData);
    }
    else {
        setDisplayRequests(data);
    }
},[type]);


useEffect(() => {
    getEmployeeDetails();
    getResignationRequests();
},[]);

return (
    <div className="main-content">
    <div className="page-content">
      <div className="container-fluid">
                    <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <h5 className='ml-3'><b>Resignation Requests</b></h5>
                    </div>
                    <br />
                    <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <lable className="ml-1">
                                Filter 
                            </lable>
                            <select
                                name=""
                                id=""
                                className="form-control"
                                value={type}
                                onChange={(e) => { setType(e.target.value) }}
                            >
                                <option value="All">All</option>
                                <option value="Pending">Pending</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Rejected">Rejected</option>
                                
                            </select>
                        </div>
                    </div>
                    </div>
                    <div className="table-responsive mt-3 fixTableHead">
                    <table className="table table-bordered" style={{}}>
                        <thead className="">
                        <tr>
                            <th>Sl No.</th>
                            <th>Staff Name</th>
                            <th>Apply date</th>
                            <th>Last Working Date</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                            {displayRequests ? 
                                displayRequests?.map((i, key) => (
                                    <tr key={key}>
                                        <td>{key+1}</td>
                                        <td>{i.first_name} {i.last_name}</td>
                                        <td>{formatDate(i.apply_date?.split('T')[0])}</td>
                                        <td>{formatDate(i.last_working_date?.split('T')[0])}</td>
                                        <td>{i.email}</td>
                                        <td>{i.phone}</td>
                                        <td>
                                        <span className={`badge badge-soft-${i?.status=="ACCEPTED"?"success":i?.status=="REJECTED"?"danger":"warning"}`} >
                                            {i.status}
                                        </span>
                                        </td>
                                        {i?.status == "PENDING" ? <td>
                                            <button title="Accept" onClick={() => {handleSubmit(i.id, "ACCEPTED")}} style={{ backgroundColor: 'green', color: 'white', border: 'none',borderRadius: '5px', fontSize:"0.9em" }}>
                                            <i className="fas fa-check" style={{ color: 'white' }}></i>
                                            </button>
                                            <button title="Reject" onClick={() => {handleSubmit(i.id, "REJECTED")}} className="ml-2" style={{ backgroundColor: 'red', color: 'white', border: 'none',borderRadius: '5px' }}>
                                            <i className="fas fa-times" style={{ color: 'white' }}></i>
                                            </button>
                                        </td> : <td></td>}
                                    </tr>
                                ))
                            : <>No data</>}
                        </tbody>
                    </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default ResignationRequests