import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { SESSION_ROLE } from '../../utils/sessionStorageContants';
import { STUDENT_GATE_PASS_UPLOAD, STUDENT_HOSTEL_ROOMS, STUDENT_SESSION_GET } from '../../utils/InfoUploadingApiConstants';
import { STUDENT_HOSTEL_DETAILS} from '../../utils/Hostel.apiConst';
import { ASSET_EMPLOYEE_DOCUMENT } from "../../utils/AssetsReferenceTypes";
import { SessionOpt } from "../../Data/student/sessionData";
import DocumentsModal from "../../modals/Students/DocumentsModal";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { getFileUrl } from "../../Helpers/Helpers";
import { Select, Input, Form, Button, FormInstance} from "antd";
import { DatePicker, Space, theme } from 'antd';

function StudentGatePass({ setLoading, collegeId }) {

  let role = sessionStorage.getItem(SESSION_ROLE);
  const employee_id = sessionStorage.getItem("employee_id");

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [adv, setAdv] = useState([]);
  const [data, setData] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [userId, setUserId] = useState("");
  const [year, setYear] = useState("");

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  var emp_id = sessionStorage.getItem("employee_id");

  const addAttachment = async (e) => {

    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_DOCUMENT,
        `Attachment`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setUser({ ...user, attachment: d ? d : "" });
    } catch (error) {
      console.log(error);
    }
  };

  const [user, setUser] = useState({
    request_date: "", 
    req_created_by: "", 
    purpose_type: "",
    out_from_date: "",
    out_to_date: "",
    out_going_purpose: "",
    attachment: "",
    request_remark_1: "",
    approved_by_1: "",
    approved_date_1: "",
    approval_status_1: "",
    approved_by_2: "",
    approved_date_2: "",
    approval_status_2: "",
    request_remark_2: "",
    status:"ACTIVE",
  });

  const clearData = () => {
    // setUser((prev) => ({
    //   ...prev,
    // request_date: "", 
    // req_created_by: "", 
    // purpose_type: "",
    // out_from_date: "",
    // out_to_date: "",
    // out_going_purpose: "",
    // attachment: "",
    // request_remark_1: "",
    // approved_by_1: "",
    // approved_date_1: "",
    // approval_status_1: "",
    // approved_by_2: "",
    // approved_date_2: "",
    // approval_status_2: "",
    // request_remark_2: "",
    // status:"ACTIVE"
    // }));
    setUser({});
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    if(name == "day_session" && value != "FULL_DAY") {
      setUser((prev) => ({
        ...prev,
        [name]: value,
        out_from_date: null,
        out_to_date: null,
        from_date: null,
        to_date: null,
      }));
    }
    else {
      setUser((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const getStudentData = async () => {
    
    if(!year) {
      return toast.error("Please Enter Year")
    }
    if(!userId) {
      return toast.error("Please Enter Enrollment Number")
    }
    
    const config = {
      method: "get",
      url: `${STUDENT_HOSTEL_DETAILS}/${userId}?year=${year}`,
      headers: {
        Authorization: `Bearer  ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        if(!res.data.data[0]) {
          setStudentData({});
          return toast.error("Student Hostel Data Not Found")
        }
        console.log('student details - ', res.data.data[0]);
        setStudentData(res.data.data[0]);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      })
  };

  const handleSubmit = async () => {

    if(!user.purpose_type) {
      return toast.error("Please Select Purpose");
    }
    if(!user.day_session) {
      return toast.error("Please Select Day Session");
    }
    if(!user.out_from_date) {
      return toast.error("Please Select From Date");
    }
    if(user.day_session == "FULL_DAY" && !user.out_to_date) {
      return toast.error("Please Select To Date");
    }
    if(!user?.out_going_purpose) {
      return toast.error("Please Enter Reason");
    }
    const config = {
      method: "post",
      url: STUDENT_GATE_PASS_UPLOAD,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        student_id: studentData?.user_id,
        college_id: collegeId,
        student_session_id: studentData?.id,
        no_of_days: user.day_session != "FULL_DAY" ? 0.5 : days,
        req_created_by: employee_id,
        request_date: new Date(),
        approved_by_1: studentData?.proctor_id ? studentData?.proctor_id : null,
        approval_status_1: "PENDING",
        approval_status_2: "PENDING",  
        hostel_fee_id: studentData?.hostel_fee_id,
      },
    };

    console.log('data to backend - ', {
      ...user,
      student_id: studentData?.user_id,
      college_id: collegeId,
      student_session_id: studentData?.id,
      no_of_days: user.day_session != "FULL_DAY" ? 0.5 : days,
      req_created_by: employee_id,
      request_date: new Date(),
      approved_by_1: studentData?.proctor_id ? studentData?.proctor_id : null,
      approval_status_1: "PENDING",
      approval_status_2: "PENDING",  
      hostel_fee_id: studentData?.hostel_fee_id,
    });
    
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Gate Pass Request Created");
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const calculateDays = () => {
    const fromDate = new Date(user.out_from_date);
    const toDate = new Date(user.out_to_date);
  
    // Check if both dates are selected
    if (!user.out_from_date || !user.out_to_date || fromDate > toDate) {
      return 0;
    }
  
    // Set both dates to midnight
    fromDate.setHours(0, 0, 0, 0);
    
    // Set toDate to the end of the day (23:59:59)
    toDate.setHours(23, 59, 59, 999);
  
    // Calculate the difference in milliseconds
    const timeDifference = toDate.getTime() - fromDate.getTime();
  
    // Calculate the number of days
    const daysDifference = timeDifference / (1000 * 3600 * 24);
  
    // Round to the nearest whole number
    const numberOfDays = Math.round(daysDifference);
  
    return numberOfDays >= 0 ? numberOfDays : 0;
  };

  const days = calculateDays();

  useEffect(() => {
    console.log('user - ', user);
  },[user]);

  return (
    <div>
        <DocumentsModal title={title} img={link} setLink={setLink} />
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0"> STUDENT GATE PASS </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-group">
                                        <label htmlFor="">
                                          Select Year
                                          <span style={{ color: "red" }}>*</span>
                                        </label>{" "}
                                        <select
                                          className="form-control"
                                          name="selectedYear"
                                          value={year}
                                          onChange={(e) => setYear(e.target.value)}
                                        >
                                          <option value="">Select Year</option>
                                          {SessionOpt?.map((i, key) => (
                                            <option value={i?.id}>
                                              {i?.name.substring(0, 4)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="mb-3 col-md-3">
                                        <label htmlFor="">
                                          Enter Enrollment No.
                                          <span style={{ color: "red" }}>*</span>
                                        </label>{" "}
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Student Enrollment No"
                                            value={userId}
                                            onChange={(e) => {
                                              setUserId(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                    <button
                                      className="btn btn-nex mt-4"
                                      onClick={getStudentData}
                                    >
                                    <i className="fa fa-search mr-1" aria-hidden="true" />{" "}
                                      Search
                                    </button>
                                    </div>
                                    {/* <div className="col-md-4">
                                      {studentData?.student_picture && <img 
                                        className="float-right mr-3"
                                        src={studentData?.student_picture} 
                                        alt="profile photo" 
                                        width="100px"
                                        height="100px"
                                        style={{ aspectRatio: "1/1", border: "1px solid black" }}  
                                      />}
                                    </div> */}
                                    </div>
                               

                                {studentData?.user_id ? (   
                                <div className="table-responsive">
                                 <h2 className="card-title text-info"><b>Student Details</b></h2>
                                <table
                                  id="datatable"
                                  className="table table-bordered  nowrap table-hover"
                                  style={{
                                    borderSpacing: 0,
                                    width: "100%",
                                  }}
                                >
                                    <thead>
                                    <br/>
                                      <tr>
                                        <th>Enrollment No </th>
                                        <td>{studentData?.user_id}</td>
                                        <th>Name </th>
                                        <td>{studentData?.name}</td>                                
                                        <th>College </th>
                                        <td>{collegeOpt?.find((s) => s.id == studentData?.college_id)?.name}</td>
                                        {studentData?.student_picture && 
                                        <td rowSpan={3} width="8%" className="text-center"><img 
                                        className="float-right mr-3"
                                        src={studentData?.student_picture} 
                                        alt="profile photo" 
                                        width="100px"
                                        height="100px"
                                        style={{ aspectRatio: "1/1", border: "1px solid black" }}  
                                      /></td>}
                                      </tr>
                                      <tr>  
                                        <th>Department </th>
                                        <td>{department?.find((s) => s.id == studentData?.department_id)?.name}</td>
                                        <th>Class </th>
                                        <td>{studentData?.class_name} ({studentData?.sem_name})</td> 
                                        <th>Session </th>
                                        <td>
                                          {studentData?.session_id}-{studentData?.session_id + 1}
                                        </td> 
                                      </tr>
                                      <tr>
                                        <th>Hostel </th>
                                        <td>{studentData?.hostel_name}</td>
                                        <th>Floor No. </th>
                                        <td>{studentData?.floor_number}</td>
                                        <th>Room No. </th>
                                        <td>{studentData?.room_number} ({studentData?.bed_number})</td>
                                      </tr>
                                    </thead>
                                </table>
                                </div>
                                ) : (
                                  <></>
                                )}

                                </div>
                            </div>
                        </div>                            
                      
                    </div>

                  {studentData?.user_id ? (      
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">

                                    <h2 className="card-title text-info"><b>Add Request Details</b></h2>
                                    <br/>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Purpose <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    name="purpose_type"
                                                    id="class"
                                                    className="form-control"
                                                    value={user.purpose_type}
                                                    onChange={handleChange1}
                                                >
                                                    <option value="">Select Purpose</option>
                                                    <option value="LEAVE">Leave</option>
                                                    <option value="GENERAL">General</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Day session <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    name="day_session"
                                                    id="class"
                                                    className="form-control"
                                                    value={user.day_session}
                                                    onChange={handleChange1}
                                                >
                                                    <option value="">Select Day Session</option>
                                                    <option value="FULL_DAY">Full Day</option>
                                                    <option value="MORNING">First Half (Half Day)</option>
                                                    <option value="EVENING">Second Half (Half Day)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                          <label htmlFor="validationCustom02">
                                            From Date <span style={{ color: "red" }}>*</span>
                                          </label>
                                          <DatePicker
                                            showTime
                                            onChange={(date, dateString) => {
                                              setUser({ ...user, out_from_date: dateString, from_date: date });
                                            }}
                                            value={user?.from_date}
                                            style={{ width: "100%" }}
                                            disabled={!user.day_session}
                                          />
                                        </div>
                                        <div className="col-md-3">
                                          <label htmlFor="validationCustom02">
                                            To Date <span style={{ color: "red" }}>*</span>
                                          </label>
                                          <DatePicker
                                            showTime
                                            onChange={(date, dateString) => {
                                              if(dateString && dateString < user?.out_from_date) {
                                                return toast.error("To Date cannot be less than From Date")
                                              }
                                              setUser({ ...user, out_to_date: dateString, to_date: date });
                                            }}
                                            value={user?.to_date}
                                            style={{ width: "100%" }}
                                            disabled={!user.day_session || user.day_session != "FULL_DAY" || !user?.out_from_date}
                                          />
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Request Remark
                                                </label>
                                                <textarea
                                                    type="text"
                                                    name="request_remark"
                                                    className="form-control"
                                                    placeholder="Enter Request Remark"
                                                    value={user.request_remark}
                                                    onChange={handleChange1}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="col-md-1">
                                          <div className="form-group">
                                              <label htmlFor="validationCustom02">Days <span style={{ color: "red" }}>*</span></label>
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  value={user.day_session != "FULL_DAY" ? "0.5" : calculateDays()}
                                                  readOnly
                                              />
                                          </div>
                                        </div>
                                        <div className="col-md-3">
                                          <label className="ml-1">Attachment</label>
                                          <br />
                                          <input
                                            type="file"
                                            name=""
                                            className="form-control ml-2"
                                            onChange={(e) => {
                                              addAttachment(e);
                                            }}
                                            style={{ width: "100%" }}
                                          />
                                          {user?.attachment ? (
                                            <button
                                              onClick={() => {
                                                setLink(user?.attachment);
                                                setTitle("Attachment");
                                              }}
                                              data-toggle="modal"
                                              data-target="#DocumentsModal"
                                              className="btn btn-nex btn-sm my-1 ml-3"
                                              title="View"
                                            >
                                              View
                                            </button>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                        <div className="col-md-9">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  Reason <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <textarea
                                                    type="text"
                                                    name="out_going_purpose"
                                                    className="form-control"
                                                    placeholder="Enter Reason"
                                                    value={user.out_going_purpose}
                                                    onChange={handleChange1}
                                                />
                                            </div>
                                        </div>
                                          
                                    </div>
                                    
                                    {/* <h6>Approval By Hostel Authority [Level-1] </h6> */}
                                    <div className="row">
                                    {role != "WARDEN" && role != "SUPERWARDEN" 
                                    ? <><hr/>
                                        <h2 className="card-title text-info"><b>Warden Approval Details</b></h2>
                                        <br/>
                                        <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="validationCustom02">
                                              Proctor Approval Status
                                            </label>
                                            <select
                                                name="approval_status_1"
                                                id="class"
                                                className="form-control"
                                                value={user.approval_status_1}
                                                onChange={handleChange1}
                                            >
                                                {/* <option value="">Select Approval Status</option> */}
                                                <option value="APPROVED">Approve</option>
                                                <option value="DECLINED">Decline</option>
                                            </select>
                                        </div>
                                      </div></>
                                    : <></>}
                                    </div>
                                    <br/>
                                    <br/>
                                    <div className="row float-right mr-3">
                                      <button
                                        className="btn btn-nex  btn-md"
                                        type="submit"
                                        name="submit"
                                        onClick={handleSubmit}
                                      >
                                      <i aria-hidden="true" /> Submit
                                      </button>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                      <div>
                        <p></p>
                      </div>
                  )}    

                </div>
            </div>
        </div>
      
    </div>
  )
}

export default StudentGatePass;
