import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDCARD_TEMPLATE_GET } from '../utils/InfoUploadingApiConstants';
import "./idcard.scss";

function IdCardModal({id, setLoading, data}) {

    const ID = id;
    console.log("id -", ID);

    // const [data, setData] = useState([]);

    // const getData = async () => {
    //     setLoading(1);
    //     const config = {
    //       method: "get",
    //     //   url: `${IDCARD_TEMPLATE_GET}?id=${ID}`,
    //       url: IDCARD_TEMPLATE_GET,
    //       headers: {
    //         Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
    //         "Content-Type": "application/json",
    //       },
    //     };
    
    //     await axios(config)
    //       .then((res) => {
    //         setData(res.data.data);
    //         setData(res.data.data.find(item => item.id === ID));
    //         console.log("setData -", res.data.data);
    //         setLoading(0);
    //       })
    //       .catch((err) => {
    //         setLoading(0);
    //         console.log(err);
    //         toast.error("Something went wrong");
    //       });
    //   };
    
    //   useEffect(() => {
    //     getData();
    //   },[]);

    //   console.log("data -", data);

      console.log("data?.header_color -", data?.header_color);

  return (
    <div className='IdCardModal '>

        <div
          className="modal fade"
          id="IdCardModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
            <div
              className={data?.design_type == "Horizontal" ? "modal-dialog design-modal modal-dialog-centered modal-lg " : "modal-dialog modal-dialog-centered "} 
              role="document"
            >
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="text-primary">{data?.id_card_title}</h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                    </div>

<div  className="modal-body"
>
    <div className={data?.design_type == "Horizontal" ? "card horizontal-body " : "card vertical-body justify-content-between"} 
    >
  {data && (
    <>
      {data?.design_type === "Horizontal" ? (

<div className="row">

  <div className='col-sm-3'>
  
  <img
    className="img-responsive mx-auto d-block"
    src={data?.logo}
    width="30%"
    height="100%"
    style={{ aspectRatio: "1/1" }}
    alt="logo"
  />
  </div>
  <div className="col-sm-9">            
    <h5 className="text-center" > KALOL INSTITUTE AND RESEARCH CENTER </h5>
  </div>
  <div className="col-sm-12">
    <h6 className="text-center">  7F3H+G4Q, Pratap Pura Village, Kalol, Gujarat 382721 </h6>
    <h5 className="text-center" style={{ backgroundColor: data?.header_color }}>{data?.id_card_title}</h5>
  </div>

<div className="col-sm-3 mt-4">
    <img
        className="img-responsive mx-auto d-block"
        src= "../../../assets/images/reports/graduated.png"
        width="100%"
        height="35%"
        style={{ aspectRatio: "1/1" }}
        alt="Signature"
    />
    <br/>
    { data?.barcode_type === "QR Code" ?
    <img
        className="img-responsive mx-auto d-block"
        src="../../../assets/images/reports/Qrcode.png"
        width="100%"
        height="35%"
        style={{ aspectRatio: "1/1" }}
        alt="Signature"
    />
    : 
    <img
        className="img-responsive mx-auto d-block"
        src="../../../assets/images/reports/barcode.png"
        width="100%"
        height="40%"
        style={{ aspectRatio: "1/1" }}
        alt="Signature"
    />
    }
</div>

<div className="col-sm-9">
<table className="table table-borderless content-two">

<tbody className="tbody-modal">
    <tr>
        <th  width="10%"> Name :</th> 
        <td  width="10%"> AAAAAA </td>
    </tr>
    { data?.class_name ?
    <tr>
        <th  width="10%" >Class :</th>    
        <td   width="10%"> First Year </td>
    </tr>
    : ""
    }
    { data?.father_name ?
    <tr>
        <th  width="10%">Father Name :</th> 
        <td  width="10%"> Basavaraj </td>
    </tr>
    : ""
    }
    { data?.mother_name ?
    <tr>
    <th  width="10%">Mother Name :</th> 
    <td  width="10%"> Suma </td>
    </tr>
    : ""
    }
    { data?.student_address ?
    <tr>
    <th  width="10%">Student Address :</th> 
    <td  width="10%"> Hosayallapur, Dharwad </td>
    </tr>
    : ""
    }                    
    { data?.phone ?
    <tr>
    <th  width="10%">Phone :</th>
    <td  width="10%"> 9900676737 </td>
    </tr>
    : ""
    }
    { data?.date_of_birth ? 
    <tr>
    <th  width="10%">Date of Birth :</th> 
    <td  width="10%"> 05-11-2001 </td>
    </tr>
    : ""
    }
    { data?.blood_grp ?
    <tr>
    <th  width="10%">Blood Group :</th> 
    <td  width="10%"> O+ </td>
    </tr>
    : ""
    } 
    
</tbody>
</table>
</div>

<div className="col-sm-12">   
<img
    className="img-responsive float-right"
    src={data?.signature}
    width="30%"
    height="50%"
    // style={{ aspectRatio: "1/1" }}
    alt="Signature"
/>
</div>

</div>

      ) : (


        <div className="row">

            <div className="row">
              <div className='col-sm-3'>
              
              <img
                className="img-responsive mx-auto d-block"
                src={data?.logo}
                width="40%"
                height="100%"
                style={{ aspectRatio: "1/1" }}
                alt="logo"
              />
              </div>
              <div className="col-sm-9">            
                <h5 className="text-center" > KALOL INSTITUTE AND RESEARCH CENTER </h5>
              </div>
              <div className="col-sm-12">
                <h6 className="text-center">  7F3H+G4Q, Pratap Pura Village, Kalol, Gujarat 382721 </h6>
                <h5 className="text-center" style={{ backgroundColor: data?.header_color }}>{data?.id_card_title}</h5>
              </div>

            </div>

            <div className="row">

            <div className="col-sm-3 mt-4">
                <img
                    className="img-responsive mx-auto d-block"
                    src= "../../../assets/images/reports/graduated.png"
                    width="100%"
                    height="40%"
                    style={{ aspectRatio: "1/1" }}
                    alt="Signature"
                />
                <br/>
                { data?.barcode_type === "QR Code" ?
                <img
                    className="img-responsive mx-auto d-block"
                    src="../../../assets/images/reports/Qrcode.png"
                    width="100%"
                    height="40%"
                    style={{ aspectRatio: "1/1" }}
                    alt="Signature"
                />
                : 
                <img
                    className="img-responsive mx-auto d-block"
                    src="../../../assets/images/reports/barcode.png"
                    width="100%"
                    height="40%"
                    style={{ aspectRatio: "1/1" }}
                    alt="Signature"
                />
                }
            </div>

        <div className="col-sm-9">
        <table className="table table-borderless">

            <tbody className="tbody-modal">
                <tr>
                    <th  width="10%"> Name :</th> 
                    <td  width="10%"> AAAAAA </td>
                </tr>
                { data?.class_name ?
                <tr>
                    <th  width="10%" >Class :</th>    
                    <td   width="10%"> First Year </td>
                </tr>
                : ""
                }
                { data?.father_name ?
                <tr>
                    <th  width="10%">Father Name :</th> 
                    <td  width="10%"> Basavaraj </td>
                </tr>
                : ""
                }
                { data?.mother_name ?
                <tr>
                <th  width="10%">Mother Name :</th> 
                <td  width="10%"> Suma </td>
                </tr>
                : ""
                }
                { data?.student_address ?
                <tr>
                <th  width="10%">Student Address :</th> 
                <td  width="10%"> Hosayallapur, Dharwad </td>
                </tr>
                : ""
                }                    
                { data?.phone ?
                <tr>
                <th  width="10%">Phone :</th>
                <td  width="10%"> 9900676737 </td>
                </tr>
                : ""
                }
                { data?.date_of_birth ? 
                <tr>
                <th  width="10%">Date of Birth :</th> 
                <td  width="10%"> 05-11-2001 </td>
                </tr>
                : ""
                }
                { data?.blood_grp ?
                <tr>
                <th  width="10%">Blood Group :</th> 
                <td  width="10%"> O+ </td>
                </tr>
                : ""
                } 
                
            </tbody>
        </table>
        </div>

            </div>

        <div className="row">
            <div className="col-sm-12">   
            <img
                className="img-responsive float-right d-block"
                src={data?.signature}
                width="30%"
                height="50%"
                // style={{ aspectRatio: "1/1" }}
                alt="Signature"
            />
            </div>
        </div>

        </div>
        
      )}
    </>
  )}
  </div>
</div>

                </div>
            </div>
        </div>
      
    </div>
  )
}

export default IdCardModal;
