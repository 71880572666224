import axios from "axios";
import React from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import { SessionOpt } from "../../../Data/student/sessionData";
import {
  HOSTEL,
  HOSTEL_FLOORS,
  HOSTEL_ROOMS,
  HOSTEL_TYPE,
  HOSTEL_BEDS,
  HOSTEL_BED_DETAILS,
  HOSTEL_ATTENDANCE_STUDENT_DETAILS,
  HOSTEL_ATTENDANCE_SAVE
} from "../../../utils/Hostel.apiConst";
import {
  LOCAL_COLLEGE,
} from "../../../utils/LocalStorageConstants";
import ModalHostelRooms from "../../../modals/ModalHostelRooms";
import { EMPLOYEE_ALL2} from "../../../utils/apiConstants";
import { Select, Input, DatePicker, Form, Button, FormInstance, Popover, Radio } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Space, Switch } from 'antd';

function HostelAttendance({ setLoading, collegeId }) {
  const [user, setUser] = useState({
    date: "",
    dateString: "",
    room_name_number: "",
    room_hostel_id: "",
    hostel_room_type_id: "",
    hostel_no_bed: "",
    floor_number: "",
  });

  const months = [
    { key: "01", name: "JANUARY" },
    { key: "02", name: "FEBRUARY" },
    { key: "03", name: "MARCH" },
    { key: "04", name: "APRIL" },
    { key: "05", name: "MAY" },
    { key: "06", name: "JUNE" },
    { key: "07", name: "JULY" },
    { key: "08", name: "AUGUST" },
    { key: "09", name: "SEPTEMBER" },
    { key: "10", name: "OCTOBER" },
    { key: "11", name: "NOVEMBER" },
    { key: "12", name: "DECEMBER" }
  ];

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const emp_id = sessionStorage.getItem("employee_id");
  
  let auth = sessionStorage.getItem("UMS_auth");
  let empHostelId = sessionStorage.getItem("HOSTEL_ID");
  const [displayData, setDisplayData] = useState([]);
  const [hostelData, setHostelData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [floorData, setFloorData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [promotes, setPromotes] = useState([]);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");

  const [def, setDef] = useState("P");

  const [show, setShow] = useState([]);

  const tableRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Hostel Rooms Report ",
    sheet: "Users",
  });

  const role = sessionStorage.getItem("role");
  // console.log("role", role);


  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const getData1 = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({ ...config, url: `${HOSTEL_FLOORS}` })
      .then((res) => {
        // setLoading(0);
        setFloorData(res.data.data); //This line to check if floorData is being updated
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong",err);
      });

    await axios({ ...config, url: `${HOSTEL}` })
      .then((res) => {
        // setLoading(0);
        setHostelData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong",err);
      });

    await axios({ ...config, url: `${HOSTEL_TYPE}?college_id=${collegeId}` })
      .then((res) => {
        // setLoading(0);
        setRoomData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
      })
      setLoading(0);
  };
 
  const getData = async () => {

    if(!user.dateString) {
      return toast.error("Please Select Date");
    }
    if(!user.room_hostel_id) {
      return toast.error("Please Select Hostel");
    }
    const config = {
      method: "get",
      url: `${HOSTEL_ATTENDANCE_STUDENT_DETAILS}?date=${user.dateString}&hostel_id=${user.room_hostel_id}&floor_number=${user.floor_number}&room_type=${user.hostel_room_type_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    axios(config)
      .then((res) => {
        setLoading(0);
        console.log('student attendance data - ', res.data.data);
        let temp = res.data.data;
        setData(temp);
        setDisplayData(temp);
        setPromotes(temp?.map((i,k) => {return {
          student_session_id: i?.student_session_id, 
          hostel_fees_id: i?.id,
          attendance: i?.attendance ? i?.attendance : "P",
          id: i?.attendance_id,
          note: i?.note ? i?.note : "",
        }}));
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  }

  const handleSave = async () => {
    if(promotes?.length == 0) {
      return toast.error("No Students Selected");
    }

    console.log('promotes - ', promotes);

    const config = {
      method: "post",
      url: `${HOSTEL_ATTENDANCE_SAVE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        emp_id,
        date: user?.dateString,
        data: promotes,
      }
    };

    setLoading(1);
    axios(config)
      .then((res) => {
        setLoading(0);
        getData();
        toast.success("Attendance Saved");
      })
      .catch((err) => {
        setLoading(0);
        getData();
        console.log(err);
        toast.error("Something Went Wrong");
      });

  }


  useEffect(() => {
    const hostelfloorsValues = [];

    for (const item of floorData) {
      const value = item.hostelfloors;
      hostelfloorsValues.push(value);
    }

    let x = hostelfloorsValues?.filter(
      (s) => s.hostelname == user?.room_hostel_id
    )[0]?.floors + 1;
    let arr = [];
    let cnt = 0;
    while (x) {
      arr.push(cnt);
      cnt++;
      x--;
    }
    setShow(arr);

  }, [user?.room_hostel_id]);

  useEffect(() => {
    setPromotes([]);
    setData([]);
    setDisplayData([]);
  },[user.date, user.room_hostel_id, user.hostel_room_type_id]);
  
  useEffect(() => {
    getData1();
  }, []);

  const changeAttendance = (id, value) => {
    let tempData = promotes?.map((s,i) => s);
    tempData.find((s) => s.hostel_fees_id == id).attendance = value;
    setPromotes(tempData);
  };

  const changeNote = (id, value) => {
    let tempData = promotes?.map((s,i) => s);
    tempData.find((s) => s.hostel_fees_id == id).note = value;
    setPromotes(tempData);
  };

  useEffect(() => {
    const filteredData = data?.filter(
      (item) =>
        item?.user_id?.toLowerCase()?.includes(searchName?.toLowerCase())
    );
    setDisplayData(filteredData);
  },[searchName]);


  //Sets default value for all students
  useEffect(() => {
    if(def == "P" || def == "A" || def == "V") {
      let temp = promotes?.map((i,k) => i);

      for(let i of temp) {
        i.attendance = def;
      }

      setPromotes(temp);
    }
  },[def]);

  return (
    <div ClassName="HostelRoom">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Hostel Attendance</h4>
                      <h4 className="mb-0 mr-5">{role == "WARDEN" && employee && hostelData.find((s)=> s.id == employee[0]?.hostel_id)?.hostel_name}</h4>
                    </div>
                  </div>
                </div>
            <>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title">Search Criteria</h2>
                      <br />
                      <div className="row">
                        <div className="col-md-2">
                        <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Date <span style={{ color: "red" }}>*</span>
                            </label>
                            <br />
                            <DatePicker
                              onChange={(date, dateString) => {
                                setUser({ ...user, dateString: dateString, date: date });
                              }}
                              value={user?.date}
                              style={{ width: "98%" }}
                            />
                        </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Hostel <span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="room_hostel_id"
                              id="hostel"
                              value={user.room_hostel_id}
                              onChange={handleChange}
                            >
                              <option value="">Select Hostel</option>
                              {role == "WARDEN" ? 
                              hostelData?.filter((s) => s.id == empHostelId)?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.hostel_name}
                                </option>
                              ))
                              : hostelData?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.hostel_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Floor Number{" "}
                            </label>
                            <select
                              className="form-control"
                              name="floor_number"
                              id="floornumber"
                              value={user.floor_number}
                              onChange={handleChange}
                            >
                              <option value="">All</option>
                              {show &&
                                show?.map((item, key) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <label htmlFor="validationCustom02">
                              Room Type
                            </label>
                            {/* <select
                              className="form-control"
                              name="hostel_room_type_id"
                              id="type"
                              value={user.hostel_room_type_id}
                              onChange={handleChange}
                            >
                              <option value="">All</option>
                              {roomData?.filter((s) => s.hostel_id == user.room_hostel_id)?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.room_type}
                                </option>
                              ))}
                            </select> */}
                            <Select
                              showSearch
                              className="float-right mr-3"
                              placeholder="Default"
                              optionFilterProp="children"
                              onChange={(value) => {
                                setUser({...user, hostel_room_type_id: value})
                              }}
                              value={user.hostel_room_type_id}
                              options={[{label: "All", value: ""},...roomData?.filter((s) => s.hostel_id == user.room_hostel_id)?.map((i, key) => 
                               {return ( {
                                label: i.room_type,
                                value: i.id
                              })})]}
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="col-md-1">
                            <Button 
                              type="primary mt-4"
                              onClick={getData}
                            >
                              Search
                            </Button>
                      </div>
                      </div>
                      
                    </div>
                  </div>
                  {/* end card */}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3">
                          <div className="row card-title text-uppercase">
                              {" "}
                              <h6>Hostel Students List</h6>
                              <br />
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search by Enrollment No."
                                    value={searchName}
                                    onChange={(e) => {
                                      setSearchName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="col-md-9">
                                <Button 
                                    type="primary"
                                    className="float-right"
                                    onClick={handleSave}
                                    disabled={promotes?.length == 0}
                                  >
                                    Save Attendance
                                  </Button>
                                  <Select
                                    showSearch
                                    className="float-right mr-3"
                                    placeholder="Default"
                                    optionFilterProp="children"
                                    onChange={(value) => {
                                      setDef(value);
                                    }}
                                    value={def}
                                    options={[
                                      {
                                        value: "P",
                                        label: "Present",
                                      },
                                      {
                                        value: "A",
                                        label: "Absent",
                                      },
                                      {
                                        value: "V",
                                        label: "Vacation",
                                      }
                                    ]}
                                    style={{ width: "20%" }}
                                    disabled={promotes?.length == 0}
                                  />
                                  
                                </div>
                            </div>
                        </div>
                      <hr />
                      <h6>Total Students = {displayData?.length}</h6>
                      <div className="table-responsive">
                      <table
                        id="datatable"
                        className="table table-bordered nowrap"
                        ref={tableRef}
                        style={{
                          borderCollapse: "collapse",
                          borderSpacing: 0,
                          width: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            <th>Sl. No.</th>
                            <th>Enrollment No.</th>
                            <th>Name</th>
                            <th>Floor</th>
                            <th>Room No.</th>
                            <th>Status</th>
                            <th className="text-center">Action</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {displayData?.map((data, key) => {
                              return (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{data?.user_id}</td>
                                  <td>{data?.name}</td>
                                  <td>{data?.floor_number}</td>
                                  <td>{data?.room_name_number} ({data?.hostel_no_bed})</td>
                                  <td>
                                    <span className={`badge badge-soft-${data?.attendance == 'P' ? "success" : data?.attendance == 'A' ? "danger" : data?.attendance == 'V' ? "primary" : "secondary"}`}>
                                      {data?.attendance == 'P' ? "Present" : data?.attendance == 'A' ? "Absent" : data?.attendance == 'V' ? "Vacation" : "NA"}
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <Radio.Group 
                                      optionType="button"
                                      buttonStyle="solid"
                                      // disabled={data?.attendance_id}
                                      options={[
                                        {
                                          label: "Present",
                                          value: "P",
                                        },
                                        {
                                          label: "Absent",
                                          value: "A",
                                        },
                                        {
                                          label: "Vacation",
                                          value: "V",
                                        },
                                      ]}     
                                      value={promotes?.find((s) => s.hostel_fees_id == data?.id)?.attendance}
                                      onChange={(e) => {
                                        changeAttendance(data?.id, e.target.value);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Note"
                                      value={promotes?.find((s) => s.hostel_fees_id == data?.id)?.note}
                                      onChange={(e) => {
                                        changeNote(data?.id, e.target.value);
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {/* end col */}
              </div>{" "}
              {/* end row */}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostelAttendance;
