import React, { useState, useEffect } from "react";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "../../utils/Academics.apiConst";
import axios from "axios";
import { toast } from "react-toastify";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import Nodata from "../../Components/NoData/Nodata";
import {sessionOpt} from '../../Data/jsonData/Academics/Academics';
import Select from "react-select";

function AddSubjects({ collegeId, setLoading }) {
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), , collegeId]);

  const [classOpt, setClassOpt] = useState();
  const [semOpt, setSemOpt] = useState();
  const [selectedSemOpt, setSelectedSemOpt] = useState();

  //Fucntion to get data of classes
  const getClassData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2] = await Promise.all([
      await axios({
        ...config,
        url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),

      await axios({
        ...config,
        url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
    ]);
  };

  // const semOpt = [
  //   {
  //     name: '1st Sem',
  //     id: 1
  //   },
  //   {
  //     name: '2nd Sem',
  //     id: 2
  //   },
  //   {
  //     name: '3rd Sem',
  //     id: 3
  //   },
  //   {
  //     name: '4th Sem',
  //     id: 4
  //   },
  //   {
  //     name: '5th Sem',
  //     id: 5
  //   },
  //   {
  //     name: '6th Sem',
  //     id: 6
  //   },
  //   {
  //     name: '7th Sem',
  //     id: 7
  //   },
  //   {
  //     name: '8th Sem',
  //     id: 8
  //   },
  //   {
  //     name: '9th sem',
  //     id: 9
  //   },
  //   {
  //     name: '10th sem',
  //     id: 10
  //   }
  // ]

  const [data, setData] = useState([]);

  const [edit, setEdit] = useState(false);

  const [addNew, setAddNew] = useState(false);

  const [user, setUser] = useState({
    name: "",
    semester_id: "",
    class_id: "",
    college_id: "",
    department_id: "",
    type: "",
    code: "",
    credit: "",
    from_year:"",
    to_year:"",
    revision_no:"",
    revision_date:"",
    is_elective:"0",
    is_other_branches:"0",
    is_non_gradial:"0",
    is_feedback:"0",
    is_open_elective:"0",
    lecture_hours:"",
    lecture_unit:"week",
    lab_hours:"",
    lab_unit:"week",
    tut_hours:"",
    tut_unit:"week",
    seminar_hours:"",
    seminar_unit:"week",
    internal_max_marks:"",
    internal_min_marks:"",
    viva_max_marks:"",
    viva_min_marks:"",
    midterm_max_marks:"",
    midterm_min_marks:"",
    status:"ACTIVE"
  });

  const clearData = () => {
    setUser((prev) => ({
      ...prev,
      name: "",
      semester_id: "",
      class_id: "",
      college_id: "",
      department_id: "",
      type: "",
      code: "",
      credit: "",
      from_year:"",
      to_year:"",
      revision_no:"",
      revision_date:"",
      is_elective:"0",
      is_other_branches:"0",
      is_non_gradial:"0",
      is_feedback:"0",
      is_open_elective:"0",
      lecture_hours:"",
      lecture_unit:"week",
      lab_hours:"",
      lab_unit:"week",
      tut_hours:"",
      tut_unit:"week",
      seminar_hours:"",
      seminar_unit:"week",
      internal_max_marks:"",
      internal_min_marks:"",
      viva_max_marks:"",
      viva_min_marks:"",
      midterm_max_marks:"",
      midterm_min_marks:"",
      status:"ACTIVE"
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async (a, b, c) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?college_id=${collegeId}&&department_id=${a}&&class_id=${b}&&semester_id=${c}&&getInactive=0`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleSearch = () => {
    if (!user.department_id || !user.class_id || !user.semester_id)
      return toast.error("Mandatory Fields are required");
    getData(user.department_id, user.class_id, user.semester_id);
  };

  const handleSubmit = async () => {
    const config = {
      method: "post",
      url: ACADEMICS_ADD_SUBJECT,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        college_id: collegeId,
      },
    };

    console.log('data to backend - ', user);
    if (!user.department_id || !user.class_id || !user.semester_id)
      return toast.error("Please Select Department");
    else if (!user.class_id)
      return toast.error("Please Select Class");
    else if (!user.semester_id)
      return toast.error("Please Select Semester");
    else if (!user.code)
      return toast.error("Please Enter Course Code");
    else if (!user.name)
      return toast.error("Please Enter Course Name");
    else if (!user.from_year)
      return toast.error("Please Select Effective From Academic Year");
    else if (!user.type)
      return toast.error("Please Select Course Type");

    
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data added successfully");
        handleSearch();
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleEdit = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: ACADEMICS_ADD_SUBJECT + `/${user.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Success");
        setEdit(false);
        handleSearch();
        clearData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const handleDelete = async (i) => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${ACADEMICS_ADD_SUBJECT}/${i?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE",
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Data Deleted");
        handleSearch();
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Some Error Occured");
      });
  };

  const handleDepartmentChange = (selectedOption) => {
    setUser((prevUser) => ({
      ...prevUser,
      department_id: selectedOption?.value || null,
      class_id: "", // Reset class_id when department changes
      program_id: departmentOpt?.find(
        (s) => s.id == selectedOption?.value
      )?.program_id,
    }));
};

const handleChangeSelect = (name, selectedOption) => {
    setUser((prevUser) => ({
      ...prevUser,
      [name]: selectedOption?.value || null,
    }));
};

  useEffect(() => {
    getData();
    getClassData();
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create New Course</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Academics</a>
                      </li>
                      <li className="breadcrumb-item active">Create New Course</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title text-danger"><b>Course Information</b></h2>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Department
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            id="class"
                            className="form-control"
                            value={user.department_id}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                department_id: e.target.value,
                                program_id: departmentOpt?.find(
                                  (s) => s.id == e.target.value
                                )?.program_id,
                              }));
                            }}
                          >
                            <option value="">Select Department</option>
                            {departmentOpt?.map((i, key) => (
                              <option key={key} value={i.id}>
                                {i.name},{" "}
                                {
                                  programOpt?.find((s) => s.id == i.program_id)
                                    .name
                                }
                              </option>
                            ))}
                          </select> */}

                        <Select
                            className="form-group"
                            name='department_id'
                            value={departmentOpt.find(option => option.value === user.department_id)}
                            onChange={handleDepartmentChange}
                            options={departmentOpt.map((i) => {
                            const programName = programOpt.find(item => i.program_id === item.id)?.name || 'Program Not Found';
                            console.log(`Department ID: ${i.id}, Program Name: ${programName}`);
                              return {
                                value: i.id,
                                label: `${i.name}, ${programName}`
                              };
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Class
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            name="class_id"
                            id="class"
                            className="form-control"
                            value={user.class_id}
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedSemOpt(
                                semOpt.filter(
                                  (item) => item.class_id == e.target.value
                                )
                              );
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s?.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option key={key} value={i.id}>
                                  {i.name}
                                </option>
                              ))}
                          </select> */}

                          <Select
                            options={classOpt
                              ?.filter((s) => s?.department_id == user.department_id)
                              ?.map((i) => ({ value: i.id, label: i.name }))}
                            value={user.class_id ? { value: user.class_id, label: classOpt?.find((i) => i.id == user.class_id)?.name } : null}
                            onChange={(selectedOption) => handleChangeSelect('class_id', selectedOption)}
                            placeholder="Select Class"
                          />

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Semester
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            name="semester_id"
                            id="semester_id"
                            className="form-control"
                            value={user.semester_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Semester</option>
                            {selectedSemOpt?.map((i, key) => (
                              <option key={key} value={i.id}>
                                {i.name}
                              </option>
                            ))}
                          </select> */}

                        <Select
                            className="form-group"
                            name='semester_id'
                            value={
                              user.semester_id
                              ? {
                                value: user.semester_id,
                                label: semOpt
                                ?.filter((s) => s.class_id == user.class_id)
                                ?.find((i) => i.id == user.semester_id)?.name,
                              }
                              : null
                            }
                            onChange={(selectedOption) => setUser((prev) => ({ ...prev, semester_id: selectedOption.value }))}
                            options={semOpt?.filter(s => s.class_id == user.class_id)?.map((i) => {
                            console.log(`Semester ID: ${i.id}, Semester Name: ${i.name}`);
                              return {
                                value: i.id,
                                label: i.name
                              };
                            })}
                          />

                        </div>
                      </div>
                      {addNew || edit ? (
                        <>
                        <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Course Code<span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="code"
                                value={user.code}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Course Code"
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Course Name
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Course Name"
                                value={user.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Effective From Academic Year
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              {/* <input
                                type="text"
                                name="from_year"
                                className="form-control"
                                placeholder="Effective From Academic Year"
                                value={user.from_year}
                                onChange={handleChange}
                              /> */}
                              <select name="from_year" id="from_year" required="" className="form-control" value={user?.from_year} onChange={handleChange}>
                                <option value="">Select Effective From Academic Year</option>
                                {sessionOpt &&
                                  sessionOpt?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>{item?.name.substring(0,4)}</option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Effecive To Academic Year
                              </label>
                              {/* <input
                                type="text"
                                name="to_year"
                                className="form-control"
                                placeholder=" Effective To Academic Year"
                                value={user.to_year}
                                onChange={handleChange}
                              /> */}
                              <select name="to_year" id="to_year" required="" className="form-control" value={user?.to_year} onChange={handleChange}>
                                <option value="">Select Effective To Academic Year</option>
                                {sessionOpt &&
                                  sessionOpt?.map((item, key) => {
                                    return (
                                      <option value={item?.id}>{item?.name.substring(0,4)}</option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Revision No. / Revision Date
                              </label>
                              <div className="row">
                              <div className="col-md-4">

                              <input
                                type="text"
                                name="revision_no"
                                className="form-control"
                                placeholder="Rev No."
                                value={user.revision_no}
                                onChange={handleChange}
                              />
                              </div>
                              <div className="col-md-8">

                               <input
                                type="date"
                                name="revision_date"
                                className="form-control"
                                placeholder="Revision Date"
                                value={user.revision_date}
                                onChange={handleChange}
                              />
                              </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Is Elective course?
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="is_elective"
                                id="class"
                                className="form-control"
                                value={user.is_elective}
                                onChange={handleChange}
                              >
                                <option value="0">NO</option>
                                <option value="1">YES</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Is this Course Offered in other branches?
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="is_other_branches"
                                id="class"
                                className="form-control"
                                value={user.is_other_branches}
                                onChange={handleChange}
                              >
                                 <option value="0">NO</option>
                                <option value="1">YES</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Is Non Gradial Course?
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="is_non_gradial"
                                id="class"
                                className="form-control"
                                value={user.is_non_gradial}
                                onChange={handleChange}
                              >
                                <option value="0">NO</option>
                                <option value="1">YES</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Course Type
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="type"
                                id="class"
                                className="form-control"
                                value={user.type}
                                onChange={handleChange}
                              >
                                <option value="">Select Course Type</option>
                                <option value="LECTURE">Theory</option>
                                <option value="NON_LECTURE">Non Lecture</option>
                                <option value="PRACTICAL">Practical</option>
                                <option value="SGT">SGT</option>
                                <option value="SDL">SDL</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Is feedback to be filled?
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="is_feedback"
                                id="class"
                                className="form-control"
                                value={user.is_feedback}
                                onChange={handleChange}
                              >
                                <option value="0">NO</option>
                                <option value="1">YES</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Is Open Elective?
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="is_open_elective"
                                id="class"
                                className="form-control"
                                value={user.is_open_elective}
                                onChange={handleChange}
                              >
                                <option value="0">NO</option>
                                <option value="1">YES</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Credits
                              </label>
                              <input
                                type="number"
                                name="credit"
                                value={user.credit}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Course Credits"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <hr />
                            <h2 className="card-title text-danger"><b>Teaching Scheme</b></h2>
                            <br />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Lecture / Theory Hours
                              </label>
                              <input
                                type="number"
                                name="lecture_hours"
                                value={user.lecture_hours}
                                onChange={handleChange}
                                className="form-control"
                                placeholder=" Enter Lecture / Theory Hours"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Unit
                              </label>
                              <select
                                name="lecture_unit"
                                id="class"
                                className="form-control"
                                value={user.lecture_unit}
                                onChange={handleChange}
                              >
                                <option value="week">Per Week</option>
                                <option value="day">Per Day</option>
                                <option value="month">Per Month</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Lab / Clinical / Practical Hours
                              </label>
                              <input
                                type="number"
                                name="lab_hours"
                                value={user.lab_hours}
                                onChange={handleChange}
                                className="form-control"
                                placeholder=" Enter Lab / Clinical / Practical Hours"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Unit
                              </label>
                              <select
                                name="lab_unit"
                                id="class"
                                className="form-control"
                                value={user.lab_unit}
                                onChange={handleChange}
                              >
                                <option value="week">Per Week</option>
                                <option value="day">Per Day</option>
                                <option value="month">Per Month</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Tut / Field Work Hours
                              </label>
                              <input
                                type="number"
                                name="tut_hours"
                                value={user.tut_hours}
                                onChange={handleChange}
                                className="form-control"
                                placeholder=" Enter Tut / Field Work Hours"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Unit
                              </label>
                              <select
                                name="tut_unit"
                                id="class"
                                className="form-control"
                                value={user.tut_unit}
                                onChange={handleChange}
                              >
                                <option value="week">Per Week</option>
                                <option value="day">Per Day</option>
                                <option value="month">Per Month</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Seminar Project Hours
                              </label>
                              <input
                                type="number"
                                name="seminar_hours"
                                value={user.seminar_hours}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Seminar Project Hours"
                              />
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Unit
                              </label>
                              <select
                                name="seminar_unit"
                                id="class"
                                className="form-control"
                                value={user.seminar_unit}
                                onChange={handleChange}
                              >
                                <option value="week">Per Week</option>
                                <option value="day">Per Day</option>
                                <option value="month">Per Month</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <hr /><h2 className="card-title text-danger"><b>Examination Scheme</b></h2>
                            <br />
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Internal Max Marks
                              </label>
                              <input
                                type="number"
                                name="internal_max_marks"
                                value={user.internal_max_marks}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Internal Maximum Marks"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                              Internal Min Marks
                              </label>
                              <input
                                type="number"
                                name="internal_min_marks"
                                value={user.internal_min_marks}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Internal Minimum Marks"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                              Viva Max Marks
                              </label>
                              <input
                                type="number"
                                name="viva_max_marks"
                                value={user.viva_max_marks}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Viva Maximum Marks"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                              Viva Min Marks
                              </label>
                              <input
                                type="number"
                                name="viva_min_marks"
                                value={user.viva_min_marks}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Viva Minimum Marks"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                              Midterm Max Marks
                              </label>
                              <input
                                type="number"
                                name="midterm_max_marks"
                                value={user.midterm_max_marks}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Midterm Maximum Marks"
                              />
                            </div>
                          </div>

                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                              Midterm Min Marks
                              </label>
                              <input
                                type="number"
                                name="midterm_min_marks"
                                value={user.midterm_min_marks}
                                onChange={handleChange}
                                className="form-control"
                                placeholder="Enter Midterm Minimum Marks"
                              />
                            </div>
                          </div>

                        </>
                      ) : null}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ml-auto">
                        {/* <button class="btn btn-nex btn-rounded btn-sm " type="submit" name="add" onclick= "return false"><i class="fa fa-plus" aria-hidden="true" ></i> Add lesson</button>
                              <br><br> */}
                        {addNew == false && edit == false ? (
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1 "
                            type="submit"
                            name="submit"
                            onClick={handleSearch}
                          >
                            <i className="fa fa-search" aria-hidden="true" />{" "}
                            Search
                          </button>
                        ) : null}

                        {edit == false ? (
                          <>
                            {addNew == false ? (
                              <button
                                className="btn btn-nex btn-rounded float-lg-right mx-1 "
                                type="submit"
                                name="submit"
                                onClick={() => setAddNew(true)}
                              >
                                <i className="fa fa-add" aria-hidden="true" /> +
                                Add New
                              </button>
                            ) : (
                              <>
                                <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1"
                                  type="submit"
                                  name="submit"
                                  onClick={handleSubmit}
                                >
                                  <i
                                    className="fa fa-save"
                                    aria-hidden="true"
                                  />{" "}
                                  Save
                                </button>
                                <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                  type="submit"
                                  name="submit"
                                  // style={{aspectRatio:'1/1'}}
                                  onClick={() => {
                                    setEdit(false);
                                    setAddNew(false);
                                  }}
                                >
                                  {"<   "}&nbsp;&nbsp;&nbsp;{" "}
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  />
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1"
                            type="submit"
                            name="submit"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Edit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title"> Courses List</h4>
                      </div>
                    </div>
                    <hr />
                    <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th> Sl. No.</th>
                          <th>Department</th>
                          <th>Class</th>
                          <th>Semester</th>
                          <th>Course Name</th>
                          <th>Course Code</th>
                          <th>Course Type</th>
                          <th>Credits</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((d, key) => {
                          return (
                            <tr>
                              <td>{key + 1}</td>
                              <td>
                                {
                                  departmentOpt.find(
                                    (item) => item.id == d.department_id
                                  )?.name
                                }
                              </td>

                              <td>
                                {
                                  classOpt?.find(
                                    (item) => item.id == d.class_id
                                  )?.name
                                }
                              </td>
                              <td>
                                {
                                  semOpt?.find(
                                    (item) => item.id == d.semester_id
                                  )?.name
                                }
                              </td>
                              <td>{d.name}</td>
                              <td>{d.code}</td>
                              <td>{d.type}</td>
                              <td>{d.credit}</td>

                              <td>
                                <acronym title="Edit">
                                  <a
                                    href="javascript:void(0)"
                                    className="badge badge-light"
                                    onClick={() => {
                                      setEdit(true);
                                      setUser({ ...d });
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit "
                                      aria-hidden="true"
                                    />
                                  </a>
                                </acronym>
                                <acronym title="Inactive">
                                  <a
                                    href="javascript:void(0)"
                                    className=" badge badge-light ml-2"
                                    onClick={() => {
                                      handleDelete(d);
                                    }}
                                  >
                                    <i
                                      className="fa fa-thumbs-down"
                                      aria-hidden="true"
                                      style={{ color: "red" }}
                                    />
                                  </a>
                                </acronym>
                                <a href="javascript:void(0)"> </a>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {data?.length == 0 ? <Nodata /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubjects;
