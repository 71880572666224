import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDCARD_TEMPLATE_CREATE, IDCARD_TEMPLATE_GET, IDCARD_TEMPLATE_UPDATE } from '../../utils/InfoUploadingApiConstants';
import { toast } from 'react-toastify';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { getFileUrl } from '../../Helpers/Helpers';
import DocumentsModal from '../../modals/Students/DocumentsModal';
import IdCardModal from '../../modals/IdCardModal';

function IdCardTemplate({ setLoading }) {

  const navigate = useNavigate()

  const fileref = useRef(null);
  let role = sessionStorage.getItem("role");

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false)
  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");

  const [modalData, setModalData] = useState([]);

  const [info, setInfo] = useState({
    background_img: "",
    logo: "",
    signature: "",
    id_card_title: "",
    header_color: "",
    class_name: "", 
    father_name: "",
    mother_name: "",
    student_address: "",
    phone: "",
    date_of_birth: "",
    blood_grp: "",
    design_type: "Horizontal",
    barcode_type: "Barcode",
    height: "",
    width: "",
  });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setInfo((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
    setInfo((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };

  const clearData = () => {
    setInfo({
        background_img: "",
        logo: "",
        signature: "",
        id_card_title: "",
        header_color: "",
        class_name: "",
        father_name: "",
        mother_name: "",
        student_address: "",
        phone: "",
        date_of_birth: "",
        blood_grp: "",
        design_type: "",
        barcode_type: "",
        height: "",
        width: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {

    setLoading(1);
    const config = {
      method: "post",
      url: IDCARD_TEMPLATE_CREATE,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: info,
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        getData();
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  console.log("info -", data);

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.background_img = d;
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange2 = async (e) => {
    console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      
      info.logo = d;
      
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange3 = async (e) => {
    console.log(e.target.files[0]);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
     
      info.signature = d;
    } catch (error) {
      console.log(error);
    }
  };

  console.log("info?.class_name -", info?.class_name);

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: IDCARD_TEMPLATE_GET,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    console.log('url - ', IDCARD_TEMPLATE_GET)

    await axios(config)
      .then((res) => {
        setData(res.data.data);
        console.log("data -", data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    getData();
  },[]);

  const handleEdit = async () => {
    setLoading(1)
    const config = {
      method: "put",
      url: IDCARD_TEMPLATE_UPDATE + `/${info?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...info,
        class_name: info.class_name ? 1 : 0, // Convert boolean values to 0 or 1
      father_name: info.father_name ? 1 : 0,
      mother_name: info.mother_name ? 1 : 0,
      student_address: info.student_address ? 1 : 0,
      phone: info.phone ? 1 : 0,
      date_of_birth: info.date_of_birth ? 1 : 0,
      blood_grp: info.blood_grp ? 1 : 0,
      },
    }

    console.log("info-update", info);
    console.log("info?.id -", info?.id)

    await axios(config)
      .then(res => {
        setLoading(0)
        toast.success("Data Updated successfully")
        clearData();
        getData();
      })
      .catch(err => {
        setLoading(0)
        console.log(err);
        toast.error('Something went wrong')
      })
  }


  const handleDelete = async (i) => {
    setLoading(1)
    const config = {
      method: "put",
      url: `${IDCARD_TEMPLATE_UPDATE}/${i?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        status: "INACTIVE"
      },
    }
    axios(config)
      .then(res => {
        setLoading(0)
        toast.success('Data Deleted')
        getData();
      })
      .catch(err => {
        setLoading(0)
        toast.error('Some Error Occured')
      })
  }

  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleView = (id) => {
    // Do something with the ID, such as displaying it in the modal
    console.log("Viewing item with ID:", id);
    // You can also store the ID in state if needed
    setSelectedItemId(id);
    // Or perform any other action based on the ID
};

  return (
    <div>
       <>
       <DocumentsModal title={title} img={link} setLink={setLink}/>
       <IdCardModal 
          id={selectedItemId}
          setLoading={setLoading} 
          data={modalData}
       />
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">ID CARD TEMPLATE</h4>                  
                            </div>
                        </div>
                    </div>
    
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="">Id Card Title</label>
                                                <input type="text"
                                                    placeholder="Enter the Id Card Title"
                                                    className="form-control"
                                                    name="id_card_title"
                                                    value={info?.id_card_title}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="">Background Image</label>
                                                <input type="file"
                                                    placeholder="Attach the file"
                                                    className="form-control"
                                                    name="background_img"
                                                    ref={fileref}
                                                    onChange={(e) => {
                                                      handleChange1(e);
                                                    }}
                                                />
                                                {info?.background_img?.length > 0 ? (
                                                  <button
                                                    onClick={() => {
                                                      setLink(info?.background_img);
                                                      setTitle("Background Image");
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#DocumentsModal"
                                                    className="btn btn-primary btn-sm my-1"
                                                  >
                                                    View
                                                  </button>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="">Logo</label>
                                                <input type="file"
                                                    placeholder="Attach the file"
                                                    className="form-control"
                                                    name="logo"
                                                    ref={fileref}
                                                    onChange={(e) => {
                                                      handleChange2(e);
                                                    }}
                                                />
                                                {info?.logo?.length > 0 ? (
                                                  <button
                                                    onClick={() => {
                                                      setLink(info?.logo);
                                                      setTitle("Logo");
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#DocumentsModal"
                                                    className="btn btn-primary btn-sm my-1"
                                                  >
                                                    View
                                                  </button>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="">Signature</label>
                                                <input type="file"
                                                    placeholder="Attach the file"
                                                    className="form-control"
                                                    name="signature"
                                                    ref={fileref}
                                                    onChange={(e) => {
                                                      handleChange3(e);
                                                    }}
                                                />
                                                {info?.signature?.length > 0 ? (
                                                  <button
                                                    onClick={() => {
                                                      setLink(info?.signature);
                                                      setTitle("Signature");
                                                    }}
                                                    data-toggle="modal"
                                                    data-target="#DocumentsModal"
                                                    className="btn btn-primary btn-sm my-1"
                                                  >
                                                    View
                                                  </button>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="">Header Color</label>
                                                <input type="color"
                                                    placeholder="Enter The Header Color"
                                                    className="form-control"
                                                    name="header_color"
                                                    value={info?.header_color}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                    Design Type
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    name="design_type"
                                                    id="design_type"
                                                    className="form-control"
                                                    value={info?.design_type}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Horizontal">Horizontal</option>
                                                    <option value="Vertical">Vertical</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                    Barcode Type
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <select
                                                    name="barcode_type"
                                                    id="barcode_type"
                                                    className="form-control"
                                                    value={info?.barcode_type}
                                                    onChange={handleChange}
                                                >
                                                    <option value="Barcode">Barcode</option>
                                                    <option value="QR Code">QR Code</option>
                                                    <option value="None">None</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="">Height Size (in cm)</label>
                                                <input type="number"
                                                    placeholder="Enter size in centimeter (cm)"
                                                    className="form-control"
                                                    name="height"
                                                    value={info?.height}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor="">Width Size (in cm)</label>
                                                <input type="number"
                                                    placeholder="Enter size in centimeter (cm)"
                                                    className="form-control"
                                                    name="width"
                                                    value={info?.width}
                                                    onChange={(e) => {
                                                      handleChange(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
        
                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class=" form-check-label mr-2" htmlFor="switch1"> Class :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch1" 
                                                    switch="success"
                                                    name="class_name" 
                                                    checked={info?.class_name == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, class_name: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                    
                                                />
                                                <label for="switch1" data-on-label="Yes" data-off-label="No" >.</label>
                                            </div>
                                        </div>

                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class="mr-2"> Father Name :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch2" 
                                                    switch="success"
                                                    name="father_name" 
                                                    checked={info?.father_name == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, father_name: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                />
                                                <label for="switch2" data-on-label="Yes" data-off-label="No" >.</label>
                                            </div>
                                        </div>

                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class="mr-2"> Mother Name :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch3" 
                                                    switch="success"
                                                    name="mother_name" 
                                                    checked={info?.mother_name == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, mother_name: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                    
                                                />
                                                <label for="switch3" data-on-label="Yes" data-off-label="No" >.</label>
                                            </div>
                                        </div>

                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class="mr-2"> Student Address :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch4" 
                                                    switch="success"
                                                    name="student_address" 
                                                    checked={info?.student_address == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, student_address: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                    
                                                />
                                                <label for="switch4" data-on-label="Yes" data-off-label="No" >.</label>
                                            </div>
                                        </div>

                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class="mr-2"> Phone :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch5" 
                                                    switch="success"
                                                    name="phone" 
                                                    checked={info?.phone == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, phone: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                />
                                                <label for="switch5" data-on-label="Yes" data-off-label="No" className="ml-2" >.</label>
                                            </div>
                                        </div>

                                        <div className='col-md-2'>

                                        </div>

                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class="mr-2"> Date Of Birth :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch6" 
                                                    switch="success"
                                                    name="date_of_birth" 
                                                    checked={info?.date_of_birth == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, date_of_birth: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                />
                                                <label for="switch6" data-on-label="Yes" data-off-label="No" >.</label>
                                            </div>
                                        </div>

                                        <div className='col-md-2'>
                                        <br/>
                                        <br/>
                                            <div className='form-check'>
                                                <label for class="mr-2"> Blood Group :</label>
                                                <input
                                                    type='checkbox' 
                                                    id="switch7" 
                                                    switch="success"
                                                    name="blood_grp" 
                                                    checked={info?.blood_grp == 1}
                                                    onChange={(e)=> {
                                                        setInfo({...info, blood_grp: e.target.checked ? 1 : 0})
                                                    }}
                                                    data-on-label="Yes"
                                                    data-off-label="No"
                                                />
                                                <label for="switch7" data-on-label="Yes" data-off-label="No" >.</label>
                                            </div>
                                        </div>

                {/* <div className="row "> */}
                    <div className="col-md-12 mr-5">
                      {
                        edit == false ? <button
                          className="btn btn-nex btn-rounded float-right "
                          type="submit"
                          name="submit"
                          onClick={handleSubmit}
                        >
                          <i className="fa fa-save" aria-hidden="true" /> Save
                        </button> :
                          <button
                            className="btn btn-nex btn-rounded float-right "
                            type="submit"
                            name="Update"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Update
                          </button>
                      }
                    </div>
                {/* </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-4">
                                            {" "}
                                            <h4 className="card-title"> ID CARD TEMPLATE LIST</h4>
                                        </div>
                                    </div>

                                    <table
                                      id="datatable"
                                      className="table table-bordered dt-responsive nowrap table-hover "
                                      style={{
                                        borderCollapse: "collapse",
                                        borderSpacing: 0,
                                        width: "100%",
                                      }}
                                    >

                                        <thead>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th>ID Card Title</th>
                                                <th>Design Type</th>
                                                <th>Barcode Type</th>
                                                <th>Class</th>
                                                <th>Father_name</th>
                                                <th>Mother Name</th>
                                                <th>Student Address</th>
                                                <th>Phone</th>
                                                <th>Date of Birth</th>
                                                <th>Blood_grp</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                          {data?.map((item, key) => {
                                            return (
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() => {
                                                            handleView(item.id);
                                                            setModalData(item);
                                                        }
                                                        }
                                                        data-toggle="modal"
                                                        data-target="#IdCardModal"
                                                    >
                                                    {item?.id_card_title} -({item?.height + "x" + item?.width })
                                                    <i
                                                      aria-hidden="true"
                                                      style={{ color: "blue" }}
                                                    />
                                                    </a>
                                                </td>
                                                <td>{item?.design_type}</td>
                                                <td>{item?.barcode_type}</td>
                                                <td>{item?.class_name == "1" ? "Yes" : "No"}</td>
                                                <td>{item?.father_name == "1" ? "Yes" : "No"}</td>
                                                <td>{item?.mother_name == "1" ? "Yes" : "No"}</td>
                                                <td>{item?.student_address == "1" ? "Yes" : "No"}</td>
                                                <td>{item?.phone == "1" ? "Yes" : "No"}</td>
                                                <td>{item?.date_of_birth == "1" ? "Yes" : "No"}</td>
                                                <td>{item?.blood_grp == "1" ? "Yes" : "No"}</td>
                                                <td>
                            <acronym title="Edit">
                              <a href="javascript:void(0)" 
                                 onClick={() => { 
                                    setEdit(true); 
                                    setInfo({ ...item }) 
                                 }}
                              >
                                <i className="fa fa-edit " aria-hidden="true" />
                              </a>
                            </acronym> &nbsp;&nbsp;&nbsp;
                            <acronym title="Inactive">
                              <a
                                href="javascript:void(0)"
                                onClick={() => handleDelete(item)}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                  style={{ color: "red" }}
                                />
                              </a>
                              </acronym>
                          </td>
                                            </tr>
                                            )
                                          })}
                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>
      
                </div>
            </div>
        </div>
        </>
    </div>
  )
}

export default IdCardTemplate;
