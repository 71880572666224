import axios from "axios";
import React, { useState , useEffect} from "react";
import { CONFIRM_PASSWORD } from "../../utils/apiConstants";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import getUniversityDetails from "../../utils/universityDetails.api";
import validator from 'validator';

function ForgotPassword({ setCollegeId, changeCollege }) {
  //UNIVERSITY DETAILS START
const [universityData, setUniversityData] = useState([]);
const [showPassword, setShowPassword] = useState(false);


const [errorMessage, setErrorMessage] = useState('') ;
const [matchingError, setMatchingError] = useState('');
const [color, setColor] = useState('red');
const [matchingColor, setMatchingColor] = useState('red');
  
const validate = (value) => { 

    if (validator.isStrongPassword(value, { 
        minLength: 8, minLowercase: 1, 
        minUppercase: 1, minNumbers: 1, minSymbols: 1 
    })) { 
        setErrorMessage('Strong Password'); 
        setColor('green');
    } else { 
        setErrorMessage('Weak Password'); 
        setColor('red');
    } 
} 

useEffect(() => {
  const fetchData = async () => {
    try {
      const data = await getUniversityDetails();
      setUniversityData(data);
    } catch (error) {
      console.error(`Error fetching university details: ${error.message}`);
    }
  };

  fetchData();
}, []);

//UNIVERSITY DETAILS END
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const validateMatching = (value) => {
    if(password == value) {
      setMatchingError("Matching");
      setMatchingColor("green");
    }
    else {
      setMatchingError("Not Matching");
      setMatchingColor("red");
    }
  }

  const [loading, setLoading] = useState(0);

  const { id } = useParams();

  const navigate = useNavigate();

  const handeResetPassword = async (e) => {
    e.preventDefault();
    if (!password) return toast.error("Password is Required");
    if (!cpassword) return toast.error("Confirm Password is Required");
    if (password !== cpassword)
      return toast.error("New Password and Confirm Password should be same");
    setLoading(1);
    const config = {
      method: "post",
      url: CONFIRM_PASSWORD,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${id}`,
      },
      data: {
        password: password,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success(res?.data?.message);
        setCPassword("");
        setPassword("");
        setMatchingError("Not Matching");
        setMatchingColor("red");
        setErrorMessage('Weak Password'); 
        setColor('red');
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        setCPassword("");
        setPassword("");
        setMatchingError("Not Matching");
        setMatchingColor("red");
        setErrorMessage('Weak Password'); 
        setColor('red');
      });
    setLoading(0);
  };

  return (
    <div className="Login">
      <Loader loading={loading} />
      <>
        <div>
          <div className="container-fluid p-0 ">
            <div className="row no-gutters">
              <div className="col-lg-4 m-auto">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        {/* <div>
                              <a href="javascript:void(0)" className="logo">
                                <img
                                  src={universityData.logo_secondary}
                                  height={50}
                                  alt="logo"
                                />
                              </a>
                            </div> */}
                        <div>
                          <p><b>{universityData.name}</b></p>
                          {/* <div className="text-center"> */}
                            {/* <div>
                              <a href="javascript:void(0)" className="logo">
                                <img
                                  src={universityData.logo_secondary}
                                  height={50}
                                  alt="logo"
                                />
                              </a>
                            </div> */}
                            {/* <h4 className="font-size-18 mt-2">Welcome !</h4> */}
                            {/* <p className="text-muted">
                              Sign in to continue to <br /> {universityData.name} Portal.
                            </p> */}
                            {/* <br /> */}
                            <p>1. Password should have minimum of 8 characters.</p>
                            <p>2. Must have one uppercase and one lowercase letter.</p>
                            <p>3. Must have one number and one special character.</p>
                          {/* </div> */}
                          <div className=" mt-3">
                            <form className="form-horizontal">
                              <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon" style={{position: "absoulte", top: "30%"}}/>
                                <label htmlFor="userpassword">Password</label>
                                <input
                                  type={showPassword ? "text" : "password"}
                                  // type="password"
                                  className="form-control"
                                  placeholder="Enter New Password"
                                  value={password}
                                  onChange={(e) => {
                                    setPassword(e.target.value);
                                    setCPassword('');
                                    setMatchingError('Not Matching');
                                    setMatchingColor('red');
                                    validate(e.target.value)
                                  }}
                                />
                                <i
                                    className={`${
                                      showPassword
                                        ? "ri-eye-line"
                                        : "ri-eye-close-line"
                                    } password-toggler`}
                                    style={{
                                      position: "absolute",
                                      top: "30%",
                                      right: "15px",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      transform: "translateY(-25%)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  ></i>
                                  <span style={{ 
                                      fontWeight: 'bold',
                                      color: color, 
                                  }}>{errorMessage}</span>
                              </div>

                              <div className="form-group auth-form-group-custom mb-4">
                              <i className="ri-lock-2-line auti-custom-input-icon" style={{position: "absoulte", top: "30%"}}/>
                                <label htmlFor="userpassword">
                                  Confirm Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Confirm New Password"
                                  value={cpassword}
                                  onChange={(e) => {
                                    setCPassword(e.target.value);
                                    validateMatching(e.target.value);
                                  }}
                                /> 
                                  <span style={{ 
                                      fontWeight: 'bold',
                                      color: matchingColor, 
                                  }}>{matchingError}</span> 
                              </div>

                              <div className="mt-4 text-center">
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                  name="submit"
                                  value="login"
                                  onClick={handeResetPassword}
                                  disabled={color == 'red' || matchingColor == 'red'}
                                >
                                  Reset Password
                                </button>
                              </div>

                              <div className="mt-3 text-center">
                                <a
                                  onClick={() => {
                                    navigate("/");
                                  }}
                                  className=" cursor-pointer text-muted"
                                >
                                  Go to Login
                                </a>
                              </div>
                            </form>
                          </div>
                          <div className="text-center mt-2">
                            <p> Crafted with{" "}
                              <i className="mdi mdi-heart text-danger" /> by{" "}
                              <a href="https://www.nexenstial.com/">
                                {" "}
                                Nexenstial{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-8 d-sm-flex justify-content-center align-items-center d-none "
                style={{ maxHeight: "100vh", overflow: "hidden" }}
              >
                <div
                  id="carousel-example-1z"
                  className="carousel slide mb-5"
                  data-ride="carousel"
                >
                  <ol className="carousel-indicators">
                    <li
                      data-target="#carousel-example-1z"
                      data-slide-to={0}
                      className="active"
                    />
                    <li data-target="#carousel-example-1z" data-slide-to={1} />
                    <li data-target="#carousel-example-1z" data-slide-to={2} />
                    <li data-target="#carousel-example-1z" data-slide-to={3} />
                  </ol>
                  <div className="carousel-inner" role="listbox">
                    <div
                      className="carousel-item active"
                      style={{ height: "100vh" }}
                    >
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_1}
                        alt="First slide"
                      />
                    </div>
                    <div className="carousel-item" style={{ height: "100vh" }}>
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_2}
                        alt="Second slide"
                      />
                    </div>
                    <div className="carousel-item" style={{ height: "100vh" }}>
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_3}
                        alt="Third slide"
                      />
                    </div>
                    <div className="carousel-item" style={{ height: "100vh" }}>
                      <img
                        className="d-block w-100 h-100"
                        src={universityData.banner_img_4}
                        alt="Third slide"
                      />
                    </div>
                  </div>
                  <a
                    className="carousel-control-prev"
                    href="#carousel-example-1z"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="carousel-control-next"
                    href="#carousel-example-1z"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default ForgotPassword;
