import React, { useState } from "react";
import papa from "papaparse";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { EMPLOYEE_ADD_BULK_ENTITLEMENT } from "../../utils/apiConstants";
import axios from "axios";
import { Link } from "react-router-dom";

function ModalEntitlementUpload({ setLoading,setShowBulkResult,setBulkResult }) {
  const [data, setData] = useState();

  var commonConfig = { delimiter: "," };

  const handleUpload = async () => {
    setLoading(1);
    const config = {
      method: "post",
      url: EMPLOYEE_ADD_BULK_ENTITLEMENT,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { employeeData: data },
    };

    console.log('data - ', data);

    await axios(config)
      .then((res) => {
        console.log('result - ', res.data.result)
        setBulkResult(res.data.result);
        setShowBulkResult(true);
      })
      .catch((err) => {
        console.log("Bulk Upload Error : " + err);
        toast.error("Something went wrong");
      });

    setLoading(0);
  };

  const handleProcessCSV = (d) => {
    console.log('d - ', d);
    const arr = [];
    for (const i of d) {
        let fromFormattedDate="";
        let toFormattedDate="";
        if(i["From Date"]) {
            const [fday, fmonth, fyear] = i["From Date"]?.split('-');
            fromFormattedDate = `${fyear}-${fmonth}-${fday}`;
        }
        if(i["To Date"]) {
            const [tday, tmonth, tyear] = i["To Date"]?.split('-');
            toFormattedDate = `${tyear}-${tmonth}-${tday}`;
        }
        let obj = {};
        obj = {
          s_no: i["s_no"],
          staff_id: i["Staff ID"],
          name: i["Name"]?.trim(),
          year: i["Year"]?.trim(),
          leave_type_code: i["Leave Type Code"],
          earning_policy_code: i["Earning Policy Code"],
          total_leaves: i["Total Leaves"],
          from_date: fromFormattedDate,
          to_date: toFormattedDate,
          taken: i["Taken"],
          balance: i["Balance"],
          work_flow_code: i["Workflow Code"]
        };
      arr.push(obj);
      console.log('obj - ', obj);
    }
    console.log(arr);
    setData(arr);
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    // console.log("hi")
    if (files) {
      papa.parse(files[0], {
        ...commonConfig,
        header: true,
        complete: async (res) => {
          console.log("com", res);
          await handleProcessCSV(res.data);
        },
      });
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="ModalEntitlementUpload"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="ModalEntitlementUpload"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="ModalEntitlementUpload">
                Import CSV File
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <ol>
                <li>
                
                  The first line of
                  your CSV file should be the column headers as in the table
                  example.
                </li>
                <li>
                    Make sure that your file is UTF-8 to avoid
                    unnecessary encoding problems.
                </li>
                <li>
                    Employee Staff Id is required.
                </li>
                <li>Accepted Date Format : <b>DD-MM-YYYY</b></li>
              </ol>
              <br />
              <p className="alert alert-danger">
                 Note : All the Fields mentioned in CSV file are required before Upload.
              </p>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="validationCustom02">
                      Select CSV File
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                    <br />
                    <p className="text-danger">*only csv format is allowed</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                name="submit"
                value="bulk"
                onClick={handleUpload}
                data-dismiss="modal"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEntitlementUpload;
