import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { JOB_OPENINGS, JOB_APPLICATIONS } from "../../../utils/apiConstants";
import { SearchOutlined } from "@ant-design/icons";
import { Select, Button, Input, Space, Table, Tag } from "antd";
import Highlighter from "react-highlight-words";
import ModalReviewJobApplication from "./ModalReviewJobApplication";
import { ROUTES } from "../../../Router/routerConfig";

function ReviewJobApplications({ collegeId, setLoading }) {
  const [jobOpenings, setJobOpenings] = useState([]);

  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const [application, setApplication] = useState([]);

  const [status, setStatus] = useState("");

  const [state, setState] = useState({});

  const navigate = useNavigate();

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (_, data) => {
        return (
          <>
            <img 
              src={data?.photo} 
              alt="profile photo" 
              width="80px"
              height="80px"
              style={{ aspectRatio: "1/1", border: "1px solid black" }}  
            />
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name - b.name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Applied for",
      dataIndex: "job_name",
      key: "job_name",
      ...getColumnSearchProps("job_name"),
      sorter: (a, b) => a.job_name - b.job_name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Applied Date",
      dataIndex: "application_date",
      key: "application_date",
      ...getColumnSearchProps("application_date"),
      sorter: (a, b) => a.application_date - b.application_date,
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    //   key: "email",
    // },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      ...getColumnSearchProps("gender"),
      sorter: (a, b) => a.gender - b.gender,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      ...getColumnSearchProps("age"),
    },
    {
      title: "Status",
      dataIndex: "selection_status",
      key: "selection_status",
      ...getColumnSearchProps("selection_status"),
      sorter: (a, b) => a.selection_status - b.selection_status,
      sortDirections: ["descend", "ascend"],
      render: (_, data) => {
        // let color;
        // if (data.selection_status === "PENDING_APPLICATION") {
        //   color = "	#ffcc00";
        // } else if (data.selection_status === "APPROVED") {
        //   color = "green";
        // } else if (data.selection_status === "REJECTED") {
        //   color = "red";
        // }

        return (
          <span
            className={`badge badge-soft-${
              data.selection_status === "PENDING_APPLICATION"
                ? "warning"
                : data.selection_status === "CALLED_FOR_INTERVIEW"
                ? "primary"
                : data.selection_status === "SELECTED_INTERVIEW"
                ? "success"
                : "danger"
            }`}
          >
            {data.selection_status === "PENDING_APPLICATION"
              ? "Review Pending"
              : data.selection_status === "CALLED_FOR_INTERVIEW"
              ? "Called for Interview"
              : data.selection_status === "REJECTED_APPLICATION"
              ? "Application Rejected"
              : data.selection_status === "SELECTED_INTERVIEW"
              ? "Selected Candidate"
              : data.selection_status}
          </span>
          // <Tag color={color} tag={data.selection_status}>
          //   <b>{data.selection_status}</b>
          // </Tag>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, data) => {
        return (
          <>
            <Button
              // style={{ backgroundColor: "#4CA64C", color: "white" }}
              className="mb-1 px-2"
              title="Update Status"
              type="primary"
              data-toggle="modal"
              data-target="#ModalPayRollEarning"
              onClick={() => setApplication(data)}
            >
              <i
                className="fa fa-edit"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />
            </Button>
            <Button
              // style={{ backgroundColor: "#4CA64C", color: "white" }}
              title="View Application"
              type="primary"
              className="ml-2 px-2"
              onClick={() => navigate(`${ROUTES.HR.Interview.JobApplicationProfile}/${data.id}`)}
            >
              <i
                className="fas fa-eye p-0"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />
            </Button>
            {data?.selection_status == "SELECTED_INTERVIEW" ? <Button
              // style={{ backgroundColor: "#4CA64C", color: "white" }}
              title="Add as Employee"
              type="primary"
              className="ml-2 px-2"
              onClick={() => navigate(`${ROUTES.HR.Employee.AddStaff}/${data.id}`)}
            >
              <i
                className="fas fa-plus p-0"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />
            </Button> : <></>}
          </>
        );
      },
    },
  ];

  const getJobOpenings = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: JOB_OPENINGS,
    };

    await axios(config)
      .then((res) => {
        console.log("job openings data - ", res.data.data);
        setJobOpenings(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const getApplications = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: JOB_APPLICATIONS,
    };

    await axios(config)
      .then((res) => {
        let tempData = res.data.data;

        for (let i of tempData) {
          i.name = i?.first_name + " " + i?.last_name;
          i.age = calculateAge(i?.dob);
          i.application_date = formatDate(i?.createdAt?.split('T')[0])
        }
        console.log("applications data - ", res.data.data);
        setData(tempData);
        setDisplayData(tempData);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  // const handleSubmit = async () => {
  //   if (!user.title) {
  //     return toast.error("Please Enter title of Job Opening");
  //   }
  //   console.log("data to backend - ", user);
  //   setLoading(1);
  //   const config = {
  //     method: "post",
  //     url: JOB_OPENINGS,
  //     headers: {
  //       Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
  //       "Content-Type": "application/json",
  //     },
  //     data: user,
  //   };

  //   await axios(config)
  //     .then((res) => {
  //       setLoading(0);
  //       toast.success("Job Opening Added");
  //       getAllData();
  //     })
  //     .catch((err) => {
  //       setLoading(0);
  //       console.log(err);
  //       toast.error("Something went wrong");
  //     });
  // };
  const filterOption = (
    input: string,
    option?: { label: string, value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    // getJobOpenings();
    getApplications();
  }, []);

  useEffect(() => {
    if (status) {
      setDisplayData(data?.filter((s) => s.selection_status == status));
    } else {
      setDisplayData(data);
    }
  }, [status]);

  return (
    <div>
      <ModalReviewJobApplication
        data={application}
        setLoading={setLoading}
        getApplications={getApplications}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Review Job Applications</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Interview</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Review Job Applications
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <h3 className="card-title mr-3">Job Applications</h3>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <h6 className="mt-2">Filter by Status</h6>
                          <Select
                            className="ml-3 mb-3 float-right"
                            showSearch
                            placeholder="Filter by Status"
                            optionFilterProp="children"
                            onChange={(value) => {
                              setStatus(value);
                            }}
                            filterOption={filterOption}
                            value={status}
                            options={[
                              {
                                value: "",
                                label: "All",
                              },
                              {
                                value: "PENDING_APPLICATION",
                                label: "Pending Applications",
                              },
                              {
                                value: "CALLED_FOR_INTERVIEW",
                                label: "Called for Interview",
                              },
                               {
                                value: "SELECTED_INTERVIEW",
                                label: "Select Candidates",
                              },
                              {
                                value: "REJECTED_APPLICATION",
                                label: "Rejected Applications",
                              },
                            ]}
                            style={{ width: "50%" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <table
                      id="datatable"
                      className="table table-bordered dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Sl. No.</th>
                          <th>Name</th>
                          <th>Aplpied for</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Gender</th>
                          <th>Age</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((d, key) => {
                          return (
                            <tr>
                              <td>{key + 1}</td>
                              <td>
                                {d?.first_name} {d?.last_name}
                              </td>
                              <td>
                                {
                                  jobOpenings?.find(
                                    (s) => s.id == d?.job_openings_id
                                  )?.title
                                }
                              </td>
                              <td>{d?.phone}</td>
                              <td>{d?.email}</td>
                              <td>{d?.gender}</td>
                              <td>{calculateAge(d?.dob)}</td>
                              <td>
                                <span
                                  className={`badge badge-soft-${
                                    d?.selection_status == "PENDING"
                                      ? "warning"
                                      : d?.selection_status == "APPROVED"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {d?.selection_status}
                                </span>
                              </td>
                              <td>
                                {d?.selection_status == "PENDING" ? (
                                  <button className="btn btn-sm btn-nex">
                                    Review
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table> */}
                    <Table columns={columns} dataSource={displayData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewJobApplications;
