import React from "react";
import "./Dashboard.scss";
import ReactApexChart from "react-apexcharts";
import Calender from "../../../Components/Calender/Calender";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import {
  DASHBOARD_HR,
  DASHBOARD_HR1,
  DASHBOARD_HR2,
  LEAVE_APPLICATION,
} from "../../../utils/apiConstants";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { REPORT_STAFF_ATTENDANCE } from "../../../utils/Reports.apiConst";
import { EMPLOYEEALL, EMPLOYEE_BIO_IDS } from "../../../utils/apiConstants";

function DashboardHR({ setLoading, collegeId }) {
  console.log("collegeId", collegeId);

  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  //States
  const [data, setData] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [pending, setPending] = useState(0);
  const [upcoming, setUpcoming] = useState(0);

  const [collegeData, setCollegeData] = useState(getCollegeData());

  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, setTotalAbsent] = useState(0);
  const [clgPresent, setClgPresent] = useState(0);
  const [clgAbsent, setClgAbsent] = useState(0);
  const [refresh, setRefresh] = useState(0);

  // console.log(data?.college_staff?.map((s) => s?.count));
  // console.log(data?.college_staff?.map((s) => String(s.college_id)));

  //Get Data
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      // url: DASHBOARD_HR + "?role=" + sessionStorage.getItem(SESSION_ROLE),
      url:
        DASHBOARD_HR +
        `?role=${sessionStorage.getItem(SESSION_ROLE)}&college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getData1 = async () => {
    setLoading(1);
    let count = 0,
      count1 = 0;
    const config = {
      method: "get",
      // url: DASHBOARD_HR + "?role=" + sessionStorage.getItem(SESSION_ROLE),
      url: DASHBOARD_HR1,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setData1(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });

    const config1 = {
      url: LEAVE_APPLICATION,
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const date = new Date();

    await axios(config1)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        try {
          for (var i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].status == "PENDING") {
              count++;
            }
            const dateComp = new Date(res.data.data[i].from_date.split("T")[0]);
            if (
              date.getTime() < dateComp.getTime() &&
              res.data.data[i].status == "APPROVED"
            ) {
              count1++;
            }
          }
          setPending(count);
          setUpcoming(count1);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getData2 = () => {
    setLoading(1);
    const config = {
      method: "get",
      url: DASHBOARD_HR2,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setData2(res.data.data);
        console.log("data2", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  const getEmpAttendance = async () => {
    setLoading(1);
    const user = {
      from_date: getCurrentDate(),
      to_date: getCurrentDate(),
    };

    let dateParts = user.from_date.split("-");
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];
    let from_date = `${day}/${month}/${year}`;
    let to_date = from_date;
    const config1 = {
      method: "get",
      url: `${EMPLOYEE_BIO_IDS}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        let bioIds = res.data.data;
        let bioIds2;

        const config = {
          method: "get",
          url: `${REPORT_STAFF_ATTENDANCE}?from_date=${from_date}&to_date=${to_date}`,
          headers: {
            "Content-Type": "application/json",
          },
        };

        const config2 = {
          method: "get",
          url: `${EMPLOYEE_BIO_IDS}?college_id=${collegeId}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
        axios(config2)
          .then((res) => {
            setLoading(0);
            bioIds2 = res.data.data;
          })
          .catch((err) => {
            setLoading(0);
            toast.error("Something went wrong");
            console.log(err);
          });

        await axios(config)
          .then((res) => {
            let countsByEmpcode = res.data.data;
            let countsByEmpcode2 = countsByEmpcode;
            countsByEmpcode = countsByEmpcode.filter(
              (s) => s?.Empcode && bioIds.includes(s.Empcode)
            );

            let p = 0,
              a = 0;
            countsByEmpcode.map((i, k) => {
              if (i.Status == "P") {
                p += 1;
              } else {
                a += 1;
              }
            });
            setTotalPresent(p);
            setTotalAbsent(a);
            countsByEmpcode2 = res.data.data;
            countsByEmpcode2 = countsByEmpcode2.filter(
              (s) => s?.Empcode && bioIds2?.includes(s.Empcode)
            );

            let p2 = 0,
              a2 = 0;
            countsByEmpcode2.map((i, k) => {
              if (i.Status == "P") {
                p2 += 1;
              } else {
                a2 += 1;
              }
            });
            setClgPresent(p2);
            setClgAbsent(a2);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  //Chart Data 1

  const overviewChartOptions = {
    series: [0, 0, 0, 0, 0],
    labels: ["Ayurveda", "Management", "Engineering", "Law", "Arts"],
    chart: {
      width: 180,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100px",
            height: "100px",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  //Chart Data 2
  const overviewChartOptions2 = {
    series: [
      {
        name: "Male",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Female",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"],
        },
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: [
          "Ayrveda",
          "Engineering",
          "Law",
          "Dental",
          "Management",
          "Arts",
          "Commerce",
        ],
      },
    },
  };

  // function refreshPage() {
  //   if (!refresh) window.location.reload();
  //   setRefresh(1);
  // }

  useEffect(() => {
    getData();
  }, [sessionStorage.getItem(SESSION_ROLE)]);

  useEffect(() => {
    getData1();
    getData2();
    getEmpAttendance();
    // refreshPage();
  }, []);

  useState(() => {
    setCollegeData(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);
  return (
    <div className="DashboardHR">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">
                    Hello , {sessionStorage.getItem("emp_name")}
                  </h4>

                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Staff
                          </p>
                          <h4 className="mb-0">
                            {collegeId == 1111001
                              ? data?.number_of_staff - 1 || 0
                              : data?.number_of_staff || 0}
                          </h4>
                          {/* <h4 className="mb-0">0</h4> */}
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            Today Present Staff
                          </p>
                          <h4 className="mb-0">{clgPresent}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-heart-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {data2 &&
                              data2.filter(
                                (employee) =>
                                  employee.employee_category ==
                                    "TEACHING STAFF" &&
                                  employee.college_id ==
                                    sessionStorage.getItem("college_id")
                              ).length}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-2-fill font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            Total Number of Non Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {collegeId == 1111001
                              ? (data2 &&
                                  data2.filter(
                                    (employee) =>
                                      employee.employee_category ==
                                        "NON-TEACHING STAFF" &&
                                      employee.college_id ==
                                        sessionStorage.getItem("college_id")
                                  ).length - 1) ||
                                0
                              : (data2 &&
                                  data2.filter(
                                    (employee) =>
                                      employee.employee_category ==
                                        "NON-TEACHING STAFF" &&
                                      employee.college_id ==
                                        sessionStorage.getItem("college_id")
                                  ).length) ||
                                0}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-follow-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2"> Overall Employee</p>
                          <h4 className="mb-0">
                            {/* {data1?.number_of_staff - 1 || 0} */}
                            {data2?.length - 1}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2">
                            {" "}
                            Overall Today Present Staff
                          </p>
                          <h4 className="mb-0">{totalPresent}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-heart-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2">
                            {" "}
                            Overall Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {(data2 &&
                              data2.filter(
                                (employee) =>
                                  employee.employee_category == "TEACHING STAFF"
                              ).length) ||
                              0}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-2-fill font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="font-size-14 mb-2">
                            {" "}
                            Overall Non Teaching Staff
                          </p>
                          <h4 className="mb-0">
                            {(data2 &&
                              data2.filter(
                                (employee) =>
                                  employee.employee_category ==
                                  "NON-TEACHING STAFF"
                              ).length - 1) ||
                              0}
                          </h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-follow-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row px-3 mb-2">
                <h6>Employee Leave Overview</h6>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="card ">
                    <div className="card-body ">
                      <p className="text-truncate font-size-14 mb-2">
                        {" "}
                        Pending Leave Approvals
                      </p>
                      <div className="row">
                        <h4 className="pl-3">{pending}</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card ">
                    <div className="card-body ">
                      <p className="text-truncate font-size-14 mb-2">
                        {" "}
                        Upcoming Employee on Leaves
                      </p>
                      <div className="row">
                        <h4 className="pl-3">{upcoming}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                {/* <div
                  className="col-md-6 row d-flex"
                  style={{ maxwidth: "500px" }}
                >
                  <div className="col-12">
                    <div className="card ">
                      <div
                        className="card-body p-5"
                        style={{ maxHeight: "600px", maxWidth: "600px" }}
                      >
                        <h4 className="card-title mb-4">Overview</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions}
                            series={overviewChartOptions.series}
                            type="donut"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card ">
                      <div
                        className="card-body"
                        style={{ maxHeight: "600px", maxWidth: "600px" }}
                      >
                        <h4 className="card-title mb-4">Male vs Female</h4>
                        <div id="chart">
                          <ReactApexChart
                            options={overviewChartOptions2.options}
                            series={overviewChartOptions2.series}
                            type="bar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="card p-3 w-100">
                    <h3 className="my-3">Event Calendar</h3>
                    <Calender setLoading={setLoading} collegeId={collegeId} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body ">
                      <h4 className="card-title mb-4">
                        Pending Leave Approvals
                      </h4>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body ">
                      <h4 className="card-title mb-4">Announcements</h4>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body ">
                      <h4 className="card-title mb-4">
                        Upcoming employee on Leaves
                      </h4>
                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHR;
