import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { ADD_BIOMETRIC_ATTENDANCE } from "../../../utils/apiConstants";

function AttendanceModal({ setLoading, data1, reloadData }) {
  const [user, setUser] = useState({
    fromDate: new Date().toISOString().split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
  });

  const transformedData = data1;

  const [employeeArray, setEmployeeArray] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    console.log(employeeArray);
    //config for axios
    const config = {
      method: "post",
      url: `${ADD_BIOMETRIC_ATTENDANCE}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        biometric_ids: employeeArray?.map((item) => item?.biometric_id),
        fromDate: user?.fromDate,
        toDate: user?.toDate,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        toast.success("Successfully Re-Fetched Data");
        console.log(res);
        setLoading(0);
        setUser({
          fromDate: new Date().toISOString().split("T")[0],
          toDate: new Date().toISOString().split("T")[0],
        });
        setEmployeeArray([]);
        reloadData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
        setLoading(0);
      });
  };

  const onSelect = (selectedList, selectedItem) => {
    console.log(selectedList, selectedItem);
    if (selectedItem.full_name === "ALL") {
      setEmployeeArray(data1?.filter((s) => s.full_name != "ALL"));
    } else {
      const allSelected = selectedList.some((item) => item.full_name === "ALL");
      if (allSelected) {
        setEmployeeArray(data1);
      } else {
        setEmployeeArray(selectedList);
      }
    }
  };

  const onRemove = (selectedList, removedItem) => {
    if (removedItem.full_name === "ALL") {
      setEmployeeArray([]);
    } else {
      const allSelected = selectedList.some((item) => item.full_name === "ALL");
      if (allSelected) {
        setEmployeeArray(
          selectedList.filter((item) => item.full_name !== "ALL")
        );
      } else {
        setEmployeeArray(selectedList);
      }
    }
  };

  return (
    <div className="ModalLeaveReview">
      <div
        className="modal fade"
        id="AttendanceMonthlyModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Biometric Attendance
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>
                Please select least number of days to fetch data faster from API
              </h5>
              <div className="row mt-4">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      From Date <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      max={new Date().toISOString().split("T")[0]}
                      name="fromDate"
                      value={user?.fromDate}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      To Date<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      max={new Date().toISOString().split("T")[0]}
                      name="toDate"
                      value={user?.toDate}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      {" "}
                      Select Employees<span style={{ color: "red" }}>*</span>
                    </label>
                    <Multiselect
                      options={transformedData}
                      selectedValues={employeeArray}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue={"full_name"}
                    />
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-between px-2">
                <button
                  className="btn btn-success btn-rounded btn-outline"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceModal;
