import { ROUTES } from "../../../Router/routerConfig";

const stat = "static";
const drop = "dropdown";

let role = sessionStorage.getItem("role");

var InfoWarden;

InfoWarden = [
    // {
    //     title: "Student Details",
    //     type: stat,
    //     route: ROUTES.Warden.StudentDetails,
    //     icon: <i className="ri-folder-chart-line mr-2" />,
    // },
    {
        title: "Student Details",
        type: stat,
        route: ROUTES.Warden.AssignHostelStudents,
        icon: <i className="fas fa-user mr-2" />,
    },
    {
      title: "HR",
      type: drop,
      icon: <i className="ri-user-fill mr-2"></i>,
      drop: [
        {
          title: "Leave",
          type: drop,
          drop: [
            {
              title: "Entitlement",
              type: stat,
              route: ROUTES.Warden.Leave.Entitlement,
            },
            {
              title: "Application",
              type: stat,
              route: ROUTES.Warden.Leave.Application,
            },
            {
              title: "Schedule",
              type: stat,
              route: ROUTES.Warden.Leave.Schedule,
            },
          ],
        },
      ],
    },
    {
      title: "Attendance",
      type: stat,
      route: ROUTES.Warden.HostelAttendance,
      icon: <i className="fas fa-check-circle mr-2" />, 
    },
    {
      title: "Hostel",
      type: stat,
      route: ROUTES.Warden.Hostel,
      icon: <i className="fas fa-building mr-2" />,
    },
    {
      title: "Hostel Floors",
      type: stat,
      route: ROUTES.Warden.HostelFloors,
      icon: <i className="fas fa-layer-group mr-2" />,
    },
    {
      title: "Room Type",
      type: stat,
      route: ROUTES.Warden.RoomType,
      icon: <i className="fas fa-tag mr-2" />,
    },
    {
      title: "Hostel Rooms",
      type: stat,
      route: ROUTES.Warden.HostelRooms,
      icon: <i className="fas fa-door-closed mr-2" />,
    },
    {
      title: "Bed List",
      type: stat,
      route: ROUTES.Warden.HostelBeds,
      icon: <i className="fas fa-bed mr-2" />,
    },
    {
      title: "Hostel Fees",
      type: stat,
      route: ROUTES.Warden.HostelFees,
      icon: <i className="fas fa-money-bill-wave mr-2" />,
    },
    {
      title: "Hostel Reports",
      type: drop,
      drop: [
        {
          title: "Fee Pending Report",
          type: stat,
          route: ROUTES.Warden.HostelfeePendingReport,
        },
        {
          title: "Hostel Exit Report",
          type: stat,
          route: ROUTES.Warden.HostelRemovalReport,
        },
        {
          title: "Hostel Rooms Report",
          type: stat,
          route: ROUTES.Warden.HostelRoomsReport,
        },
        {
          title: "Department wise Fee Report",
          type: stat,
          route: ROUTES.Warden.HostelDepartmentWiseFeeReport,
        },
      ],
      icon: <i class="fas fa-file-alt mr-2"></i>,
    },
    {
      title: "Gate Pass",
      type: drop,
      icon: <i className="fas fa-id-badge mr-2" />,
      drop: [
        {
          title: "Requests List",
          type: stat,
          route: ROUTES.Warden.ViewStudentGatePass,
          icon: <i className="fas fa-id-badge mr-2" />,
        },
        {
          title: "Add New Request",
          type: stat,
          route: ROUTES.Warden.StudentGatePass,
          icon: <i className="fas fa-id-badge mr-2" />,
        }
      ]
    },
    // {
    //   title: "Hostel Details",
    //   type: stat,
    //   route: ROUTES.Warden.HostelDetails,
    //   icon: <i className="fas fa-money-bill-wave mr-2" />,
    // },      
];

let a = new Set();
InfoWarden = InfoWarden?.filter((s) => {
  if (!a.has(s.title)) {
    a.add(s.title);
    return true;
  } else {
    return false;
  }
});

console.log(InfoWarden);

export { InfoWarden };

// export const InfoWarden = role == "WARDEN" ? [
//     {
//         title: "Student Details",
//         type: stat,
//         route: ROUTES.Warden.StudentDetails,
//         icon: <i className="ri-folder-chart-line mr-2" />,
//     },
//     {
//       title: "Hostel Rooms",
//       type: stat,
//       route: ROUTES.Warden.HostelRooms,
//       icon: <i className="ri-folder-chart-line mr-2" />,
//     },
//     {
//       title: "Hostel Fees",
//       type: stat,
//       route: ROUTES.Warden.HostelFees,
//       icon: <i className="ri-folder-chart-line mr-2" />,
//     },
//     {
//         title: "Room Type",
//         type: stat,
//         route: ROUTES.Warden.RoomType,
//         icon: <i className="ri-folder-chart-line mr-2" />,
//       },
//       {
//         title: "Hostel",
//         type: stat,
//         route: ROUTES.Warden.Hostel,
//         icon: <i className="ri-folder-chart-line mr-2" />,
//       },
//       {
//         title: "Hostel Floors",
//         type: stat,
//         route: ROUTES.Warden.HostelFloors,
//         icon: <i className="ri-folder-chart-line mr-2" />,
//       },
      
//   ] : [];