import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from "react-select";
import { TRANSFER_CLASS, TRANSFER_CLASS_REQUESTS_UPDATE } from '../../utils/apiConstants'
function ModalTransferClass({getOldRequests, employeeOptSubs, courseData, week, employeeOpt, user, setUser, setLoading }) {

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const handleUpdate = async(id) => {
        setLoading(1);
    const config = {
        method: "put",  
        url: `${TRANSFER_CLASS_REQUESTS_UPDATE}/${id}?status=PENDING&emp_to=${user.emp_to}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },  
    }

    axios(config)
        .then((res) => {
          setLoading(0);
          toast.success(`Request updated Successfully`);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Error while updating request");
        })
    getOldRequests();
    setUser({...user, emp_to: ''});
    setLoading(0);
 }

    const handleSubmit = async () => {
        if(!user.emp_to) {
            return toast.error("Please select staff to transfer class");
        } 
        setLoading(1);
        const config = {
            method: 'post',
            url: `${TRANSFER_CLASS}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
            data: {
                emp_from : user.emp_from,
                emp_to : user.emp_to,
                date : user.date,
                timetable_id : user.timetable.id,
                apply_date : new Date().toISOString(),
                type: "TRANSFER"
            }
        }
        await axios(config)
            .then((res) => {
                setLoading(0)
                toast.success(`Class Transfered to ${employeeOpt.find((i) => i.id == user.emp_to)
                ?.first_name + " " + employeeOpt.find((i) => i.id == user.emp_to)?.last_name}`)
            })
            .catch(err => {
                setLoading(0);
                console.log(err);
                toast.error(err.response.data.message);
            })
        getOldRequests();
        setUser({...user, emp_to: ''});
        setLoading(0);
    }

    return (
        <div>
            <div
                className="modal fade"
                id="ModalTransferClass"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    role="document"
                >
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Transfer Class
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                            <div className="row">
                            <table class="table table-bordered text-center">
                                <tbody>
                                    <tr>
                                        <th>Date</th>
                                        <td>{formatDate(user.date)}</td>
                                        <th>Class</th>
                                        <td>{user?.timetable?.class_name} - {user?.timetable?.semester_name} - {'(' + user?.timetable?.section_name + ')'}</td>
                                    </tr>
                                    <tr>
                                        <th>Day</th>
                                        <td>{user.timetable?.day}</td>
                                        <th>Course</th>
                                        <td>{courseData?.find((s) =>s.id == user.timetable?.course_id)?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Time</th>
                                        <td>{user.timetable?.time_from} {"->"} {user.timetable?.time_to}</td>
                                        <th>Staff Name</th>
                                        <td>{user.edit?.first_name ? `${user.edit?.first_name} ${user.edit?.last_name}` : "Not Transfered" }
                                            {/* {user.edit?.status ? <span className={`badge badge-soft-${user.edit?.status=="ACCEPTED"? "success":(user.edit?.status=="REJECTED"?"danger":"warning")} ml-3`} >
                                                {user.edit?.status}
                                            </span>: <></>} */}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                                {!user.is_edit ? 
                                <>
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <label htmlFor="validationCustom02">
                                        Transfer To<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <Select
                                    name="employee_id"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={
                                        employeeOptSubs && employeeOptSubs?.map((i) => ({
                                            label: i.first_name + " " + i.last_name + ' (' + i.course_name + ')',
                                            value: i.id,
                                            }))
                                    }
                                    value={
                                        user.emp_to
                                        ? {
                                            label: employeeOptSubs.find((i) => i.id == user.emp_to)
                                                ?.first_name + " " + employeeOptSubs.find((i) => i.id == user.emp_to)?.last_name + ' (' + employeeOptSubs.find((i) => i.id == user.emp_to)?.course_name + ')',
                                            value: user.emp_to,
                                            }
                                        : null
                                    }
                                    onChange={(selectedOption) => {
                                        setUser((prev) => ({
                                        ...prev,
                                        emp_to: selectedOption ? selectedOption.value : "",
                                        }));
                                    }}
                                    placeholder="Select Staff"
                                    />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    {user.edit?.status == "PENDING" || user.edit?.status == "REJECTED" ? 
                                     <button className="btn btn-nex float-right mr-3" data-dismiss="modal" aria-label="Close" onClick={() => {handleUpdate(user.edit?.transfer_id)}}>Update</button>
                                    : <button className="btn btn-nex float-right mr-3" data-dismiss="modal" aria-label="Close" onClick={handleSubmit}>Transfer</button>
                                    }
                                </div>
                                </> 
                                
                                : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalTransferClass;