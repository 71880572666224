import axios from "axios";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { EMPLOYEE_PAY_PAYSLIP } from "../../../utils/apiConstants";
import ReactToPrint from "react-to-print";
import "./ModalPayRoll.scss";
import { useEffect } from "react";
import getUniversityDetails from "../../../utils/universityDetails.api";

function ModalPayRollProcess({
  employee,
  earning,
  deduction,
  bonus,
  setLoading,
  collegeId,
  month,
  year,
  universityData,
  department,
  jobPositionOpt,
  paid,
  getData,
  generatedPaySlip,
  leaveData,
  currMonthDays,
  holidays,
}) {
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [basicSalary, setBasicSalary] = useState(0);

  useEffect(() => {
    if (employee) {
      let deductions =
        (leaveData
          ?.filter((s) => s.paid_leave == 1)
          ?.reduce(
            (acc, curr) =>
              acc +
              Math.round(
                (employee.basic_salary / currMonthDays) * curr?.number_of_days
              ),
            0
          ) || 0) +
        parseInt(
          (employee?.a_count -
            leaveData?.reduce((i, k) => i + k.number_of_days, 0)) *
            (employee.basic_salary / currMonthDays)
        );
      setBasicSalary(employee?.basic_salary - deductions);
      console.log("Basic salary - ", employee?.basic_salary - deductions);
    }
  }, [employee, leaveData]);

  const componentRef = useRef();

  const [payment, setPayment] = useState(0);

  const pay = async () => {
    setLoading(1);
    const config = {
      method: "post",
      url: `${EMPLOYEE_PAY_PAYSLIP}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        employee_id: employee.id,
        month: month,
        year: year,
        present: employee.p_count,
        absent: employee.a_count > 0 ? employee.a_count : 0,
        holidays: holidays,
        weekly_off: employee.wo_count,
        earning_amount: earning?.reduce(
          (acc, curr) =>
            acc +
            (curr.type == "percentage"
              ? Math.round((basicSalary * curr.percentage) / 100)
              : curr.earning_id == 7
              ? Math.round(
                  curr.amount -
                    (curr.amount /
                      ((paid
                        ? generatedPaySlip?.salary_details?.p_count
                        : employee?.p_count) +
                        (paid
                          ? generatedPaySlip?.salary_details?.a_count
                          : employee?.a_count))) *
                      (paid
                        ? generatedPaySlip?.salary_details?.a_count
                        : employee?.a_count)
                )
              : curr.amount),
          0
        ),
        bonus_amount: bonus?.reduce(
          (acc, curr) =>
            acc +
            (curr.type == "percentage"
              ? Math.round((basicSalary * curr.percentage) / 100)
              : curr.amount),
          0
        ),
        deduction_amount:
          deduction?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.amount),
            0
          ) +
          employee?.pf_salary * 0.12,
        initial_basic_salary: employee?.basic_salary,
        basic_salary: basicSalary,
        net_salary:
          parseInt(basicSalary) +
          earning?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.earning_id == 7
                ? Math.round(
                    curr.amount -
                      (curr.amount /
                        ((paid
                          ? generatedPaySlip?.salary_details?.p_count
                          : employee?.p_count) +
                          (paid
                            ? generatedPaySlip?.salary_details?.a_count
                            : employee?.a_count))) *
                        (paid
                          ? generatedPaySlip?.salary_details?.a_count
                          : employee?.a_count)
                  )
                : curr.amount),
            0
          ) +
          bonus?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.amount),
            0
          ) -
          deduction?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.amount),
            0
          ) -
          employee?.pf_salary * 0.12,
        earning: JSON.stringify(earning),
        bonus: JSON.stringify(bonus),
        deduction: JSON.stringify(deduction),
        salary_details: JSON.stringify(employee),
        leave_details: JSON.stringify(leaveData),
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Pay Slip Stored");
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  return (
    <div className="ModalPayRollProcess">
      <div
        className="modal fade"
        id="ModalPayRollProcess"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100"
          role="document"
        >
          <div className="modal-content ModalPayRollProcess p-3">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Pay Slip
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setPayment(0)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div
              className="page-break"
              style={{ backgroundColor: "white" }}
            ></div>
            <div className="modal-body" style={{ backgroundColor: "white" }}>
              <div
                className=""
                style={{ backgroundColor: "white" }}
                ref={componentRef}
              >
                <div className="border p-5">
                  <div className="row">
                    <div className="col-sm-3">
                      <img
                        src={universityData.logo_secondary}
                        alt=""
                        width="100%"
                      />
                    </div>
                    <div className="col-sm-8 mb-2">
                      <div className="row justify-content-center text-center">
                        <h3 className="text-center">
                          {JSON.parse(localStorage.getItem("COLLEGE")).find(
                            (c) => c.id == sessionStorage.getItem("college_id")
                          )?.name || "College not found"}{" "}
                          ,{universityData.city}.
                        </h3>
                      </div>
                      <div className="row justify-content-center text-center">
                        <p className="text-center">
                          {
                            JSON.parse(localStorage.getItem("COLLEGE")).find(
                              (c) =>
                                c.id == sessionStorage.getItem("college_id")
                            )?.location
                          }
                        </p>
                      </div>
                      <div className="row justify-content-center text-center">
                        <p className="text-center">
                          {" "}
                          Phone :{" "}
                          {
                            JSON.parse(localStorage.getItem("COLLEGE")).find(
                              (c) =>
                                c.id == sessionStorage.getItem("college_id")
                            )?.phone
                          }
                        </p>
                        <p className="text-center ml-2">
                          {" "}
                          Email :{" "}
                          {
                            JSON.parse(localStorage.getItem("COLLEGE")).find(
                              (c) =>
                                c.id == sessionStorage.getItem("college_id")
                            )?.email
                          }
                          .
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-1"></div>

                    <div className="col-sm-12 mb-3">
                      <hr />
                      <h4 className="text-center">
                        Salary Slip for the month of{" "}
                        {months?.find((s) => s.value == month)?.label}-{year}
                      </h4>
                      <hr />
                    </div>

                    <div className="col-sm-6">
                      <table>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Name</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            {paid ? (
                              <h5>
                                : {generatedPaySlip?.salary_details?.first_name}{" "}
                                {generatedPaySlip?.salary_details?.last_name}
                              </h5>
                            ) : (
                              <h5>
                                : {employee?.first_name} {employee?.last_name}
                              </h5>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Designation</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            {paid ? (
                              <h5>
                                : {generatedPaySlip?.salary_details?.role}
                              </h5>
                            ) : (
                              <h5>: {employee?.role}</h5>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Pay Scale</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            {paid ? (
                              <h5>
                                :{" "}
                                {
                                  generatedPaySlip?.salary_details
                                    ?.payscale_title
                                }
                              </h5>
                            ) : (
                              <h5>: {employee?.payscale_title}</h5>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Bank A/C No.</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            {paid ? (
                              <h5>
                                :{" "}
                                {
                                  generatedPaySlip?.salary_details
                                    ?.account_number
                                }
                              </h5>
                            ) : (
                              <h5>: {employee?.account_number}</h5>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-sm-6">
                      <table>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Date of Joining</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            <h5>
                              :{" "}
                              {paid
                                ? formatDate(
                                    generatedPaySlip?.salary_details?.date_of_joining?.split(
                                      "T"
                                    )[0]
                                  )
                                : formatDate(
                                    employee?.date_of_joining?.split("T")[0]
                                  )}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Department</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            <h5>
                              :{" "}
                              {paid
                                ? department?.find(
                                    (s) =>
                                      s.id ==
                                      generatedPaySlip?.salary_details
                                        ?.department_id
                                  )?.name
                                : department?.find(
                                    (s) => s.id == employee.department_id
                                  )?.name}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Basic Pay</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            <h5>
                              :{" "}
                              {paid
                                ? parseInt(
                                    generatedPaySlip?.initial_basic_salary
                                  )?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })
                                : parseInt(
                                    employee?.basic_salary
                                  )?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                            </h5>
                          </td>
                        </tr>
                        <tr>
                          <th width="50%">
                            <h5>
                              <b>Working days</b>{" "}
                            </h5>
                          </th>
                          <td width="50%">
                            {paid ? (
                              <h5>
                                :{" "}
                                { generatedPaySlip?.salary_details?.a_count < 0 ? 0 : (  generatedPaySlip?.salary_details?.p_count +
                                  generatedPaySlip?.salary_details?.a_count )}
                              </h5>
                            ) : (
                              <h5>: {employee?.p_count + employee?.a_count}</h5>
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="row mt-3 text-center">
                    <div className="col-sm-12 col-md-12">
                      <table className="table table-bordered slip-table">
                        <tr>
                          <th>P</th>
                          <th>A</th>
                          <th>WO</th>
                          <th>H</th>
                          {(paid
                            ? generatedPaySlip?.leave_details
                            : leaveData
                          )?.map((i, k) => {
                            return <th>{i.leave_type}</th>;
                          })}
                        </tr>
                        <tr>
                          <td>
                            {paid
                              ? generatedPaySlip?.salary_details?.p_count
                              : employee?.p_count}
                          </td>
                          <td>
                            {paid
                              ? (generatedPaySlip?.salary_details?.a_count < 0 ? 0 : generatedPaySlip?.salary_details?.a_count )
                              : employee?.a_count}
                          </td>
                          <td>
                            {paid
                              ? generatedPaySlip?.salary_details?.wo_count
                              : employee?.wo_count}
                          </td>
                          <td>
                            {paid ? generatedPaySlip?.holidays : holidays}
                          </td>
                          {(paid
                            ? generatedPaySlip?.leave_details
                            : leaveData
                          )?.map((i, k) => {
                            return <td>{i.number_of_days}</td>;
                          })}
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="row mt-3 text-center">
                    <div className="col-sm-6">
                      <div>
                        <table className="table table-bordered slip-table">
                          <thead>
                            <tr>
                              <th colSpan={2}>
                                <h5>EARNING</h5>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th className="text-left">Basic Salary</th>
                              <td className="text-right">
                                {paid
                                  ? parseInt(
                                      generatedPaySlip?.basic_salary
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })
                                  : basicSalary?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                              </td>
                            </tr>
                            {(paid ? generatedPaySlip?.earning : earning)?.map(
                              (i, k) => {
                                return (
                                  <tr>
                                    <th className="text-left">{i.title}</th>
                                    <td className="text-right">
                                      {(i.type == "percentage"
                                        ? Math.round(
                                            (paid
                                              ? parseInt(
                                                  generatedPaySlip?.basic_salary
                                                ) * i.percentage
                                              : basicSalary * i.percentage) /
                                              100
                                          )
                                        : i?.earning_id == 7
                                        ? Math.round(
                                            i.amount -
                                              (i.amount /
                                                ((paid
                                                  ? generatedPaySlip
                                                      ?.salary_details?.p_count
                                                  : employee?.p_count) +
                                                  (paid
                                                    ? generatedPaySlip
                                                        ?.salary_details
                                                        ?.a_count
                                                    : employee?.a_count))) *
                                                (paid
                                                  ? generatedPaySlip
                                                      ?.salary_details?.a_count
                                                  : employee?.a_count)
                                          )
                                        : i.amount
                                      )?.toLocaleString("en-IN", {
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {(paid ? generatedPaySlip?.bonus : bonus)?.map(
                              (i, k) => {
                                return (
                                  <tr>
                                    <th className="text-left">{i.title}</th>
                                    <td className="text-right">
                                      {(i.type == "percentage"
                                        ? Math.round(
                                            (paid
                                              ? parseInt(
                                                  generatedPaySlip?.basic_salary
                                                ) * i.percentage
                                              : basicSalary * i.percentage) /
                                              100
                                          )
                                        : i.amount
                                      )?.toLocaleString("en-IN", {
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            {[
                              ...Array(
                                Math.max(
                                  0,
                                  ((
                                    paid
                                      ? generatedPaySlip?.deduction?.length
                                      : deduction?.length
                                  )
                                    ? paid
                                      ? generatedPaySlip?.deduction?.length
                                      : deduction?.length
                                    : 0) -
                                    ((
                                      paid
                                        ? generatedPaySlip?.earning?.length
                                        : earning?.length
                                    )
                                      ? paid
                                        ? generatedPaySlip?.earning?.length
                                        : earning?.length
                                      : 0) -
                                    ((
                                      paid
                                        ? generatedPaySlip?.bonus?.length
                                        : bonus?.length
                                    )
                                      ? paid
                                        ? generatedPaySlip?.bonus?.length
                                        : bonus?.length
                                      : 0)
                                )
                              ),
                            ].map((_, index) => (
                              <tr>
                                <td className="text-left">-</td>
                                <td className="text-right">-</td>
                              </tr>
                            ))}
                            {(
                              paid
                                ? generatedPaySlip?.salary_details?.pf_salary
                                : employee?.pf_salary
                            ) ? (
                              <tr>
                                <td className="text-left">-</td>
                                <td className="text-right">-</td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            <tr>
                              {/* <th className="text-left">Gross Salary</th> */}
                              <th className="text-left">Total Earning</th>
                              <td className="text-right">
                                {paid
                                  ? (
                                      parseInt(generatedPaySlip?.basic_salary) +
                                      generatedPaySlip?.earning?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          (curr.type == "percentage"
                                            ? Math.round(
                                                (parseInt(
                                                  generatedPaySlip?.basic_salary
                                                ) *
                                                  curr.percentage) /
                                                  100
                                              )
                                            : curr.earning_id == 7
                                            ? Math.round(
                                                curr.amount -
                                                  (curr.amount /
                                                    ((paid
                                                      ? generatedPaySlip
                                                          ?.salary_details
                                                          ?.p_count
                                                      : employee?.p_count) +
                                                      (paid
                                                        ? generatedPaySlip
                                                            ?.salary_details
                                                            ?.a_count
                                                        : employee?.a_count))) *
                                                    (paid
                                                      ? generatedPaySlip
                                                          ?.salary_details
                                                          ?.a_count
                                                      : employee?.a_count)
                                              )
                                            : curr.amount),
                                        0
                                      ) +
                                      generatedPaySlip?.bonus?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          (curr.type == "percentage"
                                            ? Math.round(
                                                (parseInt(
                                                  generatedPaySlip?.basic_salary
                                                ) *
                                                  curr.percentage) /
                                                  100
                                              )
                                            : curr.amount),
                                        0
                                      )
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })
                                  : (
                                      parseInt(basicSalary) +
                                      earning?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          (curr.type == "percentage"
                                            ? Math.round(
                                                (basicSalary *
                                                  curr.percentage) /
                                                  100
                                              )
                                            : curr.earning_id == 7
                                            ? Math.round(
                                                curr.amount -
                                                  (curr.amount /
                                                    ((paid
                                                      ? generatedPaySlip
                                                          ?.salary_details
                                                          ?.p_count
                                                      : employee?.p_count) +
                                                      (paid
                                                        ? generatedPaySlip
                                                            ?.salary_details
                                                            ?.a_count
                                                        : employee?.a_count))) *
                                                    (paid
                                                      ? generatedPaySlip
                                                          ?.salary_details
                                                          ?.a_count
                                                      : employee?.a_count)
                                              )
                                            : curr.amount),
                                        0
                                      ) +
                                      bonus?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          (curr.type == "percentage"
                                            ? Math.round(
                                                (basicSalary *
                                                  curr.percentage) /
                                                  100
                                              )
                                            : curr.amount),
                                        0
                                      )
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div>
                        <table className="table table-bordered slip-table">
                          <thead>
                            <tr>
                              <th colSpan={2}>
                                <h5>DEDUCTION</h5>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(
                              paid
                                ? generatedPaySlip?.salary_details?.pf_salary
                                : employee?.pf_salary
                            ) ? (
                              <tr>
                                <th className="text-left">PF</th>
                                <td className="text-right">
                                  {(paid
                                    ? generatedPaySlip?.salary_details
                                        ?.pf_salary * 0.12
                                    : employee?.pf_salary * 0.12
                                  )?.toLocaleString("en-IN", {
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {/* <tr>
                            <th className="text-left">Paid Leave</th>
                            <td className="text-right">
                              {(
                                ((paid
                                  ? generatedPaySlip?.leave_details
                                  : leaveData
                                )
                                  ?.filter((s) => s.paid_leave == 1)
                                  ?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      Math.round(
                                        curr?.paid_leave_amount *
                                          curr?.number_of_days
                                      ),
                                    0
                                  ) || 0) +
                                parseInt(
                                  ((paid
                                    ? generatedPaySlip?.salary_details?.a_count
                                    : employee?.a_count) -
                                    (paid
                                      ? generatedPaySlip?.leave_details
                                      : leaveData
                                    )?.reduce(
                                      (i, k) => i + k.number_of_days,
                                      0
                                    )) *
                                    (paid
                                      ? parseInt(
                                          generatedPaySlip?.salary_details
                                            ?.basic_salary
                                        ) / currMonthDays
                                      : employee.basic_salary / currMonthDays)
                                )
                              )?.toLocaleString("en-IN", {
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr> */}
                            {(paid
                              ? generatedPaySlip?.deduction
                              : deduction
                            )?.map((i, k) => {
                              return (
                                <tr>
                                  <th className="text-left">{i.title}</th>
                                  <td className="text-right">
                                    {(i.type == "percentage"
                                      ? Math.round(
                                          (paid
                                            ? parseInt(
                                                generatedPaySlip?.basic_salary *
                                                  i.percentage
                                              )
                                            : basicSalary * i.percentage) / 100
                                        )
                                      : i.amount
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                </tr>
                              );
                            })}
                            {[
                              ...Array(
                                Math.max(
                                  0,
                                  ((
                                    paid
                                      ? generatedPaySlip?.earning?.length
                                      : earning?.length
                                  )
                                    ? paid
                                      ? generatedPaySlip?.earning?.length
                                      : earning?.length
                                    : 0) +
                                    ((
                                      paid
                                        ? generatedPaySlip?.bonus?.length
                                        : bonus?.length
                                    )
                                      ? paid
                                        ? generatedPaySlip?.bonus?.length
                                        : bonus?.length
                                      : 0) -
                                    ((
                                      paid
                                        ? generatedPaySlip?.deduction?.length
                                        : deduction?.length
                                    )
                                      ? paid
                                        ? generatedPaySlip?.deduction?.length
                                        : deduction?.length
                                      : 0)
                                )
                              ),
                            ].map((_, index) => (
                              <tr>
                                <td className="text-left">-</td>
                                <td className="text-right">-</td>
                              </tr>
                            ))}
                            <tr>
                              <td className="text-left">-</td>
                              <td className="text-right">-</td>
                            </tr>
                            <tr>
                              <th className="text-left">Total Deduction</th>
                              <td className="text-right">
                                {(paid
                                  ? generatedPaySlip?.deduction?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr.type == "percentage"
                                          ? Math.round(
                                              (generatedPaySlip?.basic_salary *
                                                curr.percentage) /
                                                100
                                            )
                                          : curr.amount),
                                      0
                                    ) +
                                    generatedPaySlip?.salary_details
                                      ?.pf_salary *
                                      0.12
                                  : deduction?.reduce(
                                      (acc, curr) =>
                                        acc +
                                        (curr.type == "percentage"
                                          ? Math.round(
                                              (basicSalary * curr.percentage) /
                                                100
                                            )
                                          : curr.amount),
                                      0
                                    ) +
                                    employee?.pf_salary * 0.12
                                )?.toLocaleString("en-IN", {
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <table className="table table-bordered slip-table">
                        <thead>
                          <tr>
                            <th className="text-center" width="50%">
                              NET Pay
                            </th>
                            <th className="text-center">
                              {(paid
                                ? parseInt(generatedPaySlip?.basic_salary) +
                                  generatedPaySlip?.earning?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      (curr.type == "percentage"
                                        ? Math.round(
                                            (generatedPaySlip?.basic_salary *
                                              curr.percentage) /
                                              100
                                          )
                                        : curr.earning_id == 7
                                        ? Math.round(
                                            curr.amount -
                                              (curr.amount /
                                                ((paid
                                                  ? generatedPaySlip
                                                      ?.salary_details?.p_count
                                                  : employee?.p_count) +
                                                  (paid
                                                    ? generatedPaySlip
                                                        ?.salary_details
                                                        ?.a_count
                                                    : employee?.a_count))) *
                                                (paid
                                                  ? generatedPaySlip
                                                      ?.salary_details?.a_count
                                                  : employee?.a_count)
                                          )
                                        : curr.amount),
                                    0
                                  ) +
                                  generatedPaySlip?.bonus?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      (curr.type == "percentage"
                                        ? Math.round(
                                            (generatedPaySlip?.basic_salary *
                                              curr.percentage) /
                                              100
                                          )
                                        : curr.amount),
                                    0
                                  ) -
                                  generatedPaySlip?.deduction?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      (curr.type == "percentage"
                                        ? Math.round(
                                            (generatedPaySlip?.basic_salary *
                                              curr.percentage) /
                                              100
                                          )
                                        : curr.amount),
                                    0
                                  ) -
                                  generatedPaySlip?.salary_details?.pf_salary *
                                    0.12
                                : parseInt(basicSalary) +
                                  earning?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      (curr.type == "percentage"
                                        ? Math.round(
                                            (basicSalary * curr.percentage) /
                                              100
                                          )
                                        : curr.earning_id == 7
                                        ? Math.round(
                                            curr.amount -
                                              (curr.amount /
                                                ((paid
                                                  ? generatedPaySlip
                                                      ?.salary_details?.p_count
                                                  : employee?.p_count) +
                                                  (paid
                                                    ? generatedPaySlip
                                                        ?.salary_details
                                                        ?.a_count
                                                    : employee?.a_count))) *
                                                (paid
                                                  ? generatedPaySlip
                                                      ?.salary_details?.a_count
                                                  : employee?.a_count)
                                          )
                                        : curr.amount),
                                    0
                                  ) +
                                  bonus?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      (curr.type == "percentage"
                                        ? Math.round(
                                            (basicSalary * curr.percentage) /
                                              100
                                          )
                                        : curr.amount),
                                    0
                                  ) -
                                  deduction?.reduce(
                                    (acc, curr) =>
                                      acc +
                                      (curr.type == "percentage"
                                        ? Math.round(
                                            (basicSalary * curr.percentage) /
                                              100
                                          )
                                        : curr.amount),
                                    0
                                  ) -
                                  employee?.pf_salary * 0.12
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-md-4">
                      <b>Authorized Signatory</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`row d-flex justify-content-end px-2`}>
              {paid ? (
                <ReactToPrint
                  documentTitle={" "}
                  trigger={() => (
                    <button
                      className="btn btn-success  btn-outline px-4  mr-3"
                      // data-dismiss="modal"
                      // aria-label="Close"
                    >
                      {" "}
                      <span className="mr-2">
                        <i class="ri-printer-line"></i>
                      </span>
                      Print
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              ) : (
                <div data-dismiss="modal" aria-label="Close" onClick={pay}>
                  <button className="btn btn-success mr-5">Pay</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPayRollProcess;
