import React from "react";
import { useEffect } from "react";

function ModalHostelfeeReport({selectedStudent, departmentData, collegeData, hostel, roomTypes, rooms, floors}) {

  function formatDateToMonthYear(datetime) {
    const date = new Date(datetime);
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options);
}

  console.log('selected student - ', selectedStudent)
  return (
    <div
      className="modal fade text-left"
      id="ModalHostelfeeReport"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ zIndex: 1060 }} // Adjust the z-index for ModalHostelfeeReport
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-75"
        role="document"
      >
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Student Hostel Details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span
                aria-hidden="true"
                onClick={() => {
                  
                }}
              >
                ×
              </span>
            </button>
          </div>
          <div className="modal-body">
          <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 alogn-items-center">
                    <img
                      className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                      src={`${
                        selectedStudent?.student_picture
                          ? selectedStudent?.student_picture
                        :  "../../../assets/images/reports/graduated.png"
                      }
                                `}
                      width="40%"
                      style={{ aspectRatio: "1/1" }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-primary">Student Details</h6>
                      </div>
                      <div className="col-md-12">
                        <table className="table fee-table table-bordered">
                          <tr>
                            <th width="25%">Enrollment No </th>
                            <td width="25%"> {selectedStudent?.user_id}</td>
                            <th width="25%">Session </th>
                            <td width="25%">
                              {" "}
                              {selectedStudent?.session_id}-
                              {selectedStudent?.session_id + 1}
                            </td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td> {selectedStudent?.name}</td>
                            <th>College</th>
                            <td> 
                              {
                                collegeData?.find(
                                  (s) =>
                                    s.id == selectedStudent?.college_id
                                )?.name
                              }
                            </td>
                          </tr>
                          <tr>
                            <th>Gender</th>
                            <td> {selectedStudent?.gender}</td>
                            <th>Department</th>
                            <td>
                              {" "}
                              {
                                departmentData?.find(
                                  (s) =>
                                    s.id == selectedStudent?.department_id
                                )?.name
                              }
                            </td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td> {selectedStudent?.phone}</td>
                            <th>Class</th>
                            <td> {selectedStudent?.class_name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <h5 className="mt-4 text-center">{selectedStudent?.name}</h5>
                    <h6 className="text-center">({selectedStudent?.user_id})</h6>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-primary">Hostel Details</h6>
                      </div>
                      <div className="col-md-12">
                        <table className="table fee-table table-bordered">
                          <tr>
                            <th width="25%">Hostel</th>
                            <td width="25%">
                              {" "}
                              {
                                hostel.find(
                                  (s) => s.id == selectedStudent?.hostel_id
                                )?.hostel_name
                              }
                            </td>
                            <th width="25%">Period</th>
                            <td width="25%">{formatDateToMonthYear(selectedStudent?.from_month)} to {formatDateToMonthYear(selectedStudent?.to_month)}</td>
                          </tr>
                          <tr>
                            <th>Floor</th>
                            <td>
                              {" "}
                              {
                                rooms.find((s) => s.id == selectedStudent?.room_id)
                                  ?.floor_number
                              }
                            </td>
                            <th>Amount</th>
                            <td>
                              {" "}
                              {selectedStudent?.amount?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>Room Type</th>
                            <td>
                              {" "}
                              {
                                roomTypes?.find(
                                  (s) =>
                                    s.id ==
                                    rooms.find(
                                      (s) => s.id == selectedStudent?.room_id
                                    )?.hostel_room_type_id
                                )?.room_type
                              }
                            </td>
                            <th>Paid amount</th>
                            <td>
                              {" "}
                              {parseInt(
                                selectedStudent?.paid_amount
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>Room number</th>
                            <td>
                              {" "}
                              {
                                rooms.find((s) => s.id == selectedStudent?.room_id)
                                  ?.room_name_number
                              }
                            </td>
                            <th>Balance</th>
                            <td>
                              {" "}
                              {(
                                selectedStudent?.amount -
                                selectedStudent?.paid_amount -
                                selectedStudent?.discount
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>Bed number</th>
                            <td> {selectedStudent?.bed_no}</td>
                            <th>Fee Status</th>
                            <td>
                              <span
                                className={`ml-1 badge badge-soft-${
                                  selectedStudent?.amount -
                                  selectedStudent?.paid_amount -
                                  selectedStudent?.discount ==
                                  0
                                    ? "success"
                                    : selectedStudent?.amount -
                                    selectedStudent?.paid_amount -
                                    selectedStudent?.discount ==
                                    selectedStudent?.amount
                                    ? "danger"
                                    : "warning"
                                }`}
                              >
                                {selectedStudent?.amount -
                                  selectedStudent?.paid_amount -
                                  selectedStudent?.discount ==
                                0
                                  ? "Paid"
                                  : selectedStudent?.amount -
                                  selectedStudent?.paid_amount -
                                  selectedStudent?.discount ==
                                  selectedStudent?.amount
                                  ? "Not Paid"
                                  : "Partial"}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalHostelfeeReport;
