import { ROUTES } from "../../../Router/routerConfig";

const stat = "static";

let role = sessionStorage.getItem("role");

export const InfoUploading = [
  role == "WEBSITE" ?
    ({
      title: "Media",
      type: stat,
      route: ROUTES.mediaInfo,
      icon: <i className="ri-folder-chart-line mr-2" />,
    }
      , {
      title: "Admission Notifications",
      type: stat,
      route: ROUTES.AdmissionNotifications,
      icon: <i className="ri-folder-chart-line mr-2" />,
    },
    {
      title: "University Notifications",
      type: stat,
      route: ROUTES.UniversityNotifications,
      icon: <i className="ri-folder-chart-line mr-2" />,
    }, {
      title: "Event Calendar",
      type: stat,
      route: ROUTES.EventCalendar,
      icon: <i className="ri-folder-chart-line mr-2" />,
    }, {
      title: "Recruitment",
      type: stat,
      route: ROUTES.Recruitment,
      icon: <i className="ri-folder-chart-line mr-2" />,
    }

    ) : {
      title: "Events",
      type: stat,
      route: ROUTES.homeoEvents,
      icon: <i className="ri-folder-chart-line mr-2" />,
    },
  {
    title: "Research and Publication",
    type: stat,
    route: ROUTES.ResearchandPublication,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Results",
    type: stat,
    route: ROUTES.HomeopathyResults,
    icon: <i className="ri-folder-chart-line mr-2" />,
  }, {
    title: "Student List",
    type: stat,
    route: ROUTES.HomeopathyStudentsList,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },

  {
    title: "College Permission",
    type: stat,
    route: ROUTES.CollegePermission,
    icon: <i className="ri-folder-chart-line mr-2" />,
  }

];
