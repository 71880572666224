import React, { useState } from "react";
import papa from "papaparse";
import { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { EMPLOYEE_ADD_BIOMETRIC_BULK } from "../../../utils/apiConstants";
import axios from "axios";

function BioMetricUpload({ setLoading, type }) {
  const [data, setData] = useState();

  let fileRef = useRef();
  var commonConfig = { delimiter: "," };

  const handleUpload = async () => {
    console.log(data);
    // return;
    setLoading(1);
    const config = {
      method: "post",
      url: EMPLOYEE_ADD_BIOMETRIC_BULK,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then((res) => {
        toast.success("Successfully Entered Biometric Details");
        fileRef.current.value = null;
        setLoading(0);
      })
      .catch((err) => {
        console.log("Bulk Upload Error :" + err);
        toast.error("Something went wrong");
        setLoading(0);
      });
  };

  // const handleProcessCSV = (d) => {
  //   console.log("d - ", d);
  //   const arr = [];
  //   for (const i of d) {
  //     let obj = {};
  //     obj = {
  //       biometric_id: i["Biometric Id"],
  //       in_time: i["in_time  (00:00)"],
  //       out_time: i["out_time  (00:00)"],
  //       work_time: i["work_time (00:00)"],
  //       name: i["Employee Name"]?.trim(),
  //       date: i["Date (dd/mm/yyyy)"],
  //       status: i["Status (P or A or WO)"],
  //     };
  //     arr.push(obj);
  //   }

  //   console.log(arr);
  //   setData(arr);
  // };

  // const handleFileUpload = async (e) => {
  //   const files = e.target.files;
  //   // console.log("hi")
  //   if (files) {
  //     papa.parse(files[0], {
  //       ...commonConfig,
  //       header: true,
  //       complete: async (res) => {
  //         console.log("com", res);
  //         await handleProcessCSV(res.data.data);
  //       },
  //     });
  //   }
  // };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (files) {
      papa.parse(files[0], {
        ...commonConfig,
        header: true,
        complete: async (res) => {
          console.log("Parsed result: ", res);
          if (Array.isArray(res.data)) {
            await handleProcessCSV(res.data);
          } else {
            console.error("Parsed data is not an array:", res.data);
            toast.error(
              "Failed to parse CSV file. Please check the file format."
            );
          }
        },
      });
    }
  };

  const handleProcessCSV = (d) => {
    console.log("Processing CSV data: ", d);
    const arr = [];
    for (const i of d) {
      let obj = {};
      const standardizedKeys = {};
      // Standardize the keys by trimming them
      for (let key in i) {
        standardizedKeys[key.trim()] = i[key];
      }
      obj = {
        biometric_id: standardizedKeys["Biometric Id"]?.trim(),
        in_time: standardizedKeys["in_time  (00:00)"]?.trim(),
        out_time: standardizedKeys["out_time  (00:00)"]?.trim(),
        work_time: standardizedKeys["work_time (00:00)"]?.trim(),
        name: standardizedKeys["Employee Name"]?.trim(),
        date: standardizedKeys["Date (dd/mm/yyyy)"]?.trim(),
        status: standardizedKeys["Status (P or A or WO)"]?.trim(),
      };
      arr.push(obj);
    }

    console.log("Processed data array: ", arr);
    setData(arr);
  };

  return (
    <div>
      <div
        className="modal fade"
        id="biometricUpload"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {type == "Upload" ? "Import CSV File" : "Download CSV File"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <ol>
                <li>
                  Your CSV data should be in the format below. The first line of
                  your CSV file should be the column headers as in the table
                  example. Also make sure that your file is UTF-8 to avoid
                  unnecessary encoding problems.
                </li>
                <li>
                  All the Fields mentioned in CSV file are required before
                  Upload.
                </li>
                <li>
                  Accepted Date Formats <b>(DD/MM/YYYY)</b>
                </li>
                <li>
                  Accepted Time Formats <b>(HH:MM)</b>
                </li>
                <li>Biometric Id is Mandatory.</li>
              </ol>

              <br />

              {type == "Upload" ? (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">
                        Select CSV File
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="file"
                        ref={fileRef}
                        className="form-control"
                        accept=".csv"
                        onChange={handleFileUpload}
                      />
                      <br />
                      <p className="text-danger">*only csv format is allowed</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {type == "Upload" ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  name="submit"
                  value="bulk"
                  onClick={handleUpload}
                  data-dismiss="modal"
                >
                  Upload
                </button>
              ) : (
                <a
                  href="https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/ananya_assets/templets/Biometric+Template.csv"
                  download
                >
                  <button className="btn btn-primary" value="bulk">
                    Download
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BioMetricUpload;
