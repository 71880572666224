import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import ModalPayRollProcess from "../../../../modals/HR/PayRoll/ModalPayRollProcess";
import {
  EMPLOYEE_EARNING,
  EMPLOYEE_BONUS,
  EMPLOYEE_DEDUCTION,
  PAYROLL_EMPLOYEES,
  EMPLOYEE_GENERATED_PAYSLIP,
  EMPLOYEE_REDUCE_ATTENDANCE,
  PAYROLL_EMP_LEAVE,
  EMPLOYEE_PAY_PAYSLIP,
} from "../../../../utils/apiConstants";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
} from "../../../../utils/LocalStorageConstants";
import getUniversityDetails from "../../../../utils/universityDetails.api";
import * as XLSX from "xlsx/xlsx.mjs";

function PayRollProcess({ setLoading, collegeId }) {
  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [universityData, setUniversityData] = useState([]);

  const [collegeOpt, setCollegeOpt] = useState(
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null
  );

  const [month, setMonth] = useState("");
  const [year, setYear] = useState("2024");
  const [type, setType] = useState("pending");
  const [designationSearch, setDesignationSearch] = useState("");
  const [searchName, setSearchName] = useState("");

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));
  const [empData, setEmpData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [generatedPaySlip, setGeneratedPaySlip] = useState([]);

  // const [earning, setEarning] = useState([]);
  // const [bonus, setBonus] = useState([]);
  // const [deduction, setDeduction] = useState([]);
  // const [employee, setEmployee] = useState([]);
  // const [leaveData, setLeaveData] = useState([]);
  const [holidays, setHolidays] = useState(0);

  const [promotes, setPromotes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [paid, setPaid] = useState(true);
  const [currMonthDays, setCurrMonthDays] = useState(0);

  const getData = async () => {
    return new Promise(async (resolve, reject) => {
      if (!year) reject(toast.error("Please Select Year"));
      if (!month) reject(toast.error("Please Select Month"));
      const config = {
        method: "get",
        url: `${PAYROLL_EMPLOYEES}?college_id=${collegeId}&year=${year}&month=${month}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        },
      };
      setLoading(1);
      await axios(config)
        .then((res) => {
          setLoading(0);
          console.log("number of holidays - ", res.data.holidays);
          let holidays = res.data.holidays ? res.data.holidays : 0;
          for (let i of res.data.data) {
            i.a_count = i.a_count - holidays > 0 ? i.a_count - holidays : 0;
          }
          console.log("employees - ", res.data.data);
          setEmpData(res.data.data);
          setDisplayData(res.data.data);
          setHolidays(res.data.holidays);
          const daysInCurrentMonth = new Date(
            year,
            parseInt(month),
            0
          ).getDate();
          resolve(res.data.data);
          console.log("number of days in month - ", daysInCurrentMonth);
          setCurrMonthDays(daysInCurrentMonth);
        })
        .catch((err) => {
          setLoading(0);
          reject(err);
          console.log(err);
          toast.error("Something Went Wrong");
        });
    });
  };

  const togglePromotion = (id, data) => {
    const isPromoted = promotes?.some((student) => student.id == id);

    if (isPromoted) {
      setPromotes((prevPromotes) =>
        prevPromotes?.filter((student) => student.id != id)
      );
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, { id, data }]);
    }
  };

  const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    const updatedPromotes = displayData
      ?.filter((d) => !d.pay_slip_id && d.basic_salary)
      ?.map((d) => ({
        id: d.id,
        data: d,
      }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

  const getPaySlip = async (id) => {
    let earning, bonus, deduction, leaveData;
    const config = {
      method: "get",
      url: `${EMPLOYEE_EARNING}/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        earning = res.data.data;
        // setEarning(res.data.data);
        console.log("earning - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);

    setLoading(1);
    await axios({ ...config, url: `${EMPLOYEE_BONUS}/${id}` })
      .then((res) => {
        setLoading(0);
        bonus = res.data.data;
        // setBonus(res.data.data);
        console.log("bonus - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);

    setLoading(1);
    await axios({ ...config, url: `${EMPLOYEE_DEDUCTION}/${id}` })
      .then((res) => {
        setLoading(0);
        // setDeduction(res.data.data);
        deduction = res.data.data;
        console.log("deduction - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });

    setLoading(1);
    await axios({
      ...config,
      url: `${PAYROLL_EMP_LEAVE}?id=${id}&month=${month}&year=${year}`,
    })
      .then((res) => {
        setLoading(0);
        // setLeaveData(res.data.data);
        leaveData = res.data.data;
        console.log("leaves - ", res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });

    setLoading(0);

    return { earning, bonus, deduction, leaveData };
  };

  const getGeneratedPaySlip = async (id) => {
    const config = {
      method: "get",
      url: `${EMPLOYEE_GENERATED_PAYSLIP}/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        let tempData = res.data.data[0];
        tempData.earning = JSON.parse(tempData.earning)
          ? JSON.parse(tempData.earning)
          : [];
        tempData.bonus = JSON.parse(tempData.bonus)
          ? JSON.parse(tempData.bonus)
          : [];
        tempData.deduction = JSON.parse(tempData.deduction)
          ? JSON.parse(tempData.deduction)
          : [];
        tempData.salary_details = JSON.parse(tempData.salary_details)
          ? JSON.parse(tempData.salary_details)
          : [];
        tempData.leave_details = JSON.parse(tempData.leave_details)
          ? JSON.parse(tempData.leave_details)
          : [];
        setGeneratedPaySlip(tempData);
        console.log("generated payslip - ", tempData);
        const daysInCurrentMonth = new Date(
          parseInt(tempData?.year),
          parseInt(tempData?.month),
          0
        ).getDate();
        console.log("number of days in month - ", daysInCurrentMonth);
        setCurrMonthDays(daysInCurrentMonth);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  useEffect(() => {
    let filteredData = [];

    setDesignationSearch("");
    filteredData = empData?.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchName.toLowerCase()) ||
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(searchName.toLowerCase())
    );
    setDisplayData(filteredData);
  }, [searchName]);

  useEffect(() => {
    setDisplayData(
      empData?.filter(
        (item) => !designationSearch || item.role == designationSearch
      )
    );
  }, [designationSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log("promotes - ", promotes);
  }, [promotes]);

  const pay = async (
    employee,
    basicSalary,
    leaveData,
    earning,
    bonus,
    deduction,
    typeObj
  ) => {
    setLoading(1);
    console.log(typeObj);

    const config = {
      method: typeObj?.type == "post" ? "post" : "put",
      url:
        typeObj?.type == "post"
          ? `${EMPLOYEE_PAY_PAYSLIP}`
          : `${EMPLOYEE_PAY_PAYSLIP}/${typeObj?.id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        employee_id: employee.id,
        month: month,
        year: year,
        present: employee.p_count,
        absent: employee.a_count > 0 ? employee.a_count : 0,
        holidays: holidays,
        weekly_off: employee.wo_count,
        earning_amount: earning?.reduce(
          (acc, curr) =>
            acc +
            (curr.type == "percentage"
              ? Math.round((basicSalary * curr.percentage) / 100)
              : curr.earning_id == 7
              ? Math.round(
                  curr.amount -
                    (curr.amount / (employee?.p_count + employee?.a_count)) *
                      employee?.a_count
                )
              : curr.amount),
          0
        ),
        bonus_amount: bonus?.reduce(
          (acc, curr) =>
            acc +
            (curr.type == "percentage"
              ? Math.round((basicSalary * curr.percentage) / 100)
              : curr.amount),
          0
        ),
        deduction_amount:
          deduction?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.amount),
            0
          ) +
          employee?.pf_salary * 0.12,
        initial_basic_salary: employee?.basic_salary,
        basic_salary: basicSalary,
        net_salary:
          parseInt(basicSalary) +
          earning?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.earning_id == 7
                ? Math.round(
                    curr.amount -
                      (curr.amount / (employee?.p_count + employee?.a_count)) *
                        employee?.a_count
                  )
                : curr.amount),
            0
          ) +
          bonus?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.amount),
            0
          ) -
          deduction?.reduce(
            (acc, curr) =>
              acc +
              (curr.type == "percentage"
                ? Math.round((basicSalary * curr.percentage) / 100)
                : curr.amount),
            0
          ) -
          employee?.pf_salary * 0.12,
        earning: JSON.stringify(earning),
        bonus: JSON.stringify(bonus),
        deduction: JSON.stringify(deduction),
        salary_details: JSON.stringify(employee),
        leave_details: JSON.stringify(leaveData),
      },
    };

    // console.log(config);
    await axios(config)
      .then((res) => {
        setLoading(0);
        // toast.success("Pay Slip Stored");
        // getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  // const paySelected = async () => {
  //   let count = 0;

  //   for (let i of promotes) {
  //     let basicSalary = 0;
  //     await getPaySlip(i.id)
  //       .then(async (res) => {
  //         if (i) {
  //           let deductions =
  //             (leaveData
  //               ?.filter((s) => s.paid_leave == 1)
  //               ?.reduce(
  //                 (acc, curr) =>
  //                   acc +
  //                   Math.round(
  //                     (i?.data?.basic_salary / currMonthDays) *
  //                       curr?.number_of_days
  //                   ),
  //                 0
  //               ) || 0) +
  //             parseInt(
  //               (i?.data?.a_count -
  //                 leaveData?.reduce((i, k) => i + k.number_of_days, 0)) *
  //                 (i?.data?.basic_salary / currMonthDays)
  //             );
  //           basicSalary = i?.data?.basic_salary - deductions;
  //           console.log("Basic salary - ", basicSalary);
  //         }
  //         await pay(i?.data, basicSalary).catch((err) => {
  //           console.log(err);
  //           return toast.error("Something went wrong");
  //         });
  //         count = count + 1;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         return toast.error("Something went wrong");
  //       });
  //   }
  //   toast.success(`Successfully Generated Payslip for ${count} employees`);
  //   setPromotes([]);
  //   getData();
  //   setLoading(0);
  // };

  const payIndividual = async (i, typeObj) => {
    let count = 0;
    console.log("i - ", i);
    let basicSalary = 0;
    await reduceAttendance(i?.data).then(async () => {
      await getPaySlip(i.id).then(async (paySlipData) => {
        const { earning, bonus, deduction, leaveData } = paySlipData;
        if (i) {
          let deductions =
            Math.max(
              leaveData
                ?.filter((s) => s.paid_leave == 1)
                ?.reduce(
                  (acc, curr) =>
                    acc +
                    Math.round(
                      (i?.data?.basic_salary / currMonthDays) *
                        curr?.number_of_days
                    ),
                  0
                ) || 0,
              0
            ) +
            Math.max(
              parseInt(
                (i?.data?.a_count -
                  leaveData
                    ?.filter((s) => s.deduction != 1)
                    ?.reduce((i, k) => i + k.number_of_days, 0)) *
                  (i?.data?.basic_salary / currMonthDays)
              ),
              0
            );
          basicSalary = i?.data?.basic_salary - deductions;
          if (i?.data?.p_count == 0) {
            basicSalary = 0;
          }
        }
        await pay(
          i?.data,
          basicSalary,
          leaveData,
          earning,
          bonus,
          deduction,
          typeObj
        ).then(() => {
          count = count + 1;
          setLoading(0);
          // toast.success(`Successfully Generated Payslip`);
          // setPromotes([]);
        });
      });
    });
    setLoading(0);
    // setPromotes([]);
  };

  const payMultiple = async () => {
    if (promotes.length == 0) {
      return toast.error("Please select atleast one employee");
    }

    let count = 0;

    console.log("promotes - ", promotes);
    for (let i of promotes) {
      try {
        setLoading(1);
        await payIndividual(i, { type: "post" }).then(() => {
          count = count + 1;
        });
        setLoading(0);
      } catch (error) {
        setLoading(0);
        toast.error(
          `Error while generating payslip for ${i?.data?.first_name} ${i?.data?.last_name}`
        );
      }
    }
    setLoading(0);
    getData();
    toast.success(`Payslip generated for ${count} employees`);
    setPromotes([]);
  };

  const onExport = async () => {
    console.log("exporting...");

    let expData = [];

    // let collegeName = collegeOpt?.find((s) => s.id == collegeId)?.name;
    // let monthHeading = `Payslip Report for the month of ${month}-${year}`;
    // expData.push([collegeName]);
    // expData.push([monthHeading]);

    let count = 0;
    let exp = [];
    for (let i of displayData) {
      let obj = {};
      if (i.pay_slip_id) {
        const config = {
          method: "get",
          url: `${EMPLOYEE_GENERATED_PAYSLIP}/${i.pay_slip_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
        };
        setLoading(1);
        await axios(config)
          .then((res) => {
            setLoading(0);
            let tempData = res.data.data[0];
            console.log("data obj - ", tempData);
            tempData.earning = JSON.parse(tempData.earning)
              ? JSON.parse(tempData.earning)
              : [];
            tempData.bonus = JSON.parse(tempData.bonus)
              ? JSON.parse(tempData.bonus)
              : [];
            tempData.deduction = JSON.parse(tempData.deduction)
              ? JSON.parse(tempData.deduction)
              : [];
            tempData.salary_details = JSON.parse(tempData.salary_details)
              ? JSON.parse(tempData.salary_details)
              : [];
            tempData.leave_details = JSON.parse(tempData.leave_details)
              ? JSON.parse(tempData.leave_details)
              : [];
            exp.push({ ...i, ...tempData });
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something Went Wrong");
          });
        setLoading(0);
      }
    }

    let obj = {};
    for (let j of exp) {
      console.log("j - ", j?.earning);
      obj["Sl No."] = null;
      obj["Name"] = null;
      obj["Department"] = null;
      obj["Designation"] = null;
      obj["Present"] = null;
      obj["Absent"] = null;
      obj["Week Off"] = null;
      obj["Holidays"] = null;
      obj["Bank Name"] = null;
      obj["Bank Branch"] = null;
      obj["IFSC Code"] = null;
      obj["Account Title"] = null;
      obj["Account Number"] = null;
      obj["Pay Scale"] = null;
      obj["Basic Pay"] = null;
      obj["Basic Salary"] = null;
      obj["PF Salary"] = null;

      for (let i of j.earning) {
        console.log(i?.title);
        obj[`${i?.title}`] = null;
      }
    }

    for (let j of exp) {
      for (let i of j.bonus) {
        obj[`${i?.title}`] = null;
      }
    }

    for (let j of exp) {
      for (let i of j.deduction) {
        obj[`${i?.title}`] = null;
      }
    }

    // order of column names is maintained
    expData.push(obj);

    for (let tempData of exp) {
      let obj = {};
      console.log("generated payslip - ", tempData);
      const daysInCurrentMonth = new Date(
        parseInt(tempData?.year),
        parseInt(tempData?.month),
        0
      )?.getDate();
      console.log("number of days in month - ", daysInCurrentMonth);
      setCurrMonthDays(daysInCurrentMonth);

      count = count + 1;
      obj["Sl No."] = count;
      obj["Name"] = tempData?.first_name + " " + tempData?.last_name;
      obj["Department"] = department.find(
        (s) => s.id == tempData.department_id
      )?.name;
      obj["Designation"] = jobPositionOpt?.find(
        (s) => s.id == tempData.role
      )?.name;
      obj["Present"] = tempData?.present;
      obj["Absent"] = tempData?.absent;
      obj["Week Off"] = tempData?.week_off;
      obj["Holidays"] = tempData?.holidays;
      obj["Bank Name"] = tempData?.salary_details?.bank_name;
      obj["Bank Branch"] = tempData?.salary_details?.bank_branch;
      obj["IFSC Code"] = tempData?.salary_details?.ifsc_code;
      obj["Account Title"] = tempData?.salary_details?.account_title;
      obj["Account Number"] = tempData?.salary_details?.account_number;
      obj["Pay Scale"] = tempData?.salary_details?.payscale_title;
      obj["Basic Pay"] = tempData?.initial_basic_salary;
      obj["Basic Salary"] = tempData?.basic_salary;
      obj["PF Salary"] = tempData?.pf_salary;

      for (let i of tempData.earning) {
        obj[`${i?.title}`] =
          i?.type == "percentage"
            ? Math.round((tempData?.basic_salary * i?.percentage) / 100)
            : i?.amount
            ? i?.type == "percentage"
              ? Math.round((tempData?.basic_salary * i?.percentage) / 100)
              : i?.earning_id == 7
              ? Math.round(
                  (i?.amount / (tempData?.present + tempData?.absent)) *
                    tempData?.present
                )
              : i?.amount
            : "-";
      }

      for (let i of tempData.bonus) {
        obj[`${i?.title}`] =
          i?.type == "percentage"
            ? Math.round((tempData?.basic_salary * i?.percentage) / 100)
            : i?.amount
            ? i?.type == "percentage"
              ? Math.round((tempData?.basic_salary * i?.percentage) / 100)
              : i?.amount
            : "-";
      }

      for (let i of tempData.deduction) {
        obj[`${i?.title}`] =
          "-" +
          (i?.type == "percentage"
            ? Math.round((tempData?.basic_salary * i?.percentage) / 100)
            : i?.amount
            ? i?.type == "percentage"
              ? Math.round((tempData?.basic_salary * i?.percentage) / 100)
              : i?.amount
            : "-");
      }

      obj["PF Deduction"] = "-" + tempData?.pf_salary * 0.12;
      obj["Total Earning"] =
        parseInt(tempData?.basic_salary) + parseInt(tempData?.earning_amount);
      obj["Total Bonus"] = tempData?.bonus_amount;
      obj["Gross Salary"] =
        parseInt(tempData?.basic_salary) +
        parseInt(tempData?.earning_amount) +
        parseInt(tempData?.bonus_amount);
      obj["Total Deduction"] = "-" + tempData?.deduction_amount;
      obj["Net Salary"] = tempData?.net_salary;
      expData.push(obj);
    }

    console.log("export data - ", expData);

    var worksheet = XLSX.utils.json_to_sheet(expData);
    console.log(worksheet);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `Consolidated Payslip Report.csv`);
  };

  //Reduce leave entitlements and apply leave applications for late in and early out
  const reduceAttendance = async (i) => {
    const config = {
      method: "put",
      url: `${EMPLOYEE_REDUCE_ATTENDANCE}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: {
        employee_id: i?.id,
        leave_priority_id: i?.leave_priority_id,
        year: year,
        month: month,
        number_of_days: Math.floor((i?.late_in + i?.early_out) / 3) / 2,
      },
    };
    // setLoading(1);
    // await axios(config)
    //   .then((res) => {
    //     setLoading(0);
    //     console.log('attendance deductions - ', res.data.data);
    //   })
    //   .catch((err) => {
    //     setLoading(0);
    //     console.log(err);
    //     toast.error("Something Went Wrong");
    //   });
    // setLoading(0);
  };

  useEffect(() => {
    setEmpData([]);
    setDisplayData([]);
  }, [month, year]);

  return (
    <div className="PayRollProcess">
      <div className="main-content">
        <ModalPayRollProcess
          department={department}
          jobPositionOpt={jobPositionOpt}
          universityData={universityData}
          month={month}
          year={year}
          // employee={employee}
          // earning={earning}
          // deduction={deduction}
          // bonus={bonus}
          // leaveData={leaveData}
          setLoading={setLoading}
          paid={paid}
          getData={getData}
          generatedPaySlip={generatedPaySlip}
          currMonthDays={currMonthDays}
          holidays={holidays}
        />
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Process</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">PayRoll</a>
                      </li>
                      <li className="breadcrumb-item active">Process</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Year<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={year}
                            onChange={(e) => {
                              setYear(e.target.value);
                            }}
                          >
                            <option value="">Select year</option>
                            {sessionOpt?.map((data, key) => {
                              return (
                                <option key={key} value={data.id}>
                                  {data.name?.split("-")[0]}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Select Month<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="role"
                            name="role"
                            className="form-control"
                            value={month}
                            onChange={(e) => {
                              setMonth(e.target.value);
                            }}
                          >
                            <option value="">Select Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                          <div className="form-group">
                              <label htmlFor="validationCustom01">
                                  Select Type<span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                  id="role"
                                  name="role"
                                  className="form-control"
                                  value={type}
                                  onChange={(e) => { setType(e.target.value) }}
                              >
                                  <option value="pending">Pending</option>
                                  <option value="paid">Paid</option>
                              </select>
                          </div>
                      </div> */}
                      <div className="col-md-3">
                        <button
                          className="btn btn-nex mt-4"
                          type="submit"
                          name="submit"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                    </div>

                    <div className="row "></div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="college_id">
                            Filter by Designation
                          </label>
                          <select
                            name="designation"
                            id="designation"
                            required=""
                            className="form-control"
                            value={designationSearch}
                            onChange={(e) =>
                              setDesignationSearch(e.target.value)
                            }
                          >
                            <option value="">All</option>
                            {jobPositionOpt?.map((data, key) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label>Filter By Employee Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Employee Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-5">
                        <div className="row float-right">
                          <div className="">
                            <button
                              className="btn btn-success mt-4"
                              // onClick={paySelected}
                              onClick={payMultiple}
                              disabled={promotes?.length <= 0}
                            >
                              Pay selected
                            </button>
                          </div>
                          <div className="ml-2 mr-3">
                            <button
                              className="btn btn-info mt-4"
                              onClick={onExport}
                            >
                              Export
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <b>Selected - {promotes.length}</b>
                      </div>
                      <div className="col-xl-12">
                        <div className="table-responsive mt-3 fixTableHead">
                          <table className="table table-bordered" style={{}}>
                            <thead className="">
                              <tr>
                                <th>
                                  <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                  />
                                </th>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Designation</th>
                                <th>Basic Salary</th>
                                <th>Late In</th>
                                <th>Early Out</th>
                                <th>Total</th>
                                <th>Leave Deduction (days)</th>
                                <th>Status</th>
                                <th>Payslip</th>
                              </tr>
                            </thead>
                            <tbody>
                              {displayData ? (
                                displayData?.map((i, k) => {
                                  return (
                                    <tr>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="checkbox[]"
                                          value={i.id}
                                          checked={promotes?.some(
                                            (staff) => staff.id == i.id
                                          )}
                                          onChange={() =>
                                            togglePromotion(i.id, i)
                                          }
                                          disabled={
                                            i?.pay_slip_id || !i?.basic_salary
                                          }
                                        />
                                      </td>
                                      <td>{k + 1}</td>
                                      <td>{`${i.first_name} ${i.last_name}`}</td>
                                      <td>
                                        {
                                          department.find(
                                            (s) => s.id == i.department_id
                                          )?.name
                                        }
                                      </td>
                                      <td>
                                        {
                                          jobPositionOpt?.find(
                                            (s) => s.id == i.role
                                          )?.name
                                        }
                                      </td>
                                      <td>
                                        {i?.basic_salary
                                          ? parseInt(
                                              i?.basic_salary
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })
                                          : "Not added"}
                                      </td>
                                      <td
                                        className={`text-${
                                          i?.late_in >= 3 ? "danger" : "primary"
                                        }`}
                                      >
                                        <b>{i?.late_in}</b>
                                      </td>
                                      <td
                                        className={`text-${
                                          i?.early_out >= 3
                                            ? "danger"
                                            : "primary"
                                        }`}
                                      >
                                        <b>{i?.early_out}</b>
                                      </td>
                                      <td
                                        className={`text-${
                                          i?.early_out + i?.late_in >= 3
                                            ? "danger"
                                            : "primary"
                                        }`}
                                      >
                                        <b>{i?.late_in + i?.early_out}</b>
                                      </td>
                                      <td
                                        className={`text-${
                                          Math.floor(
                                            (i?.late_in + i?.early_out) / 3
                                          ) > 0
                                            ? "danger"
                                            : "primary"
                                        }`}
                                      >
                                        <b>
                                          {Math.floor(
                                            (i?.late_in + i?.early_out) / 3
                                          ) / 2}
                                        </b>
                                      </td>
                                      {i?.pay_slip_id ? (
                                        <>
                                          <td>
                                            <span className="badge badge-soft-success">
                                              Paid
                                            </span>
                                          </td>
                                          <td className="d-flex">
                                            <button
                                              className="btn btn-info mr-2"
                                              data-toggle="modal"
                                              disabled={!i?.basic_salary}
                                              data-target="#ModalPayRollProcess"
                                              onClick={() => {
                                                // getPaySlip(i.id);
                                                // setEmployee(i);
                                                getGeneratedPaySlip(
                                                  i.pay_slip_id
                                                );
                                                setPaid(true);
                                              }}
                                            >
                                              Print
                                            </button>

                                            <button
                                              className="btn btn-info"
                                              disabled={!i?.basic_salary}
                                              onClick={async () => {
                                                await getData().then(
                                                  async (data) => {
                                                    console.log(data);
                                                    await payIndividual(
                                                      {
                                                        id: i.id,
                                                        data: data?.find(
                                                          (s) => s.id == i?.id
                                                        ),
                                                      },
                                                      {
                                                        type: "put",
                                                        id: data?.find(
                                                          (s) => s.id == i?.id
                                                        )?.pay_slip_id,
                                                      }
                                                    )
                                                      .then(async () => {
                                                        toast.success(
                                                          "Payslip Re-Generated successfully"
                                                        );
                                                        await getData();
                                                      })
                                                      .catch((err) => {
                                                        console.log(err);
                                                      });
                                                  }
                                                );
                                              }}
                                            >
                                              Re-Generate
                                            </button>
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td>
                                            {i?.pay_slip_id ? (
                                              <span className="badge badge-soft-success">
                                                Paid
                                              </span>
                                            ) : (
                                              <span className="badge badge-soft-danger">
                                                Not Paid
                                              </span>
                                            )}
                                          </td>
                                          <td className="d-flex justify-content-center items-center">
                                            <button
                                              className="btn btn-success"
                                              // data-toggle="modal"
                                              // data-target="#ModalPayRollProcess"
                                              disabled={
                                                !i?.basic_salary ||
                                                promotes.length > 0
                                              }
                                              // onClick={() => {
                                              //   getPaySlip(i.id);
                                              //   setEmployee(i);
                                              //   setPaid(false);
                                              // }}
                                              onClick={async () => {
                                                await payIndividual(
                                                  {
                                                    id: i.id,
                                                    data: i,
                                                  },
                                                  {
                                                    type: "post",
                                                  }
                                                ).then(async () => {
                                                  toast.success(
                                                    "Payslip generated successfully"
                                                  );
                                                  await getData();
                                                });
                                              }}
                                            >
                                              Generate
                                            </button>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  );
                                })
                              ) : (
                                <>Loading Employees...</>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayRollProcess;
