import { BASE_URL } from "./apiConstants";

//Academics

//Add Subject
export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`;

//Add Lesson
export const ACADEMICS_ADD_LESSON = `${BASE_URL}/api/academics/lesson`;

//Add Topic
export const ACADEMICS_ADD_TOPIC = `${BASE_URL}/api/academics/topic`;

//Add Class
export const ACADEMICS_ADD_CLASS = `${BASE_URL}/api/academics/class`;

//Add Semester
export const ACADEMICS_ADD_SEMESTER = `${BASE_URL}/api/academics/semester`;

//Add Semester
export const ACADEMICS_ADD_SECTION = `${BASE_URL}/api/academics/section`;

export const ACADEMICS_ADD_BLOOMS_LEVELS = `${BASE_URL}/api/academics/blooms-levels`;

//program_outcome

export const ACADEMICS_ADD_PROGRAM_OUTCOMES = `${BASE_URL}/api/academics/program-outcomes`;

//Class Time Table
export const ACADEMICS_CLASS_TIME_TABLE = `${BASE_URL}/api/academics/timetable`;

//Add Subject
// export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`

export const ACADEMICS_ADD_SYLLABUS = `${BASE_URL}/api/academics/course-session`;

export const ACADEMICS_UPDATE_SYLLABUS = `${BASE_URL}/api/academics/course-session`;

export const ACADEMICS_ADD_LESSON_PLAN = `${BASE_URL}/api/academics/lessonplan`;

export const ACADEMICS_PROMOTE_STUDENT = `${BASE_URL}/api/student/session`;

export const ACADEMICS_PROMOTE_DEMOTED_STUDENT = `${BASE_URL}/api/student/promoteDemotedStudent`;

export const ACADEMICS_DEMOTE_STUDENT = `${BASE_URL}/api/student/session/demoteStudents`;

export const ACADEMICS_TRANSFER_STUDENT = `${BASE_URL}/api/student/transfer`;

export const ACADEMICS_ADD_QUESTION_BANK = `${BASE_URL}/api/academics/questionbank`;

export const ACADEMICS_ADD_BATCH = `${BASE_URL}/api/academics/createPracticalBatch`;

export const ACADEMICS_GET_BATCH = `${BASE_URL}/api/academics/getPracticalBatch`;

export const ACADEMICS_UPDATE_BATCH = `${BASE_URL}/api/academics/updateBatch`;

export const ACADEMICS_ASSIGN_BATCH = `${BASE_URL}/api/academics/assignStudents`;

export const ACADEMICS_GET_ASSIGN_STU = `${BASE_URL}/api/academics/getAssignStudents`;

export const ACADEMICS_CREATE_P_ATT = `${BASE_URL}/api/academics/createPBatchAtt`;

export const ACADEMICS_GET_P_ATT = `${BASE_URL}/api/academics/getPBatchAtt`;
