import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  BASE_URL,
  CUSTOM_APPROVER,
  EMPLOYEE,
  EMPLOYEE_EDUCATION,
  EMPLOYEE_EMPLOYEMENT_TERMS,
  EMPLOYEE_EXPERIENCE,
  EMPLOYEE_LEGAL_DOCUMENTS,
  EMPLOYEE_PLACEMENT,
  EMPLOYEE_PUBLICATION,
  EMPLOYEE_TRAINING,
  HR_WORKFLOW,
} from "./../../../utils/apiConstants";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import {
  ALL_DATA,
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_JOBROLES,
} from "../../../utils/LocalStorageConstants";
import { getFileUrl } from "../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_IMAGE } from "../../../utils/AssetsReferenceTypes";
import { SESSION_ROLE } from "../../../utils/sessionStorageContants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig";
import "./EditStaff.scss";
import getUniversityDetails from "../../../utils/universityDetails.api";
import { international } from "../../../Data/Countries/international";
import { indian } from "../../../Data/Countries/india";
import DocumentsModal from "../../../modals/Students/DocumentsModal";
import { useReactToPrint } from "react-to-print";
import { Progress } from "antd";
import { HOSTEL_DETAILS } from "../../../utils/Hostel.apiConst";
import { PAYROLL_PAYSCALE, PAYROLL_SALARY } from "../../../utils/apiConstants";
import {
  USERS_COLLEGEID_UPDATE,
  USERS_GET_DATA,
  USER_UPDATE,
} from "../../../utils/InfoUploadingApiConstants";
import Modal2FA from "./Modal2FA";

function StaffBasic({ tab, id, setLoading, collegeId, fetchProgressData }) {
  const [countries, setCountries] = useState([]);

  const [link, setLink] = useState("");
  const [title, setTitle] = useState("");
  const [userSalary, setUserSalary] = useState();

  const [profilePhoto, setProfilePhoto] = useState();
  const [aadharPhoto, setAadharPhoto] = useState();

  const [payscale, setPayScale] = useState([]);

  const [erpRole, setErpRole] = useState("");

  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push(item?.country);
    });
    setCountries(arr);
  };

  const getPayScaleData = async () => {
    const config = {
      method: "get",
      url: `${PAYROLL_PAYSCALE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("payscale - ", res.data.data);
        setPayScale(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
        console.log(err);
      });
  };

  //UNIVERSITY DETAILS START
  const [universityData, setUniversityData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();

    setCountryAndState();
  }, []);

  useEffect(() => {
    getPayScaleData();
  }, []);

  //UNIVERSITY DETAILS END

  const navigate = useNavigate();

  const departmentOptions = JSON.parse(
    localStorage.getItem(ALL_DATA)
  )?.department;
  const userRolesOpt = JSON.parse(localStorage.getItem(ALL_DATA))?.userRoles;
  const collegesOpt = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));
  const userRole = sessionStorage.getItem(SESSION_ROLE);

  const [approvers, setApprovers] = useState([]);

  let tempPhoto = "";
  let tempAadhar = "";
  const [user, setUser] = useState();

  const [userdata, setUserData] = useState();

  //Function upload attachment to the s3
  const addAttachment = async (e, str) => {
    // console.log('add attachment called');
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `Employee_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0],
        e.target.value.split(".")[0]
      );
      // console.log('photo uploaded - ', d);
      setUser((prev) => ({
        ...prev,
        [str === "Student_Photo" ? "photo" : "aadhaar_photo"]: d ? d : "",
      }));
      if (str == "Student_Photo") tempPhoto = d;
      else if (str == "Aadhaar_Photo") tempAadhar = d;
    } catch (error) {
      console.log(error);
    }
  };

  //get request for approvals info
  const getApprovals = async () => {
    const config = {
      method: "get",
      url: `${HR_WORKFLOW}?college_id=${collegeId}&type=LEAVE`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        setApprovers(res.data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  //get Request for getting employee information
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        // console.log(res);
        // toast.success("Data Success");
        setUser(res.data.data);
        // console.log("Staff data -", res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.response.data.message)
        setLoading(0);
      });
  };

  const getEmployeeSalary = async () => {
    const config = {
      method: "get",
      url: `${PAYROLL_SALARY}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("Salary data - ", res.data.data);
        let s = res.data.data[0];
        setUserSalary(s);
        setLoading(0);
      })
      .catch((err) => {
        toast.error("Error while fetching salary");
        console.log(err);
        setLoading(0);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    setUserSalary((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(1);
    e.preventDefault();

    let attachmentPromises = [];
    if (profilePhoto)
      attachmentPromises.push(addAttachment(profilePhoto, "Student_Photo"));
    if (aadharPhoto)
      attachmentPromises.push(addAttachment(aadharPhoto, "Aadhaar_Photo"));

    await Promise.all(attachmentPromises);
    setLoading(1);
    e.preventDefault();

    let data = { ...user };
    if (tempPhoto) data = { ...data, photo: tempPhoto };
    if (tempAadhar) data = { ...data, aadhaar_photo: tempAadhar };

    const config = {
      method: "put",
      url: `${EMPLOYEE}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        fetchProgressData();
        setLoading(0);
        toast.success("Edited Successfully");
        setUser(res.data.data);
        getData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const EditUser = async () => {
    const config = {
      method: "put",
      url: `${USER_UPDATE}/${userdata.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...userdata,
      },
    };

    console.log("user - ", userdata);

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("ERP details updated");
        setUserData(res.data.data);
        getUsersData(userdata.id);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const editSalary = async () => {
    console.log("hi");
    console.log("user salary - ", {
      ...userSalary,
      employee_id: id,
      designation: user.role,
    });
    console.log(`${PAYROLL_SALARY}/${userSalary.id}`);
    const config = {
      method: "put",
      url: `${PAYROLL_SALARY}/${userSalary.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...userSalary,
        employee_id: id,
        designation: user.role,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Salary Updated");
        getEmployeeSalary();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Error while updating salary details");
      });
  };

  useEffect(() => {
    getData();
    getEmployeeSalary();
    // getApprovals();
  }, []);

  const [data, setData] = useState([]);
  const [placementsData, setPlacementsData] = useState([]);
  const [educationdata, setEducationData] = useState([]);
  const [termsdata, setTermsData] = useState([]);
  const [experiencedata, setExperienceData] = useState([]);
  const [docsdata, setDocsData] = useState([]);
  const [publicadata, setPublicaData] = useState([]);
  const [trainingdata, setTrainingData] = useState([]);
  const [localDepartment, setLocalDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [hostelNames, setHostelNames] = useState([]);

  const roleid = sessionStorage.getItem("role");
  const empid = sessionStorage.getItem("employee_id");

  // console.log("role -", roleid);

  const getHostelData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${HOSTEL_DETAILS}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        // console.log('hostel data - ', res.data.data);
        setHostelNames(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const getAllData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        console.log("emp -- ", res.data.data);
        setData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
        setLoading(0);
      });

    const config2 = {
      method: "get",
      url: `${EMPLOYEE_PLACEMENT}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config2)
      .then((res) => {
        // console.log(res);
        setPlacementsData(res.data.data);
        // toast.success("data fetched")
        setLoading(0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(0);
      });

    const config3 = {
      method: "get",
      url: `${EMPLOYEE_EMPLOYEMENT_TERMS}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config3)
      .then((res) => {
        // console.log(res);
        setTermsData(res.data.data);
        // toast.success("data fetched")
        setLoading(0);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(0);
      });

    const config4 = {
      method: "get",
      url: `${EMPLOYEE_EDUCATION}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config4)
      .then((res) => {
        setLoading(0);
        // console.log(res);
        setEducationData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });

    const config5 = {
      method: "get",
      url: `${EMPLOYEE_EXPERIENCE}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config5)
      .then((res) => {
        setLoading(0);
        // console.log(res);
        setExperienceData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });

    const config6 = {
      method: "get",
      url: `${EMPLOYEE_TRAINING}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config6)
      .then((res) => {
        setLoading(0);
        // console.log(res);
        setTrainingData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });

    const config7 = {
      method: "get",
      url: `${EMPLOYEE_LEGAL_DOCUMENTS}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config7)
      .then((res) => {
        setLoading(0);
        // console.log(res);
        setDocsData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });

    const config8 = {
      method: "get",
      url: `${EMPLOYEE_PUBLICATION}?employee_id=${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config8)
      .then((res) => {
        fetchProgressData();
        setLoading(0);
        // console.log(res);
        setPublicaData(res.data.data);
        // toast.success("data fetched")
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getUsersData = async (id) => {
    // console.log('hi');
    const config55 = {
      method: "get",
      url: `${USERS_GET_DATA}?user_id=${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config55)
      .then((res) => {
        const userssdata = res.data.data;
        setUserData(userssdata[0]);
        console.log("userData -", userdata);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (data) {
      console.log("user id - ", data?.user_id);
      getUsersData(data?.user_id);
    }
  }, [data]);

  useEffect(() => {
    getAllData();
    getHostelData();
  }, []);

  // console.log("data -", data);
  // console.log("placementData -", placementsData);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    const formattedTime = `${currentDate
      .getHours()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}:${currentDate
      .getSeconds()
      .toString()
      .padStart(2, "0")}`;
    return `${formattedDate} ${formattedTime}`;
  };

  // console.log("educationData -", educationdata);
  // console.log("experienceData -", experiencedata);
  // console.log("trainingData -", trainingdata);
  // console.log("DocsData -", docsdata);
  // console.log("PublicationData -", publicadata);

  // console.log("educationdata?.school_name -", educationdata?.school_name);

  const tableRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 10); // Adjust the delay as needed
  };

  const [score, setScore] = useState(null);

  const fetchProgressDataa = async () => {
    try {
      const response = await axios.get(
        BASE_URL + "/api/employee/profile-progress/" + id
      );
      setScore(response.data.score);
    } catch (error) {
      console.error("Error fetching profile progress:", error);
    }
  };

  useEffect(() => {
    console.log("user data - ", userdata);
  }, [userdata]);

  useEffect(() => {
    fetchProgressDataa();
  }, []);

  return (
    <>
      <DocumentsModal title={title} img={link} setLink={setLink} />
      <Modal2FA
        userdata={userdata}
        getUsersData={getUsersData}
        setLoading={setLoading}
      />
      <div className="StaffBasic Staff">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body">
                <div className="col-12">
                  <button
                    className="btn btn-nex mr-5 float-right"
                    onClick={handlePrint}
                  >
                    Print
                  </button>
                  {/* <button
                    className="btn btn-nex mr-5 float-right"
                    data-toggle="modal"
                    data-target="#Modal2FA"
                    disabled={!userdata || userdata?.length === 0}
                    // onClick={generateQRCode}
                  >
                    2 Factor Authentication
                  </button> */}
                </div>
                <div className="row">
                  {" "}
                  <div className="col-4">
                    <h4 className="">Employee Details</h4>
                  </div>
                </div>
                <hr />
                <br />
                {/* <form
                className="needs-validation"
              > */}
                <div className="row">
                  <div className="col-md-2">
                    <img
                      src={`${
                        user?.photo
                          ? user?.photo
                          : "../../../assets/images/reports/profile.png"
                        // "../../../assets/images/reports/profile.png"
                      }`}
                      className="rounded-circle"
                      style={{ width: "75%", aspectRatio: "1/1" }}
                      alt=""
                    />
                  </div>
                  <div className="col-md-5">
                    {user ? (
                      <>
                        <table>
                          <tr>
                            <th>
                              <h5>Name </h5>
                            </th>
                            <td>
                              <h5>
                                : {user?.first_name + " " + user?.last_name}
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>College </h6>
                            </th>
                            <td>
                              <h6>
                                :{" "}
                                {
                                  collegeOpt?.find(
                                    (s) => s.id == user?.college_id
                                  )?.name
                                }
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>Department </h6>
                            </th>
                            <td>
                              <h6>
                                :{" "}
                                {
                                  departmentOptions?.find(
                                    (s) => s.id == user?.department_id
                                  )?.name
                                }
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>Designation </h6>
                            </th>
                            <td>
                              <h6>: {user?.role_name}</h6>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>Staff Id </h6>
                            </th>
                            <td>
                              <h6>: {userdata?.staff_id}</h6>
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <hr />

                {/* ERP */}
                {roleid == "SUPERADMIN" ? (
                  <div className="accordion" id="accordionExample">
                    {/* pay roll */}
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0">ERP</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Role <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                name="role"
                                id="role"
                                required=""
                                // readOnly={true}
                                // disabled={true}
                                onChange={(e) =>
                                  setUserData({
                                    ...userdata,
                                    role: e.target.value,
                                  })
                                }
                                value={userdata?.role}
                                // value={userdata?.role}
                              >
                                <option value=""> Select</option>
                                <option value="HR">HR</option>
                                <option value="CASHIER">CASHIER</option>
                                <option value="STAFF">STAFF</option>
                                <option value="SHR">SUPER HR</option>
                                <option value="ADMIN">ADMIN (PRINCIPAL)</option>
                                <option value="ACC">ACCOUNTANT</option>
                                <option value="SUACC">SUPER ACCOUNTANT</option>
                                <option value="COE">COE</option>
                                <option value="AD-CON">
                                  ADMISSION CO-ORDINATOR
                                </option>
                                <option value="OFFICE">OFFICE CLERK</option>
                                <option value="LIB">LIBRARIAN</option>
                                <option value="IT">IT ADMIN</option>
                                <option value="WARDEN">WARDEN</option>
                                <option value="SUPERADMIN">SUPERADMIN</option>
                              </select>
                              <span className="text-danger">
                                Functionalities of ERP is based on role.
                              </span>
                            </div>
                          </div>
                          {userdata?.role == "WARDEN" ? (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="validationCustom01">
                                  {" "}
                                  Hostel <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="hostel_id"
                                  name="hostel_id"
                                  type="text"
                                  onChange={(e) =>
                                    setUserData({
                                      ...userdata,
                                      hostel_id: e.target.value,
                                    })
                                  }
                                  value={userdata?.hostel_id}
                                  className="form-control"
                                >
                                  <option value=""> Select Hostel</option>
                                  {hostelNames?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.hostel_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Employee Category{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                name="employee_category"
                                id="employee_category"
                                required=""
                                onChange={(e) =>
                                  setUserData({
                                    ...userdata,
                                    employee_category: e.target.value,
                                  })
                                }
                                value={userdata?.employee_category}
                              >
                                <option value=""> Select</option>
                                <option value="TEACHING STAFF">
                                  TEACHING STAFF
                                </option>
                                <option value="NON-TEACHING STAFF">
                                  NON-TEACHING STAFF
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Staff Id <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="staff_id"
                                name="staff_id"
                                required=""
                                placeholder="Enter Staff ID"
                                value={userdata?.staff_id}
                                onChange={(e) =>
                                  setUserData({
                                    ...userdata,
                                    staff_id: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row float-right">
                          <button
                            className="btn btn-success btn-rounded btn-outline"
                            type="submit"
                            name="submit"
                            onClick={EditUser}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* BASIC DETAILS */}
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">BASIC DETAILS</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {roleid == "SUPERADMIN" ? (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Designation{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="role"
                                name="role"
                                className="form-control"
                                required=""
                                onChange={handleChange}
                                value={user?.role}
                              >
                                <option value="" selected>
                                  {" "}
                                  Select Designation
                                </option>
                                {jobPositionOpt?.map((i, key) => (
                                  <option value={i.id} key={i.id}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Employee Type{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                name="employee_type"
                                id="employee_type"
                                required=""
                                onChange={handleChange}
                                value={user?.employee_type}
                              >
                                <option value="">Select Type</option>
                                <option value="PERMANENT">Permanent</option>
                                <option value="PROBATION">Probation</option>
                              </select>
                            </div>
                          </div>
                          {user?.employee_type == "PROBATION" ? (
                            <div className="col-md-4">
                              <div className="form-group">
                                <label htmlFor="validationCustom01">
                                  {" "}
                                  Probation Till Date
                                </label>
                                <span style={{ color: "red" }}>*</span>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="doj"
                                  name="probation_till_date"
                                  onChange={handleChange}
                                  value={
                                    user?.probation_till_date?.split("T")[0]
                                  }
                                  required=""
                                />
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Biometric Id
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="biometric_id"
                                name="biometric_id"
                                placeholder=" Enter Biometric Id"
                                onChange={handleChange}
                                value={user?.biometric_id}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                College<span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="designation"
                                name="college_id"
                                type="text"
                                onChange={handleChange}
                                value={user?.college_id}
                                className="form-control"
                              >
                                <option value=""> Select College</option>
                                {collegesOpt?.map((i, key) => (
                                  <option value={i.id} key={key}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Department{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className="form-control"
                                name="department_id"
                                id="department"
                                required=""
                                onChange={handleChange}
                                value={user?.department_id}
                                disabled={
                                  [
                                    "SUPERADMIN",
                                    "ADMIN",
                                    "HR",
                                    "SUPERHR",
                                  ].includes(userRole)
                                    ? false
                                    : true
                                }
                              >
                                <option value=""> Select Department</option>
                                {departmentOptions
                                  ?.filter(
                                    (s) => s.college_id == user?.college_id
                                  )
                                  ?.map((i, key) => (
                                    <option value={i.id} key={key}>
                                      {i.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Date of Joining
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="date"
                                className="form-control"
                                id="doj"
                                name="date_of_joining"
                                onChange={handleChange}
                                value={user?.date_of_joining?.split("T")[0]}
                                required=""
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            First Name <span style={{ color: "red" }}>
                              *
                            </span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="fname"
                            name="first_name"
                            required=""
                            placeholder=" Enter First Name"
                            value={user?.first_name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Last Name <span style={{ color: "red" }}>*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lname"
                            name="last_name"
                            placeholder=" Enter Last Name"
                            onChange={handleChange}
                            value={user?.last_name}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Gender <span style={{ color: "red" }}>*</span>{" "}
                          </label>
                          <select
                            className="form-control"
                            name="gender"
                            id="gender"
                            required=""
                            onChange={handleChange}
                            value={user?.gender}
                          >
                            <option value=""> Select</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Official Email-Id{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=" Enter Staff Email ID"
                            id="email"
                            name="email"
                            required=""
                            value={user?.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Personal Email-Id{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=" Enter Staff Email ID"
                            id="personal_email"
                            name="personal_email"
                            required=""
                            value={user?.personal_email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">Mobile </label>
                          <input
                            type="text "
                            className="form-control"
                            id="mobile"
                            name="phone"
                            placeholder=" Enter Contact Number"
                            value={user?.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="dob"
                            name="dob"
                            value={user?.dob?.split("T")[0]}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Marital Status{" "}
                          </label>
                          <select
                            className="form-control"
                            name="marital_status"
                            value={user?.marital_status}
                            onChange={handleChange}
                            id="marital"
                          >
                            <option value=""> Select</option>
                            <option value="single">Single</option>
                            <option value="married">Married</option>
                            <option value="widowed">Widowed</option>
                            <option value="not_specified">Not Specified</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Father Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="father_name"
                            placeholder=" Enter Father Name"
                            name="father_name"
                            onChange={handleChange}
                            value={user?.father_name}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Mother Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mother_name"
                            name="mother_name"
                            placeholder=" Enter Mother Name"
                            onChange={handleChange}
                            value={user?.mother_name}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Nationality</label>
                          <span style={{ color: "red" }}>*</span>
                          <select
                            type="text"
                            name="nationality"
                            className="form-control"
                            placeholder="Enter Nationality"
                            value={user?.nationality}
                            onChange={handleChange}
                          >
                            <option value="">Select Nationality</option>
                            <option value="INDIAN">Indian</option>
                            <option value="INTERNATIONAL">
                              International
                            </option>{" "}
                          </select>
                        </div>
                      </div>
                      {user?.nationality == "INTERNATIONAL" ? (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">Country</label>
                              <select
                                type="text"
                                name="country"
                                className="form-control"
                                value={user?.country}
                                onChange={handleChange}
                              >
                                <option value="">Select Country</option>
                                {countries &&
                                  countries?.map((item, key) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">State</label>
                              <span style={{ color: "red" }}>*</span>
                              <select
                                type="text"
                                name="state"
                                className="form-control"
                                value={user?.state}
                                onChange={handleChange}
                              >
                                <option value="">Select State</option>
                                {international
                                  ?.filter((s) => s.country == user?.country)[0]
                                  ?.states?.map((item, key) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                              </select>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">State</label>
                            <select
                              type="text"
                              name="state"
                              className="form-control"
                              value={user?.state}
                              onChange={handleChange}
                            >
                              <option>Select State</option>
                              {indian &&
                                indian?.map((item, key) => {
                                  return (
                                    <option value={item?.state}>
                                      {item?.state}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}
                      {user?.nationality == "INDIAN" ? (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="">District</label>
                            <span style={{ color: "red" }}>*</span>
                            <select
                              type="text"
                              name="district"
                              className="form-control"
                              value={user?.district}
                              onChange={handleChange}
                            >
                              <option>Select District</option>
                              {indian &&
                                indian
                                  ?.filter((s) => s.state == user?.state)[0]
                                  ?.districts?.map((item, key) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                            </select>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">City</label>
                          <input
                            type="text"
                            name="city"
                            placeholder="Enter City"
                            className="form-control"
                            value={user?.city}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Current Address
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            cols={1}
                            rows={1}
                            id="c_address"
                            name="current_address"
                            value={user?.current_address}
                            placeholder={"Enter Your Current Address"}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Permanent Address
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            cols={1}
                            rows={1}
                            id="p_address"
                            name="permanent_address"
                            value={user?.permanent_address}
                            placeholder={"Permanant Address"}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {user?.nationality == "INTERNATIONAL" ? (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Passport Number
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Passport Number"
                                value={user?.passport_no}
                                name="passport_no"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Visa Number
                              </label>
                              <span style={{ color: "red" }}>*</span>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Visa Number"
                                value={user?.visa_no}
                                name="visa_no"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Visa Issue Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                value={user?.visa_issue}
                                name="visa_issue"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Visa Expiry Date
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                value={user?.visa_expiry}
                                name="visa_expiry"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Qualification
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="qualification"
                            name="qualification"
                            placeholder=" Enter Qualification"
                            onChange={handleChange}
                            value={user?.qualification}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Work Experience
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="experience"
                            name="work_experience"
                            placeholder=" Enter Work Experience"
                            value={user?.work_experience}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Aadhaar Number
                          </label>
                          <span style={{ color: "red" }}>*</span>
                          <input
                            type="text"
                            className="form-control"
                            id="aadhaar"
                            name="aadhaar"
                            placeholder="Enter Aadhar Number"
                            value={user?.aadhaar}
                            onChange={handleChange}
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Staff Photo
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="photo"
                            name="photo"
                            onChange={(e) => {
                              // addAttachment(e, 'Student_Photo')
                              setProfilePhoto(e);
                            }}
                          />
                          {user?.photo?.length > 0 ? (
                            <button
                              onClick={() => {
                                setLink(user?.photo);
                                setTitle("Staff Picture");
                              }}
                              data-toggle="modal"
                              data-target="#DocumentsModal"
                              className="btn btn-primary btn-sm my-1"
                            >
                              View
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            {" "}
                            Aadhaar Photo Copy
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="photo"
                            name="photo"
                            onChange={(e) => {
                              // addAttachment(e, "Aadhaar_Photo");
                              setAadharPhoto(e);
                            }}
                          />
                          {user?.aadhaar_photo?.length > 0 ? (
                            <button
                              onClick={() => {
                                setLink(user?.aadhaar_photo);
                                setTitle("Aadhar Card");
                              }}
                              data-toggle="modal"
                              data-target="#DocumentsModal"
                              className="btn btn-primary btn-sm my-1"
                            >
                              View
                            </button>
                          ) : null}
                        </div>
                      </div>
                      {roleid == "SUPERADMIN" ? (
                        <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                EPF Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="epf"
                                name="epf_number"
                                value={user?.epf_number}
                                onChange={handleChange}
                                placeholder=" Enter EPF Number"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Work Shift
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="shift"
                                name="work_shift"
                                onChange={handleChange}
                                value={user?.work_shift}
                                placeholder="Enter Work Shift"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Location
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="location"
                                name="location"
                                value={user?.location}
                                onChange={handleChange}
                                placeholder="Enter Work Location"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row float-right">
                      <button
                        className="btn btn-success btn-rounded btn-outline"
                        type="submit"
                        name="submit"
                        onClick={handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>

                {roleid == "SUPERADMIN" || roleid == "SHR" ? (
                  <div className="accordion" id="accordionExample">
                    {/* pay roll */}
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h5 className="mb-0"> Pay Roll </h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Account Title
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="account"
                                name="account_title"
                                value={userSalary?.account_title}
                                onChange={handleChange2}
                                placeholder=" Enter Acount Title"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Bank Account Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="acnumber"
                                name="account_number"
                                value={userSalary?.account_number}
                                onChange={handleChange2}
                                placeholder=" Enter Bank Account Number"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Bank Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="bname"
                                name="bank_name"
                                value={userSalary?.bank_name}
                                onChange={handleChange2}
                                placeholder="Enter Bank Name"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                IFSC Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="ifsc"
                                name="ifsc_code"
                                value={userSalary?.ifsc_code}
                                onChange={handleChange2}
                                placeholder="Enter IFSC Code"
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                {" "}
                                Bank Branch
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="branch"
                                name="bank_branch"
                                value={userSalary?.bank_branch}
                                onChange={handleChange2}
                                placeholder="Enter Branch Name"
                              />
                            </div>
                          </div>
                          {empid == 1 || empid == 311 ? (
                            <>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="validationCustom01">
                                    Pay Scale{" "}
                                  </label>
                                  <select
                                    className="form-control"
                                    name="payscale_id"
                                    id="payscale_id"
                                    required=""
                                    onChange={(e) => {
                                      setUserSalary({
                                        ...userSalary,
                                        payscale_id: e.target.value,
                                        basic_salary: payscale?.find(
                                          (s) => s.id == e.target.value
                                        )?.basic_salary
                                          ? payscale?.find(
                                              (s) => s.id == e.target.value
                                            )?.basic_salary
                                          : 0,
                                      });
                                    }}
                                    value={userSalary?.payscale_id}
                                  >
                                    <option value=""> Select Pay Scale</option>
                                    {payscale?.map((i, k) => {
                                      return (
                                        <option value={i?.id}>
                                          {i?.title}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="validationCustom01">
                                    {" "}
                                    Basic Salary
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="basic_salary"
                                    name="basic_salary"
                                    value={userSalary?.basic_salary}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-group">
                                  <label htmlFor="validationCustom01">
                                    {" "}
                                    PF Salary
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="pf_salary"
                                    name="pf_salary"
                                    value={userSalary?.pf_salary}
                                    onChange={(e) =>
                                      setUserSalary({
                                        ...userSalary,
                                        pf_salary: e.target.value,
                                      })
                                    }
                                    // disabled={true}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="row float-right">
                          <button
                            className="btn btn-success btn-rounded btn-outline"
                            type="submit"
                            name="submit"
                            onClick={editSalary}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {/* </form> */}
              </div>
            </div>
            {/* end card */}
          </div>
        </div>

        <div style={{ display: "none" }}>
          <section ref={tableRef}>
            <div className="col-sm-12">
              <table className="table staff-print">
                {data && (
                  <thead>
                    <tr className="row">
                      <th className="text-bold col-sm-9">
                        <p className="text-dark ml-2">
                          Printed On - {getCurrentDate()}
                        </p>
                        <h3 className="mt-5 ml-4 text-primary">
                          <img
                            className="profile-user-img img-responsive mx-auto"
                            src="../../../assets/images/logo.png"
                            width="141px"
                            height="100px"
                            style={{ aspectRatio: "1/1" }}
                          />
                          <span className="ml-3 print-staff-details">
                            {
                              collegeOpt?.find((s) => s.id === data?.college_id)
                                ?.name
                            }
                          </span>
                        </h3>
                      </th>
                      <th className="col-sm-3">
                        <h2 style={{ marginTop: "25px", marginLeft: "20px" }}>
                          STAFF PROFILE
                        </h2>
                        <br />
                        <h3 className="print-staff-details mt-2 ml-3">
                          {data?.first_name + " " + data?.last_name}
                        </h3>
                      </th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  <tr className="row">
                    <td className="col-sm-8">
                      <h4 className="text-center my-3 print-staff-details">
                        PERSONAL INFORMATION
                      </h4>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Staff Name :</strong>{" "}
                            <span className="ml-3">
                              {data?.first_name + " " + data?.last_name}
                            </span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Staff ID : </strong>
                            <span className="ml-3">{data?.staff_id}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Phone Number: </strong>
                            <span className="ml-3">{data?.phone}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Email : </strong>
                            <span className="ml-3">{data?.email}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Date of Birth : </strong>
                            <span className="ml-3">
                              {data?.dob && data?.dob.substring(0, 10)}
                            </span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Gender : </strong>
                            <span className="ml-3">{data?.gender}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Mother Name : </strong>
                            <span className="ml-3">{data?.mother_name}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Father Name : </strong>
                            <span className="ml-3">{data?.father_name}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Role : </strong>
                            <span className="ml-3">{data?.role}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Employee Category : </strong>
                            <span className="ml-3">
                              {userdata?.employee_category}
                            </span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Faculty : </strong>
                            <span className="ml-3">
                              {
                                collegeOpt?.find(
                                  (s) => s.id === data?.college_id
                                )?.name
                              }
                            </span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Department : </strong>
                            <span className="ml-3">
                              {
                                departmentOptions?.find(
                                  (s) => s.id == data?.department_id
                                )?.name
                              }
                            </span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Blood Group : </strong>
                            <span className="ml-3">{data?.blood_grp}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Marital Status : </strong>
                            <span className="ml-3">{data?.marital_status}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Qualification : </strong>
                            <span className="ml-3">{data?.qualification}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Adhaar No : </strong>
                            <span className="ml-3">{data?.aadhaar}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Nationality : </strong>
                            <span className="ml-3">{data?.nationality}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>State : </strong>
                            <span className="ml-3">{data?.state}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>District : </strong>{" "}
                            <span className="ml-3">{data?.district}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>City : </strong>
                            <span className="ml-3">{data?.city}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Religion : </strong>
                            <span className="ml-3">{data?.religion}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Caste : </strong>
                            <span className="ml-3">{data?.caste}</span>
                          </p>
                        </td>
                      </tr>

                      <br />
                      <tr className="row ml-5">
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Category : </strong>
                            <span className="ml-3">{data?.category}</span>
                          </p>
                        </td>
                        <td className="col-sm-6">
                          <p style={{ margin: "0 0 0 14px" }}>
                            <strong>Employee Type: </strong>
                            <span className="ml-3">{data?.employee_type}</span>
                          </p>
                        </td>
                      </tr>
                    </td>
                    <td className="col-sm-4">
                      <div className="image-container">
                        <img
                          src={
                            data?.photo
                              ? data?.photo
                              : "../../../assets/images/reports/profile.png"
                          }
                          className="stafff-img"
                        />
                      </div>
                      <br />
                      <h3 className="text-center mr-4">
                        {data?.first_name + " " + data?.last_name}
                      </h3>
                      <br />
                      <div className="mb-3">
                        <h5 style={{ marginLeft: "30px" }}>
                          Profile Completion Progress
                        </h5>
                        <p style={{ width: "260px", marginLeft: "30px" }}>
                          {score !== null ? (
                            <Progress percent={score} />
                          ) : (
                            <p>Loading...</p>
                          )}
                        </p>
                      </div>
                    </td>
                    <td className="col-sm-12">
                      <br />
                      <br />
                      <h4 className="text-center my-3 print-staff-details">
                        CONTACT INFORMATION
                      </h4>

                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            {/* <th>Mobile No</th> */}
                            <th>Email</th>
                            <th>Contact No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {/* <td>{data?.mobile}</td> */}
                            <td>{data?.email}</td>
                            <td>{data?.phone}</td>
                          </tr>
                        </tbody>
                      </table>

                      <br />
                      <br />
                      <h4 className="text-center my-3 print-staff-details">
                        ADDRESS INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>Current Address</th>
                            <th>Permanent Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{data?.current_address}</td>
                            <td>{data?.permanent_address}</td>
                          </tr>
                        </tbody>
                      </table>

                      <br />
                      <br />
                      <h4 className="text-center my-4 print-staff-details">
                        EDUCATION INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>SL. NO</th>
                            <th>University</th>
                            <th>Year</th>
                            <th>Education Type</th>
                            <th>Grade</th>
                          </tr>
                        </thead>
                        {educationdata &&
                          educationdata?.map((education, index) => (
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{education?.university_name}</td>
                                <td>
                                  {education?.from_year
                                    ? education?.from_year + "-"
                                    : ""}
                                  {education?.to_year}
                                </td>
                                <td>
                                  {education?.education_type === "other"
                                    ? education?.other_education_type
                                    : education?.education_type}
                                </td>
                                <td>
                                  {education?.performance_score
                                    ? education?.performance_score
                                    : "__"}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>

                      {/* </td>
                          </tr>      

                          <tr>
                            <td> */}

                      <br />
                      <br />
                      <h4 className="text-center my-4 print-staff-details">
                        EXPERIENCE INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>SL. NO</th>
                            <th>Employer</th>
                            <th>Job Title</th>
                            <th>Year</th>
                            <th>Salary</th>
                            <th>Country / Region</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        {experiencedata &&
                          experiencedata?.map((experience, index) => (
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{experience?.employer}</td>
                                <td>{experience?.job_title}</td>
                                <td>
                                  {experience?.from &&
                                    experience?.from.substring(0, 10)}{" "}
                                  {"->"}{" "}
                                  {experience?.to &&
                                    experience?.to.substring(0, 10)}{" "}
                                  ({experience.duration})
                                </td>
                                <td>
                                  {experience?.salary
                                    ? experience?.salary
                                    : "__"}
                                </td>
                                <td>
                                  {experience?.region
                                    ? experience?.region
                                    : "__"}
                                </td>
                                <td>
                                  {experience?.remark
                                    ? experience?.remark
                                    : "__"}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>

                      <br />
                      <br />
                      <h4 className="text-center my-4 print-staff-details">
                        TRAINING INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>SL. NO</th>
                            <th>Course</th>
                            <th>Date</th>
                            <th>Result</th>
                            <th>Trainer</th>
                            <th>Remark</th>
                          </tr>
                        </thead>
                        {trainingdata &&
                          trainingdata?.map((training, index) => (
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{training?.course}</td>
                                <td>
                                  {training?.date &&
                                    training?.date.substring(0, 10)}
                                </td>
                                <td>
                                  {training?.result ? training?.result : "__"}
                                </td>
                                <td>
                                  {training?.trainer_id
                                    ? training?.trainer_id
                                    : "__"}
                                </td>
                                <td>
                                  {training?.remark ? training?.remark : "__"}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>

                      <br />
                      <br />
                      <h4 className="text-center my-4 print-staff-details">
                        DOCUMENT INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>SL. NO</th>
                            <th>Document</th>
                            <th>Document Type</th>
                            <th>Valid From</th>
                            <th>Valid To</th>
                          </tr>
                        </thead>
                        {docsdata &&
                          docsdata?.map((document, index) => (
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{document?.document_title}</td>
                                <td>{document?.document_type}</td>
                                <td>
                                  {document?.valid_from &&
                                  document?.valid_from.substring(0, 10)
                                    ? "Validation"
                                    : "Validation Upto"}
                                </td>
                                <td>
                                  {document?.valid_from
                                    ? document?.valid_from &&
                                      document?.valid_from.substring(0, 10) +
                                        " -> " +
                                        (document?.valid_to
                                          ? document?.valid_to &&
                                            document?.valid_to.substring(0, 10)
                                          : "Not Available")
                                    : "Not Available"}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>

                      <br />
                      <br />
                      <h4 className="text-center my-4 print-staff-details">
                        PLACEMENT INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>SL. NO</th>
                            <th>Course</th>
                            <th>Date</th>
                            <th>Result</th>
                            <th>Trainer</th>
                          </tr>
                        </thead>
                        {trainingdata &&
                          trainingdata?.map((training, index) => (
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{training?.course}</td>
                                <td>
                                  {training?.date &&
                                    training.date.substring(0, 10)}
                                </td>
                                <td>
                                  {training?.result ? training?.result : "__"}
                                </td>
                                <td>
                                  {training?.trainer_id
                                    ? training?.trainer_id
                                    : "__"}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>

                      <br />
                      <br />
                      <h4 className="text-center my-4 print-staff-details">
                        PUBLICATION INFORMATION
                      </h4>
                      <table className="table table-bordered staff-details">
                        <thead>
                          <tr>
                            <th>SL. NO</th>
                            <th>Publication</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        {publicadata &&
                          publicadata?.map((publication, index) => (
                            <tbody>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{publication?.name}</td>
                                <td>
                                  {publication?.date &&
                                    publication?.date.substring(0, 10)}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                      </table>
                    </td>
                  </tr>
                </tbody>

                {/* <br/>
                        <tfoot>
                          <tr>
                            <td className="text-dark mt-5">Printed On - {" "}{getCurrentDate()}</td>
                          </tr>
                        </tfoot> */}
              </table>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default StaffBasic;
