import React from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Flex } from "antd";
export default function ModalEntitlementTemplate({
  showBulkTemplate,
  setShowBulkTemplate,
}) {
  return (
    <>
      <Modal
        title="Download CSV Template of Staff Bulk Upload"
        centered
        open={showBulkTemplate}
        onOk={() => setShowBulkTemplate(false)}
        onCancel={() => setShowBulkTemplate(false)}
        footer={null}
        width={700}
        zIndex={1021}
      >
        <br />

        <p>
          1. All the Fields mentioned in CSV file are required before Upload.
        </p>
        <p>
          2. Leave-type code is mentioned in{" "}
          <Link to={"/leaveType"}>Leave type list.</Link>
        </p>
        <p>
          3. Earning policy is mentioned in{" "}
          <Link to={"/leaveEarningPolicy"}>Earning policy list.</Link>
        </p>
        <p>
          4. Approval workflow is mentioned in{" "}
          <Link to={"/leaveApprovalWorkflow"}>Approval workflow list.</Link>
        </p>
        <p>4. Enter total leaves, earned leaves, taken leaves correctly.</p>
        <p>
          3. Accepted Date Format <b>DD-MM-YYYY</b>
        </p>

        <br />

        <Flex justify="center" align="center">
          <a
            href="https://s3.ap-south-1.amazonaws.com/verification.nexenstial.com/ananya_assets/employee_image/Employee_Student_Photo/templetes/Entitlement_Template.csv"
            download
          >
            <Button
              type="primary"
              style={{ backgroundColor: "#1cbb8c", color: "#fff" }}
            >
              <Flex justify="center" align="center" gap={4}>
                <i class="ri-file-excel-2-fill"></i>
                <span>Download Template</span>
              </Flex>
            </Button>
          </a>
        </Flex>
        <br />
      </Modal>
    </>
  );
}
