import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ModalEmployeeLeaveApplication from "../../../modals/Employee/Leave/Application";
import {
  LEAVE_APPLICATION,
  LEAVE_ENTITLEMENT,
  LEAVE_APPROVER_APPLICATION,
  LEAVE_LEAVE_TYPE,
} from "../../../utils/apiConstants";
import { ALL_DATA } from "../../../utils/LocalStorageConstants";
import { SESSION_EMPLOYEE_ID } from "../../../utils/sessionStorageContants";
import Nodata from "../../NoData/Nodata";
import { Radio, Timeline } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

function EmployeeLeaveApplication({ setLoading, collegeId }) {
  const [data, setData] = useState([]);
  const employee = JSON.parse(localStorage.getItem(ALL_DATA)).employee;
  const [emp, setEmp] = useState("");
  const [type, setType] = useState("");
  const [edit, setEdit] = useState();
  const [entitlement, setEntitlement] = useState();

  const [leaveType, setLeaveType] = useState([]);

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      console.log("error while formating date :: ", dateString);
      return dateString;
    }
  }

  const getEmployeeId = () => {
    return sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      ? sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      : null;
  };

  const [employeeId, setEmployeeId] = useState(getEmployeeId);

  const getEntitlement = async (p) => {
    setLoading(1);

    setEmp(p);
    const config = {
      method: "get",
      url: `${LEAVE_ENTITLEMENT}?employee_id=${employeeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        setEntitlement(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getData = async (p) => {
    setLoading(1);
    setEmp(p);
    const config = {
      method: "get",
      url: `${LEAVE_APPLICATION}?employee_id=${employeeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log("review applications - ", res.data.data);
        setLoading(0);
        console.log("leave applications - ", res.data.data);
        res.data.data.sort((a, b) => {
          const comparison = a?.from_date?.localeCompare(b.from_date);
          if (comparison < 0) {
            return 1;
          } else if (comparison > 0) {
            return -1;
          } else {
            return 0;
          }
        });
        let tempData = res.data.data;
        let finalData = [];
        for (let i in tempData) {
          let approvals = [];
          let config1 = {
            method: "get",
            url: `${LEAVE_APPROVER_APPLICATION}/${tempData[i].id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
          };
          setLoading(1);
          await axios(config1)
            .then((res) => {
              setLoading(0);
              approvals = res.data.data;
              let num = approvals.find(
                (s) => s.employee_id == employeeId
              )?.approver_num;
              let permission = true;
              let before_approver = "";
              for (let k in approvals) {
                if (
                  approvals[k].approver_num < num &&
                  approvals[k].status == "ACTIVE"
                ) {
                  permission = false;
                  before_approver = `${approvals[k].first_name} ${approvals[k].last_name}`;
                  break;
                }
              }
              let obj = {
                ...tempData[i],
                approvals,
                permission,
                before_approver,
              };
              finalData.push(obj);
            })
            .catch((err) => {
              setLoading(0);
              toast.error(err.response.data.message);
            });
        }
        console.log("final data - ", finalData);
        setData(finalData);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getLeaveData = async () => {
    // setLoading(1);
    const config = {
      method: "get",
      url: LEAVE_LEAVE_TYPE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    await axios(config)
      .then((res) => {
        // setLoading(0);
        console.log("leave types - ", res.data.data);
        setLeaveType(res.data.data);
      })
      .catch((err) => {
        // setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getLeaveData();
    getData("");
    getEntitlement();
  }, []);

  return (
    <div>
      <ModalEmployeeLeaveApplication
        type={type}
        empId={employeeId}
        data={edit}
        reloadData={(d) => getData(d)}
        setLoading={setLoading}
        entitlement={entitlement}
        collegeId={collegeId}
        applicationData={data}
        leaveType={leaveType}
      />

      <div className="container mt-5 p-1">
        <div className="card">
          <div className="card-body">
            <div className="row d-flex justify-content-between p-3">
              <button
                className="btn btn-rounded btn-success btn-outline px-4"
                data-toggle="modal"
                data-target="#ModalEmployeeLeaveApplication"
                onClick={() => {
                  setType("add");
                  setEdit();
                }}
              >
                New Application
              </button>

              <button className="btn btn-rounded btn-primary btn-outline px-4">
                Export &uarr;
              </button>
            </div>

            <div>
              {data && data.length !== 0 ? (
                data.map((i, key) => (
                  <div
                    className="row my-3 mx-2 p-3 border rounded shadow report-div cursor-pointer"
                    data-toggle="modal"
                    data-target="#ModalEmployeeLeaveApplication"
                    onClick={() => {
                      setType("edit");
                      setEdit(i);
                    }}
                  >
                    <div className="col-12 row" key={key}>
                      <div className="report-title col-12">
                        {employee.find((j) => j.id === i.employee_id)
                          .first_name +
                          " " +
                          employee.find((j) => j.id === i.employee_id)
                            .last_name}
                      </div>
                      <div className="col-12 d-flex flex-nowrap justify-content-between align-items-center role-parts">
                        <div className="align-self-start text-center col-5">
                          {!i?.deduction ? (
                            <>
                              <div>
                                {formatDate(i?.from_date?.split("T")[0])}
                              </div>
                              <div>&darr;</div>
                              <div>{formatDate(i?.to_date?.split("T")[0])}</div>
                            </>
                          ) : (
                            <>
                              <div>Deducted for Late in</div>
                              <div> or Early out for</div>
                              <div>month {i?.remark}</div>
                            </>
                          )}
                          <div
                            className={`${
                              i.status == "DECLINED"
                                ? "text-danger"
                                : i.status == "APPROVED"
                                ? "text-success"
                                : i.status == "PENDING"
                                ? "text-warning"
                                : "text-info"
                            }`}
                          >
                            <b>{i.status}</b>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="d-flex">{i.session}</div>
                          <button className=" w-auto btn btn-dark p-1">
                            {i.number_of_days} Day
                          </button>
                        </div>
                        <div className="col-5">
                          <Timeline
                            mode="left"
                            items={i?.approvals?.map((j, k) => {
                              let label =
                                j?.status == "ACTIVE"
                                  ? "Pending"
                                  : formatDate(j?.updatedAt.split("T")[0]);

                              let name = `${j?.first_name} ${j?.last_name}`;
                              let color =
                                j?.status == "ACTIVE"
                                  ? "#fcb92c"
                                  : j?.status == "APPROVED"
                                  ? "green"
                                  : "red";
                              if (j?.status == "ACTIVE") {
                                return {
                                  dot: (
                                    <ClockCircleOutlined
                                      style={{ fontSize: "15px" }}
                                    />
                                  ),
                                  label: name,
                                  children: label,
                                  color: color,
                                };
                              } else {
                                return {
                                  label: name,
                                  children: label,
                                  color: color,
                                };
                              }
                            })}
                          />
                          {/* <div>
                            {i?.approvals?.map((j, k) => {
                              return (
                                <>
                                  <p style={{ fontSize: "0.8rem" }}>
                                    Approver{" "}
                                    {`${j?.approver_num} : ${j?.first_name} ${j?.last_name}`}{" "}
                                    {j?.status == "ACTIVE" ? (
                                      <span className="badge badge-soft-warning">
                                        PENDING
                                      </span>
                                    ) : j?.status == "APPROVED" ? (
                                      <span className="badge badge-soft-success">
                                        APPROVED
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger">{`${j?.status}`}</span>
                                    )}{" "}
                                    <br />
                                    <span>
                                      Date :{" "}
                                      {j?.status == "ACTIVE"
                                        ? "--"
                                        : formatDate(
                                            j?.updatedAt.split("T")[0]
                                          )}
                                    </span>
                                  </p>
                                </>
                              );
                            })}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Nodata />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeLeaveApplication;
