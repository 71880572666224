import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from "react-select";
import { SWAP_CLASS, TRANSFER_CLASS_REQUESTS_UPDATE } from '../../utils/apiConstants'
function ModalSwapClass({swapClasses, getOldRequests, employeeOptSubs, courseData, week, employeeOpt, user, setUser, setLoading }) {

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const handleUpdate = async(id) => {
        setLoading(1);
    const config = {
        method: "put",  
        url: `${TRANSFER_CLASS_REQUESTS_UPDATE}/${id}?status=PENDING&emp_to=${user.emp_to}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },  
    }

    axios(config)
        .then((res) => {
          setLoading(0);
          toast.success(`Request updated Successfully`);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Error while updating request");
        })
    getOldRequests();
    setUser({...user, emp_to: ''});
    setLoading(0);
 }

    const handleSubmit = async (timetable_id1, timetable_id2, employee_id, date) => {
        if(!timetable_id1 || !timetable_id2 || !employee_id || !date) {
            return toast.error("Unable to swap");
        } 
        setLoading(1);
        const config = {
            method: 'post',
            url: `${SWAP_CLASS}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
            data: {
                emp_from : user.emp_from,
                emp_to : employee_id,
                date : date,
                timetable_id : timetable_id1,
                timetable_id2 : timetable_id2,
                apply_date : new Date().toISOString(),
                type: "SWAP"
            }
        }
        await axios(config)
            .then((res) => {
                setLoading(0);
                toast.success("Success");
            })
            .catch(err => {
                setLoading(0);
                console.log(err);
                toast.error(err.response.data.message);
            })
        getOldRequests();
        setUser({...user, emp_to: ''});
        setLoading(0);
    }

    return (
        <div>
            <div
                className="modal fade"
                id="ModalSwapClass"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    role="document"
                >
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Swap Class
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            
                            <div className="row">
                            <table class="table table-bordered text-center">
                                <tbody>
                                    <tr>
                                        <th>Date</th>
                                        <td>{formatDate(user.date)}</td>
                                        <th>Class</th>
                                        <td>{user?.timetable?.class_name} - {user?.timetable?.semester_name} - {'(' + user?.timetable?.section_name + ')'}</td>
                                    </tr>
                                    <tr>
                                        <th>Day</th>
                                        <td>{user.timetable?.day}</td>
                                        <th>Course</th>
                                        <td>{courseData?.find((s) =>s.id == user.timetable?.course_id)?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Time</th>
                                        <td>{user.timetable?.time_from} {"->"} {user.timetable?.time_to}</td>
                                        <th>Staff Name</th>
                                        <td>{user.edit?.first_name ? `${user.edit?.first_name} ${user.edit?.last_name}` : "Not Swapped" }
                                            {/* {user.edit?.status ? <span className={`badge badge-soft-${user.edit?.status=="ACCEPTED"? "success":(user.edit?.status=="REJECTED"?"danger":"warning")} ml-3`} >
                                                {user.edit?.status}
                                            </span>: <></>} */}
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                                {!user.is_edit ? 
                                <>
                                <div className="col-md-12">
                                    <br />
                                    <h5>
                                        Classes you can swap with 
                                    </h5>
                                </div>
                                <div className="table-responsive mt-3">
                                    <table className="table table-bordered" style={{}}>
                                        <thead className="">
                                        <tr>
                                            <th>Sl No.</th>
                                            <th>Staff Name</th>
                                            <th>Date</th>
                                            <th>Day</th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Class</th>
                                            <th>Course</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {swapClasses ? 
                                                swapClasses?.map((i, key) => (
                                                    <tr key={key}>
                                                        <td>{key+1}</td>
                                                        <td>{i.first_name} {i.last_name}</td>
                                                        <td>{formatDate(user.date)}</td>
                                                        <td>{i.day}</td>
                                                        <td>{i.time_from}</td>
                                                        <td>{i.time_to}</td>
                                                        <td>{user?.timetable?.class_name} - {user?.timetable?.semester_name} - {'(' + user?.timetable?.section_name + ')'}</td>
                                                        <td>{i.course_name}</td>
                                                        <td><button className="btn btn-nex float-right mr-3" data-dismiss="modal" aria-label="Close" 
                                                                    onClick={() => {handleSubmit(i.id, user?.timetable?.id, i.employee_id, user.date)}}>
                                                                Swap</button>
                                                        </td>
                                                    </tr>
                                                ))
                                            : <>No data</>}
                                        </tbody>
                                    </table>
                                </div>
                                </>
                                : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSwapClass;