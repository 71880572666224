import React from 'react';
import {useState, useEffect} from 'react';

function AddGuestLecturer({setLoading, collegeId}) {

    return (
        <>
            <h1>Add Guest Lecturer</h1>
        </>
    )

}

export default AddGuestLecturer;
