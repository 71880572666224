import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { feeJsonData } from "../../../Data/jsonData/Fee/FeeJsonData";
import { ADDHOSTELFEE } from "../../../utils/fees.apiConst";

const HostelModalFee = ({ addData, setLoading, reloadData, maxAmount, getData}) => {
  const [addedAmount, setAddedAmount] = useState(maxAmount);

  console.log('addData - ', addData);
  console.log('add - ', addedAmount);

  const [mode, setMode] = useState("CASH");
  const [note, setNote] = useState("");
  const [discountNote, setDiscountNote] = useState("");
  const [fineNote, setFineNote] = useState("");

  const [feeid, setFeeid] = useState("");

  const [feeassign1, setFeeassign1] = useState("");
  const [hostelFees, setHostelFees] = useState([]);

  const [discount, setDiscount] = useState(0);
  const [fine, setFine] = useState(0);

  const [paymentdetails, setPaymentdetails] = useState([]);

  const params = useParams();
  const [searchParams] = useSearchParams();

  var curr = new Date();
  curr.setDate(curr.getDate());
  const [date, setDate] = useState(curr.toISOString().substring(0, 10));

  const [discountDet, setDiscountDet] = useState();

  const clearData = () => {
    setAddedAmount("");
    setNote("");
    setMode("CASH");
  };

  console.log(
    params.id,
    searchParams.get("department_id"),
    searchParams.get("student_id")
  );


  useEffect(() => {
    setAddedAmount(maxAmount)
  },[maxAmount]);


  // useEffect(() => {
  //   getData();
  // }, []);

  const handleSubmit = async () => {
    console.log(addedAmount);
    if (parseInt(addedAmount) > parseInt(maxAmount) || addedAmount == "") {
      toast.error("Please Select a valid amount");
      return;
    }
    if(!addData.id) {
      return toast.error("Something went wrong");
    }
    if(discount > 0 && !discountNote) return toast.error("Discount note is complusory");
    if(fine > 0 && !fineNote) return toast.error("Fine note is complusory");
    const data = 
      {
        mode: mode,
        amount: parseInt(addedAmount - discount),
        note: note,
        collected_by: sessionStorage.getItem("employee_id"),
        id: addData.id,
        discount: discount,
        fine: fine,
        discount_note: discountNote,
        fine_note: fineNote,
        student_session_id: addData?.student_id
      };
    console.log('data to backend - ', data);
    const config1 = {
      method: "put",
      url: `${ADDHOSTELFEE}`,
      headers: {
        "Content-type": "application/json",
      },
      data: data,
    };
    setLoading(1);
    axios({
      ...config1,
    })
      .then((res) => {
        setLoading(0);
        getData();
        toast.success("Fee collected Successfully");
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  useEffect(() => {
    console.log('fine = ', fine);
  },[fine])

  return (
    <div className="ModalAddFee">
      <div
        className="modal fade"
        id="addhostelfee"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="addfee"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addfee1">
                Hostel Fees
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">
                      {" "}
                      Date<span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      name="feedate"
                      className="form-control"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label htmlFor="">Payment Mode<span className="text-danger">*</span></label> <br />
                  <select
                    name=""
                    id=""
                    className="form-control"
                    value={mode}
                    onChange={(e) => {
                      setMode(e.target.value);
                    }}
                  >
                    {feeJsonData?.map((i, key) => (
                      <option value={i?.id} key={key}>
                        {i?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">
                      {" "}
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      name="amount"
                      className="form-control"
                      required=""
                      id="amount"
                      min={0}
                      value={addedAmount}
                      max={maxAmount}
                      onChange={(e) => {if(e.target.value <= maxAmount) setAddedAmount(e.target.value)}}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Note</label>
                    <textarea
                      cols={1}
                      rows={1}
                      className="form-control"
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        {" "}
                        Discount 
                      </label>
                      <input
                        type="number"
                        name="discount"
                        className="form-control"
                        required=""
                        id="discount"
                        min={0}
                        value={discount}
                        max={maxAmount}
                        onChange={(e) => {if(e.target.value <= maxAmount) setDiscount(e.target.value)}}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Discount Note</label>
                    <textarea
                      cols={1}
                      rows={1}
                      className="form-control"
                      value={discountNote}
                      onChange={(e) => setDiscountNote(e.target.value)}
                    />
                  </div>
                  {discount > 0 ? <p className="text-danger">Discount note is compulsory</p> : <></>}
                </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="">
                        {" "}
                        Fine 
                      </label>
                      <input
                        type="number"
                        name="fine"
                        className="form-control"
                        required=""
                        id="fine"
                        min={0}
                        value={fine}
                        max={maxAmount}
                        onChange={(e) => {setFine(e.target.value)}}
                      />
                    </div>
                    
                  </div>
                  <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="">Fine Note</label>
                    <textarea
                      cols={1}
                      rows={1}
                      className="form-control"
                      value={fineNote}
                      onChange={(e) => setFineNote(e.target.value)}
                    />
                  </div>
                  {fine > 0 ? <p className="text-danger">Fine note is compulsory</p> : <></>}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <input
                type="hidden"
                name="group_id"
                id="group_id"
                defaultValue={4}
              />
              <input
                type="hidden"
                name="master_id"
                id="master_id"
                defaultValue={1}
              />
              <button
                type="submit"
                className="btn btn-success"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleSubmit}
              >
                Collect Fee
              </button>
              {/*  <a href="javascript:void(0)"  target="_blank" type="submit" class="btn btn-success">Collect Fee & Print</a> */}
              <input type="hidden" name="id" defaultValue={1} />
              <input type="hidden" name="stdid" defaultValue={1} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelModalFee;
