import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import ModalManagementApplication from "../../../modals/HR/Leave/Management/ModalManagementApplication";
import {
  LEAVE_APPLICATION,
  LEAVE_ENTITLEMENT,
  LEAVE_LEAVE_TYPE,
  LEAVE_APPROVER_APPLICATION,
  EMPLOYEEALL,
} from "../../../utils/apiConstants";
import Nodata from "../../NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { Timeline } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

function ManagementApplication({ setLoading, collegeId }) {
  const [data, setData] = useState([]);
  const [employee] = useEmployee(collegeId);
  const [emp, setEmp] = useState("");
  const [emp1, setEmp1] = useState([]);
  const [type, setType] = useState("");
  const [edit, setEdit] = useState();
  const [entitlement, setEntitlement] = useState();
  const [employeeOpt, setEmployeeOpt] = useState([]);
  const [empData, setEmpData] = useState([]);

  const [display, setDisplay] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [appEmp, setAppEmp] = useState([]);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const getLeaveData = async () => {
    // setLoading(1);
    const config = {
      method: "get",
      url: LEAVE_LEAVE_TYPE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    await axios(config)
      .then((res) => {
        // setLoading(0);
        console.log("leave types - ", res.data.data);
        setLeaveType(res.data.data);
      })
      .catch((err) => {
        // setLoading(0);
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: EMPLOYEEALL,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setEmpData(res.data.data);
        console.log("empData -", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEntitlement = async (p) => {
    if (!p) {
      setDisplay(false);
      return;
    }
    setDisplay(true);

    setLoading(1);
    setEmp(p);

    const config = {
      method: "get",
      url: `${LEAVE_ENTITLEMENT}?employee_id=${p}&college_id=${collegeId}&status=ALL`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("entitlement data - ", res.data.data);
        setEntitlement(res.data.data);
        // console.log(res);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  const getData = async (p) => {
    console.log(" p - ", p);
    if (!p) {
      return;
    }
    setLoading(1);

    setEmp(p);
    let url1;

    if (p == "All") {
      url1 = `${LEAVE_APPLICATION}?college_id=${collegeId}`;
    } else {
      url1 = `${LEAVE_APPLICATION}?employee_id=${p}&college_id=${collegeId}`;
    }
    const config = {
      method: "get",
      url: url1,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    let url2;

    if (p == "All") {
      url2 = `${LEAVE_APPLICATION}?college_id=${collegeId}`;
    } else {
      url2 = `${LEAVE_APPLICATION}?employee_id=${sessionStorage.getItem(
        "employee_id"
      )}&college_id=${collegeId}`;
    }
    const config1 = {
      method: "get",
      url: url2,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then(async (res) => {
        res.data.data.sort((a, b) => {
          const comparison = a?.from_date?.localeCompare(b.from_date);
          if (comparison < 0) {
            return 1;
          } else if (comparison > 0) {
            return -1;
          } else {
            return 0;
          }
        });

        console.log("leave applications - ", res.data.data);
        let tempData = res.data.data;
        let finalData = [];

        for (let i in tempData) {
          let approvals = [];
          let config1 = {
            method: "get",
            url: `${LEAVE_APPROVER_APPLICATION}/${tempData[i]?.id}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            },
          };

          setLoading(1);
          await axios(config1)
            .then((res) => {
              setLoading(0);
              approvals = res.data.data;
              let obj = {
                ...tempData[i],
                approvals,
              };
              finalData.push(obj);
            })
            .catch((err) => {
              setLoading(0);
              finalData.push({ ...tempData[i], approvals: [] });
              // toast.error(err?.response?.data?.message || "Something went wrong");
            });
        }
        console.log("final data - ", finalData);
        setData(finalData);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });

    await axios(config1)
      .then((res) => {
        console.log(res.data.data);
        setEmp1(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err?.response?.data?.message || "Something went wrong");
      });
  };

  useEffect(() => {
    // getData("All");
    getLeaveData();
  }, []);

  useEffect(() => {
    let temp = [];
    // temp.push({'value':'All','label': 'All'});
    employee?.map((i, key) => {
      let obj = {};
      obj["value"] = i?.id;
      obj["label"] = i?.first_name + " " + i?.last_name;
      temp.push(obj);
    });
    setEmployeeOpt(temp);
  }, [employee]);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Leave Application List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  // const handlePrint = () => {
  //   PrintRecipt();
  // };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div>
      <ModalManagementApplication
        type={type}
        emp1={emp1}
        employee={employee}
        empId={emp}
        data={edit}
        reloadData={(d) => getData(d)}
        setLoading={setLoading}
        entitlement={entitlement}
        collegeId={collegeId}
        leaveType={leaveType}
        appEmp={appEmp}
      />
      {/* start page title */}
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Select Employee</h2>
              <br />
              <div className="row d-flex ">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">
                      Employee<span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      id="role"
                      options={employeeOpt}
                      onChange={(e) => {
                        getData(e.value);
                        getEntitlement(e.value);
                        setAppEmp(employee?.find((s) => s.id == e.value));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end card */}
        </div>
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0">Transaction Report</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <a href="/">Leave</a>
                </li>
                <li className="breadcrumb-item active">Transaction Report</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}

      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row d-flex justify-content-end p-3">
              {emp !== "" ? (
                <button
                  className="btn btn-rounded btn-success btn-outline px-4"
                  data-toggle="modal"
                  data-target="#ModalManagementApplication"
                  onClick={() => {
                    setType("add");
                    setEdit();
                  }}
                >
                  New Application
                </button>
              ) : (
                <button className="btn text-danger">
                  Select Employee to add new Application
                </button>
              )}

              {/* <button className="btn btn-rounded btn-primary btn-outline px-4">
                Export &uarr;
              </button> */}
              <button
                className="btn btn-primary rounded-pill ml-1"
                onClick={onDownload}
              >
                Export Excel
              </button>
              <button
                className="btn btn-primary rounded-pill ml-2"
                onClick={handlePrint}
              >
                Export PDF
              </button>
            </div>

            <table
              id="table_id"
              ref={tableRef}
              className="display table table-bordered  nowrap table-hover "
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
                width: "100%",
                display: "none",
              }}
            >
              <div className="col-12 text-center">
                {data.length > 0 && (
                  <div>
                    <h4 className="text-center">
                      Leave Application List -
                      {data[0]?.college_id &&
                        collegeOpt?.find((s) => s.id === data[0]?.college_id)
                          ?.name}
                      <p className="text-right float-right">
                        {" "}
                        Printed On - {" " + getCurrentDate()}{" "}
                      </p>
                    </h4>
                  </div>
                )}
                <thead>
                  <tr>
                    <th>Sl.No.</th>
                    <th>Employee Name</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Number Of Days</th>
                    <th>Session</th>
                    <th>Leave Type</th>
                    <th>Remark</th>
                    <th>Approver 1</th>
                    <th>Status</th>
                    <th>Approver 2</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data?.length == 0 ? (
                    <tr>
                      <td colSpan={10}>
                        <Nodata />
                      </td>
                    </tr>
                  ) : (
                    data.map((i, key) => {
                      return (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {empData?.find((j) => j.id === i.employee_id)
                              ?.first_name +
                              " " +
                              empData.find((j) => j.id === i.employee_id)
                                ?.last_name}
                          </td>
                          <td> {formatDate(i?.from_date?.split("T")[0])}</td>
                          <td> {formatDate(i?.to_date?.split("T")[0])}</td>
                          <td>{i?.number_of_days}</td>
                          <td>{i?.session}</td>
                          <td>{i?.approvals && i?.approvals[0]?.leave_type}</td>
                          <td>{i?.remark}</td>
                          {i.approvals &&
                            [i.approvals[0], i.approvals[1]].map((j, k) => (
                              <React.Fragment key={k}>
                                <td>
                                  {j
                                    ? ` ${j.first_name} ${j.last_name}`
                                    : "N/A"}
                                </td>
                                <td>
                                  {j ? (
                                    j.status === "DECLINED" ? (
                                      <span className="badge badge-soft-danger">
                                        DECLINED
                                      </span>
                                    ) : j.status === "APPROVED" ? (
                                      <span className="badge badge-soft-success">
                                        APPROVED
                                      </span>
                                    ) : j.status === "PENDING" ? (
                                      <span className="badge badge-soft-warning">
                                        {j.status}
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-info">
                                        {j.status}
                                      </span>
                                    )
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </React.Fragment>
                            ))}
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </div>
            </table>

            <div>
              {data && data?.length !== 0 ? (
                data?.map((i, key) => (
                  <div
                    className="row my-3 mx-2 p-3 border rounded shadow report-div cursor-pointer"
                    data-toggle="modal"
                    data-target="#ModalManagementApplication"
                    onClick={() => {
                      setType("edit");
                      setEdit(i);
                    }}
                  >
                    <div className="col-12 row" key={key}>
                      <div className="report-title col-12">
                        {empData?.find((j) => j.id === i.employee_id)
                          ?.first_name +
                          " " +
                          empData?.find((j) => j.id === i.employee_id)
                            ?.last_name}
                      </div>
                      <div className="col-12 d-flex flex-nowrap justify-content-between align-items-center role-parts">
                        <div className="align-self-start text-center col-5">
                          {!i?.deduction ? (
                            <>
                              <div>
                                {formatDate(i?.from_date?.split("T")[0])}
                              </div>
                              <div>&darr;</div>
                              <div>{formatDate(i?.to_date?.split("T")[0])}</div>
                            </>
                          ) : (
                            <>
                              <div>Deducted for Late in</div>
                              <div> or Early out for</div>
                              <div>month {i?.remark}</div>
                            </>
                          )}
                          <div
                            className={`${
                              i?.status === "DECLINED"
                                ? "text-danger"
                                : i?.status === "APPROVED"
                                ? "text-success"
                                : i?.status === "PENDING"
                                ? "text-warning"
                                : "text-info"
                            }`}
                          >
                            <span
                              className="badge badge-soft"
                              style={{ fontSize: "1em" }}
                            >
                              {i?.status}
                            </span>
                          </div>
                        </div>

                        <div className="d-flex">
                          {
                            entitlement?.find((s) => s.id == i?.entitlement)
                              ?.leave_type
                          }
                        </div>
                        <div className="col-1">
                          <div className="d-flex">{i.session}</div>
                          <button className=" w-auto btn btn-dark p-1">
                            {i?.number_of_days} Day
                          </button>
                        </div>
                        <div className="col-5">
                          <Timeline
                            mode="left"
                            items={i?.approvals?.map((j, k) => {
                              let label =
                                j?.status == "ACTIVE"
                                  ? "Pending"
                                  : formatDate(j?.updatedAt.split("T")[0]);

                              let name = `${j?.first_name} ${j?.last_name}`;
                              let color =
                                j?.status == "ACTIVE"
                                  ? "#fcb92c"
                                  : j?.status == "APPROVED"
                                  ? "green"
                                  : "red";
                              if (j?.status == "ACTIVE") {
                                return {
                                  dot: (
                                    <ClockCircleOutlined
                                      style={{ fontSize: "15px" }}
                                    />
                                  ),
                                  label: name,
                                  children: label,
                                  color: color,
                                };
                              } else {
                                return {
                                  label: name,
                                  children: label,
                                  color: color,
                                };
                              }
                            })}
                          />
                          {/* <div>
                            {i?.approvals?.map((j, k) => {
                              return (
                                <>
                                  <p style={{ fontSize: "0.8rem" }}>
                                    Approver{" "}
                                    {`${j?.approver_num} : ${j?.first_name} ${j?.last_name}`}{" "}
                                    {j?.status == "ACTIVE" ? (
                                      <span className="badge badge-soft-warning">
                                        PENDING
                                      </span>
                                    ) : j?.status == "APPROVED" ? (
                                      <span className="badge badge-soft-success">
                                        APPROVED
                                      </span>
                                    ) : (
                                      <span className="badge badge-soft-danger">{`${j?.status}`}</span>
                                    )}{" "}
                                    <br />
                                    <span>
                                      Date :{" "}
                                      {j?.status == "ACTIVE"
                                        ? "--"
                                        : formatDate(
                                            j?.updatedAt.split("T")[0]
                                          )}
                                    </span>
                                  </p>
                                </>
                              );
                            })}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Nodata />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagementApplication;
