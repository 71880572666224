import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Select, Input, DatePicker, Form, Button, FormInstance } from "antd";
import { JOB_APPLICATIONS } from "../../../utils/apiConstants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig";

function ModalViewJobOpening({opening}) {


  return (
    <div className="ModalViewJobOpening">
      <div
        className="modal fade"
        id="ModalViewJobOpening"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content text-center">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="exampleModalLongTitle">
                Job Opening Details
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="box p-3" width="100%">
                
              <h5><b>Job Opening for {opening?.title}</b></h5>
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                <table className="mt-3" width="100%">
                  <tr>
                    <th width="30%" className="text-left"><h6><b>Job Description</b></h6></th>
                    <td width="70%" className="text-left"><h6><b>: </b>{opening?.description}</h6></td>
                  </tr>
                  <tr>
                    <th width="30%" className="text-left"><h6><b>Job Specification</b></h6></th>
                    <td width="70%" className="text-left"><h6><b>: </b>{opening?.specifications}</h6></td>
                  </tr>
                  <tr>
                    <th width="30%" className="text-left"><h6><b>Eligible Age</b></h6></th>
                    <td width="70%" className="text-left"><h6><b>: </b>{opening?.from_age} years to {opening?.to_age} years</h6></td>
                  </tr>
                </table>
                  </div>
                  <div className="col-md-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalViewJobOpening;
