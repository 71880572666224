import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { REPORT_STAFF_ATTENDANCE } from "../../../../utils/Reports.apiConst";
import { toast } from "react-toastify";
import NoData from "../../../../Components/NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import {EMP_LATEIN_EARLOUT_UPDATE, EMP_ATTENDANCE} from "../../../../utils/apiConstants";
import {
  LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM
} from "../../../../utils/LocalStorageConstants";
import useEmployee from "../../../../Hooks/Employee/useEmployee";
import Select from 'react-select';
import {sessionOpt} from '../../../../Data/jsonData/Academics/Academics';
import '../../../../modals/HR/Leave/TableScroll.css';
import { Checkbox } from 'antd';
import Swal from "sweetalert2";

const EditLateInEarlyOut = ({ setLoading, collegeId }) => {

  const emp_id = sessionStorage.getItem("employee_id");

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  function isLate(lateTimeString) {
    const [hours, minutes] = lateTimeString.split(':').map(Number);
    const lateInSeconds = hours * 3600 + minutes * 60;
    const sixMinutesInSeconds = 6 * 60;
    return lateInSeconds >= sixMinutesInSeconds;
  }

  const [user, setUser] = useState({
      year1: 2024,
      month1: "",
      employee_id: "All"
  });

    const [employee] = useEmployee(collegeId);
    const [employeeOpt, setEmployeeOpt] = useState([]);

    const [changes, setChanges] = useState([]);
    const [note, setNote] = useState("");


    const [empData, setEmpData] = useState([]);
    const [biocodes, setBiocodes] = useState([]);
    const [filter, setFilter] = useState("");
    const getCollegeData = () => {
      return localStorage.getItem(LOCAL_COLLEGE)
        ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
        : null;
    };
  
    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
    const [departmentOpt, setDepartmentOpt] = useState(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
    );
    const [programOpt, setProgramOpt] = useState(
      JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
    );
  
    useEffect(() => {
      setDepartmentOpt(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
          (item) => item.college_id == user.college_id
        )
        );
        setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
        user.department_id = "";
    }, [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);
  
    useEffect(() => {
      setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
    }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

    const tableRef = useRef();

    const PrintRecipt = useReactToPrint({
      content: () => tableRef.current,
    });

    const [mainData, setMainData] = useState([]);
    const [data, setData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchName, setSearchName] = useState("");

    const navigate = useNavigate();

    function showConfirmationModal(id,type,name,date,time) {
      Swal.fire({
        title: `Confirmation`,
        showCancelButton: true,
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        confirmButtonText: "Submit",
        html: `<h5>Do not consider ${type == "late_in" ? `Late in (${time})` : `Early out (${time})`}</h5><h5>on ${date} for ${name}</h5><br />
                <label>Enter Reason</label><br /><textarea type="text" id="reason"/>`,
        preConfirm: async () => {
          const selectElement = document.getElementById("reason");
          const reason = selectElement ? selectElement.value : "";
      
          if (!reason) {
            Swal.showValidationMessage("Please Enter reason");
          }
          return reason;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const reason = result.value;
          handleEdit(id, reason, type);
        }
      });
  }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${data[0]?.DateString} Employee Attendance`,
    sheet: "Users",
  });

  const handleSubmit = async () => {


    if(!user?.year1) {
        return toast.error("Please Select Year");
    }
    if(!user?.month1) {
        return toast.error("Please Select Month");
    }
    if(!user?.employee_id) {
      return toast.error("Please Select Employee");
  }
    setLoading(1);
    const config1 = {
      method: "get",
      url: `${EMP_ATTENDANCE}?year=${user.year1}&month=${user.month1}&employee_id=${user?.employee_id}&college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config1)
    .then(async (res) => {
      console.log('emp attendance - ', res.data.data);
      setData(res.data.data);
      setDisplayData(res.data.data);
      setFilter("");
    })
    .catch((err) => {
      setLoading(0);
      console.log(err);
      setFilter("");
    });
    setLoading(0);
  }

  const handleEdit = async (id, reason, type) => {

    if(!id || !reason || !type) {
      toast.error("Unable to update biometric attendance");
    }
    setLoading(1);
    const config1 = {
      method: "put",
      url: `${EMP_LATEIN_EARLOUT_UPDATE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        id: id,
        updated_by: emp_id,
        reason: reason,
        type: type,
      }
    };

    setLoading(1);
    await axios(config1)
    .then(async (res) => {
      toast.success("Updated Successfully");
      handleSubmit();
      setChanges([]);
    })
    .catch((err) => {
      setLoading(0);
      console.log(err);
      setChanges([]);
    });
    setLoading(0);
  }

  useEffect(()=> {
    let temp = [];
    temp.push({'value':'All','label': 'All'});
    (employee?.map((i, key) => {
      let obj = {};
      obj['value'] = i?.id;
      obj['label'] = i?.first_name + " " + i?.last_name;
      temp.push(obj);
    }))
    setEmployeeOpt(temp);
  },[employee]);

  useEffect(() => {
    setLoading(1);
    if(filter == "late_in") {
      setDisplayData(data?.filter((s) => s.status == "P" && isLate(s.late_in)));
    }
    else if(filter == "erl_out") {
      setDisplayData(data?.filter((s) => s.status == "P" && isLate(s.erl_out)))
    }
    else if(filter == "both") {
      setDisplayData(data?.filter((s) => s.status == "P" && isLate(s.late_in) && isLate(s.erl_out)))
    }
    else {
      setDisplayData(data);
    }
    setLoading(0);
  },[filter]);
    
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">EDIT LATE IN AND EARLY OUT</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row d-flex align-items-center">
                <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="year">
                            Year <span className="text-danger">*</span>
                          </label>
                          <select name="year1" id="year1" required="" className="form-control" value={user?.year1} onChange={handleChange}>
                            <option value="">Select Year</option>
                            {sessionOpt &&
                              sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>{item?.name.substring(0,4)}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="month">
                            Month <span className="text-danger">*</span>
                          </label>
                          <select name="month1" id="month1" required="" className="form-control" value={user?.month1} onChange={handleChange}>
                            <option value="">Select Month</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                          </select>
                        </div>
                      </div>
                      
                  <div className="col-md-3">
                  <div className="form-group">
                    <label htmlFor="validationCustom01">
                      Employee <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select 
                      id="employee"
                      options={employeeOpt} 
                      onChange={(e) => {
                        setUser({...user, employee_id: e.value})
                      }}
                      value={employeeOpt?.find((s) => s.value == user?.employee_id)}
                      style={{zIndex: "101"}}
                    />
                  </div>
                </div>
                  <div className="col-md-2">
                    <div className="d-flex mt-2">
                      <button
                        className="btn btn-nex"
                        onClick={handleSubmit}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        name="to_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_date}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                <div className="col-md-3">
                <div className="form-group">
                          <select 
                            name="filter" 
                            id="filter" 
                            required="" 
                            className="form-control" 
                            value={filter} 
                            onChange={(e) => setFilter(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="late_in">LATE IN</option>
                            <option value="erl_out">EARLY OUT</option>
                            <option value="both">BOTH</option>
                          </select>
                        </div>
                </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                        <th className="text-uppercase" colSpan={12}>
                          Changes : {changes.length}
                        </th>
                        <tr>
                          <th>Sl.No</th>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>In Time (HH:MM)</th>
                          <th>Out Time (HH:MM)</th>
                          <th>Work Time (HH:MM)</th>
                          <th colSpan="2">Late In  (HH:MM)</th>
                          <th colSpan="2">Early Out  (HH:MM)</th>
                        </tr>
                        </thead>
                        <tbody>
                            
                        {displayData && displayData.length ? displayData?.map((i,key) => (
                            <tr>
                                <td>{key+1}</td>
                                <td>{i?.first_name} {i?.last_name}</td>
                                <td>{i?.date}</td>
                                <td>  
                                    <span className={`badge badge-soft-${i?.status=="P"?"success":(i.status=="A"?"danger":"secondary")}`} >
                                        {i.status == 'P' ? "Present" : i.status == "A" ? "Absent" : i.status}
                                    </span>
                                </td>
                                <td>{i?.in_time}</td>
                                <td>{i?.out_time}</td>
                                <td>{i?.work_time}</td>
                                <td>
                                  {i?.late_in}
                                </td>
                                <td style={{ backgroundColor: !i?.consider_late_in ? "#90ee90" : "" }}>
                                <Checkbox 
                                  checked={!i?.consider_late_in} 
                                  disabled={i?.status != "P" || !isLate(i?.late_in) || !i?.consider_late_in} 
                                  onClick={() => showConfirmationModal(i?.id, "late_in", `${i?.first_name} ${i?.last_name}`, i?.date, i?.late_in)}   
                                />
                                </td>
                                <td>
                                  {i?.erl_out}
                                </td>
                                <td style={{ backgroundColor: !i?.consider_early_out ? "#90ee90" : "" }}>
                                <Checkbox 
                                  checked={!i?.consider_early_out} 
                                  disabled={i?.status != "P" || !isLate(i?.erl_out) || !i?.consider_early_out} 
                                  onClick={() => showConfirmationModal(i?.id, "erl_out", `${i?.first_name} ${i?.last_name}`, i?.date, i?.erl_out)} 
                                /> 
                                </td>
                            </tr>
                        )) : (
                            <tr>
                              <td colSpan={12}>
                                <NoData />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLateInEarlyOut;