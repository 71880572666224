import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import { FEE_PENDING_COLLEGE_WISE, FEE_REPORT_CLG } from "../../../utils/fees.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";

const ReportFeePendingCollege = ({ setLoading }) => {
  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };


  const [data, setData] = useState([]);
  const [hosteldata, sethostelData] = useState([]);
  const [collegeOpt, setCollegeOpt] = useState(getColleges);
  const [status, setStatus] = useState('All');
  
  const [studentType, setStudentType] = useState('');

  const [totalDue, setTotalDue] = useState(0);

  const [session, setSession] = useState("");

  const [collegeTotalDue, setCollegeTotalDue] = useState(0);
  const [hostelTotalDue, setHostelTotalDue] = useState(0);

  const navigate = useNavigate();

  const tableRef = useRef();

  const getData = async () => {
    if (!session) return toast.error("Session is required");
    console.log("session_id",session);
    setLoading(1);

    const config = {
      method: "get",
      url: `${FEE_PENDING_COLLEGE_WISE}/${session}`,
    };

    await axios(config)
      .then((res) => {

        console.log("Error Data", res.data.data);

        setData(res.data.data.CollegeFees);
        sethostelData(res.data.data.HostelFees);
        console.log("API response:", res.data.data.CollegeFees);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });
    setLoading(0);
  };

  const getData2 = async () => {
    if (!session) return toast.error("Session is required");

    console.log(`${FEE_REPORT_CLG}?status=${status}&session_id=${session}&studentType=${studentType}`)
    
    const config = {
      method: "get",
      url: `${FEE_REPORT_CLG}?status=${status}&session_id=${session}&studentType=${studentType}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log('data - ', res.data.data);
        console.log('colleges - ', collegeOpt);
        let tempData = res.data.data;
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  // useEffect(() => {
  //   let total = 0;
  //   hosteldata?.map((value, index) => {
  //     total = total + value.pending;
  //   });
  //   setHostelTotalDue(total);
  // }, [hosteldata]);

  // useEffect(() => {
  //   let total = 0;
  //   data?.map((value, index) => {
  //     total = total + value.pending;
  //   });
  //   setCollegeTotalDue(total);
  // }, [data]);
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">COLLEGE WISE DUE FEE REPORT</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 p-2">
                      College Wise Due Fees Report
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card p-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="">Session<span style={{ color: "red" }}>*</span></label>
                        <select
                          name=""
                          id=""
                          value={session}
                          onChange={(e) => setSession(e.target.value)}
                          className="form-control"
                        >
                          <option value="">Select Session</option>
                          {sessionOpt.map((i, key) => (
                            <option key={key} value={i.id}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Status<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            id="category"
                            name="category"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="All">All</option>
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Student Type<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            id="category"
                            name="category"
                            value={studentType}
                            onChange={(e) => setStudentType(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="0">REGULAR</option>
                            <option value="1">SCHOLARSHIP</option>
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-3 mt-4">
                        <button
                          onClick={getData2}
                          className="btn btn-nex "
                        >
                          Search
                        </button>
                      </div>
                  </div>
                </div>

                <div className="p-3 card">
                <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">College wise Due Report</h4>
                      </div>
                      <div className="col-md-8 ">
                        <span className="float-right">
                          <button
                            className="btn btn-nex"
                            onClick={() => onDownload()}
                          >
                            Excel
                          </button>
                        </span>
                      </div>
                    </div>
                  <br />
                  <div className="card-content">
                    <div className="table-responsive">
                      <table ref={tableRef} className="table table-bordered">
                        <thead>
                        <tr>
                          <th>Sl No</th>
                          <th>College</th>
                          <th className="text-right">College Due Fees</th>
                          {/* <th>Hostel Due Fees</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {collegeOpt?.filter((s) => s.status == "ACTIVE")?.map((i,k) => {
                          return (
                            <tr>
                              <td>{k+1}</td>
                              <td>{i?.name}</td>
                              <td className="text-right">{data?.find((s) => s.college_id == i?.id) ? (parseInt(data?.find((s) => s.college_id == i?.id)?.amount) - parseInt(data?.find((s) => s.college_id == i?.id)?.paid_amount))?.toLocaleString('en-IN', {style: 'currency', currency: 'INR', minimumFractionDigits: 0}) : 0?.toLocaleString('en-IN', {style: 'currency', currency: 'INR', minimumFractionDigits: 0})}</td>
                            </tr>
                          )
                        })}
                        <tr>
                          <th></th>
                          <th>Total</th>
                          <th className="text-right">{data?.reduce((acc,curr) => acc + parseInt(curr?.amount) - parseInt(curr?.paid_amount), 0)?.toLocaleString('en-IN', {style: 'currency', currency: 'INR', minimumFractionDigits: 0})}</th>
                          {/* <th>{0}</th> */}
                        </tr>
                        </tbody>
                        
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFeePendingCollege;
