import React, { useState, useEffect, useRef } from "react";
import { Modal, Table } from "antd";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import AdvanceFeeReciept from "../Accounts/FeeCollection/AdvancePayFeeCollection";
import { useReactToPrint } from "react-to-print";
import { ROUTES } from "../../Router/routerConfig";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../utils/Academics.apiConst";
import {
  STUDENT_ADMISSION,
  STUDENT_DETAILS,
  STUDENT_SESSION_UPDATE,
  STUDENT_GENDER,
  STUDENT_SESSION,
  STUDENT_ADVANCE_PAY,
  ASSIGN_PROCTOR,
  GET_STUDENTS,
} from "../../utils/apiConstants";
import {
  LOCAL_DEPARTMENT,
  LOCAL_COLLEGE,
} from "../../utils/LocalStorageConstants";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";
import Select from "react-select";

function AssignProctor({ setLoading, collegeId }) {
  let role = sessionStorage.getItem(SESSION_ROLE);
  const locate = useLocation();

  const printRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => printRef.current,
  });

  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  useEffect(() => {
    setDepartment(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);

  const [proctor, setProctor] = useState("");

  const [selectedData, setSelectedData] = useState([]);

  const [classOpt, setClassOpt] = useState([]);

  const [sectionOpt, setSectionOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [faculty, setFaculty] = useState("");

  const [currentclass, setCurrentClass] = useState("");

  const [currentSemester, setCurrentSemester] = useState("");

  const [currentSection, setCurrentSection] = useState("");

  const [status, setStatus] = useState("ACTIVE");

  const [session, setSession] = useState("");

  const [student, setStudent] = useState([]);

  const [subdata, setSubdata] = useState([]);

  const [a, setA] = useState([]);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const getData = async () => {
    if (!faculty || !currentclass || !session)
      return toast.error("Mandatory fields are required");
    setLoading(1);
    const config = {
      method: "get",
      url:
        GET_STUDENTS +
        `?college_id=${collegeId}&department_id=${faculty}&class_id=${currentclass}&section_id=${currentSection}&semester_id=${currentSemester}&session_id=${session}&status_student=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const config1 = {
      method: "get",
      url: `${STUDENT_DETAILS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Data Main", res.data.data);

        // setData(res.data.data);

        const modifiedData = res.data.data.map((obj, index) => ({
          ...obj,
          key: obj.academic_student_session_id,
        }));
        if (modifiedData) console.log(modifiedData);
        setData(modifiedData);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Some Error Occured");
      })
      .finally(() => {
        setLoading(0);
      });
  };

  const getAllData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data3, data4] = await Promise.all([
      await axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${collegeId}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${ACADEMICS_ADD_SECTION}?college_id=${collegeId}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        }),
    ]);
  };

  useEffect(() => {
    if (locate?.state) {
      setCurrentClass(locate.state?.class);
      setCurrentSection(locate.state?.section);
      setCurrentSemester(locate.state?.sem);
      setSession(locate.state?.session);
      setFaculty(locate.state?.depart);
    }
  }, [locate?.state]);

  console.log("proctor -", proctor.value);

  const handleSubmit = async () => {
    setConfirmLoading(true);
    // const payload = { selectedData, proctor };
    const payload = {
      selectedData,
      proctor: proctor.value,
    };
    const config = {
      method: "post",
      url: `${ASSIGN_PROCTOR}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: payload,
    };

    try {
      const res = await axios(config);
      console.log(res.data);
      toast.success("Proctor Assigned Successfully");
      setProctor("");
      setSelectedData([]);
      getData();
    } catch (err) {
      console.log(err);
      toast.error("Some Error Occurred");
    } finally {
      setOpen(false);
      setConfirmLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const columns = [
    {
      title: "USN",
      dataIndex: "user_id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
  ];

  // rowSelection object indicates the need for row selection

  return (
    <div className="StudentDetails">
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <AdvanceFeeReciept
            mainData={subdata?.data}
            studentInfo={subdata?.studentInfo}
            data={a}
            collegeId={subdata?.studentInfo?.college_id}
            collegeOpt={collegeOpt}
            classData={classOpt}
            class_id={subdata?.data?.class_id}
            departmentData={department}
          />
        </div>
      </div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">ASSIGN PROTEE TO PROCTOR</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Proctoring</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Assign Proctee to Proctor
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom01">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="faculty"
                            id="section"
                            className="form-control"
                            value={faculty}
                            onChange={(e) => {
                              setFaculty(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              ALL
                            </option>
                            {department?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                          {/* <Select
                            name="faculty"
                            options={department.map((i) => ({ value: i.id, label: i.name }))}
                            value={{ value: faculty, label: faculty }}
                            onChange={(selectedOption) => setFaculty(selectedOption.value)}
                          /> */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            className="form-control"
                            value={session}
                            onChange={(e) => {
                              setSession(e.target.value);
                            }}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option value={i?.id}>{i?.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            className="form-control"
                            value={currentclass}
                            onChange={(e) => {
                              setCurrentClass(e.target.value);
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s?.department_id == faculty)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            className="form-control"
                            value={currentSemester}
                            onChange={(e) => {
                              setCurrentSemester(e.target.value);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == currentclass)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Section</label>
                          <select
                            className="form-control"
                            value={currentSection}
                            onChange={(e) => {
                              setCurrentSection(e.target.value);
                            }}
                          >
                            <option value="">Select Section</option>
                            {sectionOpt
                              ?.filter((s) => s.semester_id == currentSemester)
                              ?.map((i, key) => (
                                <option value={i?.id}>{i?.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Status</label>
                          <select
                            className="form-control"
                            value={status}
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          >
                            <option value="">Select Status</option>
                            <option value="ACTIVE">Active</option>
                            <option value="INACTIVE">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row float-right">
                      <button
                        className="btn btn-primary btn-rounded"
                        type="submit"
                        name="submit"
                        onClick={getData}
                      >
                        <i className="fa fa-search" aria-hidden="true" /> Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h4 className="card-title">Students Details</h4>
                      </div>
                      <div className="col-md-8">
                        <span className="float-right">
                          <button
                            className="btn btn-primary mx-2"
                            onClick={() => {
                              if (selectedData?.length === 0) {
                                toast.warning("Please Select Proctee");
                              } else {
                                showModal();
                              }
                            }}
                          >
                            Select Proctor
                          </button>

                          {/* SELECT PROCTOR MODAL  */}

                          <Modal
                            title="Assign Proctor"
                            open={open}
                            onOk={handleSubmit}
                            confirmLoading={confirmLoading}
                            onCancel={() => {
                              setOpen(false);
                            }}
                            zIndex={1021}
                          >
                            <div className="row mt-4 ">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={JSON.parse(
                                      localStorage.getItem("EMPLOYEE")
                                    ).map((item) => ({
                                      value: item?.id,
                                      label: `${item?.first_name} ${item?.last_name}`,
                                    }))}
                                    value={proctor}
                                    onChange={(selectedOption) =>
                                      setProctor(selectedOption)
                                    }
                                    isSearchable={true}
                                    placeholder="Select Employee"
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal>
                        </span>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <hr />
                      {data && (
                        <Table
                          rowSelection={{
                            type: "checkbox",
                            onChange: (selectedRowKeys, selectedRows) => {
                              setSelectedData(selectedRows);
                              console.log(selectedRows);
                            },
                            getCheckboxProps: (record) => ({
                              disabled: record.name === "Disabled User",
                              // Column configuration not to be checked
                            }),
                          }}
                          pagination={false}
                          columns={columns}
                          dataSource={data}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>{" "}
              {/* end col */}
            </div>{" "}
            {/* end row */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignProctor;
