import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import axios from 'axios';
import { BASE_URL } from '../../../utils/apiConstants';
import { useLocation, useParams } from "react-router-dom";
import EmployeeProfileBasic from "../../../Components/Employee/Profile/EmployeeProfileBasic";
import StaffBasic from "../../../Components/HR/Staff/StaffBasic";
import StaffEducation from "../../../Components/HR/Staff/StaffEducation";
import StaffEmploymentTerms from "../../../Components/HR/Staff/StaffEmploymentTerms";
import StaffExperience from "../../../Components/HR/Staff/StaffExperience";
import StaffLegalDocs from "../../../Components/HR/Staff/StaffLegalDocs";
import StaffPlacement from "../../../Components/HR/Staff/StaffPlacement";
import StaffPublications from "../../../Components/HR/Staff/StaffPublications";
import StaffSwitcher from "../../../Components/HR/Staff/StaffSwitcher";
import StaffTraining from "../../../Components/HR/Staff/StaffTraining";
import { SESSION_EMPLOYEE_ID } from "../../../utils/sessionStorageContants";

function EmployeeProfile({ setLoading }) {
  const getEmployeeId = () =>
    sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      ? sessionStorage.getItem(SESSION_EMPLOYEE_ID)
      : null;

  const id = getEmployeeId();
  console.log(id);

  const [tab, setTab] = useState("Basic");
  const [score, setScore] = useState(null);

  const fetchProgressData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + '/api/employee/profile-progress/' + id
      );
      setScore(response.data.score);
    } catch (error) {
      console.error('Error fetching profile progress:', error);
    }
  };

  useEffect(() => {

      fetchProgressData();

  }, []);


  return (
    <div className="EditStaff">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Human Resource</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Human Resource</a>
                      </li>
                      <li className="breadcrumb-item active">Add New Staff</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}

            {/* switcher */}
            <StaffSwitcher setTab={setTab} tab={tab} />

            <div className="mb-3">
              <h6>Profile Completion Progress</h6>
              {score !== null ? (
                <Progress percent={score} />
              ) : (
                <p>Loading...</p>
              )}
            </div>

            {/* <div className="alert alert-success alert-rounded " role="alert">
        Your Email Id is your Username and Password would be sent to your registered Mobile Number and Email Id
      </div> */}
            {tab === "Basic" && (
              <EmployeeProfileBasic tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData} />
            )}
            {tab === "Placement" && (
              <StaffPlacement id={id} tab={tab} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
            {tab === "Employment Terms" && (
              <StaffEmploymentTerms id={id} tab={tab} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
            {tab === "Education" && (
              <StaffEducation tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
            {tab === "Experience" && (
              <StaffExperience tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
            {tab === "Training" && (
              <StaffTraining tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
            {tab === "Document" && (
              <StaffLegalDocs tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
            {tab === "Publications" && (
              <StaffPublications tab={tab} id={id} setLoading={setLoading} fetchProgressData={fetchProgressData}/>
            )}
          </div>
          {/* container-fluid */}
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
}

export default EmployeeProfile;
