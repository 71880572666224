import React, { useState, useRef, useEffect } from "react";
import "../../../../modals/HR/PayRoll/ModalPayRoll.scss";
import ReactToPrint from "react-to-print";

function ModalPrintPaySlip({
  setLoading,
  collegeId,
  month,
  toMonth,
  data,
  year,
  universityData,
  department,
  jobPositionOpt,
}) {
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const componentRef = useRef();

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div className="ModalPrintPaySlip">
      <div
        className="modal fade"
        id="ModalPrintPaySlip"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered mw-100"
          role="document"
        >
          <div className="modal-content ModalPrintPaySlip p-3">
            <div className="modal-header">
              <h5 className="modal-title mt-2" id="exampleModalLongTitle">
                Print Pay Slip
              </h5>
              <ReactToPrint
                documentTitle={" "}
                trigger={() => (
                  <button
                    className="ml-3 btn btn-success"
                    // data-dismiss="modal"
                    // aria-label="Close"
                  >
                    Print
                  </button>
                )}
                content={() => componentRef.current}
              />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="page-break"></div>
            <div className="modal-body" ref={componentRef}>
              {data?.map((i, k) => {
                return (
                  <div className="border p-5" style={{ breakAfter: "page" }}>
                    <div className="row">
                      <div className="col-sm-3">
                        <img
                          src={universityData.logo_secondary}
                          alt=""
                          width="100%"
                        />
                      </div>
                      <div className="col-sm-8 mb-2">
                        <div className="row justify-content-center text-center">
                          <h3 className="text-center">
                            {JSON.parse(localStorage.getItem("COLLEGE")).find(
                              (c) =>
                                c.id == sessionStorage.getItem("college_id")
                            )?.name || "College not found"}{" "}
                            ,{universityData.city}.
                          </h3>
                        </div>
                        <div className="row justify-content-center text-center">
                          <p className="text-center">
                            {
                              JSON.parse(localStorage.getItem("COLLEGE")).find(
                                (c) =>
                                  c.id == sessionStorage.getItem("college_id")
                              )?.location
                            }
                          </p>
                        </div>
                        <div className="row justify-content-center text-center">
                          <p className="text-center">
                            {" "}
                            Phone :{" "}
                            {
                              JSON.parse(localStorage.getItem("COLLEGE")).find(
                                (c) =>
                                  c.id == sessionStorage.getItem("college_id")
                              )?.phone
                            }
                          </p>
                          <p className="text-center ml-2">
                            {" "}
                            Email :{" "}
                            {
                              JSON.parse(localStorage.getItem("COLLEGE")).find(
                                (c) =>
                                  c.id == sessionStorage.getItem("college_id")
                              )?.email
                            }
                            .
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-1"></div>

                      <div className="col-sm-12 mb-3">
                        <hr />
                        <h4 className="text-center">
                          Salary Slip for the month of{" "}
                          {months?.find((s) => s.value == i.month)?.label}-
                          {i.year}
                        </h4>
                        <hr />
                      </div>

                      <div className="col-sm-6">
                        <table>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Name</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>
                                : {i?.salary_details?.first_name}{" "}
                                {i?.salary_details?.last_name}
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Designation</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>: {i?.salary_details?.role}</h5>
                            </td>
                          </tr>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Pay Scale</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>: {i?.salary_details?.payscale_title}</h5>
                            </td>
                          </tr>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Bank A/C No.</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>: {i?.salary_details?.account_number}</h5>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-sm-6">
                        <table>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Date of Joining</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>
                                :{" "}
                                {formatDate(
                                  i?.salary_details?.date_of_joining?.split(
                                    "T"
                                  )[0]
                                )}
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Department</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>
                                :{" "}
                                {
                                  department?.find(
                                    (s) =>
                                      s.id == i?.salary_details?.department_id
                                  )?.name
                                }
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Basic Pay</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>
                                :{" "}
                                {parseInt(
                                  i?.initial_basic_salary
                                )?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <th width="50%">
                              <h5>
                                <b>Working days</b>{" "}
                              </h5>
                            </th>
                            <td width="50%">
                              <h5>
                                :{" "}
                                {i?.salary_details?.p_count +
                                  i?.salary_details?.a_count}
                              </h5>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>

                    <div className="row mt-3 text-center">
                      <div className="col-sm-12 col-md-12">
                        <table className="table table-bordered slip-table">
                          <tr>
                            <th>P</th>
                            <th>A</th>
                            <th>WO</th>
                            <th>H</th>
                            {i?.leave_details?.map((i, k) => {
                              return <th>{i.leave_type}</th>;
                            })}
                          </tr>
                          <tr>
                            <td>{i?.salary_details?.p_count}</td>
                            <td>{i?.salary_details?.a_count}</td>
                            <td>{i?.salary_details?.wo_count}</td>
                            <td>{i?.holidays}</td>
                            {i?.leave_details?.map((i, k) => {
                              return <td>{i.number_of_days}</td>;
                            })}
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div className="row mt-3 text-center">
                      <div className="col-sm-6">
                        <div>
                          <table className="table table-bordered slip-table">
                            <thead>
                              <tr>
                                <th colSpan={2}>
                                  <h5>EARNING</h5>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th className="text-left">Basic Salary</th>
                                <td className="text-right">
                                  {parseInt(i?.basic_salary)?.toLocaleString(
                                    "en-IN",
                                    {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    }
                                  )}
                                </td>
                              </tr>
                              {/* {i?.salary_details?.pf_salary ? (
                                <tr>
                                  <th className="text-left">PF Salary</th>
                                  <td className="text-right">
                                    {(
                                      i?.salary_details?.pf_salary * 0.12
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )} */}
                              {i?.earning?.map((j, k) => {
                                return (
                                  <tr>
                                    <th className="text-left">{j.title}</th>
                                    <td className="text-right">
                                      {(j.type == "percentage"
                                        ? Math.round(
                                            parseInt(
                                              i?.basic_salary * j.percentage
                                            ) / 100
                                          )
                                        : j?.earning_id == 7
                                        ? Math.round(
                                            j.amount -
                                              (j.amount /
                                                (i?.salary_details?.p_count +
                                                  i?.salary_details?.a_count)) *
                                                i?.salary_details?.a_count
                                          )
                                        : j.amount
                                      )?.toLocaleString("en-IN", {
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                );
                              })}
                              {i?.bonus?.map((j, k) => {
                                return (
                                  <tr>
                                    <th className="text-left">{j.title}</th>
                                    <td className="text-right">
                                      {(j.type == "percentage"
                                        ? Math.round(
                                            (parseInt(i?.basic_salary) *
                                              j.percentage) /
                                              100
                                          )
                                        : j.amount
                                      )?.toLocaleString("en-IN", {
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                );
                              })}
                              {[
                                ...Array(
                                  Math.max(
                                    0,
                                    i?.deduction?.length -
                                      i?.earning?.length -
                                      i?.bonus?.length
                                  )
                                ),
                              ].map((_, index) => (
                                <tr>
                                  <td className="text-left">-</td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}
                              {i?.salary_details?.pf_salary ? (
                                <tr>
                                  <td className="text-left">-</td>
                                  <td className="text-right">-</td>
                                </tr>
                              ) : (
                                <></>
                              )}
                              <tr>
                                <th className="text-left">Gross Salary</th>
                                <td className="text-right">
                                  {(
                                    parseInt(i?.basic_salary) +
                                    parseInt(i?.earning_amount) +
                                    parseInt(i?.bonus_amount)
                                  )?.toLocaleString("en-IN", {
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <table className="table table-bordered slip-table">
                            <thead>
                              <tr>
                                <th colSpan={2}>
                                  <h5>DEDUCTION</h5>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {/* <tr>
                                <th className="text-left">Paid Leave</th>
                                <td className="text-right">
                                  {(
                                    (i?.leave_details
                                      ?.filter((s) => s.paid_leave == 1)
                                      ?.reduce(
                                        (acc, curr) =>
                                          acc +
                                          Math.round(
                                            curr?.paid_leave_amount *
                                              curr?.number_of_days
                                          ),
                                        0
                                      ) || 0) +
                                    parseInt(
                                      (i?.salary_details?.a_count -
                                        i?.leave_details?.reduce(
                                          (i, k) => i + k.number_of_days,
                                          0
                                        )) *
                                        (parseInt(
                                          i?.salary_details?.basic_salary
                                        ) /
                                          new Date(
                                            i?.year,
                                            parseInt(i?.month),
                                            0
                                          ).getDate())
                                    )
                                  )?.toLocaleString("en-IN", {
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                              </tr> */}
                              {i?.salary_details?.pf_salary ? (
                                <tr>
                                  <th className="text-left">PF</th>
                                  <td className="text-right">
                                    {(
                                      i?.salary_details?.pf_salary * 0.12
                                    )?.toLocaleString("en-IN", {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                </tr>
                              ) : (
                                <></>
                              )}
                              {i?.deduction?.map((j, k) => {
                                return (
                                  <tr>
                                    <th className="text-left">{j.title}</th>
                                    <td className="text-right">
                                      {(j.type == "percentage"
                                        ? Math.round(
                                            parseInt(i?.basic_salary) *
                                              j.percentage
                                           / 100)
                                        : j.amount
                                      )?.toLocaleString("en-IN", {
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </td>
                                  </tr>
                                );
                              })}
                              {[
                                ...Array(
                                  Math.max(
                                    0,
                                    i?.bonus?.length +
                                      i?.earning?.length -
                                      i?.deduction?.length
                                  )
                                ),
                              ].map((_, index) => (
                                <tr>
                                  <td className="text-left">-</td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-left">-</td>
                                <td className="text-right">-</td>
                              </tr>
                              <tr>
                                <th className="text-left">Total Deduction</th>
                                <td className="text-right">
                                  {i?.deduction_amount?.toLocaleString(
                                    "en-IN",
                                    {
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    }
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table table-bordered slip-table">
                          <thead>
                            <tr>
                              <th className="text-center" width="50%">
                                NET Pay
                              </th>
                              <th className="text-center">
                                {i?.net_salary?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <b>Authorized Signatory</b>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={`row d-flex justify-content-end px-2`}>
              <ReactToPrint
                documentTitle={" "}
                trigger={() => (
                  <button
                    className="btn btn-success  btn-outline px-4  mr-3"
                    // data-dismiss="modal"
                    // aria-label="Close"
                  >
                    Print
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPrintPaySlip;
