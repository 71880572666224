export const feeJsonData = [
    {
        name:'Cash',
        id:"CASH"
    },
    {
        name:'Cheque',
        id:"CHEQUE"
    },
    {
        name:'DD',
        id:"DD"
    },
    {
        name:'UPI',
        id:"UPI"
    },
    {
        name:'Card',
        id:"CARD"
    },
    {
        name:'Bank Transfer',
        id:"BANK_TRANSFER"
    }

]