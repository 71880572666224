import React, { useState, useEffect } from "react";
import axios from "axios";
import NoData from "../../../../Components/NoData/Nodata";
import { useNavigate } from "react-router-dom";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import { REPORT_STAFF_ATTENDANCE_MONTHWISE } from "../../../../utils/Reports.apiConst";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../../utils/LocalStorageConstants";
import "../../../../modals/HR/Leave/TableScroll.css";
import { EMPLOYEE_BIO_IDS } from "../../../../utils/apiConstants";

function AttendanceTimeClockReport({ setLoading, collegeId }) {
  const navigate = useNavigate();

  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "22",
  ];

  const collegeid = sessionStorage.getItem("college_id");
  console.log("collegeid -", collegeid);

  const [user, setUser] = useState({
    month1: monthNums[new Date().getMonth()],
    year1: new Date().getFullYear(),
    department_id: "",
    college_id: "",
  });

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(
    () => {
      setDepartmentOpt(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
          // (item) => item.college_id == user.college_id
          (item) => item.college_id == collegeid
        )
      );
      setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
      user.department_id = "";
    },
    // [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);
    [localStorage.getItem(LOCAL_DEPARTMENT), collegeid]
  );

  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const tableRef = useRef();

  const months = [
    { key: "01", name: "JANUARY" },
    { key: "02", name: "FEBRUARY" },
    { key: "03", name: "MARCH" },
    { key: "04", name: "APRIL" },
    { key: "05", name: "MAY" },
    { key: "06", name: "JUNE" },
    { key: "07", name: "JULY" },
    { key: "08", name: "AUGUST" },
    { key: "09", name: "SEPTEMBER" },
    { key: "10", name: "OCTOBER" },
    { key: "11", name: "NOVEMBER" },
    { key: "12", name: "DECEMBER" },
  ];

  const getlocalStorage = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  // const [attendanceData, setAttendanceData] = useState([]);
  const [jobRoleOpt, setJobRoleOpt] = useState(getlocalStorage);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  function groupBy(array, key) {
    return array.reduce((result, currentItem) => {
      (result[currentItem[key]] = result[currentItem[key]] || []).push(
        currentItem
      );
      return result;
    }, {});
  }

  function countStatus(data) {
    const count = { present: 0, absent: 0, wo: 0 };
    data.forEach((item) => {
      if (item.Status === "A") {
        count.absent++;
      } else if (item.Status === "P") {
        count.present++;
      } else {
        count.wo++;
      }
    });
    return count;
  }

  const getData = async () => {
    if (!user?.month1) {
      return toast.error("Please select Month");
    }
    if (!user?.year1) {
      return toast.error("Please select Year");
    }

    // console.log("college_id - ", user.college_id);
    console.log("dept - ", user.department_id);
    const config1 = {
      method: "get",
      // url: `${EMPLOYEE_BIO_IDS}?department_id=${user.department_id}&college_id=${user.college_id}`,
      url: `${EMPLOYEE_BIO_IDS}?department_id=${user.department_id}&college_id=${collegeid}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios(config1)
      .then(async (res) => {
        let bioIds = res.data.data;
        console.log("emp ids - ", res.data.data);
        let url = "";
        console.log("year1 - ", new Date().getFullYear());
        console.log("year2 - ", user.year1);
        if (
          monthNums[new Date().getMonth()] == user.month1 &&
          new Date().getFullYear() == user.year1
        ) {
          url = `${REPORT_STAFF_ATTENDANCE_MONTHWISE}?month=${
            user.month1
          }&year=${user.year1}&last_date=${
            new Date().getDate() < 10
              ? `0${new Date().getDate()}`
              : new Date().getDate()
          }`;
        } else {
          url = `${REPORT_STAFF_ATTENDANCE_MONTHWISE}?month=${user.month1}&year=${user.year1}`;
        }
        const config = {
          method: "get",
          url: url,
          headers: {
            "Content-Type": "application/json",
          },
        };

        await axios(config)
          .then((res) => {
            console.log("month attendance data - ", res.data.data);

            let attendanceData = res.data.data;
            const parsedData = attendanceData.map((item) => ({
              Empcode: item.Empcode,
              Status: item.Status,
              Name: item.Name,
            }));

            const groupedData = groupBy(parsedData, "Empcode");

            let countsByEmpcode = [];
            for (const empcode in groupedData) {
              if (groupedData.hasOwnProperty(empcode)) {
                countsByEmpcode[empcode] = {
                  Name: groupedData[empcode][0].Name,
                  Empcode: groupedData[empcode][0].Empcode,
                  Counts: countStatus(groupedData[empcode]),
                };
              }
            }

            countsByEmpcode = countsByEmpcode.filter(
              (s) => s?.Empcode && bioIds.includes(s.Empcode)
            );
            console.log("number of employees - ", countsByEmpcode.length);
            console.log("Counts by Empcode:", countsByEmpcode);
            setMainData(countsByEmpcode);
            let filteredData = countsByEmpcode.filter((item) =>
              item.Name.toLowerCase().includes(searchName.toLowerCase())
            );
            setData(filteredData);
            setLoading(0);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${months.find((s) => s.key == user?.month1)?.name}-${
      user?.year1
    } Employee Attendance`,
    sheet: "Users",
  });

  useEffect(() => {
    const filteredData = mainData.filter((item) =>
      item.Name.toLowerCase().includes(searchName.toLowerCase())
    );
    setData(filteredData);
  }, [searchName]);

  useEffect(() => {
    setData([]);
    setMainData([]);
  }, [user?.month1, user?.year1, user?.department_id]);

  return (
    <div className="AttendanceTimeClockReport">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">STAFF ATTENDANCE MONTH WISE</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title">Select Criteria</div>
                    <div className="row mt-2">
                      {/* <div className="col-md-3"> */}
                      {/* <div className="form-group">
                          <label htmlFor="role">
                            Designation <small className="text-danger">*</small>
                          </label>
                          <select name="role" id="role" required="" className="form-control">
                            <option value="">All</option>
                            {jobRoleOpt?.map((data, key) => {
                              return <option value={data.id}>{data.name}</option>
                            })}
                          </select>
                        </div> */}
                      {/* <div className="form-group">
                          <label htmlFor="college_id">
                            College <span className="text-danger">*</span>
                          </label>
                          <select
                            name="college_id"
                            id="college_id"
                            required=""
                            className="form-control"
                            value={user.college_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {collegeOpt?.map((data, key) => {
                              return (
                                <option value={data.id}>{data.name}</option>
                              );
                            })}
                          </select>
                        </div> */}
                      {/* </div> */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="department_id"
                            id="class"
                            value={user.department_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {departmentOpt
                              ?.filter((s) => s.college_id == collegeid)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name},{" "}
                                  {
                                    programOpt.find(
                                      (item) => item.id == i.program_id
                                    )?.name
                                  }
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="month">
                            Month <span className="text-danger">*</span>
                          </label>
                          <select
                            name="month1"
                            id="month1"
                            required=""
                            className="form-control"
                            value={user?.month1}
                            onChange={handleChange}
                          >
                            <option value="">Select Month</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="year">
                            Year <span className="text-danger">*</span>
                          </label>
                          <select
                            name="year1"
                            id="year1"
                            required=""
                            className="form-control"
                            value={user?.year1}
                            onChange={handleChange}
                          >
                            <option value="">Select Year</option>
                            {sessionOpt &&
                              sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>
                                    {item?.name.substring(0, 4)}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-1 my-4 ml-4">
                        <button
                          className="btn btn-nex"
                          name="submit"
                          type="submit"
                          value="staff"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                      {/* <div className="col-md-12">
                        <button
                          className="btn btn-nex float-right"
                          name="submit"
                          type="submit"
                          value="staff"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <br />
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                      <div></div>
                      <div>
                        <button
                          className="btn btn-primary rounded-pill mr-2"
                          onClick={onDownload}
                        >
                          Excel
                        </button>
                        <button
                          className="btn btn-primary rounded-pill"
                          onClick={handlePrint}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive fixTableHead">
                          <table
                            className="table table-bordered"
                            ref={tableRef}
                          >
                            {/* <thead> */}
                            <tr>
                              <th style={{ display: "none" }} colSpan={7}>
                                KALOL INSTITUTE AND RESEARCH CENTER
                              </th>
                            </tr>
                            {/* </thead> */}
                            <thead>
                              <tr>
                                <th className="text-uppercase" colSpan={2}>
                                  Employees - {data ? data.length : 0}
                                </th>
                                <th className="text-uppercase" colSpan={3}>
                                  Year - {user?.year1}
                                </th>
                                <th className="text-uppercase" colSpan={3}>
                                  Month -{" "}
                                  {
                                    months.find((s) => s.key == user?.month1)
                                      ?.name
                                  }
                                </th>
                              </tr>
                              <tr>
                                <th>Sl.No</th>
                                <th>Employee Name</th>
                                <th>Biometric ID</th>
                                <th>Present</th>
                                <th>Absent</th>
                                <th>Weekly Off</th>
                                <th>Total</th>
                                <th>Percentage</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data && data.length ? (
                                data?.map((i, key) => {
                                  let percentage = 0;
                                  if (
                                    i?.Counts.present + i?.Counts.absent !==
                                    0
                                  ) {
                                    percentage = (
                                      (i?.Counts.present * 100) /
                                      (i?.Counts.present + i?.Counts.absent)
                                    ).toFixed(2);
                                  }
                                  let color = "#CCFFCD";
                                  if (percentage < 60) color = "#FFCCCB";
                                  else if (percentage < 80 && percentage >= 60)
                                    color = "#fffdd0";
                                  return (
                                    <tr>
                                      <td>{key + 1}</td>
                                      <td>{i?.Name}</td>
                                      <td>{i?.Empcode}</td>
                                      <td className="text-success">
                                        <b>{i?.Counts.present}</b>
                                      </td>
                                      <td className="text-danger">
                                        <b>{i?.Counts.absent}</b>
                                      </td>
                                      <td className="text-muted">
                                        <b>{i?.Counts.wo}</b>
                                      </td>
                                      <td className="text-primary">
                                        <b>
                                          {i?.Counts.present +
                                            i?.Counts.absent +
                                            i?.Counts.wo}
                                        </b>
                                      </td>
                                      <td style={{ backgroundColor: color }}>
                                        <b>{percentage}%</b>
                                      </td>
                                      {/* <td key={key}>
                                    <span className={`badge badge-soft-${i?.Status=="P"?"success":(i.Status=="A"?"danger":"secondary")}`} >
                                        {i.Status == 'P' ? "Present" : i.Status == "A" ? "Absent" : i.Status}
                                    </span>
                                </td> */}
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={12}>
                                    <NoData />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    {/* {data ?  <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>Staff Attendance Report</h5>
                      </div>

                      <div className="col-md-4 d-flex  justify-content-around">
                        <h6>
                          Present:<span className="text-success"> P </span>{" "}
                        </h6>
                        <h6>
                          Late:<span className="text-warning"> L </span>{" "}
                        </h6>
                        <h6>
                          Absent:<span className="text-danger"> A </span>{" "}
                        </h6>
                        <h6>
                          Holiday:<span className="text-secondary"> H</span>{" "}
                        </h6>
                      </div>

                      <div className="col-12">
                        <div className="tabel-responsive">
                          <table className='w-100'>
                            <thead>
                              <tr>
                                <th style={{ width: '100px' }} width="100px">Staff/Date</th>
                                <th style={{ width: '100px' }} width="20px" >%</th>
                                <th style={{ width: '100px' }} width="20px" >P</th>
                                <th style={{ width: '100px' }} width="20px" >L</th>
                                <th style={{ width: '100px' }} width="20px" >Action</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td>
                                  Anand
                                </td>
                                <td>
                                  <span className="badge badge-light"> 85%</span>
                                </td>
                                <td>13</td>
                                <td>33</td>
                                <td><button className="btn btn-primary btn-rounded">Export</button></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div> : <Nodata /> } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceTimeClockReport;
