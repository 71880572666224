import React, { useState, useRef, useEffect } from 'react'
// import ModalEarningPolicy from '../../../../modals/HR/Leave/ModalEarningPolicy'
// import { LEAVE_EARNING_POLICY } from '../../../../utils/apiConstants';
import axios from 'axios';
import { toast } from "react-toastify";
// import Nodata from '../../../../Components/NoData/Nodata';
import { useDownloadExcel } from "react-export-table-to-excel";
import { LOCAL_COLLEGE } from '../../../utils/LocalStorageConstants';
import { useReactToPrint } from "react-to-print";
import Select from "react-select";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import { Flex, Input } from 'antd';
import Swal from "sweetalert2";

import {EMPLOYEE, SUBMIT_RESIGNATION} from "../../../utils/apiConstants"

function StaffResignation({collegeId, setLoading}) {


  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    catch(err) {
      console.log(err);
      return dateString;
    }
  }

  let emp_id = sessionStorage.getItem("employee_id");

  const { TextArea } = Input;

    const [user, setUser] = useState({
        employee_id: "",
        employee_id_cc: "",
        employee_id_bcc: "",
        date: new Date().toISOString().substring(0, 10),
    });

    const [empData, setEmpData] = useState([]);

    const getEmployeeDetails = async () => {
      setLoading(1);
      const config = {
        method: "get",
        url: EMPLOYEE + "/" + emp_id,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      };
      await axios(config)
      .then((res) => {
          setLoading(0);
          console.log('emp data - ', res.data.data);
          setResignationText()
          setEmpData(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        });
    }

    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };
    
    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

    const [selectedValue, setSelectedValue] = useState([]);     //to
    const [selectedValueCC, setSelectedValueCC] = useState([]); //cc
    const [selectedValueBCC, setSelectedValueBCC] = useState([]); //bcc

    const [employeeOpt] = useEmployee(collegeId);

    const [resignationText, setResignationText] = useState(
      `From,
${empData?.first_name} ${empData?.last_name}
${empData?.role_name ? empData?.role_name : "[ENTER ROLE]"}
Kalol Institute and Research Center
Kalol, Gandhinagar, Gujarat-382721.
      
Dear Sir/Madam,
I am writing to formally resign from my position as ${empData?.role_name ? empData?.role_name : "[ENTER ROLE]"} at Kalol Institute and Research Center, effective from ${user?.date ? formatDate(user?.date) : "[ENTER DATE]"}.
  
I have enjoyed my time working at Kalol Institute and Research Center and appreciate the opportunities I've had to grow and develop professionally. However, I have decided to pursue other opportunities.
  
I am committed to ensuring a smooth transition during my remaining time with the Institute. Please let me know how I can assist in finding and training my replacement.
  
Thank you for the support and guidance you've provided during my tenure at Kalol Institute and Research Center. I wish the Institute continued success in the future.
  
Sincerely,
${empData?.first_name && empData?.last_name ? empData?.first_name + " " + empData?.last_name : "[ENTER NAME]"}`
    );


    const updatedEmployeeOpt = employeeOpt?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + " " + item.last_name,
        };
      });

    const tableRef = useRef();

    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: " Earning Policy List ",
      sheet: "Users",
    });

    const PrintRecipt = useReactToPrint({
        content: () => tableRef.current,
      });

    const handlePrint = () => {
        // Make the table visible
        tableRef.current.style.display = 'table';
      
        // Delay the PDF export
        setTimeout(() => {
          PrintRecipt();
          // Hide the table again if needed
          tableRef.current.style.display = 'none';
        }, 1); // Adjust the delay as needed
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const getCurrentDate = () => {
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
      return formattedDate;
    };

    const onChangeText = (e) => {
      setResignationText(e.target.value);
    };

    const handleSubmit = async () => {
      if (!emp_id || !resignationText || !user?.date || !user?.employee_id) {
        return toast.error("Please Enter Mandatory Details");
      }
      let obj = {
        employee_id: emp_id,
        to: user?.employee_id,
        cc: user?.employee_id_cc,
        bcc: user?.employee_id_bcc,
        desc: resignationText,
        last_working_date: user?.date,
        apply_date: new Date()?.toISOString()?.split("T")[0]
      };
      const config = {
        method: "post",
        url:SUBMIT_RESIGNATION,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
        data: obj,
      };
      Swal.fire({
        title: "Submit Resignation Request",
        text: "Resignation letter will be mailed and Request for resignation will be raised.",
        showCancelButton: true,
        confirmButtonText: "Submit",
      }).then(async (s) => {
        if (s.isConfirmed) {
          setLoading(1);
          await axios(config)
          .then((res) => {
            setLoading(0);
            console.log(res);
            toast.success("Submitted Resignation Letter");
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
        }
        setLoading(0);
      });
};

useEffect(() => {
        setResignationText(`From,
${empData?.first_name} ${empData?.last_name}
${empData?.role_name ? empData?.role_name : "[ENTER ROLE]"}
Kalol Institute and Research Center.
Kalol, Gandhinagar, Gujarat-382721.
      
Dear Sir/Madam,
I am writing to formally resign from my position as ${empData?.role_name ? empData?.role_name : "[ENTER ROLE]"} at Kalol Institute and Research Center, effective from ${user?.date ? formatDate(user?.date) : "[ENTER DATE]"}.
  
I have enjoyed my time working at Kalol Institute and Research Center and appreciate the opportunities I've had to grow and develop professionally. However, I have decided to pursue other opportunities.
  
I am committed to ensuring a smooth transition during my remaining time with the Institute. Please let me know how I can assist in finding and training my replacement.
  
Thank you for the support and guidance you've provided during my tenure at Kalol Institute and Research Center. I wish the Institute continued success in the future.
  
Sincerely,
${empData?.first_name && empData?.last_name ? empData?.first_name + " " + empData?.last_name : "[ENTER NAME]"}`);
    },[empData]);

    useEffect(() => {
      setResignationText(`From,
${empData?.first_name} ${empData?.last_name}
${empData?.role_name ? empData?.role_name : "[ENTER ROLE]"}
Kalol Institute and Research Center.
Kalol, Gandhinagar, Gujarat-382721.
      
Dear Sir/Madam,
I am writing to formally resign from my position as ${empData?.role_name ? empData?.role_name : "[ENTER ROLE]"} at Kalol Institute and Research Center, effective from ${user?.date ? formatDate(user?.date) : "[ENTER DATE]"}.
  
I have enjoyed my time working at Kalol Institute and Research Center and appreciate the opportunities I've had to grow and develop professionally. However, I have decided to pursue other opportunities.
  
I am committed to ensuring a smooth transition during my remaining time with the Institute. Please let me know how I can assist in finding and training my replacement.
  
Thank you for the support and guidance you've provided during my tenure at Kalol Institute and Research Center. I wish the Institute continued success in the future.
  
Sincerely,
${empData?.first_name && empData?.last_name ? empData?.first_name + " " + empData?.last_name : "[ENTER NAME]"}`);
    }, [user?.date]);

    useEffect(() => {
        const idToSelect = () => {
          try {
            if (user.employee_id) {
              const temp = user.employee_id.map((item) => {
                const filteredObject = updatedEmployeeOpt.find(
                  (item2) => item2.value === item
                );
    
                return {
                  value: item,
                  label: filteredObject ? filteredObject.label : "Not Found",
                };
              });
    
              return temp;
            } else {
              return [];
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
          }
        };
    
        setSelectedValue(idToSelect());
      }, [user.employee_id]);

      useEffect(() => {
        const idToSelect = () => {
          try {
            if (user.employee_id_cc) {
              const temp = user.employee_id_cc.map((item) => {
                const filteredObject = updatedEmployeeOpt.find(
                  (item2) => item2.value === item
                );
    
                return {
                  value: item,
                  label: filteredObject ? filteredObject.label : "Not Found",
                };
              });
    
              return temp;
            } else {
              return [];
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
          }
        };
    
        setSelectedValueCC(idToSelect());
      }, [user.employee_id_cc]);

      useEffect(() => {
        const idToSelect = () => {
          try {
            if (user.employee_id_bcc) {
              const temp = user.employee_id_bcc.map((item) => {
                const filteredObject = updatedEmployeeOpt.find(
                  (item2) => item2.value === item
                );
    
                return {
                  value: item,
                  label: filteredObject ? filteredObject.label : "Not Found",
                };
              });
    
              return temp;
            } else {
              return [];
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return [];
          }
        };
    
        setSelectedValueBCC(idToSelect());
      }, [user.employee_id_bcc]);

      useEffect(() => {
        console.log('user', user);
      },[user]);

      useEffect(() => {
        getEmployeeDetails();
      },[]);
  return (
    <div className='EarningPolicy'>
        <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Resignation</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="/">HR</a>
                                            </li>
                                            <li className="breadcrumb-item active">Resignation</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="card">
                                <div className="card-body">
                                    <div>
                                        <h5>Generate Resignation Letter</h5>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label htmlFor="">
                                                To<span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                isMulti
                                                value={selectedValue}
                                                name="employee_id"
                                                options={updatedEmployeeOpt}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                const selectedIds = e.map(
                                                    (item) => item.value
                                                );
                                                setUser((prev) => ({
                                                    ...prev,
                                                    employee_id: selectedIds,
                                                }));
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label htmlFor="">
                                                CC 
                                            </label>
                                            <Select
                                                isMulti
                                                value={selectedValueCC}
                                                name="employee_id_cc"
                                                options={updatedEmployeeOpt}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                const selectedIds = e.map(
                                                    (item) => item.value
                                                );
                                                setUser((prev) => ({
                                                    ...prev,
                                                    employee_id_cc: selectedIds,
                                                }));
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                        <label htmlFor="">
                                                BCC 
                                            </label>
                                            <Select
                                                isMulti
                                                value={selectedValueBCC}
                                                name="employee_id_bcc"
                                                options={updatedEmployeeOpt}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(e) => {
                                                const selectedIds = e.map(
                                                    (item) => item.value
                                                );
                                                setUser((prev) => ({
                                                    ...prev,
                                                    employee_id_bcc: selectedIds,
                                                }));
                                                }}
                                            />
                                            
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                              <br />
                                            <label htmlFor="">Last working date<span className="text-danger">*</span></label>
                                            <input
                                                type="date"
                                                name="date"
                                                onChange={handleChange}
                                                className="form-control"
                                                value={user?.date}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="">
                                      <h5>
                                        Description
                                      </h5>
                                      <p className="text-danger">*A mail of below description will be sent to selected To with cc and bcc.</p>
                                    <Flex style={{ width: '100%' }}>
                                      <TextArea
                                        style={{ width: '100%', height: '400px'}}
                                        showCount
                                        maxLength={1000}
                                        onChange={onChangeText}
                                        value={resignationText}
                                        placeholder="Enter Text here"
                                      />
                                    </Flex>
                                    </div>
                                    <div className="row ml-auto mt-3">
                                      <button
                                        className="btn btn-danger btn-rounded float-right "
                                        onClick={handleSubmit}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default StaffResignation