import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { STUDENT_GATE_PASS_LIST } from '../../utils/InfoUploadingApiConstants';
import Nodata from '../../Components/NoData/Nodata';
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT } from '../../utils/LocalStorageConstants';
import ModalStudentGatePass from '../../modals/ModalStudentGatePass';
import HostelModalStudentGatePass from '../../modals/HostelModalStudentGatePass';

function ViewStudentGatePass({ setLoading, collegeId }) {   

    const navigate = useNavigate();

    function formatDate(dateString) {
      try {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      } catch (err) {
        return dateString;
      }
    }

    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(new Date().toISOString().split("T")[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split("T")[0]);

    let date = new Date().toISOString().split("T")[0];

    const [data2, setData2] = useState("");
    const [flag,setFlag] = useState(false);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

  // When the user clicks the edit button
  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

    const [employee, setEmployee] = useState("");
    const [student, setStudent] = useState("");
    const [session, setSession] = useState("");


    const [classOpt, setClassOpt] = useState([]);

    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };
    
      const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
    
      const [department, setDepartment] = useState(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      );
    
      useEffect(() => {
        setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
      }, [localStorage.getItem(LOCAL_DEPARTMENT), collegeId]);


    const getData = async () => {

        if(!fromDate) {
          return toast.error("Plese Enter From Date");
        }
        if(!toDate) {
          return toast.error("Please Enter To Date");
        }
    
        const config = {
          method: "get",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          url: `${STUDENT_GATE_PASS_LIST}?from_date=${fromDate}&to_date=${toDate}`,
        }
        
        setLoading(1);
        await axios(config)
        .then(
          res => {
            setLoading(0);
            setData(res.data.data)
            console.log('gate pass list - ', res.data.data)
          }
        ).catch(err => {
          setLoading(0);
          console.log(err);
          toast.error('Something went wrong')
        })
        setLoading(0);    
    
      }

    const role = sessionStorage.getItem("role");

  return (
    <div>
        <ModalStudentGatePass
          flag={flag}
          setFlag={setFlag}
          setLoading={setLoading}
          data={data2}
          getData={getData}
        />

        <HostelModalStudentGatePass
          flag={flag}
          setFlag={setFlag}
          setLoading={setLoading}
          data={data2}
          getData={getData}
        />

        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0"> Student Gate Pass List </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  From date<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    name="fromDate"
                                                    className="form-control"
                                                    placeholder="From Date"
                                                    value={fromDate ? fromDate : date}
                                                    onChange={(e) => setFromDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor="validationCustom02">
                                                  To Date<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    name="toDate"
                                                    className="form-control"
                                                    placeholder="To Date"
                                                    value={toDate ? toDate : date}
                                                    onChange={(e) => setToDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 d-flex align-items-center justify-content-end">
                                      <button
                                        className="btn btn-nex btn-rounded float-lg-left "
                                        onClick={getData}
                                      >
                                      <i className="fa fa-search" aria-hidden="true" /> Search
                                      </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                <div className="table table-responsive dt-responsive table-bordered nowrap table-hove">
                              <table
                                id="datatable"
                                className="table  nowrap table-hover  "
                                style={{
                                  borderCollapse: "collapse",
                                  borderSpacing: 0,
                                  boarder: 0,
                                  width: "100%"
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th>Sl.No.</th>
                                    <th>Enrollment No.</th>
                                    <th>Name</th>
                                    <th>College</th>
                                    <th>Hostel</th>
                                    <th>Room</th>
                                    <th>Applied on</th>
                                    <th>Purpose</th>
                                    <th>Day Session</th>
                                    <th>From Date</th>
                                    <th>To Date</th>
                                    <th>Days</th>
                                    <th>Proctor Status</th>
                                    <th>Warden Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    data && data.map((d, k) => (
                                      <tr>
                                        <td>{k+1}</td>
                                        <td>{d?.student_id}</td>
                                        <td>{d?.name}</td>
                                        <td>
                                          {collegeOpt && collegeOpt
                                            ?.filter((s) => s.id == d?.college_id)
                                            ?.map((s) => s.name)
                                          }
                                        </td>
                                        <td>
                                          {d?.hostel_name} ({d?.floor_number})
                                        </td>
                                        <td>
                                          {d?.room_number} ({d?.bed_number})
                                        </td>
                                        <td>
                                          {formatDate(d?.request_date)}
                                        </td>
                                        <td>{d?.purpose_type}</td>  
                                        <td>
                                          {d?.day_session == "FULL_DAY" 
                                            ? "Full Day" : d?.day_session == "MORNING" 
                                            ? "First Half (Half Day)" 
                                            : d?.day_session == "EVENING"
                                            ? "Second Half (Half Day)"
                                            : "-"
                                          }
                                        </td>
                                        <td>{formatDate(d?.out_from_date)}</td>
                                        <td>{d?.out_to_date ? formatDate(d?.out_to_date) : formatDate(d?.out_from_date)}</td>
                                        <td>{d?.no_of_days}</td>
                                        <td> 
                                          <span className={`badge ${d?.approval_status_1 == 'APPROVED' ? 'badge-soft-success' : 
                                          d?.approval_status_1 == 'PENDING' ? 'badge-soft-warning' :
                                          d?.approval_status_1 == 'DECLINED' ? 'badge-soft-danger' : 
                                          d?.approval_status_1 == 'CANCELLED' ? 'badge-soft-danger' : ''}`}
                                          >
                                          {d?.approval_status_1}
                                          </span>
                                        </td>
                                        <td> 
                                          <p className={`badge ${d?.approval_status_2 == 'APPROVED' ? 'badge-soft-success' : 
                                          d?.approval_status_2 == 'PENDING' ? 'badge-soft-warning' :
                                          d?.approval_status_2 == 'DECLINED' ? 'badge-soft-danger' : 
                                          d?.approval_status_2 == 'CANCELLED' ? 'badge-soft-danger' : ''}`}
                                          >
                                          {d?.approval_status_2}
                                          </p>
                                        </td>
                                        <td>
                                          <a
                                            data-toggle="modal"
                                            data-target="#HostelStudentGatePass"
                                            onClick={() => {
                                              setData2({
                                                ...d,
                                              });
                                            }}
                                          >
                                            <button className="btn btn-primary btn-nex btn-rounded mr-2" type="button" name="submit"
                                              onClick={() => handleEditClick(d)}
                                            >
                                                View
                                            </button>
                                          </a> 
                                            
                                        </td>   
                                      </tr>
                                    ))
                                  }                         
                                </tbody>
                              </table>
                              {data?.length == 0 
                                ? <Nodata/>
                                : null
                              }
                            </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
      
    </div>
  )
}

export default ViewStudentGatePass;
