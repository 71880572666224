import React, { useState, useEffect} from 'react';
import axios from "axios";
import NoData from '../../../../Components/NoData/Nodata';
import { useNavigate } from "react-router-dom";
import { LOCAL_JOBROLES } from '../../../../utils/LocalStorageConstants';
import {sessionOpt} from '../../../../Data/jsonData/Academics/Academics';
import { REPORT_STSFF_ATTENDANCE_MONTHWISE_ID } from "../../../../utils/Reports.apiConst";
import { EMPLOYEE } from "../../../../utils/apiConstants";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";

function AttendanceMonthEmployee({ setLoading, collegeId }) {

  let emp_id = sessionStorage.getItem("employee_id");
  const [user, setUser] = useState({
    month1 : "",
    year1 : "",
  })

  const [empData, setEmpData] = useState([]);
  const [data, setData] = useState([]);
  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [wo, setWo] = useState(0);
  const tableRef = useRef();

  const months = [
    { key: "01", name: "JANUARY" },
    { key: "02", name: "FEBRUARY" },
    { key: "03", name: "MARCH" },
    { key: "04", name: "APRIL" },
    { key: "05", name: "MAY" },
    { key: "06", name: "JUNE" },
    { key: "07", name: "JULY" },
    { key: "08", name: "AUGUST" },
    { key: "09", name: "SEPTEMBER" },
    { key: "10", name: "OCTOBER" },
    { key: "11", name: "NOVEMBER" },
    { key: "12", name: "DECEMBER" }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const fetchEmployeeDetails = async () => {
    const config = {
        method: "get",
        url: `${EMPLOYEE}/${emp_id}`,
        headers: {
          "Content-Type": "application/json",
        },
    };
    setLoading(1);
      await axios(config)
        .then((res) => {
          console.log('employee data - ', res.data.data);
          setEmpData(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
        setLoading(0);
        console.log(err);
        });
    setLoading(0);
  }

  const parseDateString = (dateString) => {
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; 
    const year = parseInt(parts[2], 10);

    return new Date(year, month, day);
  };

  const getData = async () => {
    if(!user?.month1) {
        return toast.error("Please select Month");
    }
    if(!user?.year1) {
    return toast.error("Please select Year");
    }

    if(!empData.biometric_id) {
        return toast.error("Unable to get Employye biometric id");
    }

    const config = {
        method: "get",
        url: `${REPORT_STSFF_ATTENDANCE_MONTHWISE_ID}?empcode=${empData.biometric_id}&month=${user?.month1}&year=${user?.year1}`,
        headers: {
          "Content-Type": "application/json",
        },
    };
    setLoading(1);
      await axios(config)
        .then((res) => {
          console.log('month attendance data - ', res.data.data);
          let currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1; 
          const currentYear = currentDate.getFullYear();
          const currentDateOfMonth = currentDate.getDate();

          console.log(currentDateOfMonth, currentMonth, currentYear);

          if(currentMonth == parseInt(user.month1) && currentYear == user.year1) {
            let tempData = res.data.data;
            tempData = tempData.filter((s) => parseInt(s.DateString.substring(0,2)) <= currentDateOfMonth)
            let p=0,a=0,w=0;
            for(let i in tempData) {
                if(tempData[i].Status == 'P') p++;
                else if(tempData[i].Status == 'A') a++;
                else w++;
            }
            setPresent(p);
            setAbsent(a);
            setWo(w);
            const sortedData = tempData.sort((a, b) => {
            const dateA = parseDateString(a.DateString);
            const dateB = parseDateString(b.DateString);
            return dateA - dateB;
            });
            console.log('sorted data - ', sortedData);
            setData(sortedData);
          } 
          else {
            let tempData = res.data.data;
            let p=0,a=0,w=0;
            for(let i in tempData) {
                if(tempData[i].Status == 'P') p++;
                else if(tempData[i].Status == 'A') a++;
                else w++;
            }
            setPresent(p);
            setAbsent(a);
            setWo(w);
            const sortedData = tempData.sort((a, b) => {
            const dateA = parseDateString(a.DateString);
            const dateB = parseDateString(b.DateString);

            return dateA - dateB;
            });
            console.log('sorted data - ', sortedData);
            setData(sortedData);
          }
          setLoading(0);
        })
        .catch((err) => {
        setLoading(0);
        console.log(err);
        });
    setLoading(0);
  }

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${months.find((s) => s.key == user?.month1)?.name}-${user?.year1} Employee Attendance`,
    sheet: "Users",
  });

  useEffect(() => {
    setData([]);
  },[user?.month1, user?.year1]);

  useEffect(() => {
    console.log(parseInt("01"));
    fetchEmployeeDetails();
  },[]);

  return (
    <div className='AttendanceTimeClockReport'>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
          <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <h4 className="mb-0">STAFF ATTENDANCE MONTH WISE</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                  <div className="card-title">Select Criteria</div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="month">
                            Month <span className="text-danger">*</span>
                          </label>
                          <select name="month1" id="month1" required="" className="form-control" value={user?.month1} onChange={handleChange}>
                            <option value="">Select Month</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBURARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="year">
                            Year <span className="text-danger">*</span>
                          </label>
                          <select name="year1" id="year1" required="" className="form-control" value={user?.year1} onChange={handleChange}>
                            <option value="">Select Year</option>
                            {sessionOpt &&
                              sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>{item?.name.substring(0,4)}</option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-1 my-4">
                        <button
                          className="btn btn-nex my-2"
                          name="submit"
                          type="submit"
                          value="staff"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-body">
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div></div>
                    <div>
                      <button
                        className="btn btn-primary rounded-pill mr-2"
                        onClick={onDownload}
                      >
                        Excel
                      </button>
                      <button
                        className="btn btn-primary rounded-pill"
                        onClick={handlePrint}
                      >
                        PDF
                      </button>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered" ref={tableRef}>
                        {/* <thead> */}
                          <tr><th style = {{display:"none"}} colSpan={7}>KALOL INSTITUTE AND RESEARCH CENTER</th></tr>
                        {/* </thead> */}
                        <thead>
                          <tr>
                        <th className="text-uppercase" colSpan={2}>
                          {empData.first_name} {empData.last_name}
                        </th>
                        <th className="text-uppercase" colSpan={1}>
                          Year - {user?.year1}
                        </th>
                        <th className="text-uppercase" colSpan={2}>
                          Month - {months.find((s) => s.key == user?.month1)?.name}
                        </th>
                        <th className="text-uppercase" colSpan={2}>
                          Present - {present}
                        </th>
                        <th className="text-uppercase" colSpan={2}>
                          Absent - {absent}
                        </th>
                        <th className="text-uppercase" colSpan={1}>
                          WO - {wo}
                        </th>
                        </tr>
                        <tr>
                          <th>Sl.No</th>
                          <th>Date</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                          <th>Over Time</th>
                          <th>Early Out</th>
                          <th>Late In</th>
                          <th>Work Time</th>
                          <th>Status</th>
                          <th>Remark</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && data.length ? data?.map((i,key) => (
                            <tr>
                                <td>{key+1}</td>
                                <td>{i?.DateString}</td>
                                <td>{i?.INTime}</td>
                                <td>{i?.OUTTime}</td>
                                <td>{i?.OverTime}</td>
                                <td>{i?.Erl_Out}</td>
                                <td>{i?.Late_In}</td>
                                <td>{i?.WorkTime}</td>
                                <td key={key}>
                                    <span className={`badge badge-soft-${i?.Status=="P"?"success":(i.Status=="A"?"danger":"secondary")}`} >
                                        {i.Status == 'P' ? "Present" : i.Status == "A" ? "Absent" : i.Status}
                                    </span>
                                </td>
                                <td>{i?.Remark}</td>
                            </tr>
                        )) : (
                            <tr>
                              <td colSpan={12}>
                                <NoData />
                              </td>
                            </tr>
                          )}
                            
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                     {/* {data ?  <div className="row mt-3">
                      <div className="col-md-8">
                        <h5>Staff Attendance Report</h5>
                      </div>

                      <div className="col-md-4 d-flex  justify-content-around">
                        <h6>
                          Present:<span className="text-success"> P </span>{" "}
                        </h6>
                        <h6>
                          Late:<span className="text-warning"> L </span>{" "}
                        </h6>
                        <h6>
                          Absent:<span className="text-danger"> A </span>{" "}
                        </h6>
                        <h6>
                          Holiday:<span className="text-secondary"> H</span>{" "}
                        </h6>
                      </div>

                      <div className="col-12">
                        <div className="tabel-responsive">
                          <table className='w-100'>
                            <thead>
                              <tr>
                                <th style={{ width: '100px' }} width="100px">Staff/Date</th>
                                <th style={{ width: '100px' }} width="20px" >%</th>
                                <th style={{ width: '100px' }} width="20px" >P</th>
                                <th style={{ width: '100px' }} width="20px" >L</th>
                                <th style={{ width: '100px' }} width="20px" >Action</th>
                              </tr>
                            </thead>
                            <tbody>

                              <tr>
                                <td>
                                  Anand
                                </td>
                                <td>
                                  <span className="badge badge-light"> 85%</span>
                                </td>
                                <td>13</td>
                                <td>33</td>
                                <td><button className="btn btn-primary btn-rounded">Export</button></td>
                              </tr>

                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div> : <Nodata /> } */}


                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default AttendanceMonthEmployee;