import axios from "axios";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ModalHolidayList from "../../../../modals/HR/Leave/ModalHolidayList";
import { LEAVE_HOLIDAY_LIST } from "../../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_HOLIDAY_TYPE,
} from "../../../../utils/LocalStorageConstants";
import Nodata from "../../../NoData/Nodata";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";

function LeaveHolidayList({ setLoading }) {
  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [htype, setHtype] = useState("");
  const [data, setData] = useState();
  const htypeOpt = JSON.parse(localStorage.getItem(LOCAL_HOLIDAY_TYPE));
  const [type, setType] = useState();
  const [edit, setEdit] = useState();

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const dept = [
    {
      title: "Holiday",
      code: "Custom",
    },
    {
      title: "Sunday",
      code: "Default",
    },
  ];

  const calcDiff = (a1, a2) => {
    console.log(a1, a2);
    const date1 = new Date(a1);
    const date2 = new Date(a2);
    const differenceInTime = date2.getTime() - date1.getTime();
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);
    return differenceInDays >= 0 ? differenceInDays + 1 : 0;
  };

  const getHolidayList = async (s) => {
    setHtype(s);
    setLoading(1);
    console.log(s);
    const config = {
      method: "get",
      url: `${LEAVE_HOLIDAY_LIST}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        // toast.success('Data fetched')
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getHolidayList();
  }, []);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: " Earning Policy List ",
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  //   const handlePrint = () => {
  //     PrintRecipt();
  //   };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  return (
    <div>
      <ModalHolidayList
        reloadData={(d) => getHolidayList(d)}
        type={type}
        data={edit}
        id={htype}
        setLoading={setLoading}
      />
      {/* <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">Select Holiday Type</h2>
                            <br />
                            <div className="row d-flex ">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="validationCustom01">
                                            Holiday Type<span style={{ color: "red" }}>*</span>
                                        </label>
                                        <select
                                            id="role"
                                            name="role"
                                            className="form-control"
                                            value={htype}
                                            onChange={(e) => { getHolidayList(e.target.value) }}
                                        >
                                            <option value="" selected>All</option>
                                            {
                                                htypeOpt?.map((i,key)=>(
                                                    <option value={i.id}>{i.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                htype!==''
                                ?
                                <div className="row ">
                                    <div className="col-md-12 ml-auto">
                                        <button
                                            className="btn btn-nex btn-rounded "
                                            data-toggle="modal"
                                            data-target="#ModalHolidayList"
                                            onClick={() => { setType("add"); setEdit() }}
                                        >
                                            + Add New
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="text-danger">
                                    to add New Holiday select perticular holiday type
                                </div>

                            }

                        </div>
                    </div>
                </div>
            </div> */}

      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row ">
              <div className="col-md-12 ml-auto d-flex justify-content-end mb-3">
                <button
                  className="btn btn-nex btn-rounded "
                  data-toggle="modal"
                  data-target="#ModalHolidayList"
                  onClick={() => {
                    setType("add");
                    setEdit();
                  }}
                >
                  + Add New
                </button>
                <button
                  className="btn btn-nex rounded-pill ml-3 float-right"
                  onClick={onDownload}
                >
                  Export Excel
                </button>
                <button
                  className="btn btn-nex rounded-pill ml-2"
                  onClick={handlePrint}
                >
                  Export PDF
                </button>
              </div>
            </div>

            <div>
              {data && data?.length !== 0 ? (
                data?.map((i, key) => (
                  <div className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow">
                    <div className="col-6" key={key}>
                      <div className="role-code">
                        <h5>{i.name}</h5>
                        {i.number_of_days == 1
                          ? `${i.number_of_days} day`
                          : `${i.number_of_days} days`}
                      </div>
                      {/* <p>Description : {i.description}</p> */}
                      <p>
                        <b></b>
                      </p>
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <b>{formatDate(i.from_date.split("T")[0])}</b>
                        </div>
                        <div
                          style={{ fontSize: "18px" }}
                          className="d-flex align-items-center"
                        >
                          &nbsp;&nbsp; &rarr; &nbsp;&nbsp;
                        </div>
                        <div className="d-flex align-items-center">
                          <b>{formatDate(i.to_date.split("T")[0])}</b>
                        </div>
                      </div>
                    </div>
                    <div className="col-3"></div>
                    <div className="col-3 d-flex align-items-center justify-content-end">
                      <button
                        data-toggle="modal"
                        data-target="#ModalHolidayList"
                        onClick={() => {
                          setType("edit");
                          setEdit(i);
                        }}
                        className="btn btn-nex"
                      >
                        <i className="ri-pencil-fill"></i>
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <Nodata titleTop={"No data available please add data"} />
              )}
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <table
            id="table_id"
            ref={tableRef}
            className="display table table-bordered  nowrap table-hover "
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
              width: "100%",
              display: "none",
            }}
          >
            <div className="col-12 text-center">
              {/* {data?.length > 0 && (
                                            <div>
                                              <h4 className="text-center">
                                                Leave Holiday List -
                                                {data[0]?.college_id && collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
                                              </h4>
                                              <span> <p className="text-right float-right"> Printed On - {" " + getCurrentDate()} </p></span>
                                            </div>
                                          )} */}
              <thead>
                <tr>
                  <td colSpan={10}>
                    <div>
                      <h4 className="text-center">
                        Leave Holiday List -{" "}
                        {data?.length > 0 &&
                          data[0]?.college_id &&
                          collegeOpt?.find((s) => s.id === data[0]?.college_id)
                            ?.name}
                      </h4>
                      <span>
                        <p className="text-right float-right">
                          Printed On - {getCurrentDate()}
                        </p>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Sl.No.</th>
                  <th>Holiday on</th>
                  <th>Holiday Type</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {data && data?.length == 0 ? (
                  <tr>
                    <td colSpan={10}>
                      <Nodata />
                    </td>
                  </tr>
                ) : (
                  data?.map((i, key) => {
                    return (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{i?.holiday_type_id}</td>
                        <td>{i?.name}</td>
                        <td>{formatDate(i?.from_date.split("T")[0])}</td>
                        <td>{formatDate(i?.to_date.split("T")[0])}</td>
                        <td>{i?.description}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </div>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LeaveHolidayList;
