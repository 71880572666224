import React, { useState, useEffect } from "react";
import {
  ACADEMICS_ADD_SUBJECT,
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SECTION,
  ACADEMICS_ADD_SEMESTER,
} from "../../utils/Academics.apiConst";
import {
  STUDENT_SESSION,
  STUDENT_COURSE_REGISTRATION,
} from "../../utils/apiConstants";
import { SessionOpt } from "../../Data/student/sessionData";
import axios from "axios";
import { toast } from "react-toastify";
import {
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import Nodata from "../../Components/NoData/Nodata";
import Select from "react-select";

function CourseRegistration({ setLoading, collegeId }) {
  const [session, setSession] = useState("");
  const [classOpt, setClassOpt] = useState();
  const [semOpt, setSemtOpt] = useState([]);
  const [sectionOpt, setSectionOpt] = useState();
  const [subjectOpt, setSubjectOpt] = useState([]);
  const [selectedSubjectOpt, setSelectedSubjectOpt] = useState([]);

  const [promotes, setPromotes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(() => {
    setDepartmentOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == collegeId
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), , collegeId]);

  //Fucntion to get data of classes
  const getClassData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    const [data1, data2, data4, data3] = await Promise.all([
      axios({
        ...config,
        url: ACADEMICS_ADD_SEMESTER + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setSemtOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
      axios({
        ...config,
        url: ACADEMICS_ADD_SUBJECT + `?college_id=${collegeId}&getInactive=0`,
      })
        .then((res) => {
          console.log("subjects - ", res.data.data);
          setSubjectOpt(res.data.data);
          setLoading(0);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
      axios({
        ...config,
        url: ACADEMICS_ADD_CLASS + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
      axios({
        ...config,
        url: ACADEMICS_ADD_SECTION + `?college_id=${collegeId}`,
      })
        .then((res) => {
          setSectionOpt(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Something went wrong");
        }),
    ]);
  };

  const [data, setData] = useState([]);

  const [user, setUser] = useState({
    class_id: "",
    department_id: "",
    semester_id: "",
    section_id: "",
    session_id: "",
  });

  const clearData = () => {
    setUser({
      ...user,
      class_id: "",
      department_id: "",
      semester_id: "",
      section_id: "",
      session_id: "",
    });
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setUser((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target || e;

    if (name === "department_id") {
      // Reset class, semester, and section when department changes
      setUser({
        ...user,
        department_id: value,
        class_id: "",
        semester_id: "",
        section_id: "",
        course_id: "",
      });
      setSelectedSubjectOpt([]);
    } else if (name === "class_id") {
      // Reset semester and section when class changes
      setUser({
        ...user,
        class_id: value,
        semester_id: "",
        section_id: "",
        course_id: "",
      });
      setSelectedSubjectOpt([]);
    } else if (name === "semester_id") {
      // Reset section when semester changes
      setUser({
        ...user,
        semester_id: value,
        section_id: "",
        course_id: "",
      });
      setSelectedSubjectOpt(subjectOpt.filter((s) => s.semester_id == value));
    } else {
      // General case for other inputs
      setUser({
        ...user,
        [name]: value,
      });
    }
  };

  const getStudentData = async () => {
    if (
      !user.session_id ||
      !user.semester_id ||
      !user.class_id ||
      !user.department_id
    ) {
      toast.error("Please Enter Required Details");
      return;
    }

    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url:
        STUDENT_SESSION +
        `?session_id=${user.session_id}&section_id=${user?.section_id}&semester_id=${user?.semester_id}&class_id=${user?.class_id}&department_id=${user?.department_id}&status=ACTIVE`,
    };

    await axios(config)
      .then((res) => {
        let tempData = res.data.data;
        for (let i in tempData) {
          if (tempData[i].courses) {
            let c = 0,
              total = 0;
            let jsonCourseData = JSON.parse(tempData[i].courses);
            for (let j in jsonCourseData) {
              if (jsonCourseData[j].status == "ACTIVE") {
                c =
                  c +
                  (parseInt(
                    subjectOpt?.find((s) => s.id == jsonCourseData[j].course_id)
                      .credit
                  )
                    ? parseInt(
                        subjectOpt?.find(
                          (s) => s.id == jsonCourseData[j].course_id
                        ).credit
                      )
                    : 0);
                total = total + 1;
              }
            }
            tempData[i].credits = c;
            tempData[i].courses = jsonCourseData;
            tempData[i].total_subjects = total;
          } else {
            tempData[i].credits = 0;
            tempData[i].courses = [];
            tempData[i].total_subjects = 0;
          }
        }
        setData(tempData);
        console.log("student data - ", tempData);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
    setLoading(0);
  };

  const handleSubmit = async (student) => {
    let coursesArr = student.courses;

    if (coursesArr.find((s) => s.course_id == user?.course_id)) {
      coursesArr.find((s) => s.course_id == user?.course_id).status = "ACTIVE";
      coursesArr.find((s) => s.course_id == user?.course_id).updatedAt =
        new Date()?.toISOString().slice(0, 19).replace("T", " ");
    } else {
      let obj = {
        course_id: user?.course_id,
        status: "ACTIVE",
        createdAt: new Date()?.toISOString().slice(0, 19).replace("T", " "),
        updatedAt: new Date()?.toISOString().slice(0, 19).replace("T", " "),
      };

      coursesArr.push(obj);
    }
    console.log("courses array - ", JSON.stringify(coursesArr));
    let coursesString = JSON.stringify(coursesArr);
    const config = {
      method: "put",
      url: STUDENT_COURSE_REGISTRATION + `/${student.student_semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        courses: coursesString,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        getStudentData();
        toast.success("Course Registration successful");
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const unregister = async (student) => {
    let coursesArr = student.courses;

    if (coursesArr.find((s) => s.course_id == user?.course_id)) {
      coursesArr.find((s) => s.course_id == user?.course_id).status =
        "INACTIVE";
      coursesArr.find((s) => s.course_id == user?.course_id).updatedAt =
        new Date()?.toISOString().slice(0, 19).replace("T", " ");
    } else {
      return toast.success("Course is not registered");
    }
    console.log("courses array - ", JSON.stringify(coursesArr));
    let coursesString = JSON.stringify(coursesArr);
    const config = {
      method: "put",
      url: STUDENT_COURSE_REGISTRATION + `/${student.student_semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        courses: coursesString,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        getStudentData();
        toast.success("Course Removal successful");
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const registerMultipleStudents = async () => {
    if (promotes.length == 0) {
      return toast.error("No Students Selected");
    }
    promotes?.map((item, index) => {
      handleSubmit(item);
    });
    setPromotes([]);
  };

  const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    console.log("data - ", data);
    const updatedPromotes = data.filter(
      (i) =>
        !(
          i?.courses?.length > 0 &&
          i?.courses?.find((s) => s.course_id == user?.course_id) &&
          i?.courses?.find((s) => s.course_id == user?.course_id).status ==
            "ACTIVE"
        )
    );
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

  const togglePromotion = (student, sem_id) => {
    const isPromoted = promotes?.some(
      (student) => student.student_semester_id === sem_id
    );

    if (isPromoted) {
      setPromotes((prevPromotes) =>
        prevPromotes?.filter(
          (student) => student.student_semester_id !== sem_id
        )
      );
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, student]);
    }
  };

  useEffect(() => {
    console.log("filtered subjects - ", selectedSubjectOpt);
  }, [selectedSubjectOpt]);

  useEffect(() => {
    console.log("promotes - ", promotes);
  }, [promotes]);

  useEffect(() => {
    getClassData();
  }, []);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Course Registration</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Academics</a>
                      </li>
                      <li className="breadcrumb-item active">
                        Course Registration
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="">Search Criteria</h5>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Select Department
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            id="class"
                            className="form-control"
                            value={user.department_id}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                department_id: e.target.value,
                              }));
                            }}
                          >
                            <option value="">Select Department</option>
                            {departmentOpt?.map((i, key) => (
                              <option key={key} value={i.id}>
                                {i.name},{" "}
                                {
                                  programOpt?.find((s) => s.id == i.program_id)
                                    .name
                                }
                              </option>
                            ))}
                          </select> */}

                          <Select
                            value={
                              user?.department_id
                                ? {
                                    value: user?.department_id,
                                    label: departmentOpt.find(
                                      (data) => data.id == user?.department_id
                                    )?.name,
                                  }
                                : null
                            }
                            // onChange={(selectedOption) => {
                            //   setUser((prev) => ({
                            //     ...prev,
                            //     department_id: selectedOption
                            //       ? selectedOption.value
                            //       : "",
                            //   }));
                            // }}
                            onChange={(selectedOption) => {
                              handleChange({
                                name: "department_id",
                                value: selectedOption
                                  ? selectedOption.value
                                  : "",
                              });
                            }}
                            options={departmentOpt.map((i) => ({
                              value: i.id,
                              label: `${i.name}, ${
                                programOpt?.find((s) => s.id === i.program_id)
                                  ?.name
                              }`,
                            }))}
                            placeholder="Select Department"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Academic Year<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="session_id"
                            id="class"
                            value={user?.session_id}
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: "session_id",
                                  value: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="">Select Academic</option>
                            {SessionOpt.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Class<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="class_id"
                            id="class"
                            value={user.class_id}
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: "class_id",
                                  value: e.target.value,
                                },
                              });
                            }}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s?.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Semester<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="semester_id"
                            id="class"
                            value={user.semester_id}
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  name: "semester_id",
                                  value: e.target.value,
                                },
                              });
                              setSelectedSubjectOpt(
                                subjectOpt?.filter(
                                  (s) => s.semester_id == e.target.value
                                )
                              );
                              console.log("sem id - ", e.target.value);
                              console.log("subjects - ", subjectOpt);
                            }}
                          >
                            <option value="">Select Semester</option>
                            {semOpt
                              ?.filter((s) => s?.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                            {/* {
                                classOpt?.map((i, key) => (
                                  <option value={i.id}>{i.name}</option>
                                ))
                              } */}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">Section</label>
                          <select
                            className="form-control"
                            name="section_id"
                            id="class"
                            value={user?.section_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Section</option>
                            {sectionOpt
                              ?.filter(
                                (s) =>
                                  s.department_id == user.department_id &&
                                  s.semester_id == user.semester_id
                              )
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Subject<span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            id="course_id"
                            className="form-control"
                            value={user.course_id}
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedLessonOpt(
                                lessonOpt.filter(
                                  (item) => item.course_id == e.target.value
                                )
                              );
                            }}
                            name="course_id"
                          >
                            <option value=""> Select Subject</option>
                            {selectedSubjectOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>{" "} */}

                          <Select
                            value={
                              user?.course_id
                                ? {
                                    value: user?.course_id,
                                    label: selectedSubjectOpt?.find(
                                      (data) => data.id === user?.course_id
                                    )?.name,
                                  }
                                : null
                            }
                            onChange={(selectedOption) => {
                              handleChange({
                                target: {
                                  name: "course_id",
                                  value: selectedOption.value,
                                },
                              });
                            }}
                            options={selectedSubjectOpt.map((i) => ({
                              value: i.id,
                              label: i.name,
                            }))}
                            placeholder="Select Subject"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ml-auto">
                        <button
                          className="btn btn-nex btn-rounded float-lg-right mx-1 "
                          type="submit"
                          name="submit"
                          onClick={getStudentData}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        {" "}
                        <h5 className="">
                          Students - {data ? data?.length : 0}
                        </h5>
                      </div>
                      {/* <div className="col-md-4">
                        {" "}
                        <h5 className="ml-4">Course : {subjectOpt?.find((s) => s.id == user?.course_id)?.name}</h5>
                      </div>
                      <div className="col-md-4">
                        {" "}
                        <h5 className="float-right mr-3">Credits : {subjectOpt?.find((s) => s.id == user?.course_id)?.credit}</h5>
                      </div> */}
                    </div>
                    {/* <hr /> */}
                    <table
                      id="datatable"
                      className="table dt-responsive nowrap table-hover "
                      style={{
                        borderCollapse: "collapse",
                        borderSpacing: 0,
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              id="select-all"
                              checked={selectAllChecked}
                              onChange={toggleSelectAll}
                            />
                          </th>
                          <th colSpan={10}>Register All</th>
                          <th>
                            {
                              <button
                                className="btn btn-success ml-2"
                                onClick={registerMultipleStudents}
                              >
                                Register
                              </button>
                            }
                          </th>
                        </tr>
                      </thead>
                      <thead>
                        <tr>
                          <th colSpan={2}>Sl. No.</th>
                          <th>Name</th>
                          <th>Class</th>
                          <th>Semester</th>
                          <th>Total Subjects</th>
                          <th>Total Credits</th>
                          <th>Course</th>
                          <th>Course Type</th>
                          <th>Type</th>
                          <th>Status</th>
                          {sessionStorage.getItem("role") != "STAFF" ||
                          sessionStorage.getItem("role") != "SUPERADMIN" ? (
                            <th className="text-center">Action</th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((i, k) => (
                            <tr>
                              <td>
                                {i?.courses?.length > 0 &&
                                i?.courses?.find(
                                  (s) => s.course_id == user?.course_id
                                ) &&
                                i?.courses?.find(
                                  (s) => s.course_id == user?.course_id
                                ).status == "ACTIVE" ? (
                                  <input
                                    type="checkbox"
                                    name="checkbox[]"
                                    checked={false}
                                    disabled={true}
                                  />
                                ) : (
                                  <input
                                    type="checkbox"
                                    name="checkbox[]"
                                    //   value={d.user_id}
                                    checked={promotes?.some(
                                      (student) =>
                                        student.student_semester_id ===
                                        i.student_semester_id
                                    )}
                                    onChange={() =>
                                      togglePromotion(i, i.student_semester_id)
                                    }
                                  />
                                )}
                              </td>
                              <td>{k + 1}</td>
                              <td>{i?.name}</td>
                              <td>{i?.class_name}</td>
                              <td>{i?.semester}</td>
                              <td>{i?.total_subjects}</td>
                              <td>{i?.credits}</td>
                              <td>
                                {
                                  subjectOpt?.find(
                                    (s) => s.id == user?.course_id
                                  )?.name
                                }
                              </td>
                              <td>
                                {
                                  subjectOpt?.find(
                                    (s) => s.id == user?.course_id
                                  )?.type
                                }
                              </td>
                              <td>
                                {subjectOpt?.find(
                                  (s) => s.id == user?.course_id
                                )?.is_open_elective == 1
                                  ? "OPEN ELECTIVE"
                                  : subjectOpt?.find(
                                      (s) => s.id == user?.course_id
                                    )?.is_open_elective == 1
                                  ? "ELECTIVE"
                                  : "CORE"}
                              </td>
                              <td>
                                {i?.courses?.length > 0 &&
                                i?.courses?.find(
                                  (s) => s.course_id == user?.course_id
                                ) &&
                                i?.courses?.find(
                                  (s) => s.course_id == user?.course_id
                                ).status == "ACTIVE" ? (
                                  <span className="badge badge-soft-success">
                                    Registered
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-danger">
                                    Not Registered
                                  </span>
                                )}
                              </td>
                              <td className="text-center">
                                {i?.courses?.length > 0 &&
                                i?.courses?.find(
                                  (s) => s.course_id == user?.course_id
                                ) &&
                                i?.courses?.find(
                                  (s) => s.course_id == user?.course_id
                                ).status == "ACTIVE" ? (
                                  <>
                                    <span
                                      className="badge badge-light mx-2"
                                      title="Remove registered course"
                                      data-toggle="tooltip"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        unregister(i);
                                      }}
                                    >
                                      <i
                                        className="fa fa-trash text-danger "
                                        style={{ fontSize: "15px" }}
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </>
                                ) : (
                                  <button
                                    className="btn-success btn mx-2"
                                    disabled={
                                      selectAllChecked || promotes?.length > 0
                                    }
                                    onClick={(e) => {
                                      handleSubmit(i);
                                    }}
                                  >
                                    Register
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {data?.length == 0 ? <Nodata /> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseRegistration;
