import axios from "axios";
import { BASE_URL } from "./apiConstants";

/**
 * @typedef {Object} UniversityDetails
 * @property {string} address
 * @property {string} admin_email
 * @property {string} admission_email
 * @property {string} admission_url
 * @property {string} banner_img_1
 * @property {string} banner_img_2
 * @property {string} banner_img_3
 * @property {string} city
 * @property {string} country
 * @property {string} dev_email
 * @property {string} email
 * @property {string} exams_url
 * @property {string} favicon
 * @property {string} id
 * @property {string} logo_primary
 * @property {string} logo_secondary
 * @property {string} name
 * @property {string} no_of_colleges
 * @property {string} noreply_email
 * @property {string} phone
 * @property {string} pin_code
 * @property {string} provost_email
 * @property {string} registrar_email
 * @property {string} server_url
 * @property {string} state
 * @property {string} superadmin_email
 * @property {string} ums_url
 * @property {string} university_url
 */

/**
 * Get details of the university.
 * @returns {Promise<UniversityDetails>} University details object.
 */
export default async function getUniversityDetails() {
  const config = {
    method: 'get',
    url: `${BASE_URL}/api/university`
  };

  try {
    const response = await axios(config);
    console.log(response.data[0]);
    return response.data[0];
  } catch (error) {
    console.error(error);
    throw error;
  }
}
