import React, { useState, useRef, useEffect } from 'react'
import { PROGRAM } from '../../../../utils/apiConstants';
import axios from 'axios'
import ModalProgram from '../../../../modals/HR/Employer/ModalProgram';
import './../Employer.scss'
import Nodata from '../../../../Components/NoData/Nodata';
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";

function EmployeeProgram({setLoading}) {
    const [data, setData] = useState(
        [
            {
                name: 'UG',
                id: 'UG'
            },
            {
                name: 'PG',
                id: 'PG'
            },
            {
                name: 'PHD',
                id: 'PHD'
            },
            {
                name: 'DIPLOMA',
                id: 'DIP'
            },
        ]
    );
    const [type, setType] = useState()
    const [edit, setEdit] = useState();

   

    const getData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: PROGRAM,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
        };

        await axios(config)
            .then((res) => {
                setLoading(0)
                console.log(res.data.data);
                setData(res.data.data)
            })
            .catch(err => {
                setLoading(0)
                console.log(err)})

    }

    useEffect(() => {
          getData();
    }, [])

    const tableRef = useRef();

    const { onDownload } = useDownloadExcel({
      currentTableRef: tableRef.current,
      filename: " Programs_List ",
      sheet: "Users",
    });

    return (
        <div className='EmployeeProgram'>
            <div className="main-content">
                <ModalProgram reloadData={getData} type={type} data={edit} setLoading={setLoading}/>
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Program</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Employer</li>
                                            <li className="breadcrumb-item active">Program</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row d-flex justify-content-end p-3">
                                        <button
                                            onClick={() => { setType("add"); setEdit() }}
                                            className="btn btn-rounded btn-success btn-outline px-4"
                                            data-toggle="modal"
                                            data-target="#ModalProgram"
                                        >
                                            Add +
                                        </button>
                                        <span>
                                        <button
                                            className="btn btn-primary rounded-pill ml-1"
                                            onClick={onDownload}
                                        >
                                            Export Excel
                                        </button>
                                        </span>
                                    </div>

                                    <table
                                      id="table_id"
                                      ref={tableRef}
                                      className="display table table-bordered  nowrap table-hover "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" , display: 'none'}}
                                    >
                                        <div><h4> Programs List </h4> 
                                        <thead>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th> Program Name </th>
                                                <th> Program Code </th>
                                                <th> Remark </th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ?
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                          :
                                          data
                                          .map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{i.name}</td>
                                              <td>{i.id}</td>
                                              <td>{i.description}</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              </tr>
                                          })
                                        }
                                        </tbody>
                                        </div>
                                    </table>
                                    

                                    <div className="row">
                                        {data && data.length !== 0 ? data.map((i, key) => (
                                            <div
                                                className="col-md-3"
                                                onClick={() => { setType("edit"); setEdit(i); }}
                                                data-toggle="modal"
                                                data-target="#ModalProgram"
                                            >
                                                <div className="card">
                                                    <div className="card-body">
                                                    <div className="col-11" key={key}>
                                                        <div className="role-title">
                                                            {i.name}
                                                        </div>
                                                        <div className="role-code">
                                                            {i.id}
                                                            <div className="d-flex align-items-center justify-content-end">
                                                                {'>'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) :
                                        <div className='mt-3'>
                                            <Nodata titleTop={'No data available for your search'}/>
                                        </div>
                                        }
                                    </div>
                                    {/* <div ref={tableRef}></div> */}
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default EmployeeProgram;