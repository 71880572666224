import React,{useState,useEffect} from "react"
import {
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import "../Students/StudentProfile/StudentProfile.scss";
import { Http } from "../../Services/Services.js";
import { CREATE_PROCTORING_MEETING, GET_PROCTEE_BY_PROCTORING_ID } from "../../utils/apiConstants.js";
import Avatar from "react-avatar";
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM } from "../../utils/LocalStorageConstants.js";
import { ROUTES } from "../../Router/routerConfig.js";
import { toast } from "react-toastify";

function StudentProfile({ setLoading, collegeId }) {

  const {proctoring_details_id} = useParams();

  console.log("proctoring_details_id -",proctoring_details_id);

  const location = useLocation();

  const navigate = useNavigate();

  const role = sessionStorage.getItem("role");

  const [studentData , setStudentData] = useState({})
  const [proctoringData, setProctoringData] = useState({
    agenda: "",
    date: "",
    issue_discussed: "",
    stress_level:"",
    proctee_opinion:"",
    proctor_opinion:"",
    other_remarks:""
  });

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setProctoringData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = async () =>{
    setLoading(1)
    const {agenda, date} = proctoringData;
    if(!agenda || !date){
      toast.error("Mandotary Fields Are Required")
    }

    await Http.post(
      `${CREATE_PROCTORING_MEETING}`,
      {
        ...proctoringData,
        proctoring_details_id
      }
    ).then((data)=>{
      toast.success("Proctoring Meeting Created Successfully")
      (role == "STAFF") ? navigate(ROUTES.Employee.ProcteeList) : navigate(ROUTES.Proctoring.MyProcteeList)
    }).catch((err)=>{
      toast.error(err.response.data.message)
    }).finally(()=>{
      setLoading(0)
    });


  }
  


  const getData = async () => {
    setLoading(1)
    await Http.get(
      `${GET_PROCTEE_BY_PROCTORING_ID}/${proctoring_details_id}`
    ).then((res) => {
      console.log("DATA")
      setStudentData(res.data.data)
    }).finally(()=>setLoading(0));

  };

  const localDepartments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));
  const localPrograms = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const localColleges = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));





  useEffect(() => {
    console.log("Hello");
    getData();
  }, []);

  return (
    <>
      <div className="StudentProfile">
        <>

        <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Proctoring Meeting Schedule</h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Proctoring</a>
                          </li>
                          
                          <li className="breadcrumb-item active">
                            {" "}
                            Create Proctoring Meeting
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page title */}
                <div>
                  <hr />
                  <h3 className="text-center">Create Proctoring Meeting</h3>
                  <hr />
                </div>

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="box box-primary">
                        <div className="box-body box-profile">
                          <div className="card py-2">
                            <ul className="list-group list-group-unbordered pt-3">
                              {studentData?.student_picture ? (
                                <img
                                  className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                                  src={studentData?.student_picture}
                                  width="50%"
                                  style={{ aspectRatio: "1/1" }}
                                />
                              ) : (
                                <Avatar
                                className="mx-auto"
                                  name={studentData?.name}
                                  round={true}
                                />
                              )}

                              <br />
                              <h5 className="profile-username text-center">
                              {studentData?.name}
                              </h5>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Enrollment No. :</b>{" "}
                                <a className="float-right text-aqua">
                                  {studentData?.user_id}
                                </a>
                              </li>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Phone :</b>{" "}
                                <a
                                  className="float-right text-aqua"
                                  href="tel:{studentData?.phone}"
                                >
                                  {studentData?.phone}
                                </a>
                              </li>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Email :</b>{" "}
                                <a
                                  className="float-right text-aqua"
                                  href={`mailto:${studentData?.email}`}
                                >
                                  {studentData?.email}
                                </a>
                              </li>
                            

                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Program :</b>{" "}
                                <a className="float-right text-aqua">
                                  {
                                    localPrograms?.find(
                                      (s) => s?.id == studentData?.program_id
                                    )?.name
                                  }
                                </a>
                              </li>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>College :</b>{" "}
                                <a className="float-right text-aqua">
                                  {
                                    localColleges?.find(
                                      (s) => s?.id == studentData?.college_id
                                    )?.name
                                  }
                                </a>
                              </li>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Department :</b>{" "}
                                <a className="float-right text-aqua">
                                  {
                                    localDepartments?.find(
                                      (s) =>
                                        s?.id == studentData?.department_id
                                    )?.name
                                  }
                                </a>
                              </li>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Gender :</b>{" "}
                                <a className="float-right text-aqua">
                                  {studentData?.gender}
                                </a>
                              </li>
                              <li className="list-group-item listnoback d-flex justify-content-between">
                                <b>Admission Date :</b>{" "}
                                <a className="float-right text-aqua">
                                  {studentData?.createdAt?.split("T")[1]}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                      <div className="card ">
                        <div className="card-body">
                          
                        <div className="row">
                          
                            
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Agenda</label>
                                  <input type="text" className="form-control" name="agenda" onChange={handleChange}/>
                                </div>
                              </div>


                              <div className="col-md-6"><div className="form-group">
                                <label>Date</label>
                                <input type="date" className="form-control" name="date" onChange={handleChange}/>
                              </div>
                              </div>
                              
                              <div className="col-md-6"><div className="form-group">
                                <label>Issue Discussed</label>
                                <input type="text" className="form-control" name="issue_discussed" onChange={handleChange}/>
                              </div>
                              </div>

                              <div className="col-md-6"><div className="form-group">
                                <label>Stress Level</label>
                                <select name="stress_level" className="form-control" onChange={handleChange}>
                                  <option value="" disabled selected>Select Option</option>
                                  <option value="MILD">MILD</option>
                                  <option value="MODERATE">MODERATE</option>
                                  <option value="SEVERE">SEVERE</option>
                                  <option value="NO STRESS">NO STRESS</option>
                                </select>
                              </div>
                              </div>

                              <div className="col-md-6"><div className="form-group">
                                <label>Proctee Opinion</label>
                                <input type="text" className="form-control" name="proctee_opinion" onChange={handleChange}/>
                              </div>
                              </div>

                              <div className="col-md-6"><div className="form-group">
                                <label>Procter Opinion</label>
                                <input type="text" className="form-control" name="proctor_opinion" onChange={handleChange}/>
                              </div>
                              </div>

                              <div className="col-md-12"><div className="form-group">
                                <label>Other Remarks</label>
                                <input type="text" className="form-control" name="other_remarks" onChange={handleChange}/>
                              </div>
                              </div>
                              
                            
                          </div>

                          <div className="text-right">
                            
                            <button className="btn btn-success" onClick={handleSubmit}>
                                Save
                            </button>

                          </div>
                        

                        </div>
                      </div>
                    </div>                                         
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}

            <div className="rightbar-overlay" />
          </div>
          
        </>
      </div>
    </>
  );
}

export default StudentProfile;
