import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { EMPLOYEE_RESEND_CREDENTIALS } from "../../../utils/apiConstants";
import { LOCAL_JOBROLES } from "../../../utils/LocalStorageConstants";
import { HR_ASSIGN_WORKFLOW } from "../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
// import "G../../../modals/HR/Leave/TableScroll.css";

function ModalResendCredentials({
  empData,
  displayData,
  setDisplayData,
  setLoading,
}) {
  const [collegeOpt, setCollegeOpt] = useState(
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null
  );

  const [promotes, setPromotes] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [searchName, setSearchName] = useState("");

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

  const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    const updatedPromotes = displayData?.map((d) => ({
      id: d.id,
      data: d,
    }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

  const resendCredentials = async () => {
    setLoading(1);
  };

  const togglePromotion = (id, data) => {
    const isPromoted = promotes?.some((student) => student.id === id);

    if (isPromoted) {
      setPromotes((prevPromotes) =>
        prevPromotes?.filter((student) => student.id !== id)
      );
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, { id, data }]);
    }
  };

  const assignAll = async () => {
    if (promotes.length == 0) {
      return toast.error("Please Select Employees");
    }

    setLoading(1);
    let cnt = 0;
    let d = [];
    for (let i of promotes) {
      const obj = {
        user_id: i?.data?.user_id,
        first_name: i?.data?.first_name,
        last_name: i?.data?.last_name,
      };
      d.push(obj);
    }
    console.log("data to backend - ", d);
    const config = {
      method: "post",
      url: `${EMPLOYEE_RESEND_CREDENTIALS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: { users: d },
    };

    await axios(config)
      .then(async (res) => {
        setLoading(0);
        console.log("count - ", res.data.data);
        if (res.data.data == 0) {
          toast.error("Sent Credentials to 0 employees");
        } else if (res.data.data == 1) {
          toast.success("Sent Credentials to 1 employee");
        } else {
          toast.success(`Sent crdentials to ${res.data.data} employees`);
        }
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Internal Server Error");
        console.log(err);
      });
    setLoading(0);
    setPromotes([]);
    "#ModalResendCredentials".modal("hide");
  };

  const assign = async (i) => {
    let cnt = 0;
    let d = [];
    const obj = {
      user_id: i?.user_id,
      first_name: i?.first_name,
      last_name: i?.last_name,
    };
    d.push(obj);
    console.log("data to backend - ", d);
    const config = {
      method: "post",
      url: `${EMPLOYEE_RESEND_CREDENTIALS}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
      data: { users: d },
    };

    setLoading(1);
    await axios(config)
      .then(async (res) => {
        setLoading(0);
        if (res.data.data > 0)
          toast.success(`Sent credentials to ${i?.first_name} ${i?.last_name}`);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Internal Server Error");
        console.log(err);
      });
    setLoading(0);
    "#ModalResendCredentials".modal("hide");
  };

  useEffect(() => {
    console.log("promotes - ", promotes);
  }, [promotes]);

  useEffect(() => {
    let filteredData = [];
    filteredData = empData?.filter(
      (item) =>
        item.first_name.toLowerCase().includes(searchName.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchName.toLowerCase()) ||
        `${item.first_name} ${item.last_name}`
          .toLowerCase()
          .includes(searchName.toLowerCase())
    );
    setDisplayData(filteredData);
  }, [searchName]);

  return (
    <div className="ModalResendCredentials">
      <div className="ModalEarningPolicy">
        <div
          className="modal fade"
          id="ModalResendCredentials"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered mw-100 w-75"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Send ERP Credentials to Employees
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <label>Search By Employee Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search By Employee Name"
                      value={searchName}
                      onChange={(e) => {
                        setSearchName(e.target.value);
                      }}
                    />
                  </div>
                  <br />
                  <div className="col-md-12 mt-3">
                    <label>Selected ({promotes.length}) : </label>
                    <button
                      className="btn btn-success ml-4"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={assignAll}
                    >
                      Send
                    </button>
                  </div>
                </div>
                <div className="table-responsive mt-3 fixTableHead">
                  <table className="table table-bordered" style={{}}>
                    <thead className="">
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            id="select-all"
                            checked={selectAllChecked}
                            onChange={toggleSelectAll}
                          />
                        </th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayData ? (
                        displayData?.map((i, k) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  type="checkbox"
                                  name="checkbox[]"
                                  value={i.id}
                                  checked={promotes?.some(
                                    (staff) => staff.id === i.id
                                  )}
                                  onChange={() => togglePromotion(i.id, i)}
                                />
                              </td>
                              <td>{`${i.first_name} ${i.last_name}`}</td>
                              <td>{i?.role}</td>
                              <td>{i?.email}</td>
                              <td>
                                <button
                                  className="btn btn-success ml-2"
                                  //   data-dismiss="modal"
                                  //   aria-label="Close"
                                  onClick={() => assign(i)}
                                  disabled={promotes?.length > 0}
                                >
                                  Send
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <>Loading Employees...</>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="row d-flex justify-content-between px-2">
                  <button
                    className="btn btn-danger btn-rounded btn-outline ml-3 mt-3"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalResendCredentials;
