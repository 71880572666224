import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../Components/NoData/Nodata";
import Select from "react-select";
import {ACADEMICS_ADD_BLOOMS_LEVELS} from "../../utils/Academics.apiConst";

function AddBloomsLevels({ collegeId, setLoading }) {

    const [data, setData] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);

    const [user, setUser] = useState({
        name: "",
        weightage: "",
        level: "",
        status: "ACTIVE",
    });
    
    const clearData = () => {
    setUser({
        level:"",
        name: "",
        weightage: "",
        status:"ACTIVE"
    });
    };

    const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
        ...prev,
        [name]: value,
    }));
    };

    const getAllData = async () => {
        setLoading(1);
        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: ACADEMICS_ADD_BLOOMS_LEVELS
        };

        axios(config)
        .then((res) => {
            console.log('levels data - ', res.data.data);
            setData(res.data.data);
            setLoading(0);
        })
        .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
        })
    }

    const handleSubmit = async () => {

        if(!user.name) {
            return toast.error("Please Enter name of the Level")
        }
        console.log('data to backend - ', user);
        setLoading(1);
        const config = {
          method: "post",
          url: ACADEMICS_ADD_BLOOMS_LEVELS,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: user,
        };
    
        await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Taxonomy Level Added");
            clearData();
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
    };

    const handleEdit = async () => {

        if(!user.name) return toast.error("Please Enter Name of the Taxonomy");
        setLoading(1);
        const config = {
          method: "put",
          url: ACADEMICS_ADD_BLOOMS_LEVELS + `/${user.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: user,
        };
    
        await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Taxonomy Updated");
            setEdit(false);
            setAddNew(true);
            clearData();
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
      };

      const handleDelete = async (i) => {
        
        setLoading(1);
        const config = {
          method: "put",
          url: `${ACADEMICS_ADD_BLOOMS_LEVELS}/${i?.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            status: "INACTIVE",
          },
        };
        axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Level Deleted");
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            toast.error("Some Error Occured");
          });
      };

    useEffect(() => {
        getAllData();
    },[])

    return (
        <div>
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Add Blooms Taxonomy Level</h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Lesson Plan</a>
                          </li>
                          <li className="breadcrumb-item active"> Add Blooms Taxonomy Level</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        {edit || addNew ? (
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Taxonomy Name"
                                value={user.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Level
                              </label>
                              <input
                                type="text"
                                name="level"
                                placeholder="Enter the Level"
                                className="form-control"
                                value={user.level}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Weightage
                              </label>
                              <input
                                type="text"
                                name="weightage"
                                placeholder="Enter the Weightage"
                                className="form-control"
                                value={user.weightage}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}
                        {edit == false ? (
                          <>
                            {addNew == false ? (
                              <button
                                className="btn btn-nex btn-rounded float-lg-right mx-1 "
                                type="submit"
                                name="submit"
                                onClick={() => setAddNew(true)}
                              >
                                <i className="fa fa-add" aria-hidden="true" /> +
                                Add New
                              </button>
                            ) : (
                              <>
                                <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1"
                                  type="submit"
                                  name="submit"
                                  onClick={handleSubmit}
                                >
                                  <i
                                    className="fa fa-save"
                                    aria-hidden="true"
                                  />{" "}
                                  Save
                                </button>
                                <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                  type="submit"
                                  name="submit"
                                  // style={{aspectRatio:'1/1'}}
                                  onClick={() => {
                                    setEdit(false);
                                    setAddNew(false);
                                  }}
                                >
                                  {"<   "}{" "}
                                </button>
                              </>
                            )}
                          </>
                        ) : (
                            <>
                          <button
                            className="btn btn-nex btn-rounded float-lg-right mx-1"
                            type="submit"
                            name="submit"
                            onClick={handleEdit}
                          >
                            <i className="fa fa-save" aria-hidden="true" /> Edit
                          </button>
                          <button
                                  className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
                                  type="submit"
                                  name="submit"
                                  // style={{aspectRatio:'1/1'}}
                                  onClick={() => {
                                    setEdit(false);
                                    setAddNew(false);
                                  }}
                                >
                                  {"<   "}{" "}
                                  
                                </button>
                          </>
                        )}
                        <br />
                        <br />
                        <div className="row">    
                          <div className="col-md-4">
                            {" "}
                            <h3 className="card-title">Taxonomy Levels List</h3>
                          </div>
                        </div>
                        <table
                          id="datatable"
                          className="table table-bordered dt-responsive nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Name</th>
                              <th>Level</th>
                              <th>Weightage</th>
                              {sessionStorage.getItem("role") != "STAFF" ? (
                                <th>Action</th>
                              ) : null}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.map((d, key) => {
                              return (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{d?.name}</td>
                                  <td>{d?.level}</td>
                                  <td>{d?.weightage}</td>
                                  {sessionStorage.getItem("role") != "STAFF" ? (
                                    <td>
                                      <acronym title="Edit">
                                        <a
                                          href="javascript:void(0)"
                                          className="badge badge-light"
                                          onClick={(e) => {
                                            setEdit(true);
                                            setUser({ ...d });
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit "
                                            aria-hidden="true"
                                          />
                                        </a>
                                      </acronym>
                                      <a href="javascript:void(0)"> </a> &nbsp;
                                      <acronym title="Delete">
                                        <a
                                          href="javascript:void(0)"
                                          className="badge badge-light ml-2"
                                          onClick={() => {
                                            handleDelete(d);
                                          }}
                                        >
                                          <i
                                            className="fa fa-thumbs-down"
                                            aria-hidden="true"
                                            style={{ color: "red" }}
                                          />
                                        </a>
                                      </acronym>
                                      <a href="javascript:void(0)"> </a>
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {data?.length == 0 ? <Nodata /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

}

export default AddBloomsLevels;