import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState, useRef } from "react";
import {
  useLocation,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig.js";
import OffCanvasStudentProfile from "../../../Components/OffCanvas/Student/OffCanvasStudentProfile";
import BasicInformation from "../../../Components/Student/Profile/BasicInformation";
import BasicInformationView from "../../../Components/Student/Profile/BasicInformationView";
import AdmissionView from "../../../Components/Student/Profile/AdmissionView";
import ParentsView from "../../../Components/Student/Profile/ParentsView";
import AttendanceView from "../../../Components/Student/Profile/AttendanceView";
import FeeView from "../../../Components/Student/Profile/FeeView";
import StudentDocuments from "../../../Components/Student/Profile/StudentDocuments";
import StudentFee from "../../../Components/Student/Profile/StudentFee";
import StudentProfileSwitches from "../../../Components/Student/Profile/StudentProfileSwitches";
import StudentStatus from "../../../Components/Student/Profile/StudentStatus";
import ModalStudentProfileUpload from "../../../modals/Students/ModalStudentProfileUpload";
import avatar from "../../../assets/images/reports/graduated.png";
import { toast } from "react-toastify";
// import
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import "./StudentProfile.scss";
import { Drawer } from "antd";
import { Http } from "../../../Services/Services.js";
import {
  STUDENT_SESSION,
  STUDENT_ADMISSION,
} from "../../../utils/apiConstants.js";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst.js";
import { useReactToPrint } from "react-to-print";
import { REPORT_STUDENT_ATTENDANCE_ALL } from "../../../utils/Reports.apiConst.js";

function StudentProfile({ setLoading, collegeId }) {
  const location = useLocation();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [data, setData] = useState(location?.state?.data);

  const [studentDetails, setStudentDetails] = useState();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [flag, setFlag] = useState(false);

  const [allStudentsDetails, setAllStudentsDetails] = useState([]);

  const [allstudentloading, setAllStudentLoading] = useState(false);

  const [user, setUser] = useState();
  const [classData, setClassData] = useState([]);

  const currentSemester = searchParams.get("sem");
  const currentDepartemnt = searchParams.get("depart");
  const currentSession = searchParams.get("session");
  const currentClass = searchParams.get("class");
  const currentSection = searchParams.get("section");

  const getData = async () => {
    setAllStudentLoading(true);
    await Http.get(
      `${STUDENT_SESSION}?class_id=${currentClass}&semester_id=${currentSemester}&session_id=${currentSession}&status=ACTIVE`
    ).then((res) => {
      setAllStudentsDetails(res.data.data);
      setAllStudentLoading(false);
    });
  };

  const getData1 = async () => {
    setAllStudentLoading(true);
    await Http.get(
      `${STUDENT_SESSION}?class_id=${searchParams.get(
        "class"
      )}&semester_id=${searchParams.get("sem")}&session_id=${searchParams.get(
        "session"
      )}&status=ACTIVE`
    ).then((res) => {
      setAllStudentsDetails(res.data.data);
      setAllStudentLoading(false);
    });
  };

  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const OpenDrawer = () => {
    setIsDrawerVisible(true);
  };

  let role = sessionStorage.getItem("role");

  const localDepartments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));
  const localPrograms = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const localColleges = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const [tab, setTab] = useState("Basic");

  const changeDir = (dir, i) => {
    console.log("i -- ", i);
    navigate(
      `${dir}/${i?.user_id}?depart=${currentDepartemnt}&session=${currentSession}&class=${currentClass}&sem=${currentSemester}&section=${currentSection}`,
      {
        state: {
          data: allStudentsDetails,
        },
      }
    );
  };

  const id = useParams().id;

  console.log(id);
  useEffect(() => {
    setData(location?.state?.data);
  }, [location.state]);

  useEffect(() => {
    if (currentClass && currentSemester && currentSession) {
      getData();
    }
  }, [currentSemester, currentClass, currentSession]);

  useEffect(() => {
    console.log("Hello");
    getData1();
  }, []);

  useEffect(() => {
    setUser(studentDetails);
  }, []);

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const getDataa = async () => {
    const config = {
      method: "get",
      url: ACADEMICS_ADD_CLASS,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setClassData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDataa();
  }, []);

  console.log("classData -", classData);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const tableRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1000); // Adjust the delay as needed
  };

  //all attendance data
  const [attendanceData, setAttendanceData] = useState([]);

  //sessions set
  const [sessionsSet, setSessionsSet] = useState(new Set());

  //all sessions array
  const [sessions, setSessions] = useState([]);

  //session selected
  const [sessionType, setSessionType] = useState([]);

  //semesters all
  const [semesterOpt, setSemesterOpt] = useState([]);

  //semesters of selected class
  const [semesters, setSemesters] = useState([]);

  //semester selected
  const [semester, setSemester] = useState("");

  //display data
  const [displayData, setDisplayData] = useState([]);

  const getStudentData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_ADMISSION}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        // setUser(res.data.data);
        // setId(res.data.session?.filter((s) => s.status == "ACTIVE")[0]?.id);
        // setS_Id(
        //   res.data.session?.filter((s) => s.status == "ACTIVE")[0]?.session_id
        // );
        setStudentDetails(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response?.data?.message);
      });
  };

  const getAttendance = async () => {
    console.log("id - ", id);
    setLoading(1);
    await Http.get(`${REPORT_STUDENT_ATTENDANCE_ALL}?student_id=${id}`)
      .then((res) => {
        setLoading(0);
        console.log("attendance data - ", res.data.data);
        setAttendanceData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    attendanceData?.forEach((value, index) => {
      setSessionsSet((prev) => new Set([...prev, value.student_session_id]));
    });
    if (attendanceData && attendanceData.length !== 0) {
      getSemesters(attendanceData[0]?.college_id);
    }
  }, [attendanceData]);

  const getSemesters = async (college_id) => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios({
      ...config,
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${college_id}`,
    })
      .then((res) => {
        setLoading(0);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    if (sessions.length !== 0) {
      setSessionType(sessions[0]);
    }
  }, [sessions]);

  useEffect(() => {
    if (sessionType && semesterOpt) {
      let clas = attendanceData?.find(
        (s) => s.student_session_id == sessionType
      );
      let arr = semesterOpt?.filter((s) => s.class_id == clas?.class_id);
      let semNames = arr?.map((value, idx) => {
        return value.name;
      });
      setSemesters(semNames);
    }
  }, [sessionType, semesterOpt]);

  useEffect(() => {
    if (semesters) {
      setSemester(semesters[0]);
    }
  }, [semesters]);

  useEffect(() => {
    const arr = Array.from(sessionsSet);
    arr.sort((a, b) => b - a);
    setSessions(arr);
  }, [sessionsSet]);

  useEffect(() => {
    getAttendance();
  }, []);

  useEffect(() => {
    let temp = attendanceData.filter(
      (s) => s.semester == semester && s.student_session_id == sessionType
    );
    setDisplayData(temp);
  }, [semester, sessionType]);

  // const navigate = useNavigate();

  const changeDir2 = (dir, i) => {
    console.log("i -- ", i);
    navigate(
      `${dir}/${i?.user_id}?session_id=${i?.id}&depart=${currentDepartemnt}&session=${currentSession}&class=${currentClass}&sem=${currentSemester}&section=${currentSection}`,
      {
        state: {
          data: data,
          student: i,
        },
      }
    );
  };

  const handleStatusUpdate = async (status) => {
    const config = {
      method: "put",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios({
      ...config,
      url: `${STUDENT_ADMISSION}/${studentDetails?.user_id}`,
      data: {
        status: status,
      },
    })
      .then((res) => {
        setLoading(0);
        getStudentData();
        toast.success(`Student ${status} success`);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  return (
    <>
      <div className="StudentProfile">
        <>
          <ModalStudentProfileUpload />
          <Drawer
            title="Students with similar class"
            placement={"right"}
            width={500}
            onClose={onClose}
            open={isDrawerVisible}
          >
            <div className="row">
              <div className="col-md-12">
                {allStudentsDetails?.map((i, key) => (
                  <div
                    className="d-flex p-2 px-3 card rounded cursor-pointer"
                    onClick={() => {
                      if (role == "ADMIN") {
                        setIsDrawerVisible(false);
                        setTab("Basic");
                        setFlag((flag) => !flag);
                        changeDir(
                          ROUTES.Principal.Student.ViewStudentProfile,
                          i
                        );
                      }
                      if (role == "SUPERADMIN") {
                        setIsDrawerVisible(false);
                        setTab("Basic");
                        setFlag((flag) => !flag);
                        changeDir(
                          ROUTES.Registar.Student.ViewStudentProfile,
                          i
                        );
                      }
                      if (role == "STAFF") {
                        setIsDrawerVisible(false);
                        setTab("Basic");
                        setFlag((flag) => !flag);
                        changeDir(
                          ROUTES.Employee.Student.ViewStudentProfile,
                          i
                        );
                      }
                    }}
                  >
                    <div className="row">
                      <div className="col-md-8">
                        <h6>{i?.name}</h6>
                        <p className="mb-0">{i?.user_id}</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          className="profile-user-img img-responsive rounded mx-auto d-block"
                          src={`${
                            i?.student_picture
                              ? i?.student_picture
                              : "../../../assets/images/reports/graduated.png"
                          }
                            `}
                          width="50%"
                          style={{ aspectRatio: "1/1" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Drawer>
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                  <div className="col-10">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li
                            className="breadcrumb-item"
                            style={{ cursor: "pointer" }}
                          >
                            <a
                              onClick={() => {
                                navigate(-1);
                              }}
                            >
                              Student
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            <a
                              onClick={() => {
                                navigate(-1);
                              }}
                            >
                              {" "}
                              Students Details
                            </a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Students Profile
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page title */}
                <div className="col-12"></div>
                {/* <div>
                  <hr />
                  <h3 className="text-center">Student Profile</h3>
                  <hr />
                </div> */}

                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="box box-primary">
                        <div className="box-body box-profile">
                          <div className="card py-2">
                            <ul className="list-group list-group-unbordered pt-1">
                              <img
                                className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                                src={`${
                                  studentDetails?.student_picture
                                    ? studentDetails?.student_picture
                                    : "../../../assets/images/reports/graduated.png"
                                }
                                  `}
                                width="50%"
                                style={{ aspectRatio: "1/1" }}
                              />
                              <br />
                              <h6 className="profile-username text-center">
                                {studentDetails?.name}
                              </h6>
                              <h6 className="text-center">
                                <span
                                  className={`badge badge-${
                                    studentDetails?.status == "ACTIVE"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {studentDetails?.status}
                                </span>
                              </h6>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Enrollment No. :</b>{" "}
                                <a className="mr-2">
                                  {studentDetails?.user_id}
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">University No. :</b>{" "}
                                <a className="mr-2">
                                  {studentDetails?.university_number}
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Phone :</b>{" "}
                                <a
                                  className="mr-2"
                                  href="tel:{studentDetails?.phone}"
                                >
                                  {studentDetails?.phone}
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Email :</b>{" "}
                                <a
                                  className="mr-2"
                                  href="mailto:{studentDetails?.email}"
                                >
                                  {studentDetails?.email}
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Program :</b>{" "}
                                <a className="mr-2">
                                  {
                                    localPrograms?.find(
                                      (s) => s?.id == studentDetails?.program_id
                                    )?.name
                                  }
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.7em" }}
                              >
                                <b className="ml-2">College :</b>{" "}
                                <a className="mr-2">
                                  {
                                    localColleges?.find(
                                      (s) => s?.id == studentDetails?.college_id
                                    )?.name
                                  }
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Department :</b>{" "}
                                <a className="mr-2">
                                  {
                                    localDepartments?.find(
                                      (s) =>
                                        s?.id == studentDetails?.department_id
                                    )?.name
                                  }
                                </a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Gender :</b>{" "}
                                <a className="mr-2">{studentDetails?.gender}</a>
                              </li>
                              <li
                                className="list-group-item listnoback d-flex justify-content-between p-1"
                                style={{ fontSize: "0.8em" }}
                              >
                                <b className="ml-2">Admission Date :</b>{" "}
                                <a className="mr-2">
                                  {studentDetails?.createdAt?.substring(0, 10)}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                      <div className="card ">
                        <div className="col-12">
                          <button
                            title="Print Student Profile"
                            className="btn btn-nex mt-3 ml-3"
                            onClick={handlePrint}
                          >
                            <i class="fa fa-print"></i>
                          </button>
                          <a
                            data-toggle="tooltip"
                            title="View Profile"
                            onClick={() => {
                              if (role == "ADMIN")
                                changeDir2(
                                  ROUTES.Principal.Student.StudentProfile,
                                  studentDetails
                                );
                              if (role == "SUPERADMIN")
                                changeDir2(
                                  ROUTES.Registar.Student.StudentProfile,
                                  studentDetails
                                );
                              if (role == "SUACC") {
                                changeDir2(
                                  ROUTES.Accountant.StudentProfile,
                                  studentDetails
                                );
                              }
                              if (role == "STAFF") {
                                changeDir2(
                                  ROUTES.Employee.Student.StudentProfile,
                                  studentDetails
                                );
                              }
                            }}
                          >
                            <button
                              title="Edit"
                              className="btn btn-nex ml-3 mr-3 mt-3"
                              onClick={handlePrint}
                            >
                              <i class="fa fa-edit"></i>
                            </button>
                          </a>
                          {studentDetails?.status == "ACTIVE" ? (
                            <button
                              title="Inactive Student"
                              className="btn btn-nex mt-3"
                              onClick={() => handleStatusUpdate("INACTIVE")}
                            >
                              <i class="fa fa-thumbs-down text-danger"></i>
                            </button>
                          ) : studentDetails?.status == "INACTIVE" ? (
                            <button
                              title="Active Student"
                              className="btn btn-nex mt-3"
                              onClick={() => handleStatusUpdate("ACTIVE")}
                            >
                              <i class="fa fa-thumbs-up text-success"></i>
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="card-body">
                          <StudentProfileSwitches
                            tab={tab}
                            setTab={setTab}
                            setLoading={setLoading}
                            collegeId={collegeId}
                            OpenDrawer={OpenDrawer}
                            allstudentloading={allstudentloading}
                            data1={[
                              "Basic",
                              "Admission",
                              "Parents",
                              "Attendance",
                              "Fee",
                              "Documents",
                            ]}
                          />
                          {tab == "Basic" && (
                            <BasicInformationView
                              setLoading={setLoading}
                              flag={flag}
                              id={id}
                              studentDetails={studentDetails}
                              setStudentDetails={setStudentDetails}
                            />
                          )}
                          {tab === "Admission" && (
                            <AdmissionView
                              id={id}
                              flag={flag}
                              setLoading={setLoading}
                              studentDetails={studentDetails}
                            />
                          )}
                          {tab === "Parents" && (
                            <ParentsView
                              id={id}
                              flag={flag}
                              setLoading={setLoading}
                              studentDetails={studentDetails}
                            />
                          )}
                          {tab === "Attendance" && (
                            <AttendanceView
                              id={id}
                              flag={flag}
                              setLoading={setLoading}
                            />
                          )}
                          {tab === "Fee" && (
                            <FeeView
                              id={id}
                              setLoading={setLoading}
                              studentDetails={studentDetails}
                            />
                          )}
                          {tab === "Documents" && (
                            <StudentDocuments setLoading={setLoading} id={id} />
                          )}
                          {tab === "Status" && (
                            <StudentStatus
                              data={data}
                              setLoading={setLoading}
                            />
                          )}
                          <div className="tab-content" id="myTabContent"></div>
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <table
                  id=""
                  ref={tableRef}
                  className="table table-bordered"
                  style={{
                    borderCollapse: "collapse",
                    borderSpacing: 0,
                    width: "100%",
                    display: "none",
                  }}
                >
                  {data && (
                    <thead>
                      <tr>
                        <th colSpan="4" className="text-bold">
                          <p className="text-dark">
                            Printed On - {getCurrentDate()}
                          </p>
                          <h4 className="mt-1 mb-2 ml-4 text-primary">
                            <img
                              className="profile-user-img img-responsive mx-auto"
                              src="../../../assets/images/logo.png"
                              width="100px"
                              height="80px"
                              style={{ aspectRatio: "1/1" }}
                            />
                            <span className="ml-3">
                              {
                                localColleges?.find(
                                  (s) => s?.id == studentDetails?.college_id
                                )?.name
                              }
                            </span>
                          </h4>
                        </th>
                        <th className="text-center">
                          <h5>STUDENT PROFILE</h5>
                          <br />
                          <br />
                          <h4>{studentDetails?.name}</h4>
                          <h5>{studentDetails?.user_id}</h5>
                        </th>
                      </tr>
                    </thead>
                  )}

                  <tbody>
                    <tr>
                      <th colSpan="4">
                        <h5 className="bg-dark text-light">
                          Basic Information
                        </h5>
                      </th>
                    </tr>

                    <tr>
                      <th width="10%">Student Name :</th>
                      <td width="10%">{studentDetails?.name}</td>
                      <th width="10%">Enrollment No :</th>
                      <td width="10%">{studentDetails?.user_id}</td>
                      <td rowSpan="14" width="10%">
                        <img
                          className="profile-user-img img-responsive  mx-auto d-block mt-5"
                          src={
                            studentDetails?.student_picture
                              ? studentDetails?.student_picture
                              : "../../../assets/images/reports/graduated.png"
                          }
                          width="230px"
                          height="200px"
                          style={{
                            aspectRatio: "1/1",
                            marginBottom: "20px",
                            marginTop: "80px",
                          }}
                        />
                        <h3 className="text-center">{studentDetails?.name}</h3>
                        <h4 className="text-center">
                          {studentDetails?.user_id}
                        </h4>
                      </td>
                    </tr>

                    <tr>
                      <th>Mobile No :</th>
                      <td>{studentDetails?.phone}</td>
                      <th>Email :</th>
                      <td>{studentDetails?.email}</td>
                    </tr>

                    <tr>
                      <th>Date of Birth :</th>
                      <td>{studentDetails?.dob}</td>
                      <th>Gender :</th>
                      <td>{studentDetails?.gender}</td>
                    </tr>

                    <tr>
                      <th>Program :</th>
                      <td>
                        {
                          localPrograms?.find(
                            (s) => s?.id == studentDetails?.program_id
                          )?.name
                        }
                      </td>
                      <th>College :</th>
                      <td>
                        {
                          localColleges?.find(
                            (s) => s?.id == studentDetails?.college_id
                          )?.name
                        }
                      </td>
                    </tr>

                    <tr>
                      <th>Department :</th>
                      <td>
                        {
                          localDepartments?.find(
                            (s) => s?.id == studentDetails?.department_id
                          )?.name
                        }
                      </td>
                      <th>Class</th>
                      <td>
                        {
                          classData?.find(
                            (s) =>
                              s?.department_id == studentDetails?.department_id
                          )?.name
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>Current Semester :</th>
                      <td>{user?.curr_sem ? user.curr_sem : "-"}</td>
                      <th>Admission Year :</th>
                      <td>
                        {user?.year_of_admission ? user.year_of_admission : "-"}
                      </td>
                    </tr>

                    <tr>
                      <th>Mother Name :</th>
                      <td>{studentDetails?.mother_name}</td>
                      <th>Father Name :</th>
                      <td>{studentDetails?.father_name}</td>
                    </tr>

                    <tr>
                      <th>Mother No :</th>
                      <td>{studentDetails?.mother_phone}</td>
                      <th>Father No :</th>
                      <td>{studentDetails?.father_phone}</td>
                    </tr>

                    <tr>
                      <th>Physically Handicap? :</th>
                      <td>
                        {studentDetails?.physically_handiCap == 1
                          ? "YES"
                          : "NO"}
                      </td>
                      <th>Marital Status :</th>
                      <td>{studentDetails?.marital_status}</td>
                    </tr>

                    <tr>
                      <th>Religion :</th>
                      <td>{studentDetails?.religion}</td>
                      <th>Category :</th>
                      <td>{studentDetails?.category}</td>
                    </tr>

                    <tr>
                      <th>Caste :</th>
                      <td>{studentDetails?.caste}</td>
                      <th>Sub Caste :</th>
                      <td>{studentDetails?.sub_caste}</td>
                    </tr>

                    <tr>
                      <th>Blood Group :</th>
                      <td>{studentDetails?.blood_grp}</td>
                      <th>Adhaar Number :</th>
                      <td>{studentDetails?.aadhar_number}</td>
                    </tr>

                    <tr>
                      <th>Nationality :</th>
                      <td>{studentDetails?.nationality}</td>
                      <th>State :</th>
                      <td>{studentDetails?.state}</td>
                    </tr>

                    <tr>
                      <th>District :</th>
                      <td>{studentDetails?.district}</td>
                      <th>City :</th>
                      <td>{studentDetails?.city}</td>
                    </tr>

                    <tr>
                      <th>Pin Code :</th>
                      <td>{studentDetails?.pin}</td>
                      <th>Application No :</th>
                      <td>
                        {user?.application_number
                          ? user?.application_number
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <th>Current Address:</th>
                      <td>{studentDetails?.current_address}</td>
                      <th>Permanent Address :</th>
                      <td>{studentDetails?.permanent_address}</td>
                    </tr>

                    <tr>
                      <th>Admission Date :</th>
                      <td>{studentDetails?.createdAt?.substring(0, 10)}</td>
                      <th>Application Number :</th>
                      <td>
                        {user?.application_number
                          ? user?.application_number
                          : "-"}
                      </td>
                    </tr>

                    <tr>
                      <th>Admission Quota :</th>
                      <td>{user?.quota ? user.quota : "-"}</td>
                      <th>Admission Sub Quota :</th>
                      <td>{user?.sub_quota ? user.sub_quota : "-"}</td>
                    </tr>

                    <tr>
                      <th>Current Semester :</th>
                      <td>{user?.curr_sem ? user.curr_sem : "-"}</td>
                      <th>Date of Application :</th>
                      <td>
                        {user?.appliedAt
                          ? user.appliedAt.substring(0, 10)
                          : "-"}
                      </td>
                    </tr>

                    <br />
                    <tr>
                      <th colSpan="4">
                        <h5 className="bg-dark text-light">12th Details</h5>
                      </th>
                    </tr>

                    <tr>
                      <th>12th Board :</th>
                      <td>{user?.pu_board ? user.pu_board : "-"}</td>
                      <th>12th Pass Month :</th>
                      <td>{user?.pu_pass_month ? user.pu_pass_month : "-"}</td>
                    </tr>
                    <tr>
                      <th>12th Seat No :</th>
                      <td>{user?.pu_seat_no ? user.pu_seat_no : "-"}</td>
                      <th>12th Percentage :</th>
                      <td>{user?.pu_perce ? user.pu_perce : "-"}</td>
                    </tr>

                    <br />
                    <tr>
                      <th colSpan="4">
                        <h5 className="bg-dark text-light">10th Details</h5>
                      </th>
                    </tr>

                    <tr>
                      <th>10th Board :</th>
                      <td>{user?.sslc_board ? user.sslc_board : "-"}</td>
                      <th>10th Pass Month :</th>
                      <td>
                        {user?.sslc_pass_month ? user.sslc_pass_month : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>10th Seat No :</th>
                      <td>{user?.sslc_seat_no ? user.sslc_seat_no : "-"}</td>
                      <th>10th Percentage :</th>
                      <td>{user?.sslc_perce ? user.sslc_perce : "-"}</td>
                    </tr>

                    <tr>
                      <th colSpan="2">
                        <h5 className="bg-dark text-light">
                          Father's Information
                        </h5>
                      </th>
                      <th colSpan="2">
                        <h5 className="bg-dark text-light">
                          Mother's Information
                        </h5>
                      </th>
                    </tr>

                    <tr>
                      <th>Father's Name :</th>
                      <td>{user?.father_name || "-"}</td>
                      <th>Mother's Name :</th>
                      <td>{user?.mother_name || "-"}</td>
                    </tr>
                    <tr>
                      <th>Mobile No :</th>
                      <td>{user?.father_phone || "-"}</td>
                      <th>Mobile No :</th>
                      <td>{user?.mother_phone || "-"}</td>
                    </tr>

                    <tr>
                      <th>Occupation :</th>
                      <td>{user?.father_occupation || "-"}</td>
                      <th>Occupation :</th>
                      <td>{user?.mother_occupation || "-"}</td>
                    </tr>

                    <tr>
                      <th>Occupation city :</th>
                      <td>{user?.father_occupation_city || "-"}</td>
                      <th>Occupation city :</th>
                      <td>{user?.mother_occupation_city}</td>
                    </tr>

                    <tr>
                      <th>Email :</th>
                      <td>{user?.father_email || "-"}</td>
                      <th>Email :</th>
                      <td>{user?.mother_email || "-"}</td>
                    </tr>
                    <tr>
                      <th>Company/Organization :</th>
                      <td>{user?.father_company || "-"}</td>
                      <th>Company/Organization :</th>
                      <td>{user?.mother_company || "-"}</td>
                    </tr>
                    <tr>
                      <th>PAN :</th>
                      <td>{user?.father_pan || "-"}</td>
                      <th>PAN :</th>
                      <td>{user?.mother_pan || "-"}</td>
                    </tr>
                    <tr>
                      <th>Industry Type :</th>
                      <td>{user?.father_industry_type || "-"}</td>
                      <th>Industry Type :</th>
                      <td>{user?.mother_industry_type || "-"}</td>
                    </tr>
                    <tr>
                      <th>Adhaar No :</th>
                      <td>{user?.father_aadhar_number || "-"}</td>
                      <th>Adhaar No :</th>
                      <td>{user?.mother_aadhar_number || "-"}</td>
                    </tr>
                    <tr>
                      <th>Sector Type :</th>
                      <td>{user?.father_sector_type || "-"}</td>
                      <th>Sector Type :</th>
                      <td>{user?.mother_sector_type || "-"}</td>
                    </tr>
                    <tr>
                      <th>Education Qualification :</th>
                      <td>{user?.father_education_qualification || "-"}</td>
                      <th>Education Qualification :</th>
                      <td>{user?.mother_education_qualification || "-"}</td>
                    </tr>
                    <tr>
                      <th>Annual Income :</th>
                      <td>{user?.father_annual_income || "-"}</td>
                      <th>Annual Income :</th>
                      <td>{user?.mother_annual_income || "-"}</td>
                    </tr>
                    <tr>
                      <th>Designation :</th>
                      <td>{user?.father_designation || "-"}</td>
                      <th>Designation :</th>
                      <td>{user?.mother_designation || "-"}</td>
                    </tr>

                    <br />
                    <tr>
                      <th colSpan="4">
                        <h5 className="bg-dark text-light">
                          Guardian's Information
                        </h5>
                      </th>
                    </tr>

                    <tr>
                      <th>Guardians Name :</th>
                      <td>{user?.guardian_name || "-"}</td>
                      <th>Mobile (Guardian) :</th>
                      <td>{user?.guardian_phone || "-"}</td>
                    </tr>
                    <tr>
                      <th>Occupation :</th>
                      <td>{user?.guardian_occupation || "-"}</td>
                      <th>Occupation city :</th>
                      <td>{user?.guardian_occupation_city || "-"}</td>
                    </tr>
                    <tr>
                      <th>Email :</th>
                      <td>{user?.guardian_email || "-"}</td>
                      <th>Company/Organization :</th>
                      <td>{user?.guardian_company || "-"}</td>
                    </tr>
                    <tr>
                      <th>PAN :</th>
                      <td>{user?.guardian_pan || "-"}</td>
                      <th>Industry Type :</th>
                      <td>{user?.guardian_industry_type || "-"}</td>
                    </tr>
                    <tr>
                      <th>Adhaar No :</th>
                      <td>{user?.guardian_aadhar_number || "-"}</td>
                      <th>Sector Type :</th>
                      <td>{user?.guardian_sector_type || "-"}</td>
                    </tr>
                    <tr>
                      <th>Education Qualification :</th>
                      <td>{user?.guardian_education_qualification || "-"}</td>
                      <th>Annual Income :</th>
                      <td>{user?.guardian_annual_income || "-"}</td>
                    </tr>
                    <tr>
                      <th>Designation :</th>
                      <td>{user?.guardian_designation || "-"}</td>
                    </tr>

                    <br />
                    <tr>
                      <th colSpan="4">
                        <h5 className="bg-dark text-light">
                          Attendance Details
                        </h5>
                      </th>
                    </tr>

                    {displayData &&
                      displayData?.map((value, key) => {
                        let percentage = 0;
                        if (
                          parseInt(value.present) + parseInt(value.absent) !==
                          0
                        ) {
                          percentage = (
                            (parseInt(value.present) * 100) /
                            (parseInt(value.present) + parseInt(value.absent))
                          ).toFixed(2);
                        }
                        let color = "#CCFFCD";
                        if (percentage < 60) color = "#FFCCCB";
                        else if (percentage < 85 && percentage >= 60)
                          color = "#fffdd0";
                        return (
                          <>
                            <tr>
                              <th> Course : </th>
                              <td>{value?.course}</td>
                            </tr>
                            <tr>
                              <th>Present :</th>
                              <td className="text-success">
                                <b>{value?.present}</b>
                              </td>
                              <th>Absent :</th>
                              <td className="text-danger">
                                <b>{value?.absent}</b>
                              </td>
                            </tr>
                            <tr>
                              <th> Total : </th>
                              <td className="text-primary">
                                <b>
                                  {parseInt(value?.present) +
                                    parseInt(value?.absent)}
                                </b>
                              </td>
                              <th> Present% : </th>
                              <td style={{ backgroundColor: color }}>
                                <b>{percentage}%</b>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </table>

                {/* end card */}
              </div>
            </div>
            {/* container-fluid */}
            <OffCanvasStudentProfile />

            <div className="rightbar-overlay" />
          </div>
        </>
      </div>
    </>
  );
}

export default StudentProfile;
