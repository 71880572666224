import React, { useState, useEffect } from "react";
import axios from "axios";
import NoData from "../../../../Components/NoData/Nodata";
import { useNavigate } from "react-router-dom";
import { LOCAL_JOBROLES } from "../../../../utils/LocalStorageConstants";
import { sessionOpt } from "../../../../Data/jsonData/Academics/Academics";
import { EMPLOYEE_LATEIN_MONTHLY_REPORT } from "../../../../utils/Reports.apiConst";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useRef } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../../utils/LocalStorageConstants";
import "../../../../modals/HR/Leave/TableScroll.css";
import { EMPLOYEE_BIO_IDS } from "../../../../utils/apiConstants";
import { Button, Popover } from "antd";

function AttendanceMonthlyLateinReport({ setLoading, collegeId }) {
  const navigate = useNavigate();

  const monthNums = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "22",
  ];

  const collegeid = sessionStorage.getItem("college_id");
  console.log("collegeid -", collegeid);

  const [user, setUser] = useState({
    month1: monthNums[new Date().getMonth()],
    year1: new Date().getFullYear(),
    department_id: "",
    college_id: "",
  });

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );
  const [programOpt, setProgramOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
  );

  useEffect(
    () => {
      setDepartmentOpt(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)).filter(
          // (item) => item.college_id == user.college_id
          (item) => item.college_id == collegeid
        )
      );
      setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
      user.department_id = "";
    },
    // [localStorage.getItem(LOCAL_DEPARTMENT), user.college_id]);
    [localStorage.getItem(LOCAL_DEPARTMENT), collegeid]
  );

  useEffect(() => {
    setDepartmentOpt(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [mainData, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchName, setSearchName] = useState("");
  const tableRef = useRef();
  const [currMonthDays, setCurrMonthDays] = useState(0);

  const months = [
    { key: "01", name: "JANUARY" },
    { key: "02", name: "FEBRUARY" },
    { key: "03", name: "MARCH" },
    { key: "04", name: "APRIL" },
    { key: "05", name: "MAY" },
    { key: "06", name: "JUNE" },
    { key: "07", name: "JULY" },
    { key: "08", name: "AUGUST" },
    { key: "09", name: "SEPTEMBER" },
    { key: "10", name: "OCTOBER" },
    { key: "11", name: "NOVEMBER" },
    { key: "12", name: "DECEMBER" },
  ];

  const getlocalStorage = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  // const [attendanceData, setAttendanceData] = useState([]);
  const [jobRoleOpt, setJobRoleOpt] = useState(getlocalStorage);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const getData = async () => {
    if (!user?.month1) {
      return toast.error("Please select Month");
    }
    if (!user?.year1) {
      return toast.error("Please select Year");
    }

    const config = {
      method: "get",
      // url: `${EMPLOYEE_LATEIN_MONTHLY_REPORT}?month=${user?.month1}&year=${user?.year1}&college_id=${user?.college_id}&department_id=${user?.department_id}`,
      url: `${EMPLOYEE_LATEIN_MONTHLY_REPORT}?month=${user?.month1}&year=${user?.year1}&college_id=${collegeid}&department_id=${user?.department_id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);

        const groupedData = res.data.data?.reduce((acc, curr) => {
          const {
            id,
            first_name,
            last_name,
            date,
            status,
            late_in,
            early_out,
            in_time,
            out_time,
          } = curr;
          if (!acc[id]) {
            acc[id] = {
              id,
              first_name,
              last_name,
              attendance: [],
              li: 0,
              eo: 0,
            };
          }
          acc[id].attendance.push({
            date,
            status,
            late_in,
            early_out,
            in_time,
            out_time,
          });
          if (
            new Date(
              "1970-01-01T" +
                acc[id].attendance?.find((s) => s.date == date)?.late_in +
                "Z"
            ).getTime() > new Date("1970-01-01T00:06Z").getTime()
          ) {
            acc[id].li = acc[id].li + 1;
          }
          if (
            new Date(
              "1970-01-01T" +
                acc[id].attendance?.find((s) => s.date == date)?.early_out +
                "Z"
            ).getTime() > new Date("1970-01-01T00:06Z").getTime()
          ) {
            acc[id].eo = acc[id].eo + 1;
          }
          return acc;
        }, {});
        const sortedEmployees = Object.values(groupedData).sort((a, b) => {
          const nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
          const nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setData(sortedEmployees);
        setDisplayData(sortedEmployees);
        // setData(Object.values(groupedData));
        console.log("monthly report data - ", sortedEmployees);

        const daysInCurrentMonth = new Date(
          parseInt(user?.year1),
          parseInt(user?.month1),
          0
        ).getDate();
        console.log("number of days in month - ", daysInCurrentMonth);
        setCurrMonthDays(daysInCurrentMonth);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${months.find((s) => s.key == user?.month1)?.name}-${
      user?.year1
    } Employee Attendance`,
    sheet: "Users",
  });

  useEffect(() => {
    const filteredData = data?.filter(
      (item) =>
        item?.first_name?.toLowerCase()?.includes(searchName?.toLowerCase()) ||
        item?.last_name?.toLowerCase()?.includes(searchName?.toLowerCase()) ||
        `${item?.first_name} ${item?.last_name}`
          ?.toLowerCase()
          ?.includes(searchName?.toLowerCase())
    );
    setDisplayData(filteredData);
  }, [searchName]);

  useEffect(() => {
    setData([]);
    setDisplayData([]);
  }, [user?.month1, user?.year1, user?.department_id]);

  return (
    <div className="AttendanceTimeClockReport">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">
                    STAFF MONTHLY LATE IN & EARLY OUT REPORT
                  </h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title">Select Criteria</div>
                    <div className="row">
                      {/* <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="college_id">
                            College <span className="text-danger">*</span>
                          </label>
                          <select 
                            name="college_id" id="college_id" required="" className="form-control"
                            value={user.college_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {collegeOpt?.map((data, key) => {
                              return <option value={data.id}>{data.name}</option>
                            })}
                          </select>
                        </div>
                      </div> */}
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="department_id"
                            id="class"
                            value={user.department_id}
                            onChange={handleChange}
                          >
                            <option value="">All</option>
                            {departmentOpt
                              ?.filter((s) => s.college_id == collegeid)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name},{" "}
                                  {
                                    programOpt.find(
                                      (item) => item.id == i.program_id
                                    )?.name
                                  }
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="month">
                            Month <span className="text-danger">*</span>
                          </label>
                          <select
                            name="month1"
                            id="month1"
                            required=""
                            className="form-control"
                            value={user?.month1}
                            onChange={handleChange}
                          >
                            <option value="">Select Month</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="year">
                            Year <span className="text-danger">*</span>
                          </label>
                          <select
                            name="year1"
                            id="year1"
                            required=""
                            className="form-control"
                            value={user?.year1}
                            onChange={handleChange}
                          >
                            <option value="">Select Year</option>
                            {sessionOpt &&
                              sessionOpt?.map((item, key) => {
                                return (
                                  <option value={item?.id}>
                                    {item?.name.substring(0, 4)}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-1 my-4 ml-2">
                        <button
                          className="btn btn-nex"
                          name="submit"
                          type="submit"
                          value="staff"
                          onClick={getData}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <br />
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                      <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                      </div>
                      <div></div>
                      <div>
                        <button
                          className="btn btn-nex rounded-pill mr-2"
                          onClick={onDownload}
                        >
                          Excel
                        </button>
                        <button
                          className="btn btn-nex rounded-pill"
                          onClick={handlePrint}
                        >
                          PDF
                        </button>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="table-responsive fixTableHead">
                          <table
                            className="table table-bordered"
                            ref={tableRef}
                          >
                            {/* <thead> */}
                            <tr>
                              <th style={{ display: "none" }} colSpan={7}>
                                KALOL INSTITUTE AND RESEARCH CENTER
                              </th>
                            </tr>
                            {/* </thead> */}
                            <thead>
                              <tr>
                                <th
                                  className="text-uppercase"
                                  colSpan={currMonthDays ? 2 : 1}
                                >
                                  Employees - {data ? data.length : 0}
                                </th>
                                <th
                                  className="text-uppercase"
                                  colSpan={currMonthDays ? 5 : 1}
                                >
                                  Year - {user?.year1}
                                </th>
                                <th
                                  className="text-uppercase"
                                  colSpan={
                                    currMonthDays ? currMonthDays - 3 : 1
                                  }
                                >
                                  Month -{" "}
                                  {
                                    months.find((s) => s.key == user?.month1)
                                      ?.name
                                  }
                                </th>
                              </tr>
                              <tr>
                                <th>Sl.No</th>
                                <th>Name</th>
                                {currMonthDays ? (
                                  <>
                                    {[...Array(currMonthDays)].map(
                                      (_, index) => (
                                        // <th key={index + 1}>{index + 1}/{user?.month1}/{user?.year1}</th>
                                        <th key={index + 1}>{index + 1}</th>
                                      )
                                    )}
                                    <th>LI</th>
                                    <th>EO</th>
                                  </>
                                ) : (
                                  <>
                                    <th></th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {displayData && displayData.length ? (
                                displayData?.map((employee, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        {employee?.first_name}{" "}
                                        {employee?.last_name}
                                      </td>
                                      {[...Array(currMonthDays)].map(
                                        (_, index) => {
                                          const day =
                                            index + 1 < 10
                                              ? `0${index + 1}`
                                              : index + 1;
                                          const date = `${day}/${user?.month1}/${user?.year1}`;
                                          return (
                                            <td
                                              key={index}
                                              // className={`text-sm text-${
                                              //   employee?.attendance?.find((s) => s.date === date)?.late_in != 'LT'
                                              //   ? "success"
                                              //   : employee?.attendance?.find((s) => s.date === date)?.early_out == 'A'
                                              //   ? "danger"
                                              //   : "secondary"}`}
                                            >
                                              <Popover
                                                content={
                                                  <>
                                                    <p>
                                                      Late in -{" "}
                                                      {
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.late_in
                                                      }
                                                    </p>
                                                    <p>
                                                      Early out -{" "}
                                                      {
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.early_out
                                                      }
                                                    </p>
                                                  </>
                                                }
                                              >
                                                {!employee?.attendance?.find(
                                                  (s) => s.date == date
                                                ) ||
                                                employee?.attendance?.find(
                                                  (s) => s.date == date
                                                )?.status != "P"
                                                  ? ""
                                                  : employee?.attendance?.find(
                                                      (s) => s.date == date
                                                    )?.late_in != "00:00" &&
                                                    employee?.attendance?.find(
                                                      (s) => s.date === date
                                                    )?.early_out !== "00:00"
                                                  ? new Date(
                                                      "1970-01-01T" +
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.late_in +
                                                        "Z"
                                                    ).getTime() >
                                                      new Date(
                                                        "1970-01-01T00:06Z"
                                                      ).getTime() &&
                                                    new Date(
                                                      "1970-01-01T" +
                                                        employee?.attendance?.find(
                                                          (s) => s.date === date
                                                        )?.early_out +
                                                        "Z"
                                                    ).getTime() >
                                                      new Date(
                                                        "1970-01-01T00:06Z"
                                                      ).getTime()
                                                    ? `LI-EO (${
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.in_time
                                                      }-${
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.out_time
                                                      })`
                                                    : ""
                                                  : employee?.attendance?.find(
                                                      (s) => s.date == date
                                                    )?.late_in != "00:00"
                                                  ? new Date(
                                                      "1970-01-01T" +
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.late_in +
                                                        "Z"
                                                    ).getTime() >
                                                    new Date(
                                                      "1970-01-01T00:06Z"
                                                    ).getTime()
                                                    ? `LI (${
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.in_time
                                                      })`
                                                    : ""
                                                  : employee?.attendance?.find(
                                                      (s) => s.date == date
                                                    )?.early_out !== "00:00"
                                                  ? new Date(
                                                      "1970-01-01T" +
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.early_out +
                                                        "Z"
                                                    ).getTime() >
                                                    new Date(
                                                      "1970-01-01T00:06Z"
                                                    ).getTime()
                                                    ? `EO (${
                                                        employee?.attendance?.find(
                                                          (s) => s.date == date
                                                        )?.out_time
                                                      })`
                                                    : ""
                                                  : ""}
                                              </Popover>
                                            </td>
                                          );
                                        }
                                      )}
                                      <td>
                                        <b>{employee?.li}</b>
                                      </td>
                                      <td>
                                        <b>{employee?.eo}</b>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    colSpan={
                                      currMonthDays ? currMonthDays + 4 : 3
                                    }
                                  >
                                    <NoData />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AttendanceMonthlyLateinReport;
