export const staffSwitches = [
    'Basic',
    // 'Placement',
    // 'Employment Terms',
    'Education',
    'Experience',
    'Training',
    'Document',
    'Publications'
]


export const staffWebAccoutsSwitches = [
    // 'No Mail',
    // 'No Account',
    'Enabled',
    'Disabled',
]