import React, { useState, useRef, useEffect } from "react";
import {
  LEAVE_LEAVE_TYPE,
  LEAVE_PRIORITY_EMPLOYEE,
  LEAVE_TYPE_PRIORITY
} from "../../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useReactToPrint } from "react-to-print";
import { LOCAL_COLLEGE } from "../../../../utils/LocalStorageConstants";
import {Button} from "antd";
import Swal from "sweetalert2";
import ModalLeavePriority from "../../../../modals/HR/Leave/ModalLeavePriority";
import ModalLeavePriorityAssign from "../../../../modals/HR/Leave/ModalLeavePriorityAssign";

function LeaveTypePriority({ setLoading, collegeId }) {

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [data, setData] = useState([]);

  const [type, setType] = useState("");
  const [edit, setEdit] = useState();

  const [empData, setEmpData] = useState([]);
  const [displayData, setDisplayData] = useState([]);

  const getData = async () => {
    
    const config = {
      method: "get",
      url: LEAVE_LEAVE_TYPE,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log('leave types - ', res.data.data);
        setLeaveTypes(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const getLeavePriorities = async () => {

    const config = {
      method: "get",
      url: `${LEAVE_TYPE_PRIORITY}?college_id=${collegeId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log('leave priorities - ', res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err?.response?.data?.message || "Something went wrong");
        console.log(err);
      });
  }

  const handleDelete = async (id, priority) => {

    const config = {
      method: "delete",
      url: `${LEAVE_TYPE_PRIORITY}/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Priority deleted successfully");
        getLeavePriorities();
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err?.response?.data?.message || "Something went wrong");
        console.log(err);
      });
  }

  const getEmpData = () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${LEAVE_PRIORITY_EMPLOYEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };
    axios(config)
      .then((res) => {
        setLoading(0);
        let tempData = res.data.data?.filter((s) => s.college_id == collegeId);
        setEmpData(tempData);
        setDisplayData(tempData);
        console.log("employees with priorities - ", tempData);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    getLeavePriorities();
    getEmpData();
  }, []);

  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Leave Type-" + new Date().getFullYear(),
    sheet: "Leave Type",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  //   const handlePrint = () => {
  //     PrintRecipt();
  //   };

  const handlePrint = () => {
    // Make the table visible
    tableRef.current.style.display = "table";

    // Delay the PDF export
    setTimeout(() => {
      PrintRecipt();
      // Hide the table again if needed
      tableRef.current.style.display = "none";
    }, 1); // Adjust the delay as needed
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  };


  return (
    <div className="LeaveLeaveType">
      <ModalLeavePriority 
        leaveTypes={leaveTypes} 
        setLoading={setLoading} 
        collegeId={collegeId} 
        getLeavePriorities={getLeavePriorities}
        edit = {edit}
        type={type}
      />

      <ModalLeavePriorityAssign 
          getEmpData={getEmpData}
          empData={empData}
          edit={edit}
          setLoading={setLoading}
          displayData={displayData}
          setDisplayData={setDisplayData}
          leavePriorities={data}
          collegeId={collegeId}
      />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Leave Type Priority</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="/">Leave</a>
                      </li>
                      <li className="breadcrumb-item active">Leave Type Priority</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="row justify-content-center my-2">
                      <h5><b>{collegeOpt?.find((s) => s.id == collegeId)?.name}</b></h5>
                  </div>
                  <div className="row">
                      <Button 
                        type="primary" 
                        className="px-2 ml-3"
                        data-toggle="modal"
                        data-target="#ModalLeavePriority"
                        style={{ background: 'green', borderColor: 'green' }}
                        onClick={() => {
                          setType("add");
                          setEdit({});
                        }}
                      >
                        + Add
                      </Button>
                  </div>
                  <div className="row mt-3">

                    {data?.map((i,k) => (
                      <>
                        <div className="col-md-1"></div>
                        <div className="col-md-10 my-3 p-3 border rounded shadow">
                            <div className="row">
                              <div className="col-md-8">
                                <p>{i?.title}</p>
                                <p className="text-green"><b>{i?.leave_type_id}</b></p>
                              </div>
                              <div className="col-md-4">
                                <Button 
                                  type="primary" 
                                  className="px-2 ml-3 float-right"
                                  title="Delete priority"
                                  style={{ background: 'red', borderColor: 'red' }}
                                  onClick={() => handleDelete(i?.id, i?.priority)}
                                >
                                  <i className="fa fa-trash " aria-hidden="true" />
                                </Button>
                                <Button 
                                  type="primary" 
                                  className="px-2 ml-3 float-right"
                                  data-toggle="modal"
                                  data-target="#ModalLeavePriorityAssign"
                                  title="Assign to employees"
                                  onClick={() => {
                                    setEdit(i)
                                  }}
                                >
                                  <i className="fa fa-tag " aria-hidden="true" />
                                </Button>
                                <Button 
                                  type="primary" 
                                  className="px-2 ml-3 float-right"
                                  data-toggle="modal"
                                  data-target="#ModalLeavePriority"
                                  title="Edit priority"
                                  onClick={() => {
                                    setType("edit")
                                    setEdit(i)
                                  }}
                                >
                                  <i className="fa fa-edit " aria-hidden="true" />
                                </Button>
                              </div>
                            </div>
                        </div>
                        <div className="col md-1"></div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LeaveTypePriority;
