import React,{useState, useRef, useEffect} from 'react';
import ModalApprovalWorkFlow from '../../../../modals/HR/Leave/ModalApprovalWorkFlow';
import ModalWorkFlowAssign from '../../../../modals/HR/Leave/ModalWorkFlowAssign';
import './ApprovalWorkflow.scss';
import {toast} from 'react-toastify';
import axios from 'axios';
import Loader from '../../../../Components/Loader/Loader';
import { CUSTOM_APPROVER, HR_WORKFLOW, EMPLOYEE} from '../../../../utils/apiConstants';
import Nodata from '../../../../Components/NoData/Nodata';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { useReactToPrint } from "react-to-print";
import { LOCAL_COLLEGE } from '../../../../utils/LocalStorageConstants';


function AppovalWorkflow({setLoading, collegeId}) {


    const [data, setData] = useState([])
    const [type, setType] = useState('')
    const [edit, setEdit] = useState()
    const [empData, setEmpData] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    const [approvers, setApprovers] = useState([])

    const [role, setRole] = useState(sessionStorage.getItem('role') ? sessionStorage.getItem('role') : null)

    const getCollegeData = () => {
        return localStorage.getItem(LOCAL_COLLEGE)
          ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
          : null;
      };
    
    const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

    const getEmpData = () => {
        setLoading(1);
        const config = {
          method: "get",
          url: `${EMPLOYEE}?college_id=${collegeId}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
        };
    
        axios(config)
          .then((res) => {
            setLoading(0);
            setEmpData(res.data.data);
            setDisplayData(res.data.data);
            console.log('employees - ', res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            toast.error(err.response.data.message);
            console.log(err);
          });
      };

    const getApprovers = async() => {
        setLoading(1)
        const config = {
            method: 'get',
            url: `${CUSTOM_APPROVER}?college_id=${collegeId}`,
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`,
                'Content-Type': 'application/json'
            },
        }
        await axios(config)
        .then(res=>{
            setApprovers(res.data.data)
        })
        .catch(err=>{
            toast.error('Something went wrong')
        })
        setLoading(0)
    }

    const getData = async () => {

        setLoading(1)

        const config = {
            method: 'get',
            url: `${HR_WORKFLOW}?type=LEAVE&college_id=${collegeId}`,
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`,
                'Content-Type': 'application/json'
            },
        }


        await axios(config)
            .then((res) => {
                setLoading(0)
                console.log('workflows - ', res.data.data);
                setData(res.data.data)
                // toast.success("data fetched")
            })
            .catch(err => {
                setLoading(0)
                toast.error(err.response.data.message)
            })
    }

    useEffect(() => {
        getData()
        getApprovers()
        getEmpData()
    }, [])

    const tableRef = useRef();

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Approval Workflow-" + new Date().getFullYear(),
        sheet: "Approval Workflow",
    });

    const PrintRecipt = useReactToPrint({
        content: () => tableRef.current,
      });
  
    //   const handlePrint = () => {
    //     PrintRecipt();
    //   };

    const handlePrint = () => {
        // Make the table visible
        tableRef.current.style.display = 'table';
      
        // Delay the PDF export
        setTimeout(() => {
          PrintRecipt();
          // Hide the table again if needed
          tableRef.current.style.display = 'none';
        }, 1); // Adjust the delay as needed
      };

      const getCurrentDate = () => {
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;
        return formattedDate;
      };

    return (
        <div className='AppovalWorkflow'>
            <ModalApprovalWorkFlow type={type} data={edit} reloadData={getData} setLoading={setLoading} approvers={approvers} collegeId={collegeId}/>
            <ModalWorkFlowAssign workflow={edit} getEmpData = {getEmpData} empData = {empData} type={type} data={data} reloadData={getData} setLoading={setLoading} approvers={approvers} collegeId={collegeId} displayData={displayData} setDisplayData={setDisplayData}/>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Approval Workflow</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Employer</li>
                                            <li className="breadcrumb-item active">Approval Wokflow</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="card">
                                <div className="card-body">

                                <div className="row d-flex justify-content-end p-3">
                                        {
                                            role !== 'ADMIN' ? <button
                                            className="btn btn-rounded btn-success btn-outline px-4"
                                            data-toggle="modal"
                                            data-target="#ModalApprovalWorkFlow"
                                            onClick={()=>{setEdit();setType('add')}}
                                        >
                                            Add +
                                        </button> : null
                                        }
                                        <button onClick={onDownload} className="btn btn-success ml-auto">
                                            Export Excel
                                        </button>
                                        <button
                                          className="btn btn-primary ml-2"
                                          onClick={handlePrint}
                                        >
                                            Export PDF
                                        </button>
                                    </div>

                                    

                                    <div>
                                        {data && data.length!==0 ? data?.map((i, key) => (

                                            role !== 'ADMIN' ?
                                            <div
                                                className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                                            >
                                                <div className="col-10 row" key={key}>
                                                    <div className="role-title">
                                                        {i.title}
                                                    </div>
                                                    <span className="badge badge-soft-success ml-3 mt-1" style={{fontSize:'1em'}}><b>Code : {i.id}</b></span>
                                                </div>
                                                <div
                                                data-toggle="modal"
                                                data-target="#ModalWorkFlowAssign"
                                                onClick={()=>{setType('edit');setEdit(i)}}
                                                className="ml-3"
                                                >
                                                <i
                                                    className="fa fa-tags "
                                                    aria-hidden="true"
                                                    style={{
                                                        color: "#364277",
                                                        cursor: "pointer",
                                                    }}
                                                    onmouseover="this.style.color='orange'"
                                                    onmouseout="this.style.color='#364277'"
                                                    /> Assign    
                                                </div>   
                                                <div 
                                                data-toggle="modal"
                                                data-target="#ModalApprovalWorkFlow"
                                                onClick={()=>{setType('edit');setEdit(i)}}
                                                className="ml-3"
                                                >   
                                                    <span><i className="fa fa-edit " aria-hidden="true"/> Edit</span>
                                                </div>
                                            </div>
                                            : <div
                                            className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                                        >
                                            <div className="col-11" key={key}>
                                                <div className="role-title">
                                                    {i.title}
                                                </div>
                                            </div>
                                        </div>
                                        ))
                                        :
                                        <Nodata/>
                                        }
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                            <table
                                      id="table_id"
                                      ref={tableRef}
                                      className="display table table-bordered  nowrap table-hover "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%", display: 'none' }}
                                    >
                                        <div className="col-12 text-center">
                                          {/* {data.length > 0 && (
                                            <div>
                                            <h4 className="text-center">
                                              Approval Workflow List -
                                                {data[0]?.college_id && collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
                                            </h4>
                                            <span> <p className="text-right float-right"> Printed On - {" " + getCurrentDate()} </p></span>
                                            </div>
                                          )} */}
                                        <thead>
                                          <tr>
                                            <td colSpan={10}>
                                              <div>
                                                <h4 className="text-center">
                                                  Approval Workflow List -{" "}
                                                    {data?.length > 0 && data[0]?.college_id &&
                                                      collegeOpt?.find((s) => s.id === data[0]?.college_id)?.name}
                                                </h4>
                                                <span>
                                                  <p className="text-right float-right">
                                                    Printed On - {getCurrentDate()}
                                                  </p>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th>Title</th>
                                                <th>Type</th>
                                                <th>Approver 1</th>
                                                <th>Approver 2</th>
                                                <th>Approver 3</th>
                                                <th>Approver 4</th>
                                                <th>Remark</th>
                                                <th>Notification</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ?
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                          :
                                          data
                                          .map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{i?.title}</td>
                                              <td>{i?.type}</td>
                                              <td>{empData?.find(s=>s.id==i?.approver_1)?.first_name+ ' '+ empData?.find(s=>s.id==i?.approver_1)?.last_name}</td>
                                              {/* <td>{i?.approver_1}</td> */}
                                              <td>{empData?.find(s=>s.id==i?.approver_2)?.first_name+ ' '+ empData?.find(s=>s.id==i?.approver_2)?.last_name}</td>
                                              {/* <td>{i?.approver_2}</td> */}
                                              <td>{empData?.find(s=>s.id==i?.approver_3)?.first_name+ ' '+ empData?.find(s=>s.id==i?.approver_3)?.last_name}</td>
                                              {/* <td>{i?.approver_3}</td> */}
                                              <td>{empData?.find(s=>s.id==i?.approver_4)?.first_name+ ' '+ empData?.find(s=>s.id==i?.approver_4)?.last_name}</td>
                                              {/* <td>{i?.approver_4}</td> */}
                                              <td>{i?.remark}</td>
                                              <td>{i?.notification}</td>
                                              </tr>
                                          })
                                        }
                                        </tbody>
                                        </div>
                                    </table>
                        </div>

                        

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppovalWorkflow