import React from 'react';
import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Http } from '../Services/Services.js';
import { STUDENT_SESSION } from '../utils/apiConstants';
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM } from '../utils/LocalStorageConstants';
import ModalStudentProfileUpload from './Students/ModalStudentProfileUpload.jsx';
import { Drawer } from 'antd';
import { ROUTES } from '../Router/routerConfig.js';
import { toast } from "react-toastify";
import StudentProfileSwitches from '../Components/Admission/Profile/StudentProfileSwitches.jsx';
import HostelEditWarden from '../Pages/HostelEditWarden.jsx';
import StudentFee from '../Components/Student/Profile/StudentFee.jsx';
import StudentDocuments from '../Components/Student/Profile/StudentDocuments.jsx';
import StudentStatus from '../Components/Student/Profile/StudentStatus.jsx';
import OffCanvasStudentProfile from '../Components/OffCanvas/Student/OffCanvasStudentProfile.jsx';
import StudentProfileSwitchesHostel from '../Components/Admission/Profile/StudentProfileSwitchesHostel.jsx';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SECTION, ACADEMICS_ADD_SEMESTER } from '../utils/Academics.apiConst.js';
import { EMPLOYEE_ALL2} from "../utils/apiConstants";

import { getFileUrl } from '../Helpers/Helpers';
import { ASSET_EMPLOYEE_IMAGE } from '../utils/AssetsReferenceTypes';
import { SESSION_ROLE } from '../utils/sessionStorageContants';
import { SessionOpt } from '../Data/student/sessionData';
import { STUDENT_ADMISSION } from '../utils/apiConstants';
import { ADDHOSTELFEE } from '../utils/fees.apiConst';
import { HOSTEL, HOSTEL_ROOMS, HOSTEL_TYPE } from '../utils/Hostel.apiConst';
import { international } from "../Data/Countries/international";
import { GET_HOSTEL_FEE } from '../utils/InfoUploadingApiConstants.js';
import Select from "react-select";
import { GET_ASSIGNED_PICKUPPOINTS, GET_TRANSPORT_FEES, PICKUP_POINTS, TRANSPORT_ROUTE } from '../utils/Transport.apiConst.js';


function StudentProfile1( { 
  setLoading, 
  collegeId,
  selectedStudentData } ) {

    const emp_id = sessionStorage.getItem("employee_id");
  
    let auth = sessionStorage.getItem("UMS_auth");

    const location = useLocation();

    const navigate = useNavigate();
  
    const [searchParams] = useSearchParams();
  
    const [data, setData] = useState(location?.state?.data);
    const [data2, setData2] = useState();
    const [data3, setData3] = useState();
    const [data4, setData4] = useState();

    const [employee, setEmployee] = useState([]);

  
    const [studentDetails, setStudentDetails] = useState();
  
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  
    const [flag, setFlag] = useState(false);
  
    const [allStudentsDetails, setAllStudentsDetails] = useState([]);
  
    const [allstudentloading, setAllStudentLoading] = useState(false);
  
    const currentSemester = searchParams.get('sem');
    const currentDepartemnt = searchParams.get('depart');
    const currentSession = searchParams.get('session');
    const currentClass = searchParams.get('class');
    const currentSection = searchParams.get('section');
  
    const getData = async () => {
      setAllStudentLoading(true)
      await Http.get(`${STUDENT_SESSION}?class_id=${currentClass}&semester_id=${currentSemester}&session_id=${currentSession}`)
      .then(res=>{
        setAllStudentsDetails(res.data.data)
        setAllStudentLoading(false)
      })
    }
  
    const getData1 = async()=>{
      setAllStudentLoading(true)
      await Http.get(`${STUDENT_SESSION}?class_id=${searchParams.get('class')}&semester_id=${searchParams.get('sem')}&session_id=${searchParams.get('session')}`)
      .then(res=>{
        setAllStudentsDetails(res.data.data)
        setAllStudentLoading(false)
      })
    }
  
    const onClose = () => {
      setIsDrawerVisible(false);
    };
  
    const OpenDrawer = () => {
      setIsDrawerVisible(true);
    };
  
    let role1 = sessionStorage.getItem("role");
  
    const localDepartments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));
    const localPrograms = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
    const localColleges = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  
    const [tab, setTab] = useState(" ");
  
    const changeDir = (dir, i) => {
      navigate(
        `${dir}/${i?.data?.student_id}?depart=${currentDepartemnt}&session=${currentSession}&class=${currentClass}&sem=${currentSemester}&section=${currentSection}`,
        {
          state: {
            data: allStudentsDetails,
          },
        }
      );
    };
  
    const id = useParams().id;
    // console.log("id",id);
  
  // console.log(id)
    useEffect(() => {
      setData(location?.state?.data);
    }, [location.state]);

    useEffect(() => {
      setStudentDetails(selectedStudentData);
    }, [selectedStudentData]);
  
  
    useEffect(()=>{
      if(currentClass && currentSemester && currentSession) 
      {
        getData()
      }
    },[currentSemester, currentClass, currentSession])

    
  const getEmpData = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE_ALL2}?employee_id=${emp_id}`,
      headers: {
        Authorization: `Bearer ${auth}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then((res) => {
        // console.log('emp data - ', res.data.data);
        setEmployee(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong",err);
      });
    
  }
  
  
    useEffect(()=>{
      // console.log("Hello")
        getData1();
        getEmpData();
    },[])

    const getData2 = async () => {
      setLoading(1);
  
      const config = {
        method: "get",
        url: `${ACADEMICS_ADD_CLASS}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      await axios(config)
        .then((res) => {
          setLoading(0);
          setData2(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
        });

        const config1 = {
          method: "get",
          url: `${ACADEMICS_ADD_SECTION}`,
          headers: {
            "Content-Type": "application/json",
          },
        };
    
        await axios(config1)
          .then((res) => {
            setLoading(0);
            setData3(res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
          });

          const config2 = {
            method: "get",
            url: `${ACADEMICS_ADD_SEMESTER}`,
            headers: {
              "Content-Type": "application/json",
            },
          };
      
          await axios(config2)
            .then((res) => {
              setLoading(0);
              setData4(res.data.data);
            })
            .catch((err) => {
              setLoading(0);
              console.log(err);
            });

    };

    useEffect(() => {
      getData2();
    },[]);

   

    const [info, setInfo] = useState({});
  let y = [];

  const fileref = useRef(null);

  const fileref1 = useRef(null);
  const fileref2 = useRef(null);
  const fileref3 = useRef(null);

  const [yearData, setYearData] = useState([]);

  const [routeData, setRouteData] = useState([]);

  //Hostel Beds
  const [floors, setFloors] = useState([]);

  const [rooms, setRooms] = useState([]);

  const [beds, setBeds] = useState([]);

  const [roomData, setRoomData] = useState([]);

  const [hostelData, setHostelData] = useState([]);

  const [data1, setData1] = useState([]);

  const [hostelFeeData, setHostelFeeData] = useState([]);

  const options = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const [info1, setInfo1] = useState({
    transport_amount: "",
    occupied_id: "",
    route_id: "",
    pickuppoint_id: "",
    ug_university: "",
    session_id: "",
    ug_university_percentage: "",
    pg_university: "",
    pg_university_percentage: "",
    ug_marksheet: "",
    pg_marksheet: "",
    student_session_id: "",
    room_hostel_id: "",
    floor_number: "",
    hostel_room_type_id: "",
    room_name_number: "",
    bed_no: "",
    hostel_amount: "",
    year_id: "",
    year: "",
    // months:[]
  });

  const clearData = () => {
    setInfo1({
      transport_amount: "",
      route_id: "",
      pickuppoint_id: "",
      transport_amount: "",
      ug_university: "",
      session_id: "",
      ug_university_percentage: "",
      pg_university: "",
      pg_university_percentage: "",
      ug_marksheet: "",
      pg_marksheet: "",
      student_session_id: "",
      room_hostel_id: "",
      floor_number: "",
      hostel_room_type_id: "",
      room_name_number: "",
      bed_no: "",
      hostel_amount: "",
      year_id:"",
      year:"",
      // months: ""
    });
  };

  const handleSelectAllMonths = (selectAllOption) => {
    if (selectedMonths.includes(selectAllOption.value)) {
        // Deselect all months
        setSelectedMonths([]);
        setInfo1(prev => ({
            ...prev,
            months: []
        }));
    } else {
        // Select all months
        const allMonths = options.map(option => option.value);
        setSelectedMonths(allMonths);
        setInfo1(prev => ({
            ...prev,
            months: allMonths
        }));
    }
};

  const [user, setUser] = useState();

  const [Id, setId] = useState("");
  const [s_id, setS_Id] = useState("");

  const [hostelFeeMessage, setHostelFeeMessage] = useState("");

  const [role, setRole] = useState(sessionStorage.getItem(SESSION_ROLE));
  const [editor, setEditor] = useState(true);

  const [pickuppointData1, setPickuppointData1] = useState([]);

  const [pickuppointData, setPickuppointData] = useState([]);

  useEffect(() => {
    setRole(sessionStorage.getItem(SESSION_ROLE));
  }, [sessionStorage.getItem(SESSION_ROLE), flag]);

  useEffect(() => {
    if (role == "ADMIN" || role == "SUPERADMIN") {
      setEditor(false);
    } else {
      setEditor(true);
    }
  }, [role, flag]);

  const getData3 = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_ADMISSION}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        // console.log(res.data.data);
        setUser(res.data.data);
        setId(searchParams.get("session_id"));
        setS_Id(searchParams.get("session"));
        setStudentDetails(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response?.data?.message);
      });

    await axios({
      ...config,
      url: `${TRANSPORT_ROUTE}?college_id=${collegeId}`,
    })
      .then((res) => {
        setLoading(0);
        setRouteData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err);
      });
    await axios({
      ...config,
      url: `${PICKUP_POINTS}?college_id=${collegeId}`,
    })
      .then((res) => {
        setPickuppointData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err);
      });
    await axios({
      ...config,
      url: `${GET_ASSIGNED_PICKUPPOINTS}?college_id=${collegeId}`,
    })
      .then((res) => {
        setPickuppointData1(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err);
      });
    await axios({
      ...config,
      url: `${GET_TRANSPORT_FEES}?student_id=${searchParams.get("session_id")}`,
    })
      .then((res) => {
        let x = res.data.data;
        // console.log('x = ', res.data.data);
        if (res.data.data.length > 0) {
          info1.year_id = x[0].year;
          info1.route_id = x[0].route_id;
          info1.pickuppoint_id = x[0].pickuppoint_id;
          info1.transport_amount = x[0].amount;
          let mon = [];
          x[0].months = JSON.parse(x[0].months);
          x[0].months.forEach((element) => {
            mon.push(options[element - 1]);
          });
          setSelectedMonths(mon);
        }
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err);
      });

    await axios({ ...config, url: `${HOSTEL}` })
      .then((res) => {
        setHostelData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
      });

    await axios({ ...config, url: `${HOSTEL_TYPE}?college_id=${collegeId}` })
      .then((res) => {
        setRoomData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
      });

    // await axios({ ...config, url: `${HOSTEL_ROOMS}?status=VACANT` })
    //   .then(async (res) => {
    //     y = res.data.data;
    //     // console.log('y - ', res.data.data);
    //     await setData1(res.data.data);
    //     await axios({
    //       ...config,
    //       url: `${ADDHOSTELFEE}?student_id=${searchParams.get("session_id")}`,
    //     })
    //       .then((res) => {
    //         let x = res.data.data;
           
    //         if (res.data.data.length > 0) {
    //           try {
    //             info1.bed_no = x[0]?.bed_no;
    //             info1.year = x[0]?.year;
                
    //             info1.room_name_number = x[0]?.room_id;
    //             let otherInfo = y?.filter(
    //               (s) => s.hostelRooms.hostel_no_bed == x[0].bed_no
    //             );
    //             info1.room_hostel_id = otherInfo[0]?.hostel.id;
    //             info1.floor_number = otherInfo[0]?.hostelRooms.floor_number;
    //             info1.hostel_room_type_id =
    //               otherInfo[0]?.hostelRooms.hostel_room_type_id;
    //               let mon = [];
    //               x[0].months = JSON.parse(x[0].months);
    //               x[0].months.forEach((element) => {
    //                 mon.push(options[element - 1]);
    //               });
    //               setSelectedMonths(mon);
    //           } catch (err) {
    //             console.log(err);
    //           }
    //         }
    //         setLoading(0);
    //       })
    //       .catch((err) => {
    //         setLoading(0);
    //         toast.error(err);
    //       });
    //   })
    //   .catch((err) => {
    //     setLoading(0);
    //     toast.error("Something Went Wrong");
    //   });
  };

  const handleChange1 = (e) => {
    console.log('info1 - ', info1);
    const { name, value } = e.target;
    setInfo1((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  
  const handleEdit = async () => {
    let months = selectedValue?.map((item, key) =>
      item.value ? item?.value : item
    );
    
    info1.transport_amount = routeData?.find(
      (s) => s.id == info1.route_id
    )?.fare;
    info1.student_session_id = Id;
    info1.session_id = s_id;
    info1.hostel_amount = data1?.filter(
      (s) => s.roomType.id == parseInt(info1.hostel_room_type_id)
    )[0]?.roomType.with_food;
    info1.occupied_id = data1?.filter(
      (s) => s.hostelRooms.hostel_no_bed == info1.bed_no
    )[0]?.hostelRooms.id;
    // console.log('info1 - ', info1);
    setLoading(1);
    const config = {
      method: "put",
      url: `${STUDENT_ADMISSION}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
        ...info1,
        months,
      },
    };

    await axios(config)
      .then(async (res) => {
        // fileref.current.value = null;
        // fileref1.current.value = null;
        // fileref2.current.value = null;
        // fileref3.current.value = null;
        toast.success("Update Success");
        await getData3();
        setLoading(0);
        console.log(res);
        navigate(ROUTES.Warden.StudentDetails)
      })
      .catch((err) => {
        setLoading(0);
        clearData();
        console.log(err);
        navigate(ROUTES.Warden.StudentDetails);
        toast.error("Something Went Wrong");
        // toast.error(err.response.data.message);
      });
  };

  const selectAllOption = { label: "Select All", value: "all" };

  const [selectedMonths, setSelectedMonths] = useState([]);

  const handleSelectChange = (selectedValues) => {
    if (selectedValues.includes(selectAllOption)) {
      setSelectedMonths(options.map((option) => option.value));
    } else {
      setSelectedMonths(
        selectedValues.filter((val) => val !== selectAllOption)
      );
    }
  };

  const isSelectAllSelected = selectedMonths.length === options.length;

  const selectedValue = isSelectAllSelected ? [...options] : selectedMonths;

  const [countries, setCountries] = useState([]);

  const setCountryAndState = async () => {
    let arr = [];
    await international?.map((item, key) => {
      arr.push(item?.country);
    });
    setCountries(arr);
  };

  useEffect(() => {
    setStudentDetails({ ...user });
  }, [user, flag]);

  useEffect(() => {
    getData3();
    setCountryAndState();
    let yearArr = [];
    let f=0;
    SessionOpt?.map((value, index) => {
      if(f==0) {
        yearArr.push((parseInt(value.id) + 1).toString());
        f=1;
      }
      yearArr.push(value.id);
    })
    // console.log("year data - ", yearArr);
    setYearData(yearArr);
  }, []);

  useEffect(() => {
    getData3();
  }, [flag]);

  useEffect(() => {
    if (info1.hostel_room_type_id || info1.room_hostel_id) {
      // console.log(info1);
      let arr =
        data1 &&
        data1?.filter(
          (s) =>
            s.roomType.room_type ==
              roomData?.find((s) => s.id == info1.hostel_room_type_id)
                ?.room_type &&
            s.hostelRooms.room_hostel_id == info1.room_hostel_id
        );
      let new_set = new Set();
      arr.forEach((item) => {
        new_set.add(item.hostelRooms.floor_number);
      });
      arr = Array.from(new_set);
      setFloors(arr);
    }
    if (info1.floor_number) {
      let arr1 =
        data1 &&
        data1?.filter(
          (s) =>
            s.roomType.room_type ==
              roomData?.find((s) => s.id == info1.hostel_room_type_id)
                ?.room_type &&
            s.hostelRooms.room_hostel_id == info1.room_hostel_id &&
            s.hostelRooms.floor_number == info1.floor_number
        );
      let new_set1 = new Set();
      arr1.forEach((item) => {
        new_set1.add(item.hostelRooms.room_name_number);
      });
      arr1 = Array.from(new_set1);
      setRooms(arr1);
    }

    if (info1.room_name_number) {
      let arr2 =
        data1 &&
        data1?.filter(
          (s) =>
            s.roomType.room_type ==
              roomData?.find((s) => s.id == info1.hostel_room_type_id)
                ?.room_type &&
            s.hostelRooms.room_hostel_id == info1.room_hostel_id &&
            s.hostelRooms.floor_number == info1.floor_number &&
            s.hostelRooms.room_name_number == info1.room_name_number
        );
      let new_set2 = new Set();
      arr2.forEach((item) => {
        new_set2.add(item.hostelRooms.hostel_no_bed);
      });
      arr2 = Array.from(new_set2);
      setBeds(arr2);
    }
  }, [
    info1.hostel_room_type_id,
    info1.room_hostel_id,
    info1.floor_number,
    info1.room_name_number,
    flag,
  ]);

  const getHostelFeeData = async () => {
    const config = {
        method: "get",
        url: GET_HOSTEL_FEE,
        headers: {
            "Content-Type": "application/json",
        },
    };

    await axios(config)
        .then((res) => {
            console.log(res.data.data)
            setHostelFeeData(res.data.data);
        })
        .catch((err) => {
            console.log(err);
        });
};

useEffect(() => {
  getHostelFeeData();
}, []);

useEffect(() => {
  if (info1.year) {
      const fetchHostelFeeData = async () => {
          try {
              const response = await axios.get(GET_HOSTEL_FEE);
              const hostelFeeDataa = response.data.data;
              // console.log("hostelFeeDataa -", hostelFeeDataa);
              const hostelAssignedForYear = hostelFeeDataa && hostelFeeDataa?.find( (item) => item?.student_id == studentDetails?.id && item?.year == info1?.year );
              if (hostelAssignedForYear) {
                  setHostelFeeMessage("HOSTEL IS ASSIGNED FOR THE YEAR");
              } else {
                  setHostelFeeMessage("");
              }
              // console.log("hostelAssignedForYear -", hostelAssignedForYear);
          } catch (error) {
              console.error('Error fetching hostel fee data:', error);
          }
      };
      fetchHostelFeeData();
  }
}, [info1.year, studentDetails?.id]);
  
    return (
      <>
        <div className="StudentProfile1">
          <>
          
            <Drawer
              title="Students with similar class"
              placement={'right'}
              width={500}
              onClose={onClose}
              open={isDrawerVisible}
            >
              <div className="row">
                <div className="col-md-12">
                
                  { 
                    allStudentsDetails?.map((i, key)=>(
                      <div className="d-flex p-2 px-3 card rounded cursor-pointer"
                        onClick={()=>{
                            if(role1=="WARDEN"){
                              setIsDrawerVisible(false)
                               setTab(" ")
                               setFlag((flag)=>!flag)
                               changeDir(ROUTES.Warden.ViewStudentProfile,i);
                            }
                        }}
                      >
                        <h6>
                        {i.studentInfo?.name}
                        </h6>
                        <p className="mb-0">
                          {i.data?.student_id}
                        </p>
                      </div>
                    ))
                  }
                </div>
              </div>
            </Drawer>
                
            <div
                className="modal fade"
                id="StudentProfile1"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
            <div
                className="modal-dialog modal-dialog-centered mw-100 w-50"
                role="document"
            >
            <div className="modal-content">

                  {/* start page title */}
                  <div className="row">
                    <div className="col-12">
                      <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLongTitle">
                          Student Profile
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={() => navigate(ROUTES.Warden.StudentDetails)}
                        >
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end page title */}
                  <div className="col-12">
                    <div className="modal-title-box d-flex align-items-center">
                    {
                        !searchParams.get("hos") && !searchParams.get("trans") ?<>
                      <button
                        className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                        onClick={() => {
                          role1 == "WARDEN"
                            ? navigate(ROUTES.Warden.StudentDetails, {
                              state: {
                                depart: searchParams.get("depart"),
                                class: searchParams.get("class"),
                                session: searchParams.get("session"),
                                section: searchParams.get("section"),
                                sem: searchParams.get("sem"),
                              },
                            })
                            :  navigate(ROUTES.Accountant.StudentDetails, {
                              state: {
                                depart: searchParams.get("depart"),
                                class: searchParams.get("class"),
                                session: searchParams.get("session"),
                                section: searchParams.get("section"),
                                sem: searchParams.get("sem"),
                              },
                            });
                        }}
                      >
                        <i className="ri-arrow-left-line"></i>
                      </button>
                      
                      
                      <h4 className="mb-0">STUDENT LIST</h4></> : null
  }
                      {
                        searchParams.get("hos") == 1 ?
                      <>
                      <button
                        className="btn ml-3 btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                        onClick={() => {
                          role1 == "WARDEN"
                            ? navigate(ROUTES.Warden.HostelFees)
                            : navigate(ROUTES.Accountant.Hostel.hostelFee);
                        }}
                      >
                        <i className="ri-arrow-left-line"></i>
                      </button>
                      <h4 className="mb-0">HOSTEL LIST</h4> </> :  null
                      }  
                
                    </div>
                  </div>
                  <div>
                    <hr />
                    <h3 className="text-center">Student Profile</h3>
                    <hr />
                  </div>
  
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="box box-primary">
                          <div className="box-body box-profile">
                            <div className="card py-2">
                              <ul className="list-group list-group-unbordered pt-3">
                                <img
                                  className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                                  src={`${studentDetails?.student_picture
                                      ? studentDetails?.student_picture
                                      : "/assets/images/Nexenstial Logo.jpg"
                                    }`}
                                  width="15%"
                                  style={{ aspectRatio: "1/1" }}
                                />
                                <br />
                                <h5 className="profile-username text-center">
                                  {studentDetails?.name}
                                  <br/> 
                                  <p><h6> ({studentDetails?.user_id}) </h6></p>
                                </h5>
                                
                          
                                <li className="list-group-item listnoback d-flex justify-content-between">
                                  <b>Program : {
                                      localPrograms?.find(
                                        (s) => s?.id == studentDetails?.program_id
                                      )?.name
                                    }
                                  </b>{" "}
                                  <b>College : {
                                      localColleges?.find(
                                        (s) => s?.id == studentDetails?.college_id
                                      )?.name
                                    }
                                  </b>{" "}  
                                </li>
                                <li className="list-group-item listnoback d-flex justify-content-between">
                                  <b>Department : {
                                      localDepartments?.find(
                                        (s) =>
                                          s?.id == studentDetails?.department_id
                                      )?.name
                                    }
                                  </b>{" "}
                                  <b>Class : {
                                      data2?.find(
                                        (s) =>
                                          s?.department_id == studentDetails?.department_id
                                      )?.name
                                    }
                                  </b>{" "}
                                </li>
                                <li className="list-group-item listnoback d-flex justify-content-between">
                                  <b>Semester : {
                                    data4?.find(
                                      (s) => s?.class_id == (data2?.find(
                                        (s) => s?.department_id == studentDetails?.department_id
                                      )?.id)
                                    )?.name
                                  }
                                  </b>{" "}
                                  <b>Section : {
                                      data3?.find(
                                        (s) =>
                                          s?.department_id == studentDetails?.department_id
                                      )?.name
                                    }
                                  </b>{" "}
                                </li>
                                
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="card ">
                          <div className="card-body">
                                  

          <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="class">Select Year</label>
              <select
                className="form-control"
                name="year"
                id="year"
                value={info1.year}
                onChange={handleChange1}
              >
                <option>Select Year</option>
                {SessionOpt &&
                  SessionOpt?.map((item, key) => {
                    return (
                      <option value={item?.id}>{item?.name?.split('-')[0]}</option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Select Months</label>
              <Select
                isMulti
                options={[selectAllOption, ...options]}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </div>
          </div>
          
          {hostelFeeMessage && <h5 className="text-primary ml-2">{hostelFeeMessage}<span>{" " + " " + info1?.year}</span></h5>}

          </div>

          <br/>
          <br/>

          {/* {info1?.year && ( */}
          <div className="row">

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="validationCustom02">
                Hostel<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                name="room_hostel_id"
                id="hostel"
                value={info1?.room_hostel_id}
                onChange={handleChange1}
              >
                <option value="">Select Hostel</option>
                {role1 == "WARDEN" ? 
                  hostelData?.filter((s)=> s.id == employee[0]?.hostel_id).map((i, key) => (
                    <option value={i.id} key={key}>
                      {i.hostel_name}
                    </option>
                  ))
                  : hostelData?.map((i, key) => (
                    <option value={i.id} key={key}>
                      {i.hostel_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="validationCustom02">
                Room Type<span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                name="hostel_room_type_id"
                id="type"
                value={info1.hostel_room_type_id}
                onChange={handleChange1}
              >
                <option value="">Select Room Type</option>
                  {[...new Set(
                  data1
                    ?.filter((s) => s.hostel.id == info1.room_hostel_id)
                    ?.map((i) => i.roomType.id)
                  )].map((uniqueId, key) => {
                    const uniqueItem = data1.find((item) => item.roomType.id === uniqueId);
                    return (
                      <option value={uniqueItem.roomType.id} key={key}>
                        {uniqueItem.roomType.room_type}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="validationCustom02">
                Floor Number <span style={{ color: "red" }}>*</span>
              </label>
              <select
                className="form-control"
                name="floor_number"
                value={info1.floor_number}
                onChange={handleChange1}
              >
                <option value="">Select Floor</option>
                {floors &&
                  floors?.map((item, key) => (
                    <option value={item}>{item}</option>
                  ))}
              </select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="validationCustom02">
                Room Number / Name <span style={{ color: "red" }}>*</span>
              </label>
              <select
                type="text"
                className="form-control"
                placeholder="Enter Hostel Name"
                name="room_name_number"
                value={info1.room_name_number}
                onChange={handleChange1}
              >
                <option value="">Select Room Number</option>
                {rooms &&
                  rooms?.map((item, key) => (
                    <option value={item}>{item}</option>
                  ))}
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="validationCustom02">
                Bed Number
                <span style={{ color: "red" }}>*</span>{" "}
              </label>
              <select
                type="text"
                className="form-control"
                placeholder="Enter Bed Number"
                name="bed_no"
                value={info1.bed_no}
                onChange={handleChange1}
              >
                <option value="">Select Bed Number</option>
                {beds &&
                  beds?.map((item, key) => (
                    <option value={item}>{item}</option>
                  ))}
              </select>
            </div>
          </div>
          </div>
        {/* )}       */}

      <div className="row float-right mr-4">
        <button className="btn btn-success"
                data-dismiss="modal"
                aria-label="Close"
                // onClick={() => navigate(ROUTES.Warden.StudentDetails)}
                onClick={handleEdit}
        >
          Allocate
        </button>
      </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end card */}

                </div>
              </div>
              {/* container-fluid */}
              <OffCanvasStudentProfile />
  
              <div className="rightbar-overlay" />
            </div>
          </>
        </div>
      </>
    );
    
  }

export default StudentProfile1;
