import React from "react";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import Loader from "../../Components/Loader/Loader";
import { getFileUrl } from "../../Helpers/Helpers";
import { ASSET_MEDIA } from "../../utils/AssetsReferenceTypes";
import { toast } from "react-toastify";
import { MEDIA_UPLOAD } from "../../utils/InfoUploadingApiConstants";
import HomeoInfo from "./HomeoInfo";
import { type } from "@testing-library/user-event/dist/type";

function MediaInfo({ setLoading }) {
  const fileref = useRef(null);
  let role = sessionStorage.getItem("role");
  const [info, setInfo] = useState({
    title: "",
    date: "",
    attachment: "",
    video: "",
    type: "",
  });

  const [type, setType] = useState("");

  const [fileType, setFileType] = useState("Attachments");

  const handleChange = (e) => {
    const { name, value } = e.target;

     // If the file type is changed, update the state
  if (name === "link") {
    setFileType(value);
  }

    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      title: "",
      date: "",
      attachment: "",
      video: "",
      type: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.date  || !info?.title || !info?.type) {
      toast.error("Please Enter all the required Details");
      return;
    }
    setLoading(1);
    const config = {
      method: "post",
      url: MEDIA_UPLOAD,
      headers: {
        "Content-Type": "application/json",
        // 'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: info,
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        clearData();
      })
      .catch((err) => {
        console.log(err);
      //  toast.error("Something Went Wrong");
      });

    setLoading(0);
  };

  // const handleChange1 = async (e) => {
  //   console.log(e.target.files[0]);
  //   let empId = Math.floor(Math.random() * 100);
  //   try {
  //     const d = await getFileUrl(
  //       ASSET_MEDIA,
  //       "media",
  //       e.target.value.split(".")[1],
  //       setLoading,
  //       e.target.files[0]
  //     );
  //     info.attachment = d;
  //    // info.video = d;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
  
      // Determine if the input is for attachment or video
      const isAttachment = e.target.name === "attachment";
  
      setInfo((prev) => ({
        ...prev,
        [isAttachment ? "attachment" : "video"]: d,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  console.log("ekk")

  return (
    <div>
      {role == "WEBSITE" ? (
        <div className="col-xl-12 p-0 col-lg-9 d-flex col-md-12 col-sm-12"
             style={{ justifyContent: "center", alignItems: "center" }} >
          <div className="card w-25" style={{ marginTop: "2rem" }}>
            <div className="card-body">
              <div className="row-gutters mt-4">
                <h4 className="text-center">Upload Media Documents</h4>
                <div style={{ marginTop: "3rem" }}>
                  <h5>Title</h5>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Title Of the Subject"
                        name="title"
                        value={info?.title}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "3rem" }}>
                  <h5>Date</h5>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        placeholder="Enter Description Of the Subject"
                        value={info?.date}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "3rem" }}>
                  <h5>Select File Type</h5>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select
                        id="section"
                        className="form-control"  
                        name="link"                     
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      >
                        <option value="Attachments"> Attachments </option>
                        <option value="Video Link"> Video Link </option>
                      </select>
                    </div>
                  </div>
                </div>
                {info.link === "Video Link" && ( 
                <div style={{ marginTop: "3rem" }}>
                  <h5>Video Info</h5>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Video Link"
                        name="video"
                        value={info?.video}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                )}
                <div style={{ marginTop: "3rem" }}>
                  <h5>Type</h5>
                  <div className="col-md-12">
                    <div className="form-group">
                      <select

                        id="section"
                        className="form-control"
                        name="type"
                        value={info?.type}
                        onChange={(e) => {
                          // setType(e.target.value)
                          handleChange(e);
                        }}
                      >
                        <option value="select"> Select </option>
                        <option value="Media"> Media </option>
                        <option value="Events"> Events </option>
                      </select>
                    </div>
                  </div>
                </div>
                {fileType === "Attachments" && (
                <div style={{ marginTop: "3rem" }}>
                  <h5>Attachment</h5>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        name="attachment"
                        placeholder="Enter Title Of the Subject"
                        ref={fileref}
                        onChange={(e) => handleChange1(e)}
                      />
                    </div>
                  </div>
                </div>
                )}
                
                

                <div className="form-group" style={{ marginTop: "3rem" }}>
                  <button className="btn btn-primary" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
       <HomeoInfo setLoading={setLoading} />
       )} 
    </div>
  );
}

export default MediaInfo;
