import React from "react";
import { PHD_ADMISSIONS } from "../../utils/apiConstants";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { ROUTES } from "../../Router/routerConfig";
import { useNavigate } from "react-router-dom";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";

function PhdAdmissions({ setLoading, collegeId }) {
  const [data, setData] = useState([]);

  const [showData, setShowData] = useState([]);

  const [spec, setSpec] = useState("");
  const [role, setRole] = useState("");


  const [session, setSession] = useState("");

  const navigate = useNavigate();


  useEffect(() => {
    setRole(getRole());
  }, [sessionStorage.getItem(SESSION_ROLE)]);


  const getRole = () => {
    return sessionStorage.getItem(SESSION_ROLE)
      ? sessionStorage.getItem(SESSION_ROLE)
      : null;
  };

  const getData = async () => {
    if (!session) return toast.error("Session is Required");
    setLoading(1);
    const config = {
      method: "get",
      url: `${PHD_ADMISSIONS}?student_application_session=${session}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then( async (res) => {
        setData(res.data.data);
        setShowData(res.data.data);
        console.log(res.data.data);
        if (spec) {
          let x = res.data.data?.filter((s) => s?.fetchPhdAdmission?.application_for == spec)
          console.log("X",x);
         await setShowData(
            res.data.data?.filter((s) => s?.fetchPhdAdmission?.application_for == spec)
          );
        }
        console.log(showData);
        setLoading(0);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });

  };

  useEffect(() => {
    if(!spec)
    {
       setShowData(data)
    }
    else{
       let da = data?.filter((s) => s?.fetchPhdAdmission?.application_for == spec) 
       console.log(da);
      setShowData(da);
    }
  }, [spec, session]);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-title">Filter</div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Specialisation</label>
                          <select
                            name="application_for"
                            id=""
                            className="form-control"
                            value={spec}
                            onChange={(e) => setSpec(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="Engineering">Engineering</option>
                            <option value="Pharmacy">Pharmacy</option>
                            <option value="Law">Law</option>
                            <option value="Science">Science</option>
                            <option value="Education">Education</option>
                            <option value="Commerce Management">
                              Commerce &amp; Management
                            </option>
                            <option value="Ayurveda">Ayurveda</option>
                            <option value="Homoeopathy">Homoeopathy</option>
                            <option value="Sanskrit">Sanskrit</option>
                            <option value="Arts Humanities">
                              Arts &amp; Humanities
                            </option>
                            <option value="IT Computer Science">
                              IT &amp; Computer Science
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">
                            Session <span className="text-danger">*</span>
                          </label>
                          <select
                            name="application_for"
                            id=""
                            className="form-control"
                            value={session}
                            onChange={(e) => setSession(e.target.value)}
                          >
                            <option value="">Select session</option>
                            {sessionOpt.map((i, key) => (
                              <option key={key} value={i.id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button onClick={getData} className="btn btn-primary">
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="card-title">Ph.D Admission's List</h4>
                      </div>
                    </div>
                    <hr />
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <tr>
                          <th>Sl No</th>
                          <th>Name</th>
                          <th>Phone</th>
                          <th>Email</th>
                          <th>Faculty</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                        {showData?.map((i, key) => (
                          <tr>
                            <td>{key + 1}</td>
                            <td>{i?.fetchPhdAdmission?.candidate_name}</td>
                            <td>{i?.fetchPhdAdmission?.phone}</td>
                            <td>{i?.fetchPhdAdmission?.email}</td>
                            <td>{i?.fetchPhdAdmission?.application_for}</td>
                            <td>{i?.fetchPhdAdmission?.payment_status}</td>
                            <td>
                              <button
                                className="btn btn-primary"
                                onClick={() =>
                                  {
                                    if (role == "SUPERADMIN") {
                                      navigate(
                                        ROUTES.Registar.Admission
                                          .PhdAdmissionsprofile,
                                        { state: { data: i } }
                                      );
                                    } else if (role == "ADMIN") {
                                      navigate(
                                        ROUTES.Principal
                                          .PhdAdmissionsprofile,
                                        { state: { data: i } }
                                      );
                                    }
                                  }}
                                  
                                  
                              >
                                View
                              </button>
                              <button
                                className="btn btn-danger ml-3"
                                onClick={() => {
                                {
                                  if (role == "SUPERADMIN") {
                                    navigate(
                                      ROUTES.Registar.Admission.PhdAdmitCard +
                                        "/" +
                                        (key + 1),
                                      { state: { data: i } }
                                    );
                                  } else if (role == "ADMIN") {
                                    navigate(
                                      ROUTES.Principal.PhdAdmitCard +
                                        "/" +
                                        (key + 1),
                                      { state: { data: i } }
                                    );
                                  }
                                }}
                              }

                              >
                                Print
                              </button>
                            </td>
                          </tr>
                        ))}
                      </table>
                    </div>
                  </div>
                  {/* end col */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhdAdmissions;
