import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Nodata from "../../../Components/NoData/Nodata";
import { ROUTES } from "../../../Router/routerConfig";
import { ALL_DATA, EMPLOYEE, EMPLOYEE_DELETE } from "../../../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_JOBROLES,
} from "../../../utils/LocalStorageConstants";
import getUniversityDetails from '../../../utils/universityDetails.api';
import { Http } from "../../../Services/Services";
import Swal from "sweetalert2";
import { Progress } from "antd";
import Select from "react-select";
import {profile} from "../../../assets/images/reports/profile.png"
import { STAFF_COLLEGEID_UPDATE, USERS_COLLEGEID_UPDATE, USERS_GET_DATA } from "../../../utils/InfoUploadingApiConstants";
import ModalTransferCollege from "../../../modals/ModalTransferCollege";



function EmployeeCollegeTransfer({ setLoading, collegeId }) {
  const [universityData, setUniversityData] = useState([]);
  const [search, setSearch] = useState("");

  const navigate = useNavigate();

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const local_departments = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [Employee, setEmployee] = useState([]);

  const [employeeData, setEmployeeData] = useState([]);

  const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

  const [status, setStatus] = useState("ACTIVE");
  const getJobRoles = () => {
    return localStorage.getItem(LOCAL_JOBROLES)
      ? JSON.parse(localStorage.getItem(LOCAL_JOBROLES))
      : [];
  };

  const [modalData, setModalData] = useState(null);

  const [flag, setFlag] = useState(false);

  const [jobRoles, setJobRoles] = useState(getJobRoles);

  const [selectedRole, setSelectedRole] = useState({
    value: "all",
    label: "ALL",
  });

  const [clg, setClg] = useState("");

  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [usersData, setUsersData] = useState([]);

  const [department, setDepartment] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const handleDepartmentChange = (selectedOption) => {
    console.log("Selected Department:", selectedOption);
    setSelectedDepartment(selectedOption);
  };

  useEffect(() => {
    setDepartment(JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)));
  }, [localStorage.getItem(LOCAL_DEPARTMENT), clg]);

  const changeDir = (dir, key) => {
    navigate(`${dir}/${key}`);
  };

  const getData = () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${EMPLOYEE}?college_id=${collegeId}&status=${status}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config)
      .then((res) => {
        setLoading(0);
        setEmployee(res.data.data);
        setFlag((flag)=>!flag)
        setEmployeeData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  const deleteEmployee = () => {
    console.log(modalData);
    var url;
    if(modalData.status == "ACTIVE"){
       url = EMPLOYEE_DELETE+`/${modalData.id}` + `?status=INACTIVE`
    }
    else if(modalData.status == "INACTIVE")
    {
       url = EMPLOYEE_DELETE+`/${modalData.id}` + `?status=ACTIVE`
    }
    Http.post(url)
    .then((res) => {
      console.log(res.data);
        setFlag((flag)=>!flag)
      toast.success("Employee Deleted Successfully");
      getData();
      Swal.fire(
        'Deleted!',
        'Employee Has been deleted',
        'success'
      )
    }
    )
    .catch((err) => {
      toast.error(err.response.data.message || "Can't able to delete Employee");
      console.log(err);
    });
  }

  useEffect(() => {
    getData();
  },[]);

  useEffect(() => {
    getData();
  }, [status]);

  const getUsersData = () => {
    setLoading(1);
    const config4 = {
      method: "get",
      url: USERS_GET_DATA,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config4)
      .then((res) => {
        setLoading(0);
        setUsersData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  useEffect(() => {
    getUsersData();
  },[]);

  const selectRole = (id) => {
    if (id == "all") {
      return setEmployeeData(Employee);
    }

    setEmployeeData(Employee.filter((item) => item.role == id));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };
  
    fetchData();
    // setCountryAndState();
  
  }, []);

  // console.log("selectedDepartment -", selectedDepartment.value);

  const handleUpdate = async (id) => {
    
    if(!clg || !selectedDepartment?.value) {
      return toast.error("Please select college and department to be transfered");
    }
    
    const staffId = (employeeData?.find((s) => s.id == id)?.user_id)
    console.log("staffId -", staffId);
    
    setLoading(1);
    const config = {
      method: "put",
      url: `${STAFF_COLLEGEID_UPDATE}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        college_id: clg,
        department_id: selectedDepartment?.value,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success("Successfully Transfered Staff");
        // getData();
      })
      .catch((err) => {
        console.log(err);
      });

      const config2 = {
        method: "put",
        url: `${USERS_COLLEGEID_UPDATE}/${staffId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          college_id: clg,
        },
      };
  
      await axios(config2)
        .then((res) => {
          toast.success("Successfully Transfered Staff");
          // getData();
        })
        .catch((err) => {
          console.log(err);
        });

    setLoading(0);
    setSelectedStaffId("");
  };

  console.log("selectedStaffId -", selectedStaffId);

  return (
    <div className="ViewStaff">
      
      <>
      <ModalTransferCollege
      flag={flag}
      setFlag={setFlag}
      setLoading={setLoading}
      data={selectedStaffId}
      // getData={getData}
      />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <form action="" method="POST">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Employee College Transfer</h4>
                      <div className="page-title-right">
                        <ol className="breadcrumb m-0">
                          <li className="breadcrumb-item">
                            <a href="javascript: void(0);">Human Resource</a>
                          </li>
                          <li className="breadcrumb-item active">
                            {" "}
                            Employee College Transfer{" "}
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end page title */}
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-body">
                        <h2 className="card-title">Select Criteria</h2>
                        <br />
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom01">
                                Designation
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="role"
                                name="role"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => selectRole(e.target.value)}
                              >
                                {/* <option value="">Select</option> */}
                                <option value="all">ALL</option>

                                {jobRoles?.map((data, key) => {
                                  return (
                                    <option value={data.id}>{data.name}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="">Status</label>
                              <select
                                className="form-control"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* end card */}
                  </div>
                </div>
              </form>
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <h4 className="">STAFF LIST</h4>
                        </div>                    
                    
                       

                        <div className="col-md-4">
                          <input
                            type="text"
                            id="myFilter"
                            className="form-control"
                            placeholder="Search Employee by name"
                            value={search}
                            onChange={(e) => {
                              setSearch(e.target.value);
                            }}
                          />
                        </div>
                 
                      </div>

                     
                        <br/>
                        

                      {/* <div className="row" id="myItems"> */}
                      <div className="row">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                        <tr>
                          <th>Sl.No</th>
                          <th>Staff Name</th>
                          <th>faculty</th>
                          <th>Department</th>
                          <th>Staff Id</th>
                          <th>Gender</th>
                          <th>Email</th>
                          <th>Mobile No</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {employeeData && employeeData.length !== 0 ? (
                          employeeData
                            ?.filter((post) => {
                              if (search === "") {
                                return post;
                              } else if (
                                (
                                  post.first_name.toLowerCase() +
                                  " " +
                                  post.last_name.toLowerCase()
                                ).includes(search.toLowerCase())
                              ) {
                                return post;
                              }
                            })
                            .filter((s) => s.id != 1).map((data, key) => {  
                              return (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>{data?.first_name}{" "}{data?.last_name}</td>
                                  <td>{collegeOpt?.find((s) => s.id == data?.college_id)?.name}</td>
                                  <td>{local_departments?.find((s) => s.id == data?.department_id)?.name}</td>
                                  <td>{usersData?.find((s) => s.id == data?.user_id)?.staff_id}</td>
                                  <td>{data?.gender}</td>
                                  <td>{data?.email}</td>
                                  <td>{data?.phone}</td>
                                  <td>
                                    <button 
                                      className="btn btn-primary"
                                      data-toggle="modal"
                                      data-target="#ModalTransferCollege"
                                      onClick={() => setSelectedStaffId(data?.id)} // Set selectedStaffId when button is clicked
                                    > Transfer College </button>
                                    
                                  </td>
                                </tr>
                              );
                            })
                        ) : (
                          <div className="col-12">
                            <Nodata titleTop={"No Employees Added"} />
                          </div>
                        )}
                        </tbody>
                        </table>
                        </div>
                        
                        </div>

                        {/* MODAL START */}
                        

                        {/* MODAL END */}
                      {/* </div> */}
                    </div>
                  </div>
                  {/* end card */}
                </div>
              </div>
              {/* view Modal */}
            </div>
          </div>
        </div>
        {/* End Page-content */}
      </>
    </div>
  );
}

export default EmployeeCollegeTransfer;
