import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import { LEAVE_PRIORITY_EMPLOYEE } from "../../../utils/apiConstants";
import { HR_ASSIGN_WORKFLOW } from "../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
import '../Leave/TableScroll.css';
import { Button, Input, Select } from "antd";

function ModalLeavePriorityAssign({
  empData,
  allData,
  edit,
  setLoading,
  displayData, 
  setDisplayData,
  getEmpData,
  leavePriorities,
  collegeId
}) {

    const [collegeOpt, setCollegeOpt] = useState(
            localStorage.getItem(LOCAL_COLLEGE)
        ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
        : null
    );

   const [promotes, setPromotes] = useState([]);
   const [selectAllChecked, setSelectAllChecked] = useState(false);
   const [searchName, setSearchName] = useState("");
   const [collegeSearch, setCollegeSearch] = useState("");

   const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    const updatedPromotes = displayData.filter((d) => !d?.leave_priority_id).map((d) => ({
      id: d.id,
      data: d,
    }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

   const togglePromotion = (id, data) => {

    const isPromoted = promotes?.some((student) => student.id === id);

    if (isPromoted) {
      setPromotes((prevPromotes) => prevPromotes?.filter((student) => student.id !== id));
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, {id, data}]);
    }
  };

  const assign = async (id, emp) => {
    if(!id) {
        return toast.error("Unable to get Employee id");
    }
    if(!edit?.id) {
        return toast.error("Unable to get leave priority");
    }

    console.log('data to backend - ', {leave_priority_id: edit?.id});
    const config = {
        method: 'put',
        url: `${LEAVE_PRIORITY_EMPLOYEE}/${id}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },
        data: {
            leave_priority_id: edit?.id
        }
    }

    setLoading(1);
    await axios(config)
        .then((res) => {
            toast.success('Success');
            setLoading(0);
        })
        .catch(err => {
            toast.error('Internal Server Error');
            setLoading(0);
        })
        setLoading(0);
    setPromotes([]);
    getEmpData();
    ('#ModalLeavePriorityAssign').modal('hide');
  }


    const assignAll = async () => {
        if(promotes.length == 0) {
            return toast.error("Please Select Employees to assign");
        }
        
        setLoading(1);
        let cnt = 0;
        for(let i of promotes) {
            if(!i?.id) {
                toast.error("Unable to get employee id");
                continue;
            }
            const config = {
                method: 'put',
                url: `${LEAVE_PRIORITY_EMPLOYEE}/${i?.id}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
                },
                data: {
                    leave_priority_id: edit?.id
                }
            }
            
            setLoading(1);
            await axios(config)
                .then((res) => {
                    cnt = cnt + 1;
                    setLoading(0);
                })
                .catch(err => {
                    toast.error('Internal Server Error');
                    setLoading(0);
                })
        }
        setLoading(0);
        toast.success(`Assigned priority to ${cnt} employees`);
        setPromotes([]);
        getEmpData();
        ('#ModalLeavePriorityAssign').modal('hide');
    }  
    
    const removeAssigned = async (id) => {
        if(!id) {
            return toast.error("Unable to get Employee id");
        }
    
        const config = {
            method: 'delete',
            url: `${LEAVE_PRIORITY_EMPLOYEE}/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
        }
        
        setLoading(1);
        await axios(config)
            .then((res) => {
                setLoading(0);
                toast.success("Removed successfully");
                getEmpData();
            })
            .catch(err => {
                setLoading(0);
                toast.error('Internal Server Error');
                getEmpData();
            })
        ('#ModalLeavePriorityAssign').modal('hide');
    }


    useEffect(() => {
        console.log('promotes - ', promotes);
    },[promotes]);

    useEffect(() => {
        let filteredData = [];
        if(collegeSearch) {
            console.log('hi1');
            console.log(collegeSearch);
            filteredData = empData.filter((item) => item.college_id == collegeSearch && 
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
        }
        else {
            filteredData = empData.filter((item) =>
          item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase())
        );
        }
        setDisplayData(filteredData);
      },[searchName]);

    useEffect(() => {
        let filteredData=[];
        if(collegeSearch) {
            if(searchName) {
                filteredData = empData.filter((item) => item.college_id == collegeSearch && 
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
            }
            else {
                filteredData = empData.filter((item) =>
                item.college_id == collegeSearch)
            }
            setDisplayData(filteredData);
        }
        else {
            setDisplayData(empData);
        }
    },[collegeSearch]);

    return (
        <div className="ModalLeavePriorityAssign">
        <div className="ModalEarningPolicy">
            <div
            className="modal fade"
            id="ModalLeavePriorityAssign"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"

            >
            <div
                className="modal-dialog modal-dialog-centered mw-100 w-75"
                role="document"
            >
                <div className="modal-content ">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                    Assign Leave Priorities to Employees 
                    </h5>
                    <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                <h5 className="">Leave Priority : {edit?.title}</h5>
                <hr />
                <div className="row">
                <div className="col-md-6 mt-3">
                    <div className="form-group">
                        <label htmlFor="college_id">
                        College
                        </label>
                        <select 
                        name="college_id" id="college_id" required="" className="form-control"
                        value={collegeSearch}
                        onChange={(e) => setCollegeSearch(e.target.value)}
                        >
                        {collegeOpt?.filter((s) => s.id == collegeId)?.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>
                        })}
                        </select>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                        <label>
                            Search By Employee Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Employee Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                </div>
                <br />
                <div className='col-md-12'>
                    <label>Assign Selected ({promotes.length}) : </label>
                    <Button 
                        type="primary"
                        className="float-right mr-4"  
                        // data-dismiss="modal" aria-label="Close" 
                        onClick={assignAll}
                        style={{ background: 'green', borderColor: 'green' }}
                    >
                        Assign
                    </Button>
                </div>
                </div>
                <div className="table-responsive mt-3 fixTableHead">
                        <table className="table table-bordered" style={{}}>
                            <thead className="">
                            <tr>
                                <th>
                                <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                />
                                </th>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>College</th>
                                <th>Leave Priority</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {displayData ? displayData?.map((i,k) => {
                                    return (
                                        <tr>
                                            <td>
                                            <input
                                                type="checkbox"
                                                name="checkbox[]"
                                                value={i.id}
                                                checked={promotes?.some((staff) => staff.id === i.id)}
                                                onChange={() => togglePromotion(i.id, i)}
                                                disabled={i?.leave_priority_id}
                                            />
                                            </td>
                                            <td>{k+1}</td>
                                            <td>{`${i.first_name} ${i.last_name}`}</td>
                                            <td>{collegeOpt.find((s) => s.id == i.college_id)?.name}</td>
                                            <td>
                                                {i?.leave_priority_id 
                                                    ? leavePriorities?.find((s) => s.id == i?.leave_priority_id)?.title 
                                                    : <span className="badge badge-soft-danger">Not Assigned</span>
                                                }
                                            </td>
                                            <td>
                                                {i?.leave_priority_id 
                                                    ?
                                                    <Button 
                                                        type="primary"
                                                        className="ml-2"
                                                        // data-dismiss="modal" 
                                                        // aria-label="Close" 
                                                        onClick={() => removeAssigned(i.id)} 
                                                        disabled={(promotes.length > 0)}
                                                        style={{ background: 'red', borderColor: 'red' }}
                                                    >
                                                        Remove
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="primary"
                                                        className="ml-2"
                                                        onClick={() => assign(i.id, i)} 
                                                        disabled={(promotes.length > 0) || i?.leave_priority_id}
                                                        style={{ background: 'green', borderColor: 'green' }}
                                                    >
                                                        Assign
                                                    </Button>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }) : <>Loading Employees...</>}
                            </tbody>
                        </table>
                        </div>
                        <hr />
                    <div className="row d-flex justify-content-between px-2 mt-3">
                    <Button
                        type="primary"
                        className="ml-3"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ background: 'red', borderColor: 'red' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        className="ml-3"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        onClick={() => {
                        assignAll();
                        }}
                        style={{ background: 'green', borderColor: 'green' }}
                    >
                        Assign
                    </Button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

export default ModalLeavePriorityAssign;