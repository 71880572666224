import { ROUTES } from "./../../../Router/routerConfig";

const drop = "dropdown";
const stat = "static";
const sheet = "sheet";
export const navbarDataAccountant = [
  {
    title: "Dashboard",
    type: stat,
    route: ROUTES.Accountant.Dashboard,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
    title: "Student Details",
    type: stat,
    route: ROUTES.Accountant.StudentDetails,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  {
        title: "My Leave",
        type: drop,
        drop: [
          {
            title: "Entitlement",
            type: stat,
            route: ROUTES.Accountant.Leave.Entitlement,
          },
          {
            title: "Application",
            type: stat,
            route: ROUTES.Accountant.Leave.Application,
          },
          {
            title: "Schedule",
            type: stat,
            route: ROUTES.Accountant.Leave.Schedule,
          },
        ],
      },

      {
        title: "HR",
        type: drop,
        icon: <i className="ri-flight-takeoff-line mr-2" />,
        drop: [
          {
            title: "Management",
            type: stat,
            route: ROUTES.Accountant.Leave.Management,
          },
          {
            title: "Review",
            type: stat,
            route: ROUTES.Accountant.Leave.Review,
          },
          {
            title: "Transaction Report",
            type: stat,
            route: ROUTES.Accountant.Leave.TransactionReport,
          },
          {
            title: "Entitlement Report",
            type: stat,
            route: ROUTES.Accountant.Leave.EntitlementReport,
          },
          {
            title: "Leave Type",
            type: stat,
            route: ROUTES.Accountant.Leave.LeaveType,
          },
          {
            title: "Earning Policy",
            type: stat,
            route: ROUTES.Accountant.Leave.EarningPolicy,
          },
          {
            title: "Custom Approver",
            type: stat,
            route: ROUTES.Accountant.Leave.CustomApprover,
          },
          {
            title: "Appoval Workflow",
            type: stat,
            route: ROUTES.Accountant.Leave.ApprovalWorkflow,
          },
         
        ],
      },
    
      // {
      //   title: "Document",
      //   type: stat,
      //   route: ROUTES.Employee.Document,
      // },
      // {
      //   title: "Team",
      //   type: drop,
      //   drop: [
      //     {
      //       title: "Discussion",
      //       type: stat,
      //       route: ROUTES.Employee.Team.Discussion,
      //     },
      //     {
      //       title: "Document & Form Sharing",
      //       type: stat,
      //       route: ROUTES.Employee.Team.Documents,
      //     },
      //     {
      //       title: "Announcement",
      //       type: stat,
      //       route: ROUTES.Employee.Team.Announcement,
      //     },
      //   ],
      // },
      // {
      //   title: "Give Resignation",
      //   type: stat,
      //   route: ROUTES.Employee.Leave.StaffResignation,
      // },

  {
    title: "Collect Fee",
    type: stat,
    route: ROUTES.Accountant.CollectFee,
    icon: <i className="ri-hand-coin-line mr-2" />,
  },
  {
    title: "Search Due Fees",
    type: stat,
    route: ROUTES.Accountant.SearchDue,
    icon: <i className="ri-search-2-line mr-2" />,
  },
  {
    title: "Fee Master",
    type: stat,
    route: ROUTES.Accountant.FeeMaster,
    icon: <i className="ri-vip-crown-line mr-2" />,
  },
  {
    title: "Fee Amount",
    type: stat,
    route: ROUTES.Accountant.FeeTypeAmount,
    icon: <i className="ri-price-tag-3-line mr-2" />,
  },
  {
    title: "Fee Discount",
    type: stat,
    route: ROUTES.Accountant.FeeDiscount,
    icon: <i className="ri-percent-line mr-2" />,
  },
  {
    title: "Fee Type",
    type: stat,
    route: ROUTES.Accountant.FeeType,
    icon: <i className="ri-copper-coin-line mr-2" />,
  },
  {
    title: "Fee Group",
    type: stat,
    route: ROUTES.Accountant.FeeGroup,
    icon: <i className="ri-currency-fill mr-2" />,
  },
  {
    title: "Reports",
    type: stat,
    route: ROUTES.Accountant.Reports,
    icon: <i className="ri-currency-fill mr-2" />,
  },
  // {
  //   title: "Fee Summary Report",
  //   type: stat,
  //   route: ROUTES.Accountant.FeeSummaryReport,
  //   icon: <i className="ri-currency-fill mr-2" />,
  // },
  {
    title: "Transport",
    type: drop,
    icon: <i className="ri-bus-line mr-2" />,
    drop: [
      {
        title: "Transport Fees",
        type: stat,
        route: ROUTES.Accountant.Transport.transportFee,
      },
        
   
      {
        title: " Assign Pickup Points",
        type: stat,
        route: ROUTES.Accountant.Transport.AssignPickupPoints,
      },
      {
        title: "Pickup Points",
        type: stat,
        route: ROUTES.Accountant.Transport.pickupPoints,
      },
      {
        title: "Assign Vehicles",
        type: stat,
        route: ROUTES.Accountant.Transport.AssignVehicles,
      },
      {
        title: "Routes",
        type: stat,
        route: ROUTES.Accountant.Transport.Route,
      },
      
      {
        title: "Vehicles",
        type: stat,
        route: ROUTES.Accountant.Transport.Vehicles,
      },
    ],
  },
  {
    title: "Hostel",
    type: drop,
    icon: <i className="ri-hotel-bed-line mr-2" />,
    drop: [
      {
        title: "Hostel Rooms",
        type: stat,
        route: ROUTES.Accountant.Hostel.HostelRooms,
      },
      {
        title: "Room Type",
        type: stat,
        route: ROUTES.Accountant.Hostel.RoomType,
      },
      {
        title: "Hostel",
        type: stat,
        route: ROUTES.Accountant.Hostel.Hostel,
      },
      {
        title: "Hostel Fees",
        type: stat,
        route: ROUTES.Accountant.Hostel.hostelFee,
      },
      {
        title: "Hostel Floors",
        type: stat,
        route: ROUTES.Accountant.Hostel.HostelFloors,
      },
    ],
  },
  {
    title: "Promote & Demote",
    type: drop,
    icon: <i className="ri-user-fill mr-2"></i>,
    drop: [
      {
        title: "Promote Student",
        type: stat,
        route: ROUTES.Accountant.Academics.PromoteStudents,
      },
      {
        title: "Demote Student",
        type: stat,
        route: ROUTES.Accountant.Academics.DemoteStudents,
      },
    ],
  },
];

export const navbarDataAccountantEmp = [
  {
    title: "Dashboard",
    type: stat,
    route: ROUTES.Accountant.Dashboard,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  
  // {
  //   title: "Leave",
  //   type: drop,
  //   drop: [
  //     {
  //       title: "Entitlement",
  //       type: stat,
  //       route: ROUTES.Accountant.Leave.Entitlement,
  //     },
  //     {
  //       title: "Application",
  //       type: stat,
  //       route: ROUTES.Accountant.Leave.Application,
  //     },
  //     {
  //       title: "Schedule",
  //       type: stat,
  //       route: ROUTES.Accountant.Leave.Schedule,
  //     },
  //   ],
  // },
  {
    title: "My Leave",
    type: drop,
    icon: <i className="ri-user-fill mr-2"></i>,
    drop: [
          {
            title: "Entitlement",
            type: stat,
            route: ROUTES.Accountant.Leave.Entitlement,
          },
          {
            title: "Application",
            type: stat,
            route: ROUTES.Accountant.Leave.Application,
          },
          {
            title: "Schedule",
            type: stat,
            route: ROUTES.Accountant.Leave.Schedule,
          },
    ],
  },
  {
    title: "HR",
    type: drop,
    icon: <i className="ri-flight-takeoff-line mr-2" />,
    drop: [
      {
        title: "Management",
        type: stat,
        route: ROUTES.Accountant.Leave.Management,
      },
      {
        title: "Review",
        type: stat,
        route: ROUTES.Accountant.Leave.Review,
      },
      {
        title: "Transaction Report",
        type: stat,
        route: ROUTES.Accountant.Leave.TransactionReport,
      },
      {
        title: "Entitlement Report",
        type: stat,
        route: ROUTES.Accountant.Leave.EntitlementReport,
      },
      {
        title: "Leave Type",
        type: stat,
        route: ROUTES.Accountant.Leave.LeaveType,
      },
      {
        title: "Earning Policy",
        type: stat,
        route: ROUTES.Accountant.Leave.EarningPolicy,
      },
      {
        title: "Custom Approver",
        type: stat,
        route: ROUTES.Accountant.Leave.CustomApprover,
      },
      {
        title: "Appoval Workflow",
        type: stat,
        route: ROUTES.Accountant.Leave.ApprovalWorkflow,
      },
     
    ],
  },
  {
    title: "Student Details",
    type: stat,
    route: ROUTES.Accountant.StudentDetails,
    icon: <i className="ri-folder-chart-line mr-2" />,
  },
  // {
  //   title: "Fee Summary Report",
  //   type: stat,
  //   route: ROUTES.Accountant.FeeSummaryReport,
  //   icon: <i className="ri-currency-fill mr-2" />,
  // },
  {
    title: "Collect Fee",
    type: stat,
    route: ROUTES.Accountant.CollectFee,
    icon: <i className="ri-hand-coin-line mr-2" />,
  },
  {
    title: "Search Due Fees",
    type: stat,
    route: ROUTES.Accountant.SearchDue,
    icon: <i className="ri-search-2-line mr-2" />,
  },
  {
    title: "Fee Master",
    type: stat,
    route: ROUTES.Accountant.FeeMaster,
    icon: <i className="ri-vip-crown-line mr-2" />,
  },
  {
    title: "Fee Amount",
    type: stat,
    route: ROUTES.Accountant.FeeTypeAmount,
    icon: <i className="ri-price-tag-3-line mr-2" />,
  },
  {
    title: "Fee Discount",
    type: stat,
    route: ROUTES.Accountant.FeeDiscount,
    icon: <i className="ri-percent-line mr-2" />,
  },
  {
    title: "Fee Type",
    type: stat,
    route: ROUTES.Accountant.FeeType,
    icon: <i className="ri-copper-coin-line mr-2" />,
  },
  {
    title: "Fee Group",
    type: stat,
    route: ROUTES.Accountant.FeeGroup,
    icon: <i className="ri-currency-fill mr-2" />,
  },
  {
    title: "Reports",
    type: stat,
    route: ROUTES.Accountant.Reports,
    icon: <i className="ri-currency-fill mr-2" />,
  },
  {
    title: "Transport",
    type: drop,
    icon: <i className="ri-bus-line mr-2" />,
    drop: [
      {
        title: "Transport Fees",
        type: stat,
        route: ROUTES.Accountant.Transport.transportFee,
      },
        
   
      {
        title: " Assign Pickup Points",
        type: stat,
        route: ROUTES.Accountant.Transport.AssignPickupPoints,
      },
      {
        title: "Pickup Points",
        type: stat,
        route: ROUTES.Accountant.Transport.pickupPoints,
      },
      {
        title: "Assign Vehicles",
        type: stat,
        route: ROUTES.Accountant.Transport.AssignVehicles,
      },
      {
        title: "Routes",
        type: stat,
        route: ROUTES.Accountant.Transport.Route,
      },
      
      {
        title: "Vehicles",
        type: stat,
        route: ROUTES.Accountant.Transport.Vehicles,
      },
    ],
  },
  // {
  //   title: "Hostel",
  //   type: drop,
  //   icon: <i className="ri-hotel-bed-line mr-2" />,
  //   drop: [
  //     {
  //       title: "Hostel Rooms",
  //       type: stat,
  //       route: ROUTES.Accountant.Hostel.HostelRooms,
  //     },
  //     {
  //       title: "Room Type",
  //       type: stat,
  //       route: ROUTES.Accountant.Hostel.RoomType,
  //     },
  //     {
  //       title: "Hostel",
  //       type: stat,
  //       route: ROUTES.Accountant.Hostel.Hostel,
  //     },
  //     {
  //       title: "Hostel Fees",
  //       type: stat,
  //       route: ROUTES.Accountant.Hostel.hostelFee,
  //     },
  //     {
  //       title: "Hostel Floors",
  //       type: stat,
  //       route: ROUTES.Accountant.Hostel.HostelFloors,
  //     },
  //   ],
  // },
  {
    title: "Promote & Demote",
    type: drop,
    icon: <i className="ri-user-fill mr-2"></i>,
    drop: [
      {
        title: "Promote Student",
        type: stat,
        route: ROUTES.Accountant.Academics.PromoteStudents,
      },
      {
        title: "Demote Student",
        type: stat,
        route: ROUTES.Accountant.Academics.DemoteStudents,
      },
    ],
  },
];
