import React, { useState } from "react";
import papa from "papaparse";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { STUDENT_ADMISSION_BULK_ENQUIRY } from "../../../utils/apiConstants";
import axios from "axios";
import { Link } from "react-router-dom";

function ModalEnquiryBlukUpload({ setLoading,setShowBulkResult,setBulkResult }) {
  const [data, setData] = useState();

  var commonConfig = { delimiter: "," };

  const handleUpload = async () => {
    setLoading(1);
    const config = {
      method: "post",
      url: STUDENT_ADMISSION_BULK_ENQUIRY,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { employeeData: data },
    };

    console.log('data - ', data);

    await axios(config)
      .then((res) => {
        setBulkResult(res.data.result);
        setShowBulkResult(true)
      })
      .catch((err) => {
        console.log("Bulk Upload Error :" + err);
        toast.error("Something went wrong");
      });

    setLoading(0);
  };

  const handleProcessCSV = (d) => {
    console.log('d - ', d);
    const arr = [];
    for (const i of d) {
      let obj = {};
        obj = {
          s_no: i["s_no"],
          name: i["Name"],
          email: i["Email"],
          phone: i["Phone"],
          address: i["Address"],
          description: i["Description"],
          note: i["Note"],
          response: i["Response"],
          program_id: i["Program_id"],
          college_id: i["College_id"],
          department_id: i["Department_id"],
          source: i["Source"],
          reference: i["Reference"],
          date: i["Date"],
          follow_up_date: i["Follow-up Date"]
        };
      arr.push(obj);
      console.log('obj - ', obj);
    }

    console.log(arr);
    setData(arr);
  };

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    // console.log("hi")
    if (files) {
      papa.parse(files[0], {
        ...commonConfig,
        header: true,
        complete: async (res) => {
          console.log("com", res);
          await handleProcessCSV(res.data);
        },
      });
    }
  };

  return (
    <div>
      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Import CSV File
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <ol>
                <li>
                
                  Your CSV data should be in the format below. The first line of
                  your CSV file should be the column headers as in the table
                  example. Also make sure that your file is UTF-8 to avoid
                  unnecessary encoding problems.
                </li>
                <li>
                All the Fields mentioned in CSV file are required before Upload.
                </li>
                <li>Accepted Date Formats <b>( DD-MM-YYYY)</b></li>
              </ol>
              <br />
              <p className="alert alert-danger">
               
                 Note : Add Department ids of Particular college in csv. Get deparment Ids from here <a href={"/department"}>Department List</a>
                
                
              </p>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="validationCustom02">
                      Select CSV File
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      accept=".csv"
                      onChange={handleFileUpload}
                    />
                    <br />
                    <p className="text-danger">*only csv format is allowed</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                name="submit"
                value="bulk"
                onClick={handleUpload}
                data-dismiss="modal"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEnquiryBlukUpload;
