import React from 'react'
import axios from 'axios';
import { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { STUDENTS_RESULTS_UPLOAD } from '../../utils/InfoUploadingApiConstants';
import { getFileUrl } from '../../Helpers/Helpers';
import { ROUTES } from "../../Router/routerConfig"
import { useNavigate } from 'react-router-dom';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';

function HomeopathyResults({ setLoading }) {

  const navigate = useNavigate()

  const fileref = useRef(null);
  let role = sessionStorage.getItem("role");
  const [info, setInfo] = useState({
    title: "",
    attachments: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const clearData = () => {
    setInfo({
      title: "",
      attachments: "",
    });
    fileref.current.value = null;
  };

  const handleSubmit = async () => {
    if (!info?.title || !info?.attachments) {
      toast.error("Please Enter all the required Details");
      return;

    }

    setLoading(1);
    const config = {
      method: "post",
      url: STUDENTS_RESULTS_UPLOAD,
      headers: {
        "Content-Type": "application/json",
        //'Authorization': `Bearer ${sessionStorage.getItem('INFO_UPLOADING_AUTH')}`
      },
      data: info,
    };

    axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Succesfully Uploaded Details");
        clearData();
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const handleChange1 = async (e) => {
    console.log(e.target.files[0]);
    let empId = Math.floor(Math.random() * 100);
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      info.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <div className="container mt-5">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div class="card-header">
                <h1 class="text-danger"> Students Results </h1>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input type="text"
                    placeholder="Enter the Recruitment Title"
                    className="form-control"
                    name="title"
                    value={info?.title}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Date</label>
                  <input type="date"
                    placeholder="Enter the Recruitment Date"
                    className="form-control"
                    name="date"
                    value={info?.date}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>
              </div> */}
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Attachments</label>
                  <input type="file"
                    placeholder="Attach the file"
                    className="form-control"
                    name="attachments"
                    ref={fileref}
                    onChange={(e) => {
                      handleChange1(e);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <button className='btn btn-success float-right' id="submit" onClick={handleSubmit}>Submit</button>
              </div>
              <div className='col-md-12 mt-2'>
                <button onClick={() => {
                  navigate(ROUTES.ViewHomeopathyResults)
                }} className='btn btn-primary float-right'>
                  View List
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeopathyResults
