import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function PhdExamProfile() {
  const { state } = useLocation();

  return <div>PhdExamProfile</div>;
}

export default PhdExamProfile;
