import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SessionOpt } from "../../../Data/student/sessionData";
import HostelModalFee from "../../../modals/Accounts/FeeCollection/HostelFeeModal";
import HostelReturnModal from "../../../modals/Accounts/FeeCollection/HostelReturnModal";
import { ACADEMICS_ADD_CLASS } from "../../../utils/Academics.apiConst";
import { STUDENT_DETAILS, EMPLOYEE_ALL } from "../../../utils/apiConstants";
import { FEE_DETAILS, ADDHOSTELFEE } from "../../../utils/fees.apiConst";
import HostelRow from "../../Accounts/FeeCollection/HostelFeeCollectionPaymentRow";
import { useReactToPrint } from "react-to-print";
import HostelPrint from "../../Accounts/FeeCollection/Hostelprint";
import { useRef } from "react";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import {
  HOSTEL_DETAILS,
  HOSTEL_FLOOR_DETAILS,
  HOSTEL_ROOM_TYPE_DETAILS,
  HOSTEL_ROOMS_DETAILS,
} from "../../../utils/Hostel.apiConst";
import "./style.scss";

const AddHostelFee = ({ setLoading, collegeId }) => {
  const navigate = useNavigate();

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  function formatDateToMonthYear(datetime) {
    const date = new Date(datetime);
    const options = { year: 'numeric', month: 'long' };
    return date.toLocaleDateString('en-US', options);
  }

  const getDepartmentData = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [departmentData, setDepartmentData] = useState();

  useEffect(() => {
    setCollegeOpt(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setDepartmentData(getDepartmentData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [feeData, setFeeData] = useState();

  const [max, setMax] = useState();

  const [fee, setFee] = useState("");

  const [roomId, setRoomID] = useState();

  const [x, setX] = useState(false);

  const [studentData, setStudentData] = useState();

  const [studentSessionData, setStudentSessionData] = useState();

  const [studentSemesterData, setStudentSemesterData] = useState();

  const [hostel, setHostel] = useState([]);
  const [floors, setFloors] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [rooms, setRooms] = useState([]);

  const [feeassign, setFeeassign] = useState("");

  const [feeassign1, setFeeassign1] = useState("");

  const [feeid, setFeeid] = useState("");

  const [paid, setPaid] = useState();

  const [classData, setClassData] = useState([]);

  const [emp, setEmp] = useState([]);

  const [addData, setAddData] = useState();

  const [returnData, setReturnData] = useState();

  const [printData, setPrintData] = useState();

  const [printSubData, setPrintSubData] = useState();

  const [prevdata, setPrevdata] = useState([]);

  const [hostelFees, setHostelFees] = useState([]);

  const [paymentdetails, setPaymentdetails] = useState([]);

  const [paidAmount, setPaidAmount] = useState(0);

  const printRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrint = async (dd, ss) => {
    await setPrintData(dd);
    await setPrintSubData(ss);
    PrintRecipt();
  };

  const params = useParams();
  const [searchParams] = useSearchParams();

  const getData = async () => {
    // setLoading(1);s
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    // await axios({
    //   ...config,
    //   url: `${FEE_DETAILS}?student_session_id=${params.id}`,
    // })
    //   .then((res) => {
    //     setFeeData(res.data.data[0]);
    //   })
    //   .catch((err) => {
    //     setLoading(0);
    //     console.log(err);
    //   });

    // await axios({ ...config, url: `${ACADEMICS_ADD_CLASS}` })
    //   .then((res) => {
    //     setClassData(res.data.data);
    //   })
    //   .catch((err) => {
    //     setLoading(0);
    //     console.log(err);
    //   });

    setLoading(1);

    await axios({
      ...config,
      url: `${STUDENT_DETAILS}/${searchParams.get("student_id")}`,
    })
      .then((res) => {
        console.log("student data - ", res.data.data);
        setStudentData(res.data.data);
        let sessionData = res.data.session;
        let semesterData = res.data.semester;
        setStudentSessionData(sessionData?.find((s) => s.status == "ACTIVE"));
        setStudentSemesterData(semesterData?.find((s) => s.status == "ACTIVE"));
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(1);
    await axios({
      ...config,
      url: `${ADDHOSTELFEE}?id=${params.id}`,
    })
      .then((res) => {
        setLoading(0);
        console.log("hostel_fees_data - ", res.data.data[0]);
        console.log("payments - ", res.data.payments);
        setPaymentdetails(res.data.payments);
        setHostelFees(res.data.data[0]);
        setMax(
          res.data.data[0].amount -
            res.data.data[0].paid_amount -
            res.data.data[0].discount
        );
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const getHostelData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    const [data1, data2, data3] = await Promise.all([
      await axios({
        ...config,
        url: `${HOSTEL_DETAILS}`,
      })
        .then((res) => {
          console.log("hostels - ", res.data.data);
          setHostel(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          toast.error("Error while fetching hostels");
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${HOSTEL_FLOOR_DETAILS}`,
      })
        .then((res) => {
          console.log("hostel floors - ", res.data.data);
          setFloors(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          toast.error("Error while fetching hostel floors");
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${HOSTEL_ROOM_TYPE_DETAILS}`,
      })
        .then((res) => {
          console.log("hostel room types - ", res.data.data);
          setRoomTypes(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          toast.error("Error while fetching Room types");
          console.log(err);
        }),

      await axios({
        ...config,
        url: `${HOSTEL_ROOMS_DETAILS}`,
      })
        .then((res) => {
          console.log("hostel rooms - ", res.data.data);
          setRooms(res.data.data);
        })
        .catch((err) => {
          setLoading(0);
          toast.error("Error while fetching Hostel rooms");
          console.log(err);
        }),
    ]);
  };

  useEffect(() => {
    getData();
    getHostelData();
  }, []);

  const submit = async () => {
    const obj = {
      student_id: searchParams.get("student_session_id"),
      session_id: params.id,
      amount: fee,
      department_id: searchParams.get("department_id"),
      room_id: roomId,
    };
    const config = {
      method: "post",
      url: ADDHOSTELFEE,
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };
    setLoading(1);
    await axios(config)
      .then(async (res) => {
        console.log(res);
        setLoading(0);
        setFee("");
        await getData();
        toast.success("Successfully added Hostel Fee");
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const submit1 = async () => {
    console.log(feeassign);
    const obj = {
      amount: fee ? fee : feeassign,
      room_id: roomId,
    };
    setLoading(1);
    const config = {
      method: "put",
      url: `${ADDHOSTELFEE}/${feeid}`,
      headers: {
        "Content-type": "application/json",
      },
      data: obj,
    };
    await axios(config)
      .then(async (res) => {
        console.log(res);
        setLoading(0);
        setFee("");
        setFeeassign("");
        setX(!x);
        // await getData();
        toast.error("Something went wrong");
        toast.success("SuccessFully Updated Hostel Fee");
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getData();
  // }, [x]);

  return (
    <div className="FeeCollectionAddFee">
      <HostelModalFee
        setLoading={setLoading}
        reloadData={getData}
        addData={addData}
        maxAmount={max}
        getData={getData}
      />
      <HostelReturnModal
        setLoading={setLoading}
        reloadData={getData}
        feeid={feeid}
        returnData={returnData}
      />

      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <HostelPrint
            collegeId={collegeId}
            mainData={hostelFees}
            subData={studentData}
            studentSessionData={studentSessionData}
            studentSemesterData={studentSemesterData}
            data={printData}
            collegeOpt={collegeOpt}
            departmentData={departmentData}
          />
        </div>
      </div>

      <div className="main-content">
        <div className="page-content">
          <div className="">
            {/* start page title */}
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center">
                <button
                  className="btn btn-sm btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3 mt-1"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <i className="ri-arrow-left-line"></i>
                </button>
                <h4 className="mb-0">COLLECT HOSTEL FEE</h4>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3 alogn-items-center">
                    <img
                      className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                      src={`${
                        studentData?.student_picture
                          ? studentData?.student_picture
                          : "../../../assets/images/reports/graduated.png"
                      }
                                `}
                      width="40%"
                      style={{ aspectRatio: "1/1" }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-primary">Student Details</h6>
                      </div>
                      <div className="col-md-12">
                        <table className="table fee-table table-bordered">
                          <tr>
                            <th width="25%">Enrollment No </th>
                            <td width="25%"> {studentData?.user_id}</td>
                            <th width="25%">Session </th>
                            <td width="25%">
                              {" "}
                              {studentSessionData?.session_id}-
                              {studentSessionData?.session_id + 1}
                            </td>
                          </tr>
                          <tr>
                            <th>Name</th>
                            <td> {studentData?.name}</td>
                            <th>Department</th>
                            <td>
                              {" "}
                              {
                                departmentData?.find(
                                  (s) =>
                                    s.id == studentSessionData?.department_id
                                )?.name
                              }
                            </td>
                          </tr>
                          <tr>
                            <th>Gender</th>
                            <td> {studentData?.gender}</td>
                            <th>Class</th>
                            <td> {studentSessionData?.class_name}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td> {studentData?.phone}</td>
                            <th>Semester</th>
                            <td> {studentSemesterData?.semester_name}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <h5 className="mt-4 text-center">{studentData?.name}</h5>
                    <h6 className="text-center">({studentData?.user_id})</h6>
                  </div>
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-12">
                        <h6 className="text-primary">Hostel Details</h6>
                      </div>
                      <div className="col-md-12">
                        <table className="table fee-table table-bordered">
                          <tr>
                            <th width="25%">Hostel</th>
                            <td width="25%">
                              {" "}
                              {
                                hostel.find(
                                  (s) => s.id == hostelFees?.hostel_id
                                )?.hostel_name
                              }
                            </td>
                            <th width="25%">Period</th>
                            <td width="25%">{formatDateToMonthYear(hostelFees?.from_month)} to {formatDateToMonthYear(hostelFees?.to_month)}</td>
                          </tr>
                          <tr>
                            <th>Floor</th>
                            <td>
                              {" "}
                              {
                                rooms.find((s) => s.id == hostelFees?.room_id)
                                  ?.floor_number
                              }
                            </td>
                            <th>Amount</th>
                            <td>
                              {" "}
                              {hostelFees?.amount?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>Room Type</th>
                            <td>
                              {" "}
                              {
                                roomTypes?.find(
                                  (s) =>
                                    s.id ==
                                    rooms.find(
                                      (s) => s.id == hostelFees?.room_id
                                    )?.hostel_room_type_id
                                )?.room_type
                              }
                            </td>
                            <th>Paid amount</th>
                            <td>
                              {" "}
                              {parseInt(
                                hostelFees?.paid_amount
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>Room number</th>
                            <td>
                              {" "}
                              {
                                rooms.find((s) => s.id == hostelFees?.room_id)
                                  ?.room_name_number
                              }
                            </td>
                            <th>Balance</th>
                            <td>
                              {" "}
                              {(
                                hostelFees?.amount -
                                hostelFees?.paid_amount -
                                hostelFees?.discount
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </td>
                          </tr>
                          <tr>
                            <th>Bed number</th>
                            <td> {hostelFees?.bed_no}</td>
                            <th>Fee Status</th>
                            <td>
                              <span
                                className={`ml-1 badge badge-soft-${
                                  hostelFees?.amount -
                                    hostelFees?.paid_amount -
                                    hostelFees?.discount ==
                                  0
                                    ? "success"
                                    : hostelFees?.amount -
                                        hostelFees?.paid_amount -
                                        hostelFees?.discount ==
                                      hostelFees?.amount
                                    ? "danger"
                                    : "warning"
                                }`}
                              >
                                {hostelFees?.amount -
                                  hostelFees?.paid_amount -
                                  hostelFees?.discount ==
                                0
                                  ? "Paid"
                                  : hostelFees?.amount -
                                      hostelFees?.paid_amount -
                                      hostelFees?.discount ==
                                    hostelFees?.amount
                                  ? "Not Paid"
                                  : "Partial"}
                              </span>
                            </td>
                          </tr>
                        </table>
                      </div>
                      {/* <div className="col-md-6">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th>Year</th>
                                            <td> : {hostelFees[0]?.year}</td>
                                        </tr>
                                        <tr>
                                            <th>Amount</th>
                                            <td> : {hostelFees[0]?.amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        </tr>
                                        <tr>
                                            <th>Paid amount</th>
                                            <td> : {hostelFees[0]?.paid_amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        </tr>
                                        <tr>
                                            <th>Balance</th>
                                            <td> : {(hostelFees[0]?.amount - hostelFees[0]?.paid_amount)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                        </tr>
                                        <tr>
                                            <th>Fee Status</th>
                                            <td> :
                                                <span className={`ml-1 badge badge-soft-${(hostelFees[0]?.amount - hostelFees[0]?.paid_amount) == 0 ? "success":((hostelFees[0]?.amount - hostelFees[0]?.paid_amount) == hostelFees[0]?.amount ?"danger":"warning")}`} >
                                                {(hostelFees[0]?.amount - hostelFees[0]?.paid_amount) == 0 ? "Paid" : (hostelFees[0]?.amount - hostelFees[0]?.paid_amount) == hostelFees[0]?.amount ? "Not Paid" : "Partial"}
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {prevdata.length > 1 ? (
              <div className="col-xl-12 mt-3 p-0 col-lg-9 col-md-12 col-sm-12 col-12">
                <div className="card h-100">
                  <div className="card-body">
                    <div className="row-gutters">
                      <div className="card-title">
                        Previous Year Hostel Due Fees
                      </div>
                    </div>
                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0">
                        <table className="table table-hovered text-wrap">
                          <thead>
                            <tr>
                              <th>Year</th>
                              <th>Due Amount</th>
                              <th>Paid Amount</th>
                              <th>Total Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {prevdata &&
                              prevdata.map((item, key) => {
                                if (
                                  item?.total - item?.paid != 0 &&
                                  item?.year !=
                                    SessionOpt.find((s) => s.id == params.id)
                                      ?.name
                                ) {
                                  return (
                                    <tr>
                                      <td>{item?.year}</td>
                                      <td>{item?.total - item?.paid}</td>
                                      <td>{item?.paid}</td>
                                      <td>{item?.total}</td>
                                      <td>
                                        {item?.total - item?.paid ==
                                        item?.total ? (
                                          <p className="badge badge-danger">
                                            Unpaid
                                          </p>
                                        ) : (
                                          <p className="badge badge-warning">
                                            Partial
                                          </p>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>{" "}
          <br />
          {/* container-fluid */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <h4 className="card-title">Student Fee Details</h4>
                    </div>
                    <div className="col-md-8 ">
                      <span className="float-right">
                        <a href="#">
                          <i className="fa fa-file-pdf-o " aria-hidden="true" />
                        </a>{" "}
                        &nbsp;{" "}
                        <a href="#">
                          <i
                            className="fa fa-file-excel-o"
                            aria-hidden="true"
                          />
                        </a>{" "}
                      </span>
                    </div>
                  </div>
                  <hr />

                  <div className="table-rep-plugin">
                    <div className="table-responsive mb-0">
                      <table
                        id="tech-companies-1"
                        className="table table-hovered text-wrap"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        <thead>
                          <tr>
                            <th>Fee Code</th>
                            <th>status</th>
                            <th>Amount</th>
                            <th>Payment ID</th>
                            <th>Date</th>
                            <th>Mode</th>
                            <th>Paid</th>
                            <th>Discount</th>
                            <th>Fine</th>
                            <th>Balance</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td id="data2">Hostel Fees</td>
                            <td>
                              <span
                                className={`badge badge-${
                                  hostelFees?.amount -
                                    hostelFees?.paid_amount -
                                    hostelFees?.discount <=
                                  0
                                    ? "success"
                                    : hostelFees?.paid_amount == 0
                                    ? "danger"
                                    : "warning"
                                }`}
                              >
                                {hostelFees?.amount -
                                  hostelFees?.paid_amount -
                                  hostelFees?.discount <=
                                0
                                  ? "Paid"
                                  : hostelFees?.paid_amount == 0
                                  ? "Not Paid"
                                  : "Partial"}
                              </span>
                            </td>
                            <td>
                              <b>
                                {hostelFees?.amount?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </b>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td>
                              <b>
                                {parseInt(
                                  hostelFees.paid_amount
                                )?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </b>
                            </td>
                            <td>
                              <b>
                                {parseInt(hostelFees.discount)?.toLocaleString(
                                  "en-IN",
                                  {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  }
                                )}
                              </b>
                            </td>
                            <td>
                              <b>
                                {parseInt(hostelFees.fine)?.toLocaleString(
                                  "en-IN",
                                  {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  }
                                )}
                              </b>
                            </td>
                            <td>
                              <b>
                                {parseInt(
                                  hostelFees.amount -
                                    hostelFees.paid_amount -
                                    hostelFees.discount
                                )?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                              </b>
                            </td>
                            <td width="220px" className="ml-2">
                              {parseInt(
                                hostelFees.amount -
                                  hostelFees.paid_amount -
                                  hostelFees.discount
                              ) == 0 &&
                              sessionStorage.getItem("role") != "CASHIER" ? (
                                <a
                                  href=""
                                  className="badge badge-light"
                                  data-toggle="modal"
                                  data-target="#return"
                                  title="Return"
                                  onClick={() => {
                                    setReturnData(paymentdetails);
                                  }}
                                >
                                  {" "}
                                  <i
                                    className="fa fa-repeat"
                                    aria-hidden="true"
                                  />
                                </a>
                              ) : parseInt(
                                  hostelFees.amount -
                                    hostelFees.paid_amount -
                                    hostelFees.discount
                                ) != 0 ? (
                                <a
                                  href=""
                                  className="badge badge-light "
                                  data-toggle="modal"
                                  data-target="#addhostelfee"
                                  title="Add Hostel Fee"
                                  onClick={() => {
                                    setAddData(hostelFees);
                                  }}
                                >
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  />
                                </a>
                              ) : null}{" "}
                            </td>
                          </tr>
                          {paymentdetails?.map((j, key) => {
                            return (
                              <tr>
                                <td></td>
                                <td></td>
                                <td>
                                  <img
                                    src="/assets/images/table-arrow.png"
                                    alt=""
                                    className="ml-3"
                                  />
                                </td>
                                <td>{j?.transaction_id}</td>
                                <td>{formatDate(j?.transaction_date)}</td>
                                <td>{j?.type}</td>
                                <td>
                                  {j?.amount?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                                <td>
                                  {j?.discount?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                                <td>
                                  {j?.fine?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </td>
                                <td></td>
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    class="badge badge-light"
                                    title="Print Reciept"
                                    data-original-title="Print"
                                    onClick={() => handlePrint(j)}
                                  >
                                    <i class="fa fa-print"></i>
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
          {/* end row */}
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
};

export default AddHostelFee;
