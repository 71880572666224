import axios from "axios";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Select, Input, DatePicker, Form, Button, FormInstance } from "antd";
import { JOB_APPLICATIONS } from "../../../utils/apiConstants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Router/routerConfig";

function ModalReviewJobApplication({ data, setLoading, getApplications }) {
  const [status, setStatus] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);

  const [isOpen, setIsOpen] = useState(true);

  const navigate = useNavigate();

  const [location, setLocation] = useState("");
  const [interviewMode, setInterviewMode] = useState("");
  const [interviewDateString, setInterviewDateString] = useState("");
  const [interviewDate, setInterviewDate] = useState("");
  const [interviewRemark, setInterviewRemark] = useState("");
  const [statusOptions, setStatusOptions] = useState([]);

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      console.log("error while formating date :: ", dateString);
      return dateString;
    }
  }

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleSubmit = async () => {

    if(!status || status == "CALLED_FOR_INTERVIEW") {
      if(!interviewDate || !interviewMode || (interviewMode == "OFFLINE" && !location)) {
        setSubmitClicked(true);
        return;
      }
    }
    setSubmitClicked(false);
    
    let d = {selection_status: status};

    if(interviewDate) d['interview_date'] = interviewDateString;
    if(interviewMode) d['interview_mode'] = interviewMode;
    if(location) d['interview_location'] = location;
    if(interviewRemark) d['interview_remark'] = interviewRemark;
    d['job_name'] = data?.job_name;
    d['email'] = data?.email;
    d['first_name'] = data?.first_name;
    d['last_name'] = data?.last_name;

    console.log('data to backend - ', d);

    const config = {
      method: "put",
      url: `${JOB_APPLICATIONS}/${data?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: d,
    };

    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Application Status updated");
        getApplications();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
    setSubmitClicked(false);
    setStatus("");
    setInterviewDate("");
    setInterviewDateString("");
    setInterviewMode("");
    setInterviewRemark("");
    setLocation("");
    setStatusOptions([]);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string, value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


  const [resume, setResume] = useState(data?.resume);

  useEffect(() => {
    console.log('interview mode - ', interviewMode)
  }, [interviewMode]);

  useEffect(() => {
    setResume(data?.resume);
  }, [data?.resume]);

  useEffect(() => {
    console.log('status - ', data?.selection_status);
    if(data?.selection_status) {
      if(data?.selection_status == "PENDING_APPLICATION") {
        setStatusOptions([{
          value: "",
          label: "Select Status",
        },
        {
          value: "CALLED_FOR_INTERVIEW",
          label: "Call for Interview",
        },
        {
          value: "REJECTED_APPLICATION",
          label: "Reject Application",
        }])
      }
      else if(data?.selection_status == "CALLED_FOR_INTERVIEW") {
        setStatusOptions([{
          value: "",
          label: "Select Status",
        },
        {
          value: "SELECTED_INTERVIEW",
          label: "Select Candidate",
        },
        {
          value: "REJECTED_INTERVIEW",
          label: "Reject Candidate",
        }])
      }
    }
    else {
      setStatusOptions([]);
    }
    setSubmitClicked(false);
    setStatus("");
    setInterviewDate("");
    setInterviewDateString("");
    setInterviewMode("");
    setInterviewRemark("");
    setLocation("");
  },[data?.selection_status]);

  return (
    <div className="ModalPayRollEarning">
      <div
        className="modal fade"
        id="ModalPayRollEarning"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="exampleModalLongTitle">
                Application for {data?.job_name}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              
              <div className="row">

                <div className="col-md-3 text-center">
                <img 
                  className="profile-user-img img-responsive rounded-circle ml-2" 
                  src={data?.photo} 
                  alt="profile photo" 
                  width="80%" 
                  height="80%"
                  style={{ aspectRatio: "1/1", border: "2px solid black" }}  
                />
                </div>
                <div className="col-md-9">
                <table className="table" style={{ borderCollapse: 'collapse' }}>
                  {/* <tr>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Applied for{" "}
                    </th>
                    <td style={{ border: 'none' }}>: {data?.job_name}</td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Status{" "}
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: {data?.selection_status}</td>
                  </tr> */}
                  <tr>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Applicant name{" "}
                    </th>
                    <td width="%" style={{ border: 'none' }}>
                      : {data?.first_name} {data?.last_name}
                    </td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Gender{" "}
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: {data?.gender}</td>
                  </tr>
                  <tr>
                    <th width="25%" className="text-right" style={{ border: 'none' }}>
                      Phone{" "}
                    </th>
                    <td width="25%" style={{ border: 'none' }}>: {data?.phone}</td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Email{" "}
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: {data?.email}</td>
                  </tr>
                  <tr>
                    <th width="25%" className="text-right" style={{ border: 'none' }}>
                      Date of Birth{" "}
                    </th>
                    <td width="25%" style={{ border: 'none' }}>
                      : {formatDate(data?.dob?.split("T")[0])}
                    </td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Age{" "}
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: {calculateAge(data?.dob)}</td>
                  </tr>
                  <tr>
                    <th width="25%" className="text-right" style={{ border: 'none' }}>
                      Nationality{" "}
                    </th>
                    <td width="25%" style={{ border: 'none' }}>: {data?.nationality}</td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Country{" "}
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: {data?.country}</td>
                  </tr>
                  <tr>
                    <th width="25%" className="text-right" style={{ border: 'none' }}>
                      State{" "}
                    </th>
                    <td width="25%" style={{ border: 'none' }}>: {data?.state}</td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      City{" "}
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: {data?.city}</td>
                  </tr>
                  <tr>
                  <th width="25%" className="text-right" style={{ border: 'none' }}>
                      Applied Date {" "}
                    </th>
                    <td width="25%" style={{ border: 'none' }}>: {formatDate(data?.createdAt?.split('T')[0])}</td>
                    <th width="12%" className="text-right" style={{ border: 'none' }}>
                      Status
                    </th>
                    <td width="38%" style={{ border: 'none' }}>: <span
                        className={`badge badge-soft-${
                          data.selection_status === "PENDING_APPLICATION"
                            ? "warning"
                            : data.selection_status === "CALLED_FOR_INTERVIEW"
                            ? "primary"
                            : data.selection_status === "SELECTED_INTERVIEW"
                            ? "success"
                            :"danger"
                        } ml-3`}
                      >
                        {data.selection_status === "PENDING_APPLICATION"
                          ? "Review Pending"
                          : data.selection_status === "CALLED_FOR_INTERVIEW"
                          ? "Called for Interview"
                          : data.selection_status === "REJECTED_APPLICATION"
                          ? "Application Rejected"
                          : data.selection_status === "SELECTED_INTERVIEW"
                          ? "Candidate Selected"
                          : data.selection_status}
                      </span>
                    </td>
                  </tr>
                  {data?.selection_status == "CALLED_FOR_INTERVIEW" 
                  ? <>
                        <tr>
                          <th width="25%" className="text-right" style={{ border: 'none' }}>
                              Interview Mode{" "}
                            </th>
                            <td width="25%" style={{ border: 'none' }}>: {data?.interview_mode}</td>
                            <th width="12%" className="text-right" style={{ border: 'none' }}>
                              Interview Date{" "}
                            </th>
                            <td width="38%" style={{ border: 'none' }}>: {formatDate(data?.interview_date?.split('T')[0])}</td>
                          </tr>
                          {data?.interview_mode == "OFFLINE" 
                          ? <>
                              <tr>
                          <th width="25%" className="text-right" style={{ border: 'none' }}>
                              Interview Location{" "}
                            </th>
                            <td width="25%" style={{ border: 'none' }}>: {data?.interview_location}</td>
                        </tr>
                         </> 
                       : <></>}
                    </> : <></>}
                </table>
                </div>
              </div>
              {/* <div className="row justify-content-center mt-1">
                <p
                  className={`alert alert-${
                    data.selection_status === "PENDING_APPLICATION"
                      ? "warning"
                      : data.selection_status === "CALLED_FOR_INTERVIEW"
                      ? "primary"
                      : "danger"
                  } ml-3`}
                >
                  {data.selection_status === "PENDING_APPLICATION"
                    ? "Review Pending"
                    : data.selection_status === "CALLED_FOR_INTERVIEW"
                    ? "Called for Interview"
                    : data.selection_status === "REJECTED_APPLICATION"
                    ? "Application Rejected"
                    : data.selection_status}
                </p>
              </div> */}
              <hr />
              {(data?.selection_status === "PENDING_APPLICATION" 
              || data.selection_status === "CALLED_FOR_INTERVIEW")
              ? <>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <label>Application Status</label>
                  <br />
                  <Select
                    showSearch
                    placeholder="Select Status"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setStatus(value);
                    }}
                    filterOption={filterOption}
                    value={status}
                    options={statusOptions}
                    style={{ width: "100%" }}
                  />
                  {submitClicked && !status ? (
                    <p className="text-danger">Please select status</p>
                  ) : (
                    <></>
                  )}
                </div>
                {status == "CALLED_FOR_INTERVIEW" ? 
                <>
                <div className="col-md-4 mt-3">
                        <label>Interview Date </label>
                        <br />
                        <DatePicker
                          onChange={(date, dateString) => {
                            setInterviewDateString(dateString);
                            setInterviewDate(date);
                            // setUser({ ...user, dob: dateString, date: date });
                          }}
                          value={interviewDate}
                          style={{ width: "100%" }}
                        />
                        {submitClicked && !interviewDate ? (
                          <p className="text-danger">
                            Please select Interview Date
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                <div className="col-md-4 mt-3">
                  <label>Interview Mode</label>
                  <br />
                  <Select
                    showSearch
                    placeholder="Select Interview Mode"
                    optionFilterProp="children"
                    onChange={(value) => {
                      setInterviewMode(value);
                    }}
                    filterOption={filterOption}
                    value={interviewMode}
                    options={[
                      {
                        value: "",
                        label: "Select Interview Mode",
                      },
                      {
                        value: "ONLINE",
                        label: "Online",
                      },
                      {
                        value: "OFFLINE",
                        label: "Offline",
                      },
                    ]}
                    style={{ width: "100%" }}
                  />
                  {submitClicked && !interviewMode ? (
                    <p className="text-danger">Please select status</p>
                  ) : (
                    <></>
                  )}
                </div>
                  {interviewMode == "OFFLINE" ? 
                  <div className="col-md-12 mt-3">
                  <label>Interview Location</label>
                        <br />
                        <Input
                          type="text"
                          name="location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          style={{ width: "100%" }}
                        />
                        {submitClicked && !location ? (
                          <p className="text-danger">Please enter location</p>
                        ) : (
                          <></>
                        )}
                  </div> : <></>}
                </> : <></>}
              <div className="col-md-12 mt-3 mr-3 mb-3">
                  <Button className="float-right" type="primary" onClick={handleSubmit}>
                    Update
                  </Button>
              </div>
              </div>
              <hr />
            </> : <></>}
              <div className="row justify-content-center mt-3 mb-3">
                <h5>Resume</h5>
              </div>
              <div className="row justify-content-center">
              {resume?.slice(resume?.length - 3) === "pdf" ? (
                <iframe src={data?.resume} width="100%" height="600px"></iframe>
              ) : (
                <img src={resume} width={500} />
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalReviewJobApplication;
