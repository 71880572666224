import axios from 'axios'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import Select from "react-select";
import '../../modals/HR/Leave/TableScroll.css';
import TransferList from 'antd/es/transfer/list';
import { TRANSFER_CLASS_REQUESTS_UPDATE, TRANSFER_CLASS_REQUESTS } from '../../utils/apiConstants'

function ModalTransferRequests({setLoading}) {

    let emp_id = sessionStorage.getItem("employee_id");

    const [type, setType] = useState("All");

    const [transferRequests, setTransferRequests] = useState([]);

    const [displayRequests, setDisplayRequests] = useState([]);
    function formatDate(dateString) {
        try {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        catch {
            return "";
        }
    }

    const getTransferRequests = async () => {
        setLoading(1);
        const config = {
            method: "get",  
            url: `${TRANSFER_CLASS_REQUESTS}/${emp_id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },  
        }
    
        axios(config)
            .then((res) => {
              console.log('requests - ', res.data.data);
              setTransferRequests(res.data.data);
              setDisplayRequests(res.data.data);
              setLoading(0);
            })
            .catch((err) => {
              setLoading(0);
              console.log(err);
              toast.error("Error while fetching requests");
            })
        setLoading(0);
      }
    

    const handleUpdate = async(id, status) => {
        setLoading(1);
    const config = {
        method: "put",  
        url: `${TRANSFER_CLASS_REQUESTS_UPDATE}/${id}?status=${status}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },  
    }

    axios(config)
        .then((res) => {
          setLoading(0);
          toast.success(`Request ${status} Successfully`);
        })
        .catch((err) => {
          setLoading(0);
          console.log(err);
          toast.error("Error while fetching requests");
        })
    getTransferRequests();
    setLoading(0);
 }

    useEffect(() => {
        if(type == "Pending") {
            let tempData = transferRequests.filter((i) => i.status == "PENDING");
            setDisplayRequests(tempData);
        }
        else if(type == "Accepted") {
            let tempData = transferRequests.filter((i) => i.status == "ACCEPTED");
            setDisplayRequests(tempData);
        }
        else if(type == "Rejected") {
            let tempData = transferRequests.filter((i) => i.status == "REJECTED");
            setDisplayRequests(tempData);
        }
        else {
            setDisplayRequests(transferRequests);
        }
    },[type]);

    useEffect(() => {
        getTransferRequests();
    },[])

    return (
        <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
                        <div className="card">
                        <div className="card-body">
                        <div className="row">
                            <h5 className='ml-3'><b>Class Transfer Requests</b></h5>
                        </div>
                        <br />
                        <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <lable className="ml-1">
                                    Filter 
                                </lable>
                                <select
                                    name=""
                                    id=""
                                    className="form-control"
                                    value={type}
                                    onChange={(e) => { setType(e.target.value) }}
                                >
                                    <option value="All">All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Accepted">Accepted</option>
                                    <option value="Rejected">Rejected</option>
                                    
                                </select>
                            </div>
                        </div>
                        </div>
                        <div className="table-responsive mt-3 fixTableHead">
                        <table className="table table-bordered" style={{}}>
                            <thead className="">
                            <tr>
                                <th>Sl No.</th>
                                <th>Staff Name</th>
                                <th>Date</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Course</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {displayRequests ? 
                                    displayRequests?.map((i, key) => (
                                        <tr key={key}>
                                            <td>{key+1}</td>
                                            <td>{i.first_name} {i.last_name}</td>
                                            <td>{formatDate(i.date?.split('T')[0])}</td>
                                            <td>{i.time_from}</td>
                                            <td>{i.time_to}</td>
                                            <td>{i.class_name} ({i.semester_name})</td>
                                            <td>{i.section_name}</td>
                                            <td>{i.course_name}</td>
                                            <td>{i.type}</td>
                                            <td>
                                            <span className={`badge badge-soft-${i?.status=="ACCEPTED"?"success":i?.status=="REJECTED"?"danger":"warning"}`} >
                                                {i.status}
                                            </span>
                                            </td>
                                            {i?.status == "PENDING" ? <td>
                                                <button title="Accept" onClick={() => {handleUpdate(i.id, "ACCEPTED")}} style={{ backgroundColor: 'green', color: 'white', border: 'none',borderRadius: '5px', fontSize:"0.9em" }}>
                                                <i className="fas fa-check" style={{ color: 'white' }}></i>
                                                </button>
                                                <button title="Reject" onClick={() => {handleUpdate(i.id, "REJECTED")}} className="ml-2" style={{ backgroundColor: 'red', color: 'white', border: 'none',borderRadius: '5px' }}>
                                                <i className="fas fa-times" style={{ color: 'white' }}></i>
                                                </button>
                                            </td> : <td></td>}
                                        </tr>
                                    ))
                                : <>No data</>}
                            </tbody>
                        </table>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalTransferRequests;