export const sessionOpt = [
  {
    name: "2025-26",
    id: "2025",
    status: "INACTIVE",
  },
  {
    name: "2024-25",
    id: "2024",
    status: "INACTIVE",
  },
  {
    name: "2023-24",
    id: "2023",
    status: "ACTIVE",
  },
  {
    name: "2022-23",
    id: "2022",
    status: "INACTIVE",
  },
  {
    name: "2021-22",
    id: "2021",
    status: "INACTIVE",
  },
  {
    name: "2020-21",
    id: "2020",
    status: "INACTIVE",
  },
  {
    name: "2019-20",
    id: "2019",
    status: "INACTIVE",
  },
];
