import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { EMPLOYEE_LIST } from "../../../utils/apiConstants";
import { SearchOutlined } from "@ant-design/icons";
import { Select, Button, Input, Space, Table, Tag } from "antd";
import Highlighter from "react-highlight-words";
import { LOCAL_COLLEGE } from "../../../utils/LocalStorageConstants";
import * as XLSX from "xlsx/xlsx.mjs";

function EmployeeList({ collegeId, setLoading }) {
  const getCollegeData = () => {
    let clgs = localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

    let arr = [{ value: "", label: "All" }];

    console.log(clgs);

    for (let i of clgs) {
      arr.push({ value: i.id, label: i.name });
    }
    console.log("arr - ", arr);
    return arr;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [data, setData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchClg, setSearchClg] = useState("");

  const navigate = useNavigate();

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //     close,
  //   }) => (
  //     <div
  //       style={{
  //         padding: 8,
  //       }}
  //       onKeyDown={(e) => e.stopPropagation()}
  //     >
  //       <Input
  //         ref={searchInput}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{
  //           marginBottom: 8,
  //           display: "block",
  //         }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined />}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => clearFilters && handleReset(clearFilters)}
  //           size="small"
  //           style={{
  //             width: 90,
  //           }}
  //         >
  //           Reset
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             confirm({
  //               closeDropdown: false,
  //             });
  //             setSearchText(selectedKeys[0]);
  //             setSearchedColumn(dataIndex);
  //           }}
  //         >
  //           Filter
  //         </Button>
  //         <Button
  //           type="link"
  //           size="small"
  //           onClick={() => {
  //             close();
  //           }}
  //         >
  //           close
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined
  //       style={{
  //         color: filtered ? "#1677ff" : undefined,
  //       }}
  //     />
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownOpenChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.current?.select(), 100);
  //     }
  //   },
  //   render: (text) =>
  //     searchedColumn === dataIndex ? (
  //       <Highlighter
  //         highlightStyle={{
  //           backgroundColor: "#ffc069",
  //           padding: 0,
  //         }}
  //         searchWords={[searchText]}
  //         autoEscape
  //         textToHighlight={text ? text.toString() : ""}
  //       />
  //     ) : (
  //       text
  //     ),
  // });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (_, data) => {
        return (
          <>
            <img
              src={
                data?.photo
                  ? data?.photo
                  : "../../../assets/images/reports/profile.png"
              }
              alt="Photo"
              width="80px"
              height="80px"
              style={{
                aspectRatio: "1/1",
                border: data?.photo ? "1px solid black" : "",
              }}
            />
          </>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      sorter: (a, b) => a.name - b.name,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Staff Id",
      dataIndex: "staff_id",
      key: "staff_id",
      ...getColumnSearchProps("staff_id"),
      sorter: (a, b) => a.staff_id - b.staff_id,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "College",
      dataIndex: "college",
      key: "college",
      ...getColumnSearchProps("college"),
      sorter: (a, b) => a.college - b.college,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      ...getColumnSearchProps("department"),
      sorter: (a, b) => a.college - b.college,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      ...getColumnSearchProps("phone"),
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Biometric",
      dataIndex: "biometric_id",
      key: "biometric_id",
      ...getColumnSearchProps("biometric_id"),
      sorter: (a, b) => a.biometric_id.localeCompare(b.biometric_id),
      sortDirections: ["descend", "ascend"],
    },
  ];

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: EMPLOYEE_LIST,
    };

    await axios(config)
      .then((res) => {
        let tempData = res.data.data;
        console.log("employee data - ", res.data.data);
        setData(tempData);
        setDisplayData(tempData);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something went wrong");
      });
  };

  const filterOption = (
    input: string,
    option?: { label: string, value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (searchClg) {
      setDisplayData(data?.filter((s) => s.college_id == searchClg));
    } else {
      setDisplayData(data);
    }
  }, [searchClg]);

  useEffect(() => {
    getData();
  }, []);

  const onExport = async () => {
    let expData = [];

    let count = 0;
    for (let i of displayData) {
      let obj = {};
      count = count + 1;
      obj["Sl No."] = count;
      obj["College"] = i?.college;
      obj["Department"] = i?.department;
      obj["Employee name"] = i?.name;
      obj["Staff Id"] = i?.staff_id;
      obj["Email"] = i?.email;
      obj["Phone"] = i?.phone;
      obj["Biometric Id"] = i?.biometric_id;

      console.log("i?.biometric_id -", i?.biometric_id);

      expData.push(obj);
    }

    var worksheet = XLSX.utils.json_to_sheet(expData);
    console.log(worksheet);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `Employee List.csv`);
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-primary d-flex justify-content-center align-items-center rounded-pill mb-2 ml-2 mt-2"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0 ml-3">Employee List</h4>
                  {/* <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Employee</a>
                      </li>
                      <li className="breadcrumb-item active">Employee List</li>
                    </ol>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        {" "}
                        <h3 className="card-title mr-3">Employee List</h3>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <h6 className="mt-2">Filter by College</h6>
                          <Select
                            className="ml-3 mb-3 float-right"
                            showSearch
                            placeholder="Filter by College"
                            optionFilterProp="children"
                            onChange={(value) => {
                              setSearchClg(value);
                            }}
                            filterOption={filterOption}
                            value={searchClg}
                            options={collegeOpt}
                            style={{ width: "50%" }}
                          />
                          <Button
                            type="primary"
                            className="ml-5 px-2"
                            title="Export to Excel"
                            onClick={onExport}
                          >
                            <i className="fas fa-file-excel" />
                          </Button>
                        </div>
                      </div>
                    </div>

                    <Table columns={columns} dataSource={displayData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeList;
