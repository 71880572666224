import { BASE_URL } from "./apiConstants";

//Media
export const MEDIA_UPLOAD = `${BASE_URL}/api/infoUploading/create`;

export const MEDIA_GET = `${BASE_URL}/api/infoUploading/get`;

export const MEDIA_UPDATE = `${BASE_URL}/api/infoUploading/update`;

//Homeo_events

export const HOMEO_EVENT_UPLOAD = `${BASE_URL}/api/infoUploading/homeoeventcreate`;

export const HOMEO_GET = `${BASE_URL}/api/infoUploading/homeoeventget`;

export const HOMEO_UPDATE = `${BASE_URL}/api/infoUploading/homeoeventupdate`;

//ResearchandPublication
export const RESEARCHANDPUBLICATION_UPLOAD = `${BASE_URL}/api/infouploading/researchandPublicationcreate`;

export const RESEARCH_PUBLICATION_GET = `${BASE_URL}/api/infoUploading/getResearchandPublication`;

//AdmissionNotifications
export const ADMISSIONNOTIFICATIONS_UPLOAD = `${BASE_URL}/api/infouploading/admissionNotificationscreate`;

export const ADMISSION_NOTIFICATIONS_GET = `${BASE_URL}/api/infouploading/getAdmissionNotifications`;

export const ADMISSION_NOTIFICATIONS_PUT = `${BASE_URL}/api/infouploading/updateAdmissionNotifications`;

//UniversityNotifications
export const UNIVERSITYNOTIFICATIONS_UPLOAD = `${BASE_URL}/api/infouploading/universityNotificationscreate`;

export const UNIVERSITY_NOTIFICATIONS_GET = `${BASE_URL}/api/infouploading/getUniversityNotifications`;

export const UNIVERSITY_NOTIFICATIONS_PUT = `${BASE_URL}/api/infouploading/updateUniversityNotifications`;

//EventCalendar
export const EVENTCALENDAR_UPLOAD = `${BASE_URL}/api/infouploading/eventCalendarcreate`;

export const EVENT_CALENDAR_GET = `${BASE_URL}/api/infouploading/getEventCalendar`;

export const EVENT_CALENDAR_PUT = `${BASE_URL}/api/infouploading/updateEventCalendar`;

//Recruitment
export const RECRUITMENT_UPLOAD = `${BASE_URL}/api/infouploading/recruitmentcreate`;

export const RECRUITMENT_GET = `${BASE_URL}/api/infoUploading/getRecruitment`;

export const RECRUITMENT_PUT = `${BASE_URL}/api/infouploading/updateRecruitment`;

//Homeopathy_Students_List
export const STUDENTS_LIST_UPLOAD = `${BASE_URL}/api/infouploading/homeopathyStudentsListcreate`;

export const STUDENTS_LIST_GET = `${BASE_URL}/api/infoUploading/getHomeopathyStudentsList`;

export const STUDENTS_LIST_PUT = `${BASE_URL}/api/infouploading/updateHomeopathyStudentsList`;


//Homeopathy_Students_Results
export const STUDENTS_RESULTS_UPLOAD = `${BASE_URL}/api/infoUploading/homeopathyResultscreate`;

export const STUDENTS_RESULTS_GET = `${BASE_URL}/api/infoUploading/getHomeopathyResults`;

export const STUDENTS_RESULTS_PUT = `${BASE_URL}/api/infoUploading/updateHomeopathyResults`;

//College_Permission
export const COLLEGE_PERMISSION_UPLOAD = `${BASE_URL}/api/infoUploading/collegePermissioncreate`;

export const COLLEGE_PERMISSION_GET = `${BASE_URL}/api/infoUploading/getCollegePermission`;

export const COLLEGE_PERMISSION_PUT = `${BASE_URL}/api/infoUploading/updateCollegePermission`;

//StaffDairy
export const STAFF_DAIRY_UPLOAD = `${BASE_URL}/api/infoUploading/staffDairycreate`;

export const STAFF_DAIRY_GET = `${BASE_URL}/api/infoUploading/getStaffDairy`;
export const STAFF_DAIRY_GET1 = `${BASE_URL}/api/infoUploading/getStaffDairy1`;

export const STAFF_DAIRY_PUT = `${BASE_URL}/api/infoUploading/updateStaffDairy`;

//alumini students

export const ALUMINI_UPLOAD = `${BASE_URL}/api/infouploading/aluministudentscreate`;

export const ALUMINI_STUDENTS_GET = `${BASE_URL}/api/infouploading/getaluministudents`;

//student_gate_pass

export const STUDENT_GATE_PASS_UPLOAD = `${BASE_URL}/api/infoUploading/studentGatePasscreate`;

export const STUDENT_GATE_PASS_GET = `${BASE_URL}/api/infoUploading/getstudentgatepass`;

export const STUDENT_GATE_PASS_GET1 = `${BASE_URL}/api/infoUploading/getstudentgatepass1`;

export const STUDENT_GATE_PASS_PUT1 = `${BASE_URL}/api/infoUploading/updatestudentgatepass1`;

export const STUDENT_GATE_PASS_LIST = `${BASE_URL}/api/infoUploading/get-gate-pass-list`;


//acdemic_student_session

export const STUDENT_SESSION_GET = `${BASE_URL}/api/infoUploading/getstudentsession`;

export const STUDENT_SESSION_PUT = `${BASE_URL}/api/infoUploading/updatestudentsession`;

export const STUDENT_SESSION_PUT_BY_ID = `${BASE_URL}/api/infoUploading/updatestudentsessionid`;

//academic_student_session_semester

export const STUDENT_SESSION_SEM_GET = `${BASE_URL}/api/infoUploading/getstudentsessionsem`;

export const STUDENT_SESSION_SEM_PUT = `${BASE_URL}/api/infoUploading/updatestudentsessionsem`;

//student_info

export const STUDENT_INFO_GET = `${BASE_URL}/api/infoUploading/getstudentsinfo`;

export const STUDENT_INFO_PUT = `${BASE_URL}/api/infoUploading/updatestudentsinfo`;


//Feedback

export const FEEDBACK_QUESTIONS_UPLOAD = `${BASE_URL}/api/infoUploading/feedbackquestionscreate`;

export const FEEDBACK_QUESTIONS_GET = `${BASE_URL}/api/infoUploading/feedbackquestionsget`;

export const FEEDBACK_QUESTIONS_PUT = `${BASE_URL}/api/infoUploading/updateFeedbackQuestions`;

export const FEEDBACK_QUESTIONS_TYPE_GET = `${BASE_URL}/api/infoUploading/getfeedbackquestionstype`;

//StaffUpdate

export const STAFF_COLLEGEID_UPDATE = `${BASE_URL}/api/infoUploading/UpdateStaffCollegeId`;

export const USERS_GET_DATA = `${BASE_URL}/api/infoUploading/getUsersStaffId`;
export const USERS_COLLEGEID_UPDATE = `${BASE_URL}/api/infoUploading/UpdateUsersCollegeId`;

export const USER_UPDATE = `${BASE_URL}/api/user/user`;

//transfered_staff_old_data

export const CREATE_TRANSFERED_STAFF_OLD_DATA =`${BASE_URL}/api/infoUploading/createTransferedStaffOldData`;

//hostel_fee

export const GET_HOSTEL_FEE = `${BASE_URL}/api/infoUploading/getHostelFee`;

//id_card_template

export const IDCARD_TEMPLATE_CREATE =`${BASE_URL}/api/infoUploading/idCardTemplatecreate`;
export const IDCARD_TEMPLATE_GET = `${BASE_URL}/api/infoUploading/getIdCardTemplate`;
export const IDCARD_TEMPLATE_UPDATE = `${BASE_URL}/api/infoUploading/updateIdCardTemplate`;