import React, { useState, useEffect } from "react";
import Calender from "../../../Components/Calender/Calender";
import "./DashboardEmployee.scss";
import {
  REPORT_STSFF_ATTENDANCE_MONTHWISE_ID,
  REPORT_STAFF_APPLICATION_ID,
} from "../../../utils/Reports.apiConst";
import { EMPLOYEE } from "../../../utils/apiConstants";
import axios from "axios";
import { toast } from "react-toastify";

function DashboardEmployee({ setLoading }) {
  const [empData, setEmpData] = useState([]);
  const [data, setData] = useState([]);

  let emp_id = sessionStorage.getItem("employee_id");

  const [present, setPresent] = useState(0);
  const [absent, setAbsent] = useState(0);
  const [wo, setWo] = useState(0);
  const [approvedApplications, setApprovedApplications] = useState([]);

  const [pending, setPending] = useState(0);
  const [approves, setApproves] = useState(0);
  const [upcoming, setUpcoming] = useState(0);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const months = [
    { key: "01", name: "1" },
    { key: "02", name: "2" },
    { key: "03", name: "3" },
    { key: "04", name: "4" },
    { key: "05", name: "5" },
    { key: "06", name: "6" },
    { key: "07", name: "7" },
    { key: "08", name: "8" },
    { key: "09", name: "9" },
    { key: "10", name: "10" },
    { key: "11", name: "11" },
    { key: "12", name: "12" },
  ];

  const fetchEmployeeDetails = async () => {
    console.log("hi");
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${emp_id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("employee data - ", res.data.data);
        setEmpData(res.data.data);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  const getData = async () => {
    if (!empData.biometric_id) return;
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    currentMonth = months.find((s) => s.name == currentMonth).key;
    const currentYear = currentDate.getFullYear();
    const currentDateOfMonth = currentDate.getDate();
    const config = {
      method: "get",
      url: `${REPORT_STSFF_ATTENDANCE_MONTHWISE_ID}?empcode=${
        empData.biometric_id
      }&month=${currentMonth}&year=${currentYear}&last_date=${
        new Date().getDate() < 10
          ? `0${new Date().getDate()}`
          : new Date().getDate()
      }`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    setLoading(1);
    await axios(config)
      .then((res) => {
        console.log("month attendance data - ", res.data.data);
        let tempData = res.data.data;
        let p = 0,
          a = 0,
          w = 0;
        for (let i in tempData) {
          if (tempData[i].Status == "P") p++;
          else if (tempData[i].Status == "A") a++;
          else w++;
        }
        setPresent(p);
        setAbsent(a);
        setWo(w);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    setLoading(0);
  };

  const fetchLeaveApplication = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url:
        REPORT_STAFF_APPLICATION_ID +
        `/${emp_id}` +
        `?year=${new Date().getFullYear()}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("leave applications - ", res.data.data);
        let tempData = res.data.data;

        setApprovedApplications(
          tempData?.filter((s) => s.sttaus == "APPROVED")
        );
        let p = 0,
          a = 0,
          u = 0;
        for (let i in tempData) {
          if (tempData[i].status == "APPROVED") {
            a = a + tempData[i].number_of_days;
          } else if (tempData[i].status != "DECLINED") {
            p = p + tempData[i].number_of_days;
          }

          if (tempData[i].status == "APPROVED") {
            const currentDate = new Date();
            const givenDate = new Date(tempData[i].from_date);
            if (givenDate >= currentDate) {
              u = u + tempData[i].number_of_days;
            }
            // else {
            //   const timeDifferenceInMs = Math.abs(givenDate - currentDate);
            //   const daysDifference = Math.floor(
            //     timeDifferenceInMs / (1000 * 60 * 60 * 24)
            //   );
            //   u = u + tempData[i].number_of_days - daysDifference;
            // }
          }
        }
        setUpcoming(u);
        setPending(p);
        setApproves(a);
      })
      .catch((err) => {
        setLoading(0);
        console.log("error :: ", err);
        toast.error("Something went wrong while fetching leave applications");
      });
  };

  useEffect(() => {
    if (empData) {
      getData();
      fetchLeaveApplication();
    }
  }, [empData]);

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  return (
    <div className="DashboardEmployee">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Dashboard</h4>
                  <h4 className="mb-0">
                    Hello , {sessionStorage.getItem("emp_name")}
                  </h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Home</li>
                      <li className="breadcrumb-item active">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <h6 className="ml-1">
                Attendance for the month of {monthNames[new Date().getMonth()]}
              </h6>
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            <b>Present</b>
                          </p>
                          <h4 className="mb-0 text-success">{present}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-follow-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            <b>Absent</b>
                          </p>
                          <h4 className="mb-0 text-danger">{absent}</h4>
                        </div>
                        <div className="text-primary">
                          <i class="ri-user-unfollow-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            <b>Weekly Off</b>
                          </p>
                          <h4 className="mb-0">{wo}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-user-shared-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <h6 className="ml-1">Leave Applications </h6>
              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            <b>Total Leave Approved</b>
                          </p>
                          <h4 className="mb-0">{approves}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-thumb-up-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            <b>Pending Leave Approvals</b>
                          </p>
                          <h4 className="mb-0">{pending}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-loader-2-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="media">
                        <div className="media-body overflow-hidden">
                          <p className="text-truncate font-size-14 mb-2">
                            {" "}
                            <b>Upcoming Leaves</b>
                          </p>
                          <h4 className="mb-0">{upcoming}</h4>
                        </div>
                        <div className="text-primary">
                          <i className="ri-alarm-line font-size-24"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="card p-3 w-100">
                    <h4 className="mt-2">Event Calendar</h4>
                    <Calender
                      setLoading={setLoading}
                      collegeId={sessionStorage.getItem("college_id")}
                      emp_id={emp_id}
                    />
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body ">
                      <h4 className="card-title mb-4">Pending Leave Approvals</h4>
                      <div className="row">
                        <div className="col-12">
                          {latestNews.map((i, key) => (
                            <div key={key} className="announcements row">
                              <div className="col-11">
                                <div className='announcement-title'>
                                  {i.title}
                                </div>
                                <div className='announcement-desc'>
                                  {i.desc}
                                </div>
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                {'>'}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div></div>
                </div>

                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body ">
                      <h4 className="card-title mb-4">Announcements</h4>
                      <div className="row">
                        <div className="col-12">
                          {announcements.map((i, key) => (
                            <div key={key} className="announcements row">
                              <div className="col-11">
                                <div className='announcement-title'>
                                  {i.title}
                                </div>
                                <div className='announcement-desc'>
                                  {i.Desc}
                                </div>
                              </div>
                              <div className="col-1 d-flex align-items-center">
                                {'>'}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div></div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body ">
                      <h4 className="card-title mb-4">Discussions</h4>
                      <div id="chart8"></div>
                    </div></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardEmployee;
