import React,{useState,useEffect} from 'react'
import { SESSION_AUTH } from '../../utils/sessionStorageContants'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../Router/routerConfig'
import getUniversityDetails from '../../utils/universityDetails.api';

function AdmintoExamination() {

    //UNIVERSITY DETAILS START
const [universityData, setUniversityData] = useState([]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const data = await getUniversityDetails();
      setUniversityData(data);
    } catch (error) {
      console.error(`Error fetching university details: ${error.message}`);
    }
  };

  fetchData();
}, []);

//UNIVERSITY DETAILS END

    const navigate = useNavigate()
    const redirect=()=>{
        const auth = sessionStorage.getItem(SESSION_AUTH)
        // window.location = 'https://exams.swaminarayanuniversity.ac.in/login/auth/'+auth
        window.open(`${universityData.exams_url}/login/auth/`+auth, '_blank');

        navigate(ROUTES.Registar.dashboard)
        // window.location = 'http://localhost:3000/login/auth/'+auth
    }

    useEffect(()=>{
        redirect()
    },[])
  return (
    <div>
        <div className='p-5'>
            Redirecting............

        </div>
    </div>
  )
}

export default AdmintoExamination