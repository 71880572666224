import React,{useState, useEffect} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import Loader from '../../../../Components/Loader/Loader'
import ModalPayRollEarning from '../../../../modals/HR/PayRoll/ModalPayRollEarning'
import { PAYROLL_EARNING , PAYROLL_EMP_EARNING} from '../../../../utils/apiConstants'
import Nodata from '../../../../Components/NoData/Nodata'
import ModalEarningAssign from './ModalEarningAssign'
import {LOCAL_JOBROLES} from "../../../../utils/LocalStorageConstants";

function PayRollEarning({setLoading}) {

    const [data,setData] = useState([])
    const [type,setType] = useState('')
    const [edit,setEdit] = useState()

    const jobPositionOpt = JSON.parse(localStorage.getItem(LOCAL_JOBROLES));

    const [empData, setEmpData] = useState([]);
    const [displayData, setDisplayData] = useState([]);

    const getData = async() => {
        
        const config = {
            method: 'get',
            url: `${PAYROLL_EARNING}`,
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}` ,
                'Content-Type': 'application/json'
            },
        }
        setLoading(1);
        await axios(config)
        .then((res)=>{
            setLoading(0)
            console.log('payroll earnings - ', res.data.data);
            setData(res.data.data);
        })
        .catch(err=>{
            setLoading(0);
            toast.error("Something Went Wrong");
            console.log(err);
        })
    }

    const getEmpData = () => {
        
        const config = {
          method: "get",
          url: `${PAYROLL_EMP_EARNING}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          },
        };

        setLoading(1);
        axios(config)
          .then((res) => {
            setLoading(0);
            setEmpData(res.data.data);
            setDisplayData(res.data.data);
            console.log('employees with earnings - ', res.data.data);
          })
          .catch((err) => {
            setLoading(0);
            toast.error("Something Went Wrong");
            console.log(err);
          });
      };

    useEffect(()=>{
        getData();
        getEmpData();
    },[])
    
    return (
        <div className='PayRollEarning'>
            <div className="main-content">
                <ModalPayRollEarning type={type} data={edit} reloadData={getData} setLoading={setLoading}/>
                <ModalEarningAssign jobPositionOpt={jobPositionOpt} getEmpData={getEmpData} edit={edit} empData={empData} displayData={displayData} setDisplayData={setDisplayData} setLoading={setLoading}/>
                <div className="page-content">
                    <div className="container-fluid">
                        {/* start page title */}
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">Earning</h4>
                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">
                                                <a href="/">PayRoll</a>
                                            </li>
                                            <li className="breadcrumb-item active">Earning</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end page title */}


                        <div className="container">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row d-flex justify-content-end p-3">
                                        <button
                                            className="btn btn-rounded btn-success btn-outline px-4"
                                            data-toggle="modal"
                                            data-target="#ModalPayRollEarning"
                                            onClick={()=>{setType('add');setEdit()}}
                                        >
                                            Add +
                                        </button>
                                    </div>

                                    <div>
                                        {data&&data.length!==0?data?.map((i, key) => (
                                            <div
                                                className="row my-3 mx-2 p-3 border rounded role-div flex-nowrap shadow"
                                            >
                                                <div className="col-10" key={key}>
                                                    <div className="role-title">
                                                        {i.title}
                                                    </div>
                                                    <div className="role-code">
                                                        {i.description}
                                                    </div>
                                                </div>
                                                <div className="col-1 d-flex align-items-center justify-content-end"
                                                    data-toggle="modal"
                                                    data-target="#ModalEarningAssign"
                                                    onClick={()=>{setType('edit');setEdit(i)}}
                                                >
                                                    <i
                                                    className="fa fa-tags "
                                                    aria-hidden="true"
                                                    style={{
                                                        color: "#364277",
                                                        cursor: "pointer",
                                                    }}
                                                    onmouseover="this.style.color='orange'"
                                                    onmouseout="this.style.color='#364277'"
                                                    />Assign
                                                </div>
                                                <div className="col-1 d-flex align-items-center justify-content-end"
                                                    data-toggle="modal"
                                                    data-target="#ModalPayRollEarning"
                                                    onClick={()=>{setType('edit');setEdit(i)}}
                                                >
                                                    <i className="fa fa-edit mr-1" aria-hidden="true"/>Edit
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div className='mt-3'>
                                            <Nodata titleTop={'No data available for your search'}/>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayRollEarning