import axios from "axios";
import React from "react";
import { useState } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import {
  REPORT_FEE_DETAILS,
  REPORT_DEPARTMENT_WISE_FEE_DETAILS,
  FEE_SUMMARY_REPORT,
} from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { toast } from "react-toastify";
import { college_title } from "../../../Data/main";
import { sessionOpt } from "../../../Data/jsonData/Academics/Academics";
import Select from "react-select";

const SummaryFeeReport = ({ setLoading, collegeId }) => {
  let role = sessionStorage.getItem("role");
  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const tableRef = useRef();

  const [data, setData] = useState([]);

  const [dates, setDates] = useState([]);

  const [deparment, setDeparment] = useState("All");
  const [session, setSession] = useState(
    sessionOpt?.find((s) => s.status == "ACTIVE")?.id
  );
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [studentSet, setStudentSet] = useState([]);
  const navigate = useNavigate();

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  const [name, setName] = useState("");
  const [status, setStatus] = useState("ACTIVE");

  const getData = async () => {
    if (!session) {
      return toast.error("Please Select Academic Session");
    }
    if (!deparment) {
      return toast.error("Please Select Department");
    }
    setLoading(1);

    let apiUrl = `${FEE_SUMMARY_REPORT}?session_id=${session}&college_id=${collegeId}&status=${status}`;

    try {
      setLoading(1);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      });

      setLoading(0);
      console.log("data - ", response.data.data);
      setData(response.data.data);
      //   const groupedTransactions = response.data.data?.reduce((acc, curr) => {
      //     if (!acc[curr.transaction_date]) {
      //         acc[curr.transaction_date] = []
      //     }
      //     acc[curr.transaction_date].push(curr);
      //     return acc;
      // }, {});

      // let temp = [];

      // for(let i in groupedTransactions) {
      //   temp.push({date: i,
      //     transactions: groupedTransactions[i],
      //     amount: groupedTransactions[i].reduce((acc,curr) =>  acc + curr.amount, 0),
      //     discount: groupedTransactions[i].reduce((acc,curr) =>  acc + curr.discount, 0),
      //     fine: groupedTransactions[i].reduce((acc,curr) =>  acc + curr.fine, 0),
      //   });
      // }

      // console.log('datewsie data - ', temp);
      // setData(temp);
    } catch (error) {
      setLoading(0);
      console.log(error);
    }

    setLoading(0);
  };

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Fee Summary Report" + name,
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const handleDepartmentChange = (selectedOption) => {
    setDeparment(selectedOption?.label);
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">FEE SUMMARY REPORT</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom02">
                        Session <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select
                        name="session_id"
                        id="fee_grp"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={
                          sessionOpt &&
                          sessionOpt.map((data) => ({
                            value: data.id,
                            label: data.name,
                          }))
                        }
                        value={
                          session
                            ? {
                                value: session,
                                label: sessionOpt.find(
                                  (data) => data.id == session
                                )?.name,
                              }
                            : null
                        }
                        onChange={(selectedOption) => {
                          setSession(selectedOption.value);
                        }}
                        placeholder="Select Session"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Department</label>

                      <Select
                        options={[
                          { value: "All", label: "All" },
                          ...departmentData
                            ?.filter((s) => s.college_id == collegeId)
                            ?.map((i) => ({ value: i.name, label: i.name })),
                        ]}
                        value={
                          deparment
                            ? {
                                value: deparment,
                                label: deparment,
                              }
                            : null
                        }
                        onChange={handleDepartmentChange}
                        placeholder="Select Department"
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="validationCustom01">Status</label>
                      <select
                        name="status"
                        className="form-control"
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value);
                        }}
                      >
                        <option value="ALL">ALL</option>
                        <option value="ACTIVE">ACTIVE</option>
                        <option value="INACTIVE">INACTIVE</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <button className="btn btn-nex mt-4" onClick={getData}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">Fee Summary Report</div>
                    {
                      <button
                        className="btn btn-nex rounded-pill"
                        onClick={onDownload}
                      >
                        Excel
                      </button>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive fixTableHeadPage">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <tr>
                            <th colSpan={17}>{college_title}</th>
                          </tr>
                          <tr>
                            <th colSpan={17}>
                              Fee Summary Report : {session}-
                              {parseInt(session) + 1}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data
                            ?.filter(
                              (s) =>
                                deparment == "All" || s.department == deparment
                            )
                            ?.map((i, k) => {
                              return (
                                <>
                                  <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th colSpan={5} className="text-center">
                                      Total Scholarship Students
                                    </th>
                                    <th colSpan={5} className="text-center">
                                      Total Non Scholarship Students
                                    </th>
                                    <th>TFW</th>
                                    <th colSpan={2} className="text-center">
                                      Total
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Department</th>
                                    <th>Semester</th>
                                    <th>Enrolled Students</th>
                                    <th>
                                      Total
                                      <br />
                                      Students
                                    </th>
                                    <th>
                                      No. of <br />
                                      Fees Paid
                                    </th>
                                    <th>Total Paid</th>
                                    {/* <th>Total Trust<br/>Grant</th> */}
                                    {/* <th>Total Fine</th> */}
                                    <th>
                                      Pending <br />
                                      Fees Number
                                    </th>
                                    <th>
                                      Pending <br /> Fees Value
                                    </th>
                                    <th>
                                      Total
                                      <br />
                                      Students
                                    </th>
                                    <th>
                                      No. of <br />
                                      Fees Paid
                                    </th>
                                    <th>Total Paid</th>
                                    {/* <th>Total Trust <br/>Grant</th> */}
                                    {/* <th>Total Fine</th> */}
                                    <th>
                                      Pending <br />
                                      Fees Number
                                    </th>
                                    <th>
                                      Pending <br /> Fees Value
                                    </th>
                                    <th>
                                      Total
                                      <br />
                                      Students
                                    </th>
                                    <th>
                                      Total <br />
                                      Paid
                                      <br />
                                      Fees
                                    </th>
                                    <th>
                                      Total <br />
                                      Pending
                                      <br />
                                      Fees
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>
                                      <b>{i?.department}</b>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th colSpan={5}></th>
                                    <th colSpan={5}></th>
                                    <th></th>
                                    <th colSpan={2}></th>
                                  </tr>

                                  {i?.fee_data?.map((j, key) => {
                                    return (
                                      <>
                                        <tr>
                                          <td></td>
                                          <td>{j?.sem_name}</td>
                                          <td>
                                            {(j?.items?.find(
                                              (s) => s.scholarship
                                            )?.count || 0) +
                                              (j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.count || 0) +
                                              Number(j?.tfw_count)}
                                          </td>
                                          <td>
                                            {j?.items?.find(
                                              (s) => s.scholarship
                                            )?.count || 0}
                                          </td>
                                          <td>
                                            {j?.items?.find(
                                              (s) => s.scholarship
                                            )?.paid_count || 0}
                                          </td>
                                          <td>
                                            {(
                                              j?.items?.find(
                                                (s) => s.scholarship
                                              )?.paid_amount || 0
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          {/* <td>{(j?.items?.find((s) => s.scholarship)?.discount || 0)?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}</td>
                                      <td>{(j?.items?.find((s) => s.scholarship)?.fine || 0)?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}</td> */}
                                          <td>
                                            {(j?.items?.find(
                                              (s) => s.scholarship
                                            )?.count || 0) -
                                              (j?.items?.find(
                                                (s) => s.scholarship
                                              )?.paid_count || 0)}
                                          </td>
                                          {/* <td>-</td> */}
                                          <td>
                                            {(
                                              (j?.items?.find(
                                                (s) => s.scholarship
                                              )?.amount || 0) -
                                              (j?.items?.find(
                                                (s) => s.scholarship
                                              )?.paid_amount || 0) -
                                              (j?.items?.find(
                                                (s) => s.scholarship
                                              )?.discount || 0)
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>
                                            {j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.count || 0}
                                          </td>
                                          <td>
                                            {j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.paid_count || 0}
                                          </td>
                                          {/* <td>-</td> */}
                                          <td>
                                            {(
                                              j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.paid_amount || 0
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          {/* <td>{(j?.items?.find((s) => !s.scholarship)?.discount || 0)?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}</td>
                                      <td>{(j?.items?.find((s) => !s.scholarship)?.fine || 0)?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}</td> */}
                                          <td>
                                            {(j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.count || 0) -
                                              (j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.paid_count || 0)}
                                          </td>
                                          {/* <td>-</td> */}
                                          <td>
                                            {(
                                              (j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.amount || 0) -
                                              (j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.paid_amount || 0) -
                                              (j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.discount || 0)
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>{j?.tfw_count}</td>
                                          <td>
                                            {(
                                              parseInt(
                                                j?.items?.find(
                                                  (s) => !s.scholarship
                                                )?.paid_amount || 0
                                              ) +
                                              parseInt(
                                                j?.items?.find(
                                                  (s) => s.scholarship
                                                )?.paid_amount || 0
                                              )
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                          <td>
                                            {(
                                              parseInt(
                                                j?.items?.find(
                                                  (s) => !s.scholarship
                                                )?.amount || 0
                                              ) -
                                              parseInt(
                                                j?.items?.find(
                                                  (s) => !s.scholarship
                                                )?.paid_amount || 0
                                              ) -
                                              parseInt(
                                                j?.items?.find(
                                                  (s) => !s.scholarship
                                                )?.discount || 0
                                              ) +
                                              (parseInt(
                                                j?.items?.find(
                                                  (s) => s.scholarship
                                                )?.amount || 0
                                              ) -
                                                parseInt(
                                                  j?.items?.find(
                                                    (s) => s.scholarship
                                                  )?.paid_amount || 0
                                                ) -
                                                parseInt(
                                                  j?.items?.find(
                                                    (s) => s.scholarship
                                                  )?.discount || 0
                                                ))
                                            )?.toLocaleString("en-IN", {
                                              style: "currency",
                                              currency: "INR",
                                              minimumFractionDigits: 0,
                                            })}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                  <tr>
                                    <th></th>
                                    <th>Total</th>
                                    {/* scholarship total */}
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => s.scholarship)
                                            ?.count || 0;
                                        const nonScholarshipCount =
                                          j?.items?.find((s) => !s.scholarship)
                                            ?.count || 0;
                                        return (
                                          acc +
                                          scholarshipCount +
                                          nonScholarshipCount
                                        );
                                      }, 0)}
                                    </th>
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => s.scholarship)
                                            ?.count || 0;
                                        return acc + scholarshipCount;
                                      }, 0)}
                                    </th>
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => s.scholarship)
                                            ?.paid_count || 0;
                                        return acc + parseInt(scholarshipCount);
                                      }, 0)}
                                    </th>
                                    {/* <th>-</th> */}
                                    <th>
                                      {i?.fee_data
                                        ?.reduce((acc, j) => {
                                          const scholarshipCount =
                                            j?.items?.find((s) => s.scholarship)
                                              ?.paid_amount || 0;
                                          return (
                                            acc + parseInt(scholarshipCount)
                                          );
                                        }, 0)
                                        ?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </th>
                                    {/* <th>
                                  {i?.fee_data?.reduce((acc, j) => {
                                    const scholarshipCount = j?.items?.find(s => s.scholarship)?.discount || 0;
                                    return acc + parseInt(scholarshipCount);
                                  }, 0)?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th> */}
                                    {/* <th>
                                  {i?.fee_data?.reduce((acc, j) => {
                                    const scholarshipCount = j?.items?.find(s => s.scholarship)?.fine || 0;
                                    return acc + parseInt(scholarshipCount);
                                  }, 0)?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th> */}
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => s.scholarship)
                                            ?.count || 0;
                                        const paid_count =
                                          j?.items?.find((s) => s.scholarship)
                                            ?.paid_count || 0;
                                        return (
                                          acc +
                                          parseInt(scholarshipCount) -
                                          parseInt(paid_count)
                                        );
                                      }, 0)}
                                    </th>
                                    {/* <th>-</th> */}
                                    <th>
                                      {i?.fee_data
                                        ?.reduce((acc, j) => {
                                          const scholarshipCount =
                                            j?.items?.find((s) => s.scholarship)
                                              ?.amount || 0;
                                          const paid_count =
                                            j?.items?.find((s) => s.scholarship)
                                              ?.paid_amount || 0;
                                          const discount =
                                            j?.items?.find((s) => s.scholarship)
                                              ?.discount || 0;
                                          return (
                                            acc +
                                            parseInt(scholarshipCount) -
                                            parseInt(paid_count) -
                                            parseInt(discount)
                                          );
                                        }, 0)
                                        ?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </th>

                                    {/* non scholarship total */}
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => !s.scholarship)
                                            ?.count || 0;
                                        return acc + scholarshipCount;
                                      }, 0)}
                                    </th>
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => !s.scholarship)
                                            ?.paid_count || 0;
                                        return acc + parseInt(scholarshipCount);
                                      }, 0)}
                                    </th>
                                    {/* <th>-</th> */}
                                    <th>
                                      {i?.fee_data
                                        ?.reduce((acc, j) => {
                                          const scholarshipCount =
                                            j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.paid_amount || 0;
                                          return (
                                            acc + parseInt(scholarshipCount)
                                          );
                                        }, 0)
                                        ?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </th>
                                    {/* <th>
                                  {i?.fee_data?.reduce((acc, j) => {
                                    const scholarshipCount = j?.items?.find(s => !s.scholarship)?.discount || 0;
                                    return acc + parseInt(scholarshipCount);
                                  }, 0)?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th> */}
                                    {/* <th>
                                  {i?.fee_data?.reduce((acc, j) => {
                                    const scholarshipCount = j?.items?.find(s => !s.scholarship)?.fine || 0;
                                    return acc + parseInt(scholarshipCount);
                                  }, 0)?.toLocaleString("en-IN", {
                                    style: "currency",
                                    currency: "INR",
                                    minimumFractionDigits: 0,
                                  })}
                                </th> */}
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        const scholarshipCount =
                                          j?.items?.find((s) => !s.scholarship)
                                            ?.count || 0;
                                        const paid_count =
                                          j?.items?.find((s) => !s.scholarship)
                                            ?.paid_count || 0;
                                        return (
                                          acc +
                                          parseInt(scholarshipCount) -
                                          parseInt(paid_count)
                                        );
                                      }, 0)}
                                    </th>
                                    {/* <th>-</th> */}
                                    <th>
                                      {i?.fee_data
                                        ?.reduce((acc, j) => {
                                          const scholarshipCount =
                                            j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.amount || 0;
                                          const paid_count =
                                            j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.paid_amount || 0;
                                          const discount =
                                            j?.items?.find(
                                              (s) => !s.scholarship
                                            )?.discount || 0;
                                          return (
                                            acc +
                                            parseInt(scholarshipCount) -
                                            parseInt(paid_count) -
                                            parseInt(discount)
                                          );
                                        }, 0)
                                        ?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </th>
                                    <th>
                                      {i?.fee_data?.reduce((acc, j) => {
                                        return acc + j?.tfw_count;
                                      }, 0)}
                                    </th>
                                    <th>
                                      {i?.fee_data
                                        ?.reduce((acc, j) => {
                                          const paid_count =
                                            parseInt(
                                              j?.items?.find(
                                                (s) => s.scholarship
                                              )?.paid_amount || 0
                                            ) +
                                            parseInt(
                                              j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.paid_amount || 0
                                            );
                                          return acc + parseInt(paid_count);
                                        }, 0)
                                        ?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </th>
                                    <th>
                                      {i?.fee_data
                                        ?.reduce((acc, j) => {
                                          const scholarshipCount =
                                            parseInt(
                                              j?.items?.find(
                                                (s) => s.scholarship
                                              )?.amount || 0
                                            ) +
                                            parseInt(
                                              j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.amount || 0
                                            );
                                          const paid_count =
                                            parseInt(
                                              j?.items?.find(
                                                (s) => s.scholarship
                                              )?.paid_amount || 0
                                            ) +
                                            parseInt(
                                              j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.paid_amount || 0
                                            );
                                          const discount =
                                            parseInt(
                                              j?.items?.find(
                                                (s) => s.scholarship
                                              )?.discount || 0
                                            ) +
                                            parseInt(
                                              j?.items?.find(
                                                (s) => !s.scholarship
                                              )?.discount || 0
                                            );
                                          return (
                                            acc +
                                            parseInt(scholarshipCount) -
                                            parseInt(paid_count) -
                                            parseInt(discount)
                                          );
                                        }, 0)
                                        ?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td colSpan={19}></td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryFeeReport;
