import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { RETURN_FEE } from "../../../utils/fees.apiConst";
import { useState } from "react";

const ModalReturnFee = ({
  setLoading,
  data,
  mainData,
  reloadData,
  collegeId,
}) => {
  const [note, setNote] = useState("");

  console.log("data - ", data);

  const handleSubmit = () => {
    if (!note) {
      return toast.error("Please add reason to return the fee");
    }
    const config = {
      method: "post",
      url: `${RETURN_FEE}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        data,
        note,
      },
    };

    console.log("note - ", note);
    console.log(config);

    setLoading(1);

    axios(config)
      .then((res) => {
        setLoading(0);
        toast.success("Successfully returned");
        setNote("");
        reloadData();
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  return (
    <div className="ModalReturnFee">
      <div
        className="modal fade"
        id="return"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="return"
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="return">
                Confirmation
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setNote("")}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Are you sure want to delete invoice, this action is
                irreversible. Do you want to proceed?
              </p>
              <label>
                Note (Reason)<span style={{ color: "red" }}>*</span>
              </label>
              <textarea
                cols="60"
                rows="5"
                onChange={(e) => {
                  setNote(e.target.value);
                }}
                value={note}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setNote("")}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="return_event"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                Return
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReturnFee;
