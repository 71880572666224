import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import ModalAddFee from "../../../modals/Accounts/FeeCollection/ModalAddFee";
import ModalCollectFee from "../../../modals/Accounts/FeeCollection/ModalCollectFee";
import ModalReturnFee from "../../../modals/Accounts/FeeCollection/ModalReturnFee";
import AddOtherFee from "../../../modals/Fee/AddOtherFee";
import {
  PREVIOUS_YEAR_DUE_FEES,
  GET_FEE_TYPE_AMOUNT,
  GET_FEE_GROUP_AMOUNT,
  FEE_DETAILS_BY_STUDENT_ID,
} from "../../../utils/fees.apiConst";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { toast } from "react-toastify";
import { ACCOUNT_FEE_MASTER } from "../../../utils/Accounts.apiConst";
import {
  FEE_COLLECT_DETAILS,
  FEE_DRAWER_STUDENTS,
} from "../../../utils/fees.apiConst";
import { EMPLOYEE_ALL2 } from "../../../utils/apiConstants";
import FeeCollectionPaymentRow from "./FeeCollectionPaymentRow";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import FeeCollectionFeeReciept from "./FeeCollectionFeeReciept";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import {
  STUDENT_ADVANCE_PAY,
  STUDENT_SESSION,
} from "../../../utils/apiConstants";
import { findIndex } from "lodash";
import { Button } from "antd";
import { ROUTES } from "../../../Router/routerConfig";

const FeeCollectionAddFee = ({ setLoading, collegeId }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [futureDate, setFutureDate] = useState(null);

  let role = sessionStorage.getItem("role");

  const [nextRoute, setNextRoute] = useState(
    ROUTES.Registar.Accounts.FeeCollection.AddFee
  );

  useEffect(() => {
    if (role == "SUPERADMIN") {
      setNextRoute(ROUTES.Registar.Accounts.FeeCollection.AddFee);
    } else if (role == "SUACC") {
      setNextRoute(ROUTES.Accountant.AddFee);
    } else if (role == "CASHIER") {
      setNextRoute(ROUTES.Cashier.AddFee);
    }
  }, [role]);

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString;
    }
  }

  useEffect(() => {
    const currentDateObject = new Date();
    const futureDateObject = new Date(currentDateObject);
    futureDateObject.setMonth(currentDateObject.getMonth() + 6);
    setCurrentDate(currentDateObject);
    setFutureDate(futureDateObject);
  }, []);

  const navigate = useNavigate();

  const changeDir = (id) => {
    console.log(`${nextRoute}/${id}`);
    navigate(`${nextRoute}/${id}`);
    getData(id);
    onClose();
  };

  const changeDir2 = (dir, id) => {
    navigate(`${dir}/${id}`);
  };

  const getCollegeData = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const getDepartmentData = () => {
    return localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());

  const [departmentData, setDepartmentData] = useState();
  let auth = sessionStorage.getItem("UMS_auth");

  useEffect(() => {
    setCollegeOpt(getCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setDepartmentData(getDepartmentData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [feeData, setFeeData] = useState([]);

  const [classData, setClassData] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [show, setShow] = useState(false);
  const [addData, setAddData] = useState();

  const [returnData, setReturnData] = useState();

  const [selectedArr, setSelectedArr] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [printData, setPrintData] = useState();

  const [printSubData, setPrintSubData] = useState();

  const [feeDetails, setFeeDetails] = useState([]);

  const [feeTypeAmount, setFeeTypeAmount] = useState([]);
  const [feeGroup, setFeeGroup] = useState([]);

  const [sessionData, setSessionData] = useState([]);

  const [prevdata, setPrevdata] = useState([]);

  const [fees, setFees] = useState([]);

  const [studentData, setStudentData] = useState([]);

  const [drawerStudents, setDrawerStudents] = useState([]);

  const [allstudentloading, setAllStudentLoading] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [otherSemFees, setOtherSemFees] = useState([]);

  const onClose = () => {
    setIsDrawerVisible(false);
  };

  const OpenDrawer = () => {
    setIsDrawerVisible(true);
  };

  const printRef = useRef();

  const PrintRecipt = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrint = async (dd, ss) => {
    await setPrintData(dd);
    await setPrintSubData(ss);
    PrintRecipt();
  };

  const params = useParams();
  const [searchParams] = useSearchParams();

  async function getFeeData(id) {
    setLoading(1);
    const config = {
      method: "get",
      url: `${FEE_DETAILS_BY_STUDENT_ID}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log("other sem fees - ", res.data.data);
        setOtherSemFees(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something went wrong");
      });
  }

  const getDrawerStudents = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    setAllStudentLoading(true);
    await axios({
      ...config,
      url: `${FEE_DRAWER_STUDENTS}?semester_id=${params.id}`,
    })
      .then((res) => {
        setAllStudentLoading(false);
        console.log("drawer students - ", res.data.data);
        setDrawerStudents(res.data.data);
      })
      .catch((err) => {
        setAllStudentLoading(false);
        console.log(err);
        // toast.error("Something Went Wrong");
      });
    setAllStudentLoading(false);
  };

  const getData = async (id) => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    setLoading(1);
    await axios({
      ...config,
      url: `${FEE_COLLECT_DETAILS}?student_semester_id=${id ? id : params.id}`,
    })
      .then((res) => {
        setLoading(0);
        console.log("student data - ", res.data.data);
        let tempData = res.data.data;
        for (let i in tempData) {
          let amt = 0,
            discount = 0,
            fine = 0;
          for (let j in tempData[i].payments) {
            amt = amt + parseInt(tempData[i].payments[j].amount);
            discount = discount + parseInt(tempData[i].payments[j].discount);
            fine = fine + parseInt(tempData[i].payments[j].fine);
          }
          tempData[i].paid_amount = amt;
          tempData[i].discount = discount;
          tempData[i].fine = fine;
        }
        setStudentData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
        toast.error("Something Went Wrong");
      });
    setLoading(0);
  };

  const handleSelectedArr = (e, eid) => {
    if (e.target.checked == false) {
      if (selectedArr.length == 0) return;
      if (selectedArr.length == 1) {
        setSelectedArr([]);
        return;
      }
      const d = [];
      for (let index = 0; index < selectedArr.length; index++) {
        if (eid !== selectedArr[index]) {
          d.push(selectedArr[index]);
          // setSelectAll(false)
          // setSelectedArr([...d])
        }
      }
      setSelectedArr(d);
    }
    if (e.target.checked == true) {
      setSelectedArr([...selectedArr, eid]);
    }
  };

  useEffect(() => {
    if (selectAll == true) {
      if (feeData?.feeDetails?.length != 0) {
        setSelectedArr(feeData?.feeDetails?.map((i) => i?.fee_type_id));
      }
    } else {
      setSelectedArr([]);
    }
  }, [selectAll]);

  useEffect(() => {
    getData();
    getDrawerStudents();
  }, [params.id]);

  useEffect(() => {
    if (studentData[0]?.user_id) {
      getFeeData(studentData[0]?.user_id);
    }
  }, [studentData[0]?.user_id]);

  return (
    <div className="FeeCollectionAddFee">
      {!feeData ? null : (
        <>
          <AddOtherFee
            setLoading={setLoading}
            getData1={getData}
            mainData={feeData?.feeDetails || []}
            collegeId={collegeId}
            session={searchParams.get("session_id")}
            class_id={searchParams.get("class_id")}
          />
          <ModalAddFee
            data={addData}
            mainData={feeData}
            setLoading={setLoading}
            reloadData={getData}
            discount={feeDetails?.fee_discount_details || []}
            collegeId={collegeId}
          />
          <ModalReturnFee
            data={returnData}
            mainData={feeData || []}
            reloadData={getData}
            setLoading={setLoading}
            collegeId={collegeId}
          />
          <ModalCollectFee
            mainData={feeData || []}
            selectedArr={selectedArr}
            setLoading={setLoading}
            reloadData={getData}
            collegeId={collegeId}
          />
          <Drawer
            title="Students in Similar Class"
            placement={"right"}
            width={500}
            onClose={onClose}
            open={isDrawerVisible}
          >
            <div className="row">
              <div className="col-md-12">
                {drawerStudents?.map((i, key) => (
                  <div
                    className="d-flex p-2 px-3 card rounded cursor-pointer"
                    onClick={() => {
                      changeDir(i?.id);
                    }}
                  >
                    <div className="row">
                      <div className="col-md-8">
                        <h6>{i?.name}</h6>
                        <p className="mb-0">{i?.user_id}</p>
                      </div>
                      <div className="col-md-4">
                        <img
                          className="profile-user-img img-responsive rounded mx-auto d-block"
                          src={`${
                            i?.student_picture
                              ? i?.student_picture
                              : "../../../assets/images/reports/graduated.png"
                          }
                            `}
                          width="50%"
                          style={{ aspectRatio: "1/1" }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Drawer>
          <div style={{ display: "none" }}>
            <div ref={printRef}>
              <FeeCollectionFeeReciept
                mainData={studentData[0] || []}
                data={printData}
                subData={printSubData}
                collegeId={collegeId}
                collegeOpt={collegeOpt}
                classData={classData}
                departmentData={departmentData}
              />
            </div>
          </div>
        </>
      )}

      <div className="main-content">
        <div className="page-content">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0">Collect Fee</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="javascript: void(0);">Fee Collection</a>
                    </li>
                    <li className="breadcrumb-item active">Collect Fee</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <img
                        className="profile-user-img img-responsive rounded-circle mx-auto d-block"
                        src={`${
                          studentData[0]?.student_picture
                            ? studentData[0]?.student_picture
                            : "../../../assets/images/reports/graduated.png"
                        }
                                `}
                        width="40%"
                        style={{ aspectRatio: "1/1" }}
                      />
                      <div className="text-center mt-3">
                        <h5>{studentData[0]?.name}</h5>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-2">
                          <h5 className="text-primary mt-2">Student Details</h5>
                        </div>
                        <div className="col-md-6">
                          <abb title="Students List">
                            <Button
                              className="btn-nex"
                              onClick={OpenDrawer}
                              type="primary"
                              // icon={<i className="fas fa-align-justify" />}
                              loading={allstudentloading}
                            >
                              Students List
                            </Button>
                          </abb>
                        </div>
                        <div className="col-md-12 mt-1">
                          <table className="table table-bordered">
                            <tr>
                              <th width="25%">Enrollment No. </th>
                              <td width="25%"> {studentData[0]?.user_id}</td>
                              <th width="25%">Session </th>
                              <td width="25%">
                                {" "}
                                {studentData[0]?.session_id}-
                                {studentData[0]?.session_id + 1}
                              </td>
                            </tr>
                            <tr>
                              <th width="25%">University No. </th>
                              <td width="25%">
                                {" "}
                                {studentData[0]?.university_number}
                              </td>

                              <th>Department</th>
                              <td> {studentData[0]?.department_name}</td>
                            </tr>
                            <tr>
                              <th>Gender</th>
                              <td> {studentData[0]?.gender}</td>
                              <th>Class</th>
                              <td> {studentData[0]?.class_name}</td>
                            </tr>
                            <tr>
                              <th>Phone</th>
                              <td> {studentData[0]?.phone}</td>
                              <th>Semester</th>
                              <td> {studentData[0]?.sem_name}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <h5 className="text-primary mt-2">
                            Pending Semester Dues
                          </h5>
                        </div>
                        <div className="col-md-12 mt-1">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Class</th>
                                <th>Semester</th>
                                <th>Amount</th>
                                <th>Trust Grant</th>
                                <th>Fine</th>
                                <th>Paid</th>
                                <th>Balance</th>
                                <th>Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {otherSemFees
                                ?.filter(
                                  (s) =>
                                    s.id != params.id &&
                                    parseInt(s?.amount) -
                                      parseInt(s?.paid_amount) -
                                      parseInt(s?.discount) >
                                      0
                                )
                                ?.map((i, k) => {
                                  return (
                                    <tr>
                                      <td>{i?.class_name}</td>
                                      <td>{i?.sem_name}</td>
                                      <td>
                                        {parseInt(i?.amount)?.toLocaleString(
                                          "en-IN",
                                          {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 0,
                                          }
                                        )}
                                      </td>
                                      <td>
                                        {parseInt(i?.discount)?.toLocaleString(
                                          "en-IN",
                                          {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 0,
                                          }
                                        )}
                                      </td>
                                      <td>
                                        {parseInt(i?.fine)?.toLocaleString(
                                          "en-IN",
                                          {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 0,
                                          }
                                        )}
                                      </td>
                                      <td>
                                        {parseInt(
                                          i?.paid_amount
                                        )?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                      </td>
                                      <td>
                                        {(
                                          parseInt(i?.amount) -
                                          parseInt(i?.discount) -
                                          parseInt(i?.paid_amount)
                                        )?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                      </td>
                                      <td>
                                        <span
                                          className={`badge badge-${
                                            parseInt(i?.amount) -
                                              parseInt(i?.paid_amount) -
                                              parseInt(i?.discount) <=
                                            0
                                              ? "success"
                                              : parseInt(i?.paid_amount) == 0
                                              ? "danger"
                                              : "warning"
                                          }`}
                                        >
                                          {parseInt(i?.amount) -
                                            parseInt(i?.paid_amount) -
                                            parseInt(i?.discount) <=
                                          0
                                            ? "Paid"
                                            : parseInt(i?.paid_amount) == 0
                                            ? "Not Paid"
                                            : "Partial"}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-nex btn-sm"
                                          onClick={() => {
                                            role == "SUACC" || role == "ACC"
                                              ? changeDir2(
                                                  ROUTES.Accountant.AddFee,
                                                  i?.id
                                                )
                                              : role == "SUPERADMIN"
                                              ? changeDir2(
                                                  ROUTES.Registar.Accounts
                                                    .FeeCollection.AddFee,
                                                  i?.id
                                                )
                                              : role == "ADMIN"
                                              ? changeDir2(
                                                  ROUTES.Principal.Accounts
                                                    .FeeCollection.AddFee,
                                                  i?.id
                                                )
                                              : role == "CASHIER"
                                              ? changeDir2(
                                                  ROUTES.Cashier.AddFee,
                                                  i?.id
                                                )
                                              : changeDir2("/");
                                          }}
                                        >
                                          Collect
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
          {/* container-fluid */}
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      {" "}
                      <h4 className="card-title">
                        Fee Group - {studentData[0]?.group_name}
                      </h4>
                    </div>
                  </div>
                  <br />
                  <div className="table-rep-plugin">
                    <div className="table-responsive mb-0">
                      <table
                        id="tech-companies-1"
                        className="table table-hovered text-wrap"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            {/* <th>Fee Group</th> */}

                            <th>Fee Code</th>
                            <th>Status</th>
                            <th>Amount</th>
                            <th>Payment ID</th>
                            <th>Mode</th>
                            <th
                              className="text-center"
                              style={{ minWidth: "30px" }}
                            >
                              Date
                            </th>
                            <th>Trust Grant</th>
                            <th>Fine</th>
                            <th>Paid</th>
                            <th>Balance</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {studentData?.map((i, k) => {
                            return (
                              <>
                                <tr>
                                  <td>{i?.fee_type_id}</td>
                                  <td>
                                    <span
                                      className={`badge badge-${
                                        i?.amount -
                                          i?.paid_amount -
                                          i?.discount <=
                                        0
                                          ? "success"
                                          : i?.paid_amount == 0
                                          ? "danger"
                                          : "warning"
                                      }`}
                                    >
                                      {i?.amount -
                                        i?.paid_amount -
                                        i?.discount <=
                                      0
                                        ? "Paid"
                                        : i?.paid_amount == 0
                                        ? "Not Paid"
                                        : "Partial"}
                                    </span>
                                  </td>
                                  <td>
                                    {i?.amount?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {i?.discount?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                  <td>
                                    <b></b>
                                    {i?.fine?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                  <td>
                                    <b>
                                      {(
                                        i?.paid_amount + i?.fine
                                      )?.toLocaleString("en-IN", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 0,
                                      })}
                                    </b>
                                  </td>
                                  <td>
                                    {(
                                      i?.amount -
                                      i?.paid_amount -
                                      i?.discount
                                    )?.toLocaleString("en-IN", {
                                      style: "currency",
                                      currency: "INR",
                                      minimumFractionDigits: 0,
                                    })}
                                  </td>
                                  <td>
                                    {i?.amount - i?.paid_amount - i?.discount >
                                    0 ? (
                                      <a
                                        href=""
                                        className="badge badge-light "
                                        id="fire"
                                        data-toggle="modal"
                                        data-target="#addfee"
                                        title="Add Fee"
                                        onClick={() => {
                                          setAddData(i);
                                        }}
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        href=""
                                        class="badge badge-light "
                                        id="fire"
                                        data-toggle="modal"
                                        data-target="#return"
                                        title="Return"
                                        onClick={() => setReturnData(i)}
                                      >
                                        <i
                                          class="fa fa-repeat"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                  </td>
                                </tr>
                                {i?.payments?.map((j, key) => {
                                  return (
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <img
                                          src="/assets/images/table-arrow.png"
                                          alt=""
                                          className="ml-3"
                                        />
                                      </td>
                                      <td>{j?.transaction_id}</td>
                                      <td>{j?.type}</td>
                                      <td className="text-center">
                                        {formatDate(j?.transaction_date)}
                                      </td>
                                      <td>
                                        {j?.discount?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                      </td>
                                      <td>
                                        {j?.fine?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                      </td>
                                      <td>
                                        {j?.amount?.toLocaleString("en-IN", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 0,
                                        })}
                                      </td>
                                      <td></td>
                                      <td>
                                        <a
                                          href="javascript:void(0)"
                                          class="badge badge-light"
                                          title="Print Reciept"
                                          data-original-title="Print"
                                          onClick={() => handlePrint(j)}
                                        >
                                          <i class="fa fa-print"></i>
                                        </a>
                                        <a
                                          href=""
                                          class="badge badge-light ml-3"
                                          id="fire"
                                          data-toggle="modal"
                                          data-target="#return"
                                          title="Return"
                                          onClick={() => {
                                            let tempData = {};
                                            let payments = [];
                                            payments.push(j);
                                            tempData.payments = payments;
                                            setReturnData(tempData);
                                          }}
                                        >
                                          <i
                                            class="fa fa-repeat"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr className="table-light">
                            <th colSpan={2}>
                              <p className="text-center">Grand Total</p>
                            </th>
                            <th>
                              {studentData
                                ?.reduce((acc, curr) => acc + curr.amount, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th colSpan={3}></th>
                            <th>
                              {studentData
                                ?.reduce((acc, curr) => acc + curr.discount, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {studentData
                                ?.reduce((acc, curr) => acc + curr.fine, 0)
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {studentData
                                ?.reduce(
                                  (acc, curr) => acc + curr.paid_amount,
                                  0
                                )
                                ?.toLocaleString("en-IN", {
                                  style: "currency",
                                  currency: "INR",
                                  minimumFractionDigits: 0,
                                })}
                            </th>
                            <th>
                              {(
                                studentData?.reduce(
                                  (acc, curr) => acc + curr.amount,
                                  0
                                ) -
                                studentData?.reduce(
                                  (acc, curr) => acc + curr.discount,
                                  0
                                ) -
                                studentData?.reduce(
                                  (acc, curr) => acc + curr.paid_amount,
                                  0
                                )
                              )?.toLocaleString("en-IN", {
                                style: "currency",
                                currency: "INR",
                                minimumFractionDigits: 0,
                              })}
                            </th>
                            <th></th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            {/* end col */}
          </div>{" "}
          {/* end row */}
        </div>
        {/* End Page-content */}
      </div>
    </div>
  );
};

export default FeeCollectionAddFee;
