import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Nodata from "../../Components/NoData/Nodata";
import Select from "react-select";
import {ACADEMICS_ADD_PROGRAM_OUTCOMES} from "../../utils/Academics.apiConst";
import { LOCAL_DEPARTMENT, LOCAL_PROGRAM } from "../../utils/LocalStorageConstants";

function ProgramOutcomes({ collegeId, setLoading }) {

    const [data, setData] = useState([]);
    const [addNew, setAddNew] = useState(false);
    const [edit, setEdit] = useState(false);

    const [departmentOpt, setDepartmentOpt] = useState(
        JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      );
      const [programOpt, setProgramOpt] = useState(
        JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      );
    
      useEffect(() => {
        setDepartmentOpt(
          JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
            (itemt) => itemt.college_id == collegeId
          )
        );
      }, [localStorage.getItem(LOCAL_DEPARTMENT), , collegeId]);

      const [info, setInfo] = useState("PO");
      const [dept, setDept] = useState("");

    const [user, setUser] = useState({
        name: "",
        description: "",
        outcome: "",
        type: "PO",
        department_id: "",
        status: "ACTIVE",
    });
    
    const clearData = () => {
    setUser({
        name: "",
        description: "",
        outcome: "",
        type: "PO",
        department_id: "",
        status: "ACTIVE",
    });
    };

    const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
        ...prev,
        [name]: value,
    }));
    };

    const handleDepartmentChange = (selectedOption) => {
        setUser((prevUser) => ({
          ...prevUser,
          department_id: selectedOption?.value || null,
        }));
    };

    const getAllData = async () => {
        setLoading(1);
        const config = {
            method: "get",
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url: ACADEMICS_ADD_PROGRAM_OUTCOMES
        };

        axios(config)
        .then((res) => {
            console.log('Program Outcome data - ', res.data.data);
            res.data.data.sort((a, b) => {
                // Extract numerical part from outcome
                const numA = parseInt(a.outcome.match(/\d+$/)[0]);
                const numB = parseInt(b.outcome.match(/\d+$/)[0]);
                
                // Compare numerical parts
                return numA - numB;
            })
            setData(res.data.data);
            setLoading(0);
        })
        .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
        })
    }

    const handleSubmit = async () => {

        if(!user.name) {
            return toast.error("Please Enter name of the Program Outcome")
        }
        console.log('data to backend - ', user);
        setLoading(1);
        const config = {
          method: "post",
          url: ACADEMICS_ADD_PROGRAM_OUTCOMES,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: user,
        };
    
        await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Program Outcome Added");
            clearData();
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
    };

    const handleEdit = async () => {

        if(!user.name) return toast.error("Please Enter Name of the Program Outcome");
        setLoading(1);
        const config = {
          method: "put",
          url: ACADEMICS_ADD_PROGRAM_OUTCOMES + `/${user.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: user,
        };
    
        await axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Program Outcome Updated");
            setEdit(false);
            setAddNew(true);
            clearData();
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            console.log(err);
            toast.error("Something went wrong");
          });
      };

      const handleDelete = async (i) => {
        
        setLoading(1);
        const config = {
          method: "put",
          url: `${ACADEMICS_ADD_PROGRAM_OUTCOMES}/${i?.id}`,
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
          data: {
            status: "INACTIVE",
          },
        };
        axios(config)
          .then((res) => {
            setLoading(0);
            toast.success("Program Outcome Deleted");
            getAllData();
          })
          .catch((err) => {
            setLoading(0);
            toast.error("Some Error Occured");
          });
      };

    useEffect(() => {
        getAllData();
    },[])

    let arr1 = [
        {
          id: "PO",
          title: "Program Outcome",
        },
        {
          id: "PSO",
          title: "Program Specific Outcome",
        },
    ]

    // console.log("user?.type -", user.type);

    return (
        <div>
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                  <div className="col-12">
                    <div className="page-title-box d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Add Program Outcomes</h4>
                    </div>
                  </div>
                </div>

                    <div className="row">
                      <div className="col-md-12">
                      <div className="card">
                        
                        <div className="card-body">
                        
                      {edit || addNew ? (

                        <div className="row">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Type
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="type"
                                id="type"
                                className="form-control"
                                value={user.type}
                                onChange={handleChange}
                              >
                                <option value="PO">Program Outcome</option>
                                <option value="PSO">Program Specific Outcome</option>
                              </select>
                            </div>
                          </div>
                          { user?.type == "PSO" ?
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                 Select Department
                              <span style={{ color: "red" }}>*</span>
                              </label>
                                <select
                                    id="class"
                                    className="form-control"
                                    value={user.department_id}
                                    onChange={(e) => {
                                      setUser((prev) => ({
                                        ...prev,
                                        department_id: e.target.value,
                                        program_id: departmentOpt?.find(
                                          (s) => s.id == e.target.value
                                        )?.program_id,
                                      }));
                                    }}
                                >
                                    <option value="">Select Department</option>
                                      {departmentOpt?.map((i, key) => (
                                        <option key={key} value={i.id}>
                                          {i.name},{" "}
                                          {
                                              programOpt?.find((s) => s.id == i.program_id)
                                              .name
                                          }
                                        </option>
                                      ))}
                                </select>

                            </div>
                          </div> 
                          : "" }  
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Enter Taxonomy Name"
                                value={user.name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Outcome
                              </label>
                              <input
                                type="text"
                                name="outcome"
                                placeholder="Enter the Level"
                                className="form-control"
                                value={user.outcome}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Description
                              </label>
                              <input
                                type="text"
                                name="description"
                                placeholder="Enter description"
                                className="form-control"
                                value={user.description}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                        </div>
                                                
                        ) : (

                        <div className="row">
                        <br />
                          
                        <div className="col-md-12">
                          <h5 className="text-danger">SEARCH CRITERIA</h5>
                        </div>

                        <br/>

                          <div className="col-md-3">
                          <br/>  
                            <div className="form-group">
                              <label htmlFor="validationCustom02">
                                Type
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                name="type"
                                id="type"
                                className="form-control"
                                value={info}
                                onChange={(e) => setInfo(e.target.value)}
                              >
                                <option value="PO">Program Outcome</option>
                                <option value="PSO">Program Specific Outcome</option>
                              </select>
                            </div>
                          </div>
                          {info === "PSO" && (
                            <div className="col-md-4">
                            <br/>     
                              <div className="form-group">
                                <label htmlFor="validationCustom02">
                                  Select Department
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  id="department"
                                  name="department_id"
                                  className="form-control"
                                  value={dept}
                                  onChange={(e) => setDept(e.target.value)}
                                >
                                  <option value="">All</option>
                                  {departmentOpt?.map((i, key) => (
                                    <option key={key} value={i.id}>
                                      {i.name},{" "}
                                      {programOpt?.find((s) => s.id == i.program_id)
                                        ?.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                        </div>

                      )}

                      {edit == false ? (
    <>
      {addNew == false ? (
        <button
          className="btn btn-nex btn-rounded float-lg-right mx-1"
          type="submit"
          name="submit"
          onClick={() => setAddNew(true)}
        >
          <i className="fa fa-add" aria-hidden="true" /> +
          Add New
        </button>
      ) : (
        <>
          <button
            className="btn btn-nex btn-rounded float-lg-right mx-1"
            type="submit"
            name="submit"
            onClick={handleSubmit}
          >
            <i
              className="fa fa-save"
              aria-hidden="true"
            />{" "}
            Save
          </button>
          <button
            className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
            type="submit"
            name="submit"
            // style={{aspectRatio:'1/1'}}
            onClick={() => {
              setEdit(false);
              setAddNew(false);
            }}
          >
            {"<   "}{" "}
          </button>
        </>
      )}
    </>
                        ) : (
                         <>
    <button
      className="btn btn-nex btn-rounded float-lg-right mx-1"
      type="submit"
      name="submit"
      onClick={handleEdit}
    >
      <i className="fa fa-save" aria-hidden="true" /> Edit
    </button>
    <button
            className="btn btn-nex btn-rounded float-lg-right mx-1  p-2 px-3"
            type="submit"
            name="submit"
            // style={{aspectRatio:'1/1'}}
            onClick={() => {
              setEdit(false);
              setAddNew(false);
            }}
          >
            {"<   "}{" "}
            
          </button>
                        </>
                      )}  

                        </div>

                        </div>
                      </div>
                    </div>

                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">

                        <br/>                    
                
                        <div className="row">    
                          <div className="col-md-4">
            
                            {" "}
                            <h3 className="card-title">Program Outcomes List</h3>
                            <br/>
                          </div>
                        </div>
                        <table
                          id="datatable"
                          className="table table-bordered dt-responsive nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              {/* <th>Sl. No.</th> */}
                              <th>Outcome Id</th>
                              <th>Type</th>
                              <th>Department</th>
                              <th>Name</th>
                              <th>Description</th>
                              {sessionStorage.getItem("role") != "STAFF" ? (
                                <th>Action</th>
                              ) : null}
                            </tr>
                          </thead>

                      <tbody>
                        {data?.map((d, index) => {
                          const showRow =
                            (info === "PO" && d.type === "PO") ||
                            (info === "PSO" && d.type === "PSO") &&
                            (dept === "" || d.department_id.toString() === dept);

                          return showRow ? (
                            <tr key={index}>
                              {/* <td width="5%">{index + 1}</td> */}
                              <td width="10%">{d?.outcome}</td>
                              <td width="10%">{arr1.find(item => item.id === d.type)?.title}</td>
                              <td width="10%">{d.department_id === 0 ? "All" : departmentOpt.find(item => item.id === d.department_id)?.name}</td>
                              <td width="20%">{d?.name}</td>
                              <td width="35%">{d?.description}</td>
                              {sessionStorage.getItem("role") !== "STAFF" && (
                                <td width="5%">
                                  <acronym title="Edit">
                                    <a
                                      href="javascript:void(0)"
                                      className="badge badge-light"
                                      onClick={(e) => {
                                        setEdit(true);
                                        setUser({ ...d });
                                      }}
                                    >
                                      <i className="fa fa-edit" aria-hidden="true" />
                                    </a>
                                  </acronym>
                                  <a href="javascript:void(0)"> </a> &nbsp;
                                  <acronym title="Delete">
                                    <a
                                      href="javascript:void(0)"
                                      className="badge badge-light ml-2"
                                      onClick={() => {
                                        handleDelete(d);
                                      }}
                                    >
                                      <i
                                        className="fa fa-thumbs-down"
                                        aria-hidden="true"
                                        style={{ color: "red" }}
                                      />
                                    </a>
                                  </acronym>
                                  <a href="javascript:void(0)"> </a>
                                </td>
                              )}
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                          
                        </table>
                        {data?.length == 0 ? <Nodata /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

}

export default ProgramOutcomes;