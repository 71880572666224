import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import { REPORT_FEE_DETAILS , REPORT_FEE_DETAILS_COLLEGE_SQL} from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { college_title } from "../../../Data/main";
import "../../../modals/HR/Leave/TableScroll.css";

const CollegeWiseCollectionReport = ({ setLoading }) => {
  const doc = new jsPDF();

  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const collegeId = sessionStorage.getItem("college_id");

  const [user, setUser] = useState({
    date: new Date().toISOString().split('T')[0], // Set to current date
    to_transaction_date: new Date().toISOString().split('T')[0], // Set to current date
  });

  const [clg, setClg] = useState("");
  const [clgid, setClgid] = useState();

  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [data3, setData3] = useState([]);

  let clgname;
  const getCollege = () => {
    let clgid1 = localStorage.getItem("clg");
    let colleges = JSON.parse(localStorage.getItem("COLLEGE"));
    clgname = colleges && colleges.find((s) => s.id == clgid1)?.code;
    console.log(clgname);
    setClg(clgname);
  };

  useEffect(() => {
    getCollege();
  }, []);

  const tableRef = useRef();

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [all, setAll] = useState("all");

  const [studentSet, setStudentSet] = useState([]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${REPORT_FEE_DETAILS_COLLEGE_SQL}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}&college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then(async (res) => {
        console.log("main Data - ", res.data.data);
        let groupedData = [];

        let tempData = res.data.data;
        for(let i in tempData) {
          let deptId = tempData[i].department_id;
          let existingGroup = groupedData.find(group => group.department_id === deptId);
          if (!existingGroup) {
            existingGroup = {
              department_id: deptId,
              department_name: departmentData?.find((s) => s.id == deptId)?.name,
              transactions: [],
              amount: parseInt(tempData[i].amount),
              discount: parseInt(tempData[i].discount),
              fine: parseInt(tempData[i].fine)
            };
            groupedData.push(existingGroup);
          }
          else {
            existingGroup.amount += parseInt(tempData[i].amount);
            existingGroup.discount += parseInt(tempData[i].discount);
            existingGroup.fine += parseInt(tempData[i].fine);
          }
          existingGroup.transactions.push(tempData[i]);
        }
        console.log('final data - ', groupedData);
        setData(groupedData);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    catch(err) {
      return dateString
    }
  }


  useEffect(() => {
    // getInitialData(); // Fetch initial data when the component mounts
  }, []);

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        // "College Name":collegeOpt?.find(s=>s.id==collegeId)?.name+'-SUKALOL',
        // "Academic Year":sessionOpt?.find(s=>s?.id==user?.session_id)?.name,
        // "Department" :departmentOpt?.find(s=>s.id==user?.department_id)?.name ,
        // "Class":classOpt?.find(s=>s?.id==user?.class_id)?.name ,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const change = async (e) => {
    if (e) {
      await setAll(e.target.value);
    }

    let data2 = [];
    for (var i = 0; i < departmentData.length; i++) {
      if (departmentData[i].college_id == clgid) {
        data2.push(departmentData[i]);
      }
    }
    console.log(departmentData, clgid);
    setData3(data2);
    let x = [];
    for (var i = 0; i < data3.length; i++) {
      for (var j = 0; j < data.length; j++) {
        if (data[j].department_id == data3[i].id) {
          var sum = 0;
          for (var k = 0; k < data[j].newAmount.length; k++) {
            sum += data[j].newAmount[k].amount;
          }
          x.push({
            id: data3[i].id,
            name: data3[i].name,
            amount: sum,
          });
        }
      }
    }

    const resultArray = [];
    const amounts = {};

    await x.forEach((item) => {
      if (amounts[item.name]) {
        amounts[item.name].amount += item.amount;
      } else {
        amounts[item.name] = { id: item.id, amount: item.amount };
      }
    });

    for (const name in amounts) {
      resultArray.push({
        id: amounts[name].id,
        name: name,
        amount: amounts[name].amount,
      });
    }
    console.log(resultArray);
    setData1(resultArray);
  };

  const exportToPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await document.querySelector("#pdf");
    pdf.html(data).then(() => {
      pdf.save("CollegeWiseCollectionReport.pdf");
    });
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">COLLEGE AND DATE WISE FEE REPORTS</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="">Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex">
                      <button
                        className="btn btn-nex mt-4"
                        onClick={getData}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" id="pdf">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">COLLEGE AND DATE WISE FEE REPORTS</div>
                    <button
                      className="btn btn-nex rounded-pill"
                      onClick={onDownload}
                    >
                      Excel
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive fixTableHeadPage">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                        <tr>
                          <th colSpan={14}>
                            {college_title}
                          </th>
                        </tr>
                        <tr>
                            <th colSpan={14}>
                            {facultyData?.find((s) => s.id == collegeId)?.name}
                            </th>
                        </tr>
                        <tr>
                          <th>Sl.No</th>
                          <th>Student Name</th>
                          <th>Admission No.</th>
                          <th>University No.</th>
                          <th>Class (Sem)</th>
                          <th>Date</th>
                          <th>Transaction Id</th>
                          <th>Payment Type</th>
                          <th>Note</th>
                          <th className="text-center">Collected By</th>
                          <th className="text-right">Paid</th>
                          <th className="text-right">Trust Grant</th>
                          <th className="text-right">Fine</th>
                          <th className="text-right">Total</th>
                        </tr>
                        </thead>

                        {data?.map((i,k) => {
                          return (<>
                            <tr className="bg-dark text-light">
                              <th colSpan={10}>{i?.department_name}</th>
                              <th>{i?.amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                              <th>{i?.discount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                              <th>{i?.fine?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                              <th>{(i?.amount + i?.fine)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                            </tr>
                            {i.transactions?.map((j,key) => {
                              return (<tr>
                                <td>{key+1}</td>
                                <td>{j?.name}</td>
                                <td>{j?.user_id}</td>
                                <td>{j?.university_number}</td>
                                <td>{j?.class_name} ({j?.sem_name})</td>
                                <td>{formatDate(j?.transaction_date)}</td>
                                <td>{j?.transaction_id}</td>
                                <td>{j?.type}</td>
                                <td>{j?.note}</td>
                                <td>{j?.first_name} {j?.last_name}</td>
                                <td className="text-right">{parseInt(j?.amount)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                <td className="text-right">{parseInt(j?.discount)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                <td className="text-right">{parseInt(j?.fine)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                <td className="text-right">{parseInt(j?.amount + j?.fine)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                              </tr>)
                            })}
                          </>)
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollegeWiseCollectionReport;
