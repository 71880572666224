import React, { useEffect, useRef, useState } from 'react'
import { COLLEGE_TYPE } from '../../../../utils/apiConstants';
import axios from 'axios'
import ModalCollegeType from '../../../../modals/HR/Employer/ModalCollegeType';
import './../Employer.scss'
import Nodata from '../../../../Components/NoData/Nodata';
import { useDownloadExcel } from "react-export-table-to-excel";

function EmployeeCollegeType({setLoading}) {

    const [data, setData] = useState([]);
    const [type, setType] = useState()
    const [edit, setEdit] = useState();

    // const dept = [
    //     {
    //         title: 'Accountant',
    //         code: 'ACT'
    //     },
    //     {
    //         title: 'Admin Specialist',
    //         code: 'APT'
    //     },
    //     {
    //         title: 'Chief Executive Officer',
    //         code: 'CEO'
    //     },
    //     {
    //         title: 'Customer Support Specialist',
    //         code: 'CS'
    //     },
    //     {
    //         title: 'HR Specialist',
    //         code: 'HR'
    //     },
    // ]

    const getData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: COLLEGE_TYPE,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
        };

        await axios(config)
            .then((res) => {
                setLoading(0)
                console.log(res.data.data);
                setData(res.data.data)
            })
            .catch(err => {
                setLoading(0)
                console.log(err)})

    }

    useEffect(() => {
        getData();
    }, [])

    const rowRef = useRef();

    const { onDownload } = useDownloadExcel({
      currentTableRef: rowRef.current,
      filename: " College_Types ",
      sheet: "Users",
    });

    return (
        <div className='EmployeeCollegeType'>
            <ModalCollegeType reloadData={getData} type={type} data={edit} setLoading={setLoading}/>
            <div className="main-content">

                <div className="page-content">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0">College Types</h4>

                                    <div className="page-title-right">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item">Employer</li>
                                            <li className="breadcrumb-item active">College Type</li>
                                        </ol>
                                    </div>

                                </div>
                            </div>
                        </div>
                        
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">

                                    <div className="row d-flex justify-content-end p-3">
                                        <button
                                            onClick={() => { setType("add"); setEdit() }}
                                            className="btn btn-rounded btn-success btn-outline px-4"
                                            data-toggle="modal"
                                            data-target="#ModalCollegeType"
                                        >
                                            Add +
                                        </button>
                                        <span>
                                          <button
                                            className="btn btn-primary rounded-pill ml-1"
                                            onClick={onDownload}
                                          >
                                            Export Excel
                                          </button>
                                        </span>
                                    </div>

                                    <table
                                      id="table_id"
                                      ref={rowRef}
                                      className="display table table-bordered  nowrap table-hover "
                                      style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%", display: 'none' }}
                                    >
                                        <div className="col-12 text-center"><h4> College Types </h4>
                                        <thead>
                                            <tr>
                                                <th>Sl.No.</th>
                                                <th> Colege Type </th>
                                                <th> College Code </th>
                                                <th> Remark </th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                          data && data?.length == 0
                                          ?
                                          <tr>
                                            <td colSpan={10}>
                                              <Nodata />
                                            </td>
                                          </tr>
                                          :
                                          data
                                          .map((i, key) => {
                                            return <tr key={key}>
                                              <td>{key + 1}</td>
                                              <td>{i.name}</td>
                                              <td>{i.id}</td>
                                              <td>{i.description}</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              </tr>
                                          })
                                        }
                                        </tbody>
                                        </div>
                                    </table>

                                    <div className="row">
                                        {data && data.length !== 0 ? data.map((i, key) => (
                                    
                                            <div
                                                className="col-md-3 "
                                                onClick={() => { setType("edit"); setEdit(i); }}
                                                data-toggle="modal"
                                                data-target="#ModalCollegeType"
                                            >
                                                     <div className="card">
                                                        <div className="card-body">
                                                        <div className="" key={key}>
                                                            <div className="role-title">
                                                                {i.name}
                                                            </div>
                                                            <div className="role-code">
                                                                {i.id}
                                                                <div className="d-flex align-items-center justify-content-end">
                                                                    {'>'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                         </div>
                                        )) :
                                            <div className='mt-3'>
                                                <Nodata titleTop={'No data available for your search'} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeeCollegeType