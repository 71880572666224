import axios from "axios";
import React from "react";
import { useState } from "react";
import Nodata from "../../../Components/NoData/Nodata";
import { REPORT_FEE_DETAILS, REPORT_DEPARTMENT_WISE_FEE_DETAILS } from "../../../utils/Reports.apiConst";
import * as XLSX from "xlsx/xlsx.mjs";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../../utils/LocalStorageConstants";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { toast } from "react-toastify";
import { college_title } from "../../../Data/main";
import Select from "react-select";

const DepartmentWiseCollectionReport = ({ setLoading, collegeId }) => {
  let role = sessionStorage.getItem("role");
  // const [user, setUser] = useState({
  //   date: "",
  //   to_transaction_date: "",
  // });

  const [user, setUser] = useState({
    date: new Date().toISOString().split('T')[0], // Set to current date
    to_transaction_date: new Date().toISOString().split('T')[0], // Set to current date
  });

  const tableRef = useRef();

  const [data, setData] = useState([]);

  const [dates, setDates] = useState([]);

  const [deparment, setDeparment] = useState("All");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const facultyData = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const departmentData = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));

  const [studentSet, setStudentSet] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function formatDate(dateString) {
    try {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    catch(err) {
      return dateString
    }
  }

  const [name, setName] = useState("");

  const getData = async () => {
    setLoading(1);
  
    let apiUrl = `${REPORT_DEPARTMENT_WISE_FEE_DETAILS}?transaction_date=${user?.date}&to_transaction_date=${user?.to_transaction_date}&college_id=${collegeId}&department_id=${deparment}`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('UMS_auth')}`,
          'Content-Type': 'application/json',
        },
      });
  
      console.log('data - ', response.data.data);

      const groupedTransactions = response.data.data?.reduce((acc, curr) => {
        if (!acc[curr.transaction_date]) {
            acc[curr.transaction_date] = []
        }
        acc[curr.transaction_date].push(curr);
        return acc;
    }, {});

    let temp = [];

    for(let i in groupedTransactions) {
      temp.push({date: i, 
        transactions: groupedTransactions[i], 
        amount: groupedTransactions[i].reduce((acc,curr) =>  acc + curr.amount, 0),
        discount: groupedTransactions[i].reduce((acc,curr) =>  acc + curr.discount, 0),
        fine: groupedTransactions[i].reduce((acc,curr) =>  acc + curr.fine, 0),
      });
    }

    console.log('datewsie data - ', temp);
    setData(temp);
      
    } catch (error) {
      console.log(error);
    }
  
    setLoading(0);
  };

  const collegeList1 = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const downloadExcel = () => {
    let json_data = [];
    for (const iterator of data) {
      const obj = {
        "Student Registeration Number": iterator?.usn,
        "Student Name": iterator?.name,
        Amount: iterator?.amount,
        "Transaction Id": iterator?.transaction_id,
        "Transaction Type": iterator?.type,
      };
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `SUKALOL-Fee Details.xlsx`);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Department Fee Collection Report of " + name,
    sheet: "Users",
  });

  const PrintRecipt = useReactToPrint({
    content: () => tableRef.current,
  });

  const handlePrint = () => {
    PrintRecipt();
  };

  const handleDepartmentChange = (selectedOption) => {
    setDeparment(selectedOption?.value);
  };

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center">
                  <button
                    className="btn btn-nex d-flex justify-content-center align-items-center rounded-pill mb-2 mr-3"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <i className="ri-arrow-left-line"></i>
                  </button>
                  <h4 className="mb-0">DEPARTMENT AND DATE WISE FEE REPORTS</h4>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card-title">Select Criteria</div>

                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">From Date</label>
                      <input
                        type="date"
                        name="date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.date}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">To Date</label>
                      <input
                        type="date"
                        name="to_transaction_date"
                        onChange={handleChange}
                        className="form-control"
                        value={user?.to_transaction_date}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Department</label>

                          <Select
                            options={[
                              { value: 'All', label: 'All' },
                              ...departmentData
                                ?.filter((s) => s.college_id == collegeId)
                                ?.map((i) => ({ value: i.id, label: i.name })),
                            ]}
                            value={
                              deparment
                                ? {
                                    value: deparment,
                                    label: deparment == 'All' ? 'All' : departmentData?.find((i) => i.id == deparment)?.name,
                                  }
                                : null
                            }
                            onChange={handleDepartmentChange}
                            placeholder="Select Department"
                          />

                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-flex">
                      <button
                        className="btn btn-nex mt-4"
                        onClick={getData}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-md-12 d-flex justify-content-between align-items-center">
                    <div className="card-title">Fee Reports</div>
                    {
                      <button
                        className="btn btn-nex rounded-pill"
                        onClick={role != "AD-CON" ? onDownload : handlePrint}
                      >
                        Excel
                      </button>
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive fixTableHeadPage">
                      <table className="table table-bordered" ref={tableRef}>
                        <thead>
                          <tr>
                            <th colSpan={14}>{college_title}</th>
                          </tr>
                          <tr>
                            <th colSpan={14}>Department and Datewise Fee Report : {formatDate(user?.date)} TO {formatDate(user?.to_transaction_date)}</th>
                          </tr>
                          <tr>
                            <th>Sl.No</th>
                            <th>Name</th>
                            <th>Admission No.</th>
                            <th>University No.</th>
                            <th>Class (Sem)</th>
                            <th>Transaction Id</th>
                            <th>Payment Type</th>
                            <th>Date</th>
                            <th>Note</th>
                            <th>Collected By</th>
                            <th className="text-right">Paid</th>
                            <th className="text-right">Trust Grant</th>
                            <th className="text-right">Fine</th>
                            <th className="text-right">Total</th>
                          </tr>
                        </thead>

                        <tbody>
                        {data?.map((i,k) => {
                          return (
                            <>
                            <tr>
                            <th className="bg-dark text-light" colSpan={10}>{formatDate(i?.date)}</th>
                            <th className="bg-dark text-light text-right">{i?.amount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                            <th className="bg-dark text-light text-right">{i?.discount?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                            <th className="bg-dark text-light text-right">{i?.fine?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                            <th className="bg-dark text-light text-right">{(i?.amount + i?.fine)?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</th>
                            </tr>
                            {i?.transactions?.map((j,key) => {
                              return (
                                <tr>
                                  <td>{key+1}</td>
                                  <td>{j?.name}</td>
                                  <td>{j?.user_id}</td>
                                  <td>{j?.university_number}</td>
                                  <td>{j?.class_name} {j?.sem_name}</td>
                                  <td>{j?.transaction_id}</td>
                                  <td>{j?.type}</td>
                                  <td>{j?.transaction_date}</td>
                                  <td>{j?.note}</td>
                                  <td>{j?.first_name} {j?.last_name}</td>
                                  <td className="text-right">{(parseInt(j?.amount))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                  <td className="text-right">{(parseInt(j?.discount))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                  <td className="text-right">{(parseInt(j?.fine))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                  <td className="text-right">{(parseInt(j?.amount) + parseInt(j?.fine))?.toLocaleString('en-IN', {style: 'currency',currency: 'INR',minimumFractionDigits: 0})}</td>
                                </tr>
                              )
                            })}
                            </>
                          )
                        })}
                        </tbody>

                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentWiseCollectionReport;
