import React, { useRef } from "react";
import "./Topbar.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { LOCAL_COLLEGE } from "./../../utils/LocalStorageConstants";
import {
  SESSION_COLLEGE_ID,
  SESSION_EMPLOYEE_ID,
} from "../../utils/sessionStorageContants";
import { ROUTES } from "../../Router/routerConfig";
import { EMPLOYEE } from "../../utils/apiConstants";
import axios from "axios";
import getUniversityDetails from "../../utils/universityDetails.api";
import { Dropdown, Space } from "antd";
import Cookies from "js-cookie";

// const Flag = ({ name, height, className }) => {
//   return (
//     <>
//       <img
//         src={`/assets/images/flags/lang_flag/${name}.jpg`}
//         height={height}
//         className={className}
//       />
//     </>
//   );
// };

function Topbar({ changeCollege }) {
  const [universityData, setUniversityData] = useState([]);
  const [localLangCode, setLocalLangCode] = useState("en");

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  const g_transalte_parent = useRef();

  const handleChangeLanguage = (e, reload) => {
    const lang_code = e?.target?.value || localStorage.getItem("lang_code");
    setLocalLangCode(lang_code);

    if (reload) {
      // window.location.reload();
      navigate(0);
      setDir(lang_code === "ur" ? "rtl" : "ltr");
    }

    localStorage.setItem("lang_code", lang_code);

    const selects = document.querySelectorAll(".goog-te-combo");

    if (selects.length > 0) {
      const lastSelect = selects[selects.length - 1];
      const langOption = lastSelect.querySelector(
        `option[value="${lang_code}"]`
      );

      if (langOption) {
        langOption.selected = true;

        const event = new Event("change", { bubbles: true });
        lastSelect.dispatchEvent(event);
      }
    }

    const existingGoogtrans = Cookies.get("googtrans");
    const languageCode = existingGoogtrans
      ? existingGoogtrans.split("/").pop()
      : null;

    const newGoogtrans = `/en/${lang_code}`;

    if (!languageCode || languageCode !== lang_code) {
      Cookies.set("googtrans", newGoogtrans, { path: "/" });
    }

    // const googtrans = Cookies.get('googtrans');

    // if(!googtrans){
    //   Cookies.set('googtrans',`/en/${lang_code}`,{ domain: '.kbnuniversity.org' });
    //   alert("okay")
    // }

    // const languageCode = Cookies.get('googtrans').split('/').pop();

    // if(languageCode !== lang_code){
    //   Cookies.remove('googtrans');
    //   Cookies.set('googtrans',`/en/${lang_code}`,{ domain: '.kbnuniversity.org' });
    // }
  };

  const items = [
    {
      label: <span translate="no">Engilsh</span>,
      key: "0",
      // icon: <Flag name={"en"} height={16} />,
      title: "en",
    },
    {
      label: <span translate="no">Hindi</span>,
      key: "1",
      // icon: <Flag name={"hi"} height={16} />,
      title: "hi",
    },
    {
      label: <span translate="no">Kannada</span>,
      key: "2",
      // icon: <Flag name={"kn"} height={16} />,
      title: "kn",
    },
    {
      label: <span translate="no">Urdu</span>,
      key: "3",
      // icon: <Flag name={"ur"} height={16} />,
      title: "ur",
    },
  ];

  useEffect(() => {
    const addScript = document.createElement("script");

    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );

    document.body.appendChild(addScript);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: true,
        },
        "google_translate_element"
      );
    };

    if (window.google && window.google.translate) {
      googleTranslateElementInit();
    }

    const storedLangCode = localStorage.getItem("lang_code");

    if (!storedLangCode) {
      localStorage.setItem("lang_code", "en");
    }

    handleChangeLanguage();

    return () => {};
  }, []);

  /////////////////////////////////////////
  /////////////////////////////////////////
  //////////RTL & LTR PROCESS START////////
  /////////////////////////////////////////
  /////////////////////////////////////////

  const [dir, setDir] = useState("ltr");

  useEffect(() => {
    const langCode = localStorage.getItem("lang_code");

    if (langCode === "ur") {
      setDir("rtl");
    } else {
      setDir("ltr");
    }
    document.documentElement.dir = dir;
  }, [dir]);

  /////////////////////////////////////////
  /////////////////////////////////////////
  //////////RTL & LTR PROCESS END//////////
  /////////////////////////////////////////
  /////////////////////////////////////////

  const location = useLocation();
  const navigate = useNavigate();
  const [role, setRole] = useState();
  const [collegeTitle, setCollegeTitle] = useState("College");
  const [empColleges, setEmpColleges] = useState([]);
  // const [collegeOpt, setCollegeOpt] = useState()
  const [dropdownOpen, setDropdownOpen] = useState();
  const [ticketDropdownOpen, setTicketDropdownOpen] = useState();

  const [employeeId, setEmployeeId] = useState(
    sessionStorage.getItem(SESSION_EMPLOYEE_ID)
  );

  const Logout = () => {
    console.log("enterd function");
    sessionStorage.clear();
    navigate("/");
  };

  const getUserRole = () => {
    return sessionStorage.getItem("role")
      ? sessionStorage.getItem("role")
      : null;
  };

  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const checkRoleAndNavigateToTicket = (whom_type) => {
    console.log("whom type - ", whom_type);
    if (whom_type == "ERP") {
      if (role == "SUPERADMIN") navigate(ROUTES.Registar.Ticket);

      if (role == "ADMIN") {
        navigate(ROUTES.Principal.Ticket);
      }

      if (role == "SUPERWARDEN") {
        navigate(ROUTES.Warden.Ticket);
      }

      if (role == "STAFF") navigate(ROUTES.Employee.Ticket);

      if (role == "SUACC" || role == "ACC") navigate(ROUTES.Accountant.Ticket);
    } else {
      if (role == "SUPERADMIN") navigate(ROUTES.Registar.TicketIT);

      if (role == "ADMIN") {
        navigate(ROUTES.Principal.TicketIT);
      }

      if (role == "STAFF") navigate(ROUTES.Employee.TicketIT);

      if (role == "SUACC" || role == "ACC")
        navigate(ROUTES.Accountant.TicketIT);

      if (role == "SUPERWARDEN") {
        navigate(ROUTES.Warden.TicketIT);
      }
    }
  };

  const [localCollege, setLocalCollege] = useState();

  const [empDetails, setEmpDetails] = useState();

  const [collegeOpt, setCollegeOpt] = useState(getColleges);

  useEffect(() => {
    setRole(getUserRole());
  }, [sessionStorage.getItem("role")]);

  useEffect(() => {
    setCollegeOpt(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  const getCollegeId = () => {
    return sessionStorage.getItem(SESSION_COLLEGE_ID)
      ? sessionStorage.getItem(SESSION_COLLEGE_ID)
      : "1111000";
  };

  const [selectedCollege, setSelectedCollege] = useState(getCollegeId);

  const changeCollegeId = (id) => {
    setSelectedCollege(id);
    changeCollege(id);
  };

  const paths = location?.pathname?.split("/");

  const getEmployee = async () => {
    const config = {
      method: "get",
      url: `${EMPLOYEE}/${employeeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        let clgs = JSON.parse(res.data.data["multi_clg_id"]);
        setEmpColleges(collegeOpt.filter((item) => clgs?.includes(item.id)));
        console.log("emp details - ", res.data.data);
        setEmpDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (employeeId) getEmployee();
  }, [employeeId]);

  useEffect(() => {
    setLocalLangCode(localStorage.getItem("lang_code"));
  }, [localLangCode]);

  //UNIVERSITY DETAILS USE EFFECT

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUniversityDetails();
        setUniversityData(data);
      } catch (error) {
        console.error(`Error fetching university details: ${error.message}`);
      }
    };

    fetchData();
  }, []);

  console.log("universityData -", universityData);

  const roles = sessionStorage.getItem("role");
  console.log("roles -", roles);

  const roleToLink = {
    SUPERADMIN: "/dashboard/registrar",
    STAFF: "/dashboard/employee",
    ADMIN: "/dashboard/principal",
    ACC: "/account/dashboard",
    SUACC: "/account/dashboard",
    HR: "/dashboard/hr",
    IT: "/IT/ticketscentre",
    OFFICE: "/frontOffice/admissionEnquiry",
    WARDEN: "/warden/assignHostelStudents",
    SUPERWARDEN: "/warden/assignHostelStudents",
    SHR: "/dashboard/hr",
    DEVELOPERS: "/ticketscentre",
  };

  const linkPath = roleToLink[roles] || "#";

  //UNIVERSITY DETAILS END

  return (
    location.pathname !== "/login" && (
      <div className="Topbar">
        <header id="page-topbar">
          <div className=" d-flex justify-content-between">
            <div className="d-flex justify-content-start mh-100">
              <div className="d-flex justify-content-start">
                <Link
                  to={linkPath}
                  className="d-flex justify-content-center align-items-center px-2"
                  style={{ background: "white" }}
                >
                  <img
                    src={universityData.logo_primary}
                    alt=""
                    className=""
                    width={180}
                  />
                </Link>
              </div>

              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
                style={{ marginLeft: "auto" }}
              >
                <i className="ri-menu-2-line align-middle" />
              </button>
            </div>

            <div
              id="google_translate_element"
              style={{ marginLeft: "auto", marginTop: "1rem" }}
              ref={g_transalte_parent}
            ></div>

            {/* <Dropdown
              menu={{
                items,
                selectable: true,
                // defaultSelectedKeys: ['0'],
                onClick: (e) => {
                  handleChangeLanguage(e, true);
                },
              }}
              trigger={["click"]}
              className="user-select-none"
            >
              <a
                onClick={(e) => e.preventDefault()}
                style={{
                  marginLeft: "auto",
                  marginTop: "1.5rem",
                  cursor: "pointer",
                }}
              >
                <Space>
                   <Flag
                    name={localLangCode || "en"}
                    height={17}
                    className={"mr-1"}
                  /> 
                  <span className="text-light" translate="no">
                    {localLangCode === "en"
                      ? "EN"
                      : localLangCode === "hi"
                      ? "HI"
                      : localLangCode === "kn"
                      ? "KN"
                      : localLangCode === "ur"
                      ? "UR"
                      : ""}
                  </span>
                  <i class="ri-arrow-down-s-line text-white "></i>
                </Space>
              </a>
            </Dropdown> */}

            <div className="d-flex">
              {/* <div>
                  <select
                    className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                    style={{ outline: "none" }}
                    value={localLangCode}
                    onChange={(e)=>{
                      handleChangeLanguage(e,true)
                    }}
                  >
                      <option className="text-secondary" value="en" translate="no">English</option>
                      <option className="text-secondary" value="hi" translate="no">Hindi</option>
                      <option className="text-secondary" value="kn" translate="no">Kannada</option>
                      <option className="text-secondary" value="ur" translate="no">Urdu</option>
                  </select>
                </div> */}
              {role === "REGISTRAR" ||
              role === "SHR" ||
              // role === "HR" ||
              role === "SUPERADMIN" ||
              role === "SUACC" ||
              role === "CASHIER" ||
              role === "WARDEN" ||
              role === "SUPERWARDEN" ||
              (role === "AD-CON" && paths.find((s) => s == "con")) ? (
                <div>
                  <select
                    className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                    style={{ outline: "none" }}
                    value={selectedCollege}
                    onChange={(e) => {
                      changeCollegeId(e.target.value);
                      localStorage.setItem("clg", e.target.value);
                    }}
                  >
                    {collegeOpt?.map((i, key) => (
                      <option className="text-secondary" value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              {role === "STAFF" ? (
                <a
                  href="https://nexenstial.in/unversity_assests/Staff%20Manual.pdf#toolbar=0"
                  target="_blank"
                  className="btn  text-light d-flex align-items-center"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  title="Staff Manual"
                  rel="noreferrer"
                >
                  <i class="ri-newspaper-line mr-1"></i>Manual
                </a>
              ) : null}
              {role === "STAFF" ? (
                <div>
                  <select
                    className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                    style={{ outline: "none" }}
                    value={selectedCollege}
                    onChange={(e) => {
                      changeCollegeId(e.target.value);
                      localStorage.setItem("clg", e.target.value);
                    }}
                  >
                    {empColleges?.map((i, key) => (
                      <option className="text-secondary" value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              {/* <div 
                className="d-flex mt-3 mr-1 bordered-none"
                style={{backgroundColor: "#364277"}}
              >
                <select
                  type="text"
                  className="form-control text-white "
                  style={{backgroundColor: "#364277", border: "none"}}
                  name="ticket"
                  onChange={(e) => {
                    if(e.target.value) {
                      checkRoleAndNavigateToTicket(e.target.value);
                    }
                  }}
                >
                  <option value="">Ticket</option>
                  <option value="ERP">ERP</option>
                  <option value="IT">IT</option>
                </select>
              </div> */}
              <div
                className="d-flex dropdown"
                onMouseLeave={() => setTicketDropdownOpen(false)}
              >
                <button
                  className="btn action-btn text-light d-flex align-items-center"
                  onClick={() => setTicketDropdownOpen((prev) => !prev)}

                  // onMouseEnter={() => setTicketDropdownOpen(true)}
                >
                  <i className="ri ri-coupon-line mr-1"></i> Ticket
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white" />
                </button>
                {ticketDropdownOpen ? (
                  <div
                    className="action-list"
                    onMouseEnter={() => setTicketDropdownOpen(true)}
                  >
                    <ul>
                      <li>
                        <div
                          className="dropdown-item w-100 cursor-pointer"
                          onClick={() => {
                            checkRoleAndNavigateToTicket("ERP");
                          }}
                        >
                          ERP
                        </div>
                      </li>
                      <li>
                        <div
                          className="dropdown-item w-100 cursor-pointer"
                          onClick={() => {
                            checkRoleAndNavigateToTicket("IT");
                          }}
                        >
                          IT
                        </div>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
              <div
                className="d-flex mt-3"
                style={{ height: "35px", width: "10px" }}
              >
                <div class="vr bg-white" style={{ width: "2px" }}>
                  <p></p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <p className="text-light d-flex">
                  {empDetails?.first_name ? empDetails?.first_name : ""}{" "}
                  {empDetails?.last_name ? empDetails?.last_name : ""}
                </p>
              </div>
              <div
                className="d-flex"
                onMouseLeave={() => setDropdownOpen(false)}
              >
                <button
                  className="btn action-btn"
                  onClick={() => setDropdownOpen((prev) => !prev)}
                  // onMouseEnter={() => setDropdownOpen(true)}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    // src="/assets/images/users/avatar-2.jpg"
                    src={
                      empDetails?.photo
                        ? empDetails?.photo
                        : "/assets/images/users/avatar-2.jpg"
                    }
                    alt="Header Avatar"
                  />
                  <span className="d-none d-xl-inline-block ml-1"></span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white" />
                </button>
                {dropdownOpen ? (
                  <div
                    className="action-list"
                    onMouseEnter={() => setDropdownOpen(true)}
                  >
                    <ul>
                      {/* {role == "STAFF" && (
                        <> */}
                      <li>
                        <button
                          type="submit"
                          className="dropdown-item"
                          onClick={() => {
                            navigate(ROUTES.Employee.Profile);
                          }}
                        >
                          <i className="ri-user-line align-middle mr-1" />{" "}
                          Profile
                        </button>
                      </li>
                      {/* </>
                      )} */}
                      <li>
                        <div
                          className="dropdown-item text-danger w-100 cursor-pointer"
                          onClick={() => {
                            Logout();
                          }}
                        >
                          <i className="ri-shut-down-line align-middle mr-1 text-danger" />{" "}
                          Logout
                        </div>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </header>
      </div>
    )
  );
}

export default Topbar;
