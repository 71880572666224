import { BASE_URL } from "./apiConstants";


///fee
export const REPORT_FEE_DETAILS = `${BASE_URL}/api/payment/details`

export const REPORT_FEE_DETAILS_SQL = `${BASE_URL}/api/payment/detailsDatewiseSql`

export const REPORT_FEE_DETAILS_COLLEGE_SQL = `${BASE_URL}/api/payment//details-college-wise-sql`

export const TRANSPORT_DATE_WISE = `${BASE_URL}/api/fee/transportPaymentData`

export const REPORT_COLLEGE_WISE_FEE_DETAILS = `${BASE_URL}/api/payment//details-college-wise`

export const REPORT_DEPARTMENT_WISE_FEE_DETAILS = `${BASE_URL}/api/payment/details-department-wise-sql`

export const FEE_SUMMARY_REPORT = `${BASE_URL}/api/payment/fee-summary-report`

export const REPORT_PROGRAM_WISE_FEE_DETAILS = `${BASE_URL}/api/payment/details-program-wise-sql`



// staff

//staffleave
export const REPORT_STAFF_DETAILS = `${BASE_URL}/api/reports/staff-details`

//staffleave
export const REPORT_STAFF_LEAVE = `${BASE_URL}/api/reports/leave-from-to`

//staff Attendance
export const REPORT_STAFF_ATTENDANCE = `${BASE_URL}/api/staff-app/todayAttendance`;
export const REPORT_STAFF_ATTENDANCE_MONTHWISE = `${BASE_URL}/api/staff-app/fetchMonthAttendance`;
export const REPORT_STSFF_ATTENDANCE_MONTHWISE_ID = `${BASE_URL}/api/staff-app/fetchEmpMonthAttendance`;
export const REPORT_STAFF_APPLICATION_ID = `${BASE_URL}/api/employee/leave-application-dashboard`;

export const EMPLOYEE_ATTENDANCE_MONTHLY_REPORT = `${BASE_URL}/api/employee/monthly-attendance-report`; 
export const EMPLOYEE_ATTENDANCE_COUNT = `${BASE_URL}/api/employee/getEmployeeAttendanceMonthlyReport`;
export const EMPLOYEE_ATTENDANCE_TIME_REPORT = `${BASE_URL}/api/employee/time-attendance-report`;
export const EMPLOYEE_LATEIN_MONTHLY_REPORT = `${BASE_URL}/api/employee/monthly-latein-report`;

//Student
//attendance
export const REPORT_STUDENT_ATTENDANCE = `${BASE_URL}/api/reports/student-attendance`
export const REPORT_STUDENT_ATTENDANCE_STAT = `${BASE_URL}/api/reports/student-attendance/stat`
export const REPORT_STUDENT_ATTENDANCE_STAT_STU_SUB = `${BASE_URL}/api/reports/student-attendance/stat-sub`
export const REPORT_STUDENT_ATTENDANCE_MONTHWISE = `${BASE_URL}/api/reports/student-attendance/month-wise`;
export const REPORT_STUDENT_ATTENDANCE_ALL = `${BASE_URL}/api/reports/student-attendance/all`;

//Feedback

export const GET_FEEDBACK_QUESTIONS = `${BASE_URL}/api/student/portal/feedback_data_fecth`;

export const GET_FEEDBACK_TRIGGER_DATA = `${BASE_URL}/api/student/portal/getFeedbackTriggerData`;

export const REPORT_FEEDBACK_FECTH = `${BASE_URL}/api/student/portal/feedbackReport`;
export const REPORT_FEEDBACK_QUESTIONS_FECTH = `${BASE_URL}/api/student/portal/getFeedbackQuestions`;
export const REPORT_TEACHERS_FECTH = `${BASE_URL}/api/student/portal/getTeachers`;
export const REPORT_TRIGGER_FETCH = `${BASE_URL}/api/student/portal/getFeedbackTriggerData`;
export const REPORT_TRIGGER_UPDATE = `${BASE_URL}/api/student/portal/trigger_feedback_type`;


