import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Toggler from "../../../../Components/Toggler/Toggler";
import { LOCAL_COLLEGE } from "../../../../utils/LocalStorageConstants";
import { ALL_DATA, EMPLOYEE, EMPLOYEE_DELETE, LEAVE_ENTITLEMENT, PAYROLL_EMP_DEDUCTION } from "../../../../utils/apiConstants";
import { HR_ASSIGN_WORKFLOW } from "../../../../utils/apiConstants";
import { getDateMeta } from "@fullcalendar/react";
// import '../Leave/TableScroll.css';

function ModalDeductionAssign({
  empData,
  allData,
  edit,
  editType,
  reloadData,
  setLoading,
  CollegeId,
  displayData, 
  setDisplayData,
  getEmpData,
  jobPositionOpt
}) {

    const [collegeOpt, setCollegeOpt] = useState(
            localStorage.getItem(LOCAL_COLLEGE)
        ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
        : null
    );

   const [promotes, setPromotes] = useState([]);
   const [selectAllChecked, setSelectAllChecked] = useState(false);
   const [searchName, setSearchName] = useState("");
   const [collegeSearch, setCollegeSearch] = useState("");
   const [designationSearch, setDesignationSearch] = useState("");

   const [policy, setPolicy] = useState()
   const [fDate, setFDate] = useState()
   const [entitled, setEntitled] = useState()
   const [carried, setCarried] = useState()
   // const [loading,setLoading] = useState(0)
    
   const [type, setType] = useState("percentage");
   const [amount, setAmount] = useState(0);
   const [percentage, setPercentage] = useState(0);

   const calculateDifference = (a1, a2) => {
       var Difference_In_Time = new Date(a2).getTime() - new Date(a1).getTime();

       var Difference_In_Days = Difference_In_Time / (1000 * 60 * 60 * 24);

       if (type != "DUTY" && type != "COMP") {
           setEntitled(entitledVAlue(Difference_In_Days + 1))
       }
   }

   const entitledVAlue = (d) => {
       return (parseInt(d) * 15 / 365).toFixed(2)
   }

   function getLastDayOfYear(year) {
       return new Date(year, 11, 31);
   }

   const [tDate, setTDate] = useState(getLastDayOfYear(new Date().getFullYear()).toISOString()?.split("T")[0])

   const toggleSelectAll = () => {
    setSelectAllChecked((prev) => !prev);
    const updatedPromotes = displayData?.filter((d) => {
        let deductions = d.deduction_ids?.split(',');
        let boole = 1;
        for(let i in deductions) {
            if(deductions[i] == edit?.id) {
                boole = 0;
            }
        }
        return boole;
    })?.map((d) => ({
      id: d.id,
      data: d,
    }));
    setPromotes(selectAllChecked ? [] : updatedPromotes);
  };

   const togglePromotion = (id, data) => {

    const isPromoted = promotes?.some((student) => student.id == id);

    if (isPromoted) {
      setPromotes((prevPromotes) => prevPromotes?.filter((student) => student.id != id));
    } else {
      setPromotes((prevPromotes) => [...prevPromotes, {id, data}]);
    }
  };

  const assign = async (id, emp) => {
    if(!id) {
        return toast.error("Unable to get Employee id");
    }

    if(type == "percentage" && !percentage) {
        return toast.error("Please Enter Percentage");
    }

    if(type == "amount" && !amount) {
        return toast.error("Please Enter Amount");
    }
    
    const data = {
        "employee_id": id,
        "deduction_id": edit?.id,
        "type": type,
        "amount": amount,
        "percentage": percentage,
    }

    console.log('data to backend - ', data);
    const config = {
        method: 'post',
        url: `${PAYROLL_EMP_DEDUCTION}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
        },
        data: data
    }

    setLoading(1);
    await axios(config)
        .then((res) => {
            setLoading(0);
            toast.success('success');
        })
        .catch(err => {
            setLoading(0);
            console.log(err);
            toast.error("Something Went Wrong");
        })
        setLoading(0);
    setPromotes([]);
    getEmpData();
    ('#ModalDeductionAssign').modal('hide');
  }


    const assignAll = async () => {
        if(promotes.length == 0) {
            return toast.error("Please Select Employees to assign");
        }
        if(type == "percentage" && !percentage) {
            return toast.error("Please Enter Percentage");
        }
    
        if(type == "amount" && !amount) {
            return toast.error("Please Enter Amount");
        }
        
        setLoading(1);
        let cnt = 0;
        for(let i in promotes) {
            const data = {
                "employee_id": promotes[i].id,
                "deduction_id": edit?.id,
                "type": type,
                "amount": amount,
                "percentage": percentage
            }
            const config = {
                method: 'post',
                url: `${PAYROLL_EMP_DEDUCTION}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
                },
                data: data
            }
            setLoading(1);
            console.log('data to backend - ', data);
            await axios(config)
                .then((res) => {
                    cnt++;
                })
                .catch(err => {
                    setLoading(0);
                    console.log(err);
                    toast.error("Something Went Wrong");
                })
                setLoading(0);
        }
        setLoading(0);
        toast.success(`Assigned Payroll Deductions to ${cnt} employees`);
        setPromotes([]);
        getEmpData();
        setCollegeSearch("");
        ('#ModalDeductionAssign').modal('hide');
    }  
    
    const removeAssigned = async (id, emp) => {
        if(!id) {
            return toast.error("Unable to get Employee id");
        }
    
        console.log('id to be deleted - ', id);
    
        console.log('url - ',  `${PAYROLL_EMP_DEDUCTION}/${id}`)
        const config = {
            method: 'put',
            url: `${PAYROLL_EMP_DEDUCTION}/${id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('UMS_auth')}`
            },
            data: {
                status: "INACTIVE",
                deduction_id: edit?.id
            }
        }
    
        setLoading(1);
        await axios(config)
            .then((res) => {
                setLoading(0);
                toast.success('Successfully removed');
            })
            .catch(err => {
                setLoading(0);
                console.log(err);
                toast.error("Something went wrong");
            })
            setLoading(0);
        setPromotes([]);
        getEmpData();
      }


    useEffect(() => {
        console.log('promotes - ', promotes);
    },[promotes]);

    useEffect(() => {
        let filteredData = [];
        if(collegeSearch) {
            console.log('hi1');
            console.log(collegeSearch);
            filteredData = empData?.filter((item) => item.college_id == collegeSearch && 
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
        }
        else {
            filteredData = empData?.filter((item) =>
          item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase())
        );
        }
        setDisplayData(filteredData);
      },[searchName]);

    useEffect(() => {
        let filteredData=[];
        if(collegeSearch) {
            if(searchName) {
                filteredData = empData?.filter((item) => item.college_id == collegeSearch && 
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
            }
            else {
                filteredData = empData?.filter((item) =>
                item.college_id == collegeSearch && (!designationSearch || item.role == designationSearch))
            }
            setDisplayData(filteredData);
        }
        else {
            setDisplayData(empData?.filter((item) => !designationSearch || item.role == designationSearch));
        }
    },[collegeSearch]);

    useEffect(() => {
        let filteredData=[];
        if(collegeSearch) {
            if(searchName) {
                filteredData = empData?.filter((item) => item.college_id == collegeSearch && (!designationSearch || item.role == designationSearch) &&
          (item.first_name.toLowerCase().includes(searchName.toLowerCase()) || item.last_name.toLowerCase().includes(searchName.toLowerCase()) 
          || `${item.first_name} ${item.last_name}`.toLowerCase().includes(searchName.toLowerCase()))
        );
            }
            else {
                filteredData = empData?.filter((item) =>
                (!designationSearch || item.role == designationSearch) && (!collegeSearch || item.college_id == collegeSearch))
            }
            setDisplayData(filteredData);
        }
        else {
            setDisplayData(empData?.filter((item) => !designationSearch || item.role == designationSearch));
        }
    },[designationSearch]);



    return (
        <div className="ModalDeductionAssign">
        <div className="ModalDeductionAssign">
            <div
            className="modal fade"
            id="ModalDeductionAssign"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"

            >
            <div
                className="modal-dialog modal-dialog-centered mw-100 w-75"
                role="document"
            >
                <div className="modal-content ">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                    Assign Deduction to Employees 
                    </h5>
                    <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="modal-body">
                <h5 className="">Deduction : {edit?.title}</h5>
                <br />
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <lable>
                                Type <span style={{ color: "red" }}>*</span>
                            </lable>
                            <select
                                className='form-control'
                                value={policy}
                                onChange={(e) => { 
                                    setType(e.target.value);
                                    setAmount(0);
                                    setPercentage(0); 
                                }}
                            >
                                <option value="percentage">Percentage</option>
                                <option value="amount">Amount</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <lable>
                                {type == "amount" ? "Amount (₹)" : "Percentage (%)"} <span style={{ color: "red" }}>*</span>
                            </lable>
                            <input
                                type="number"
                                className="form-control"
                                value={type == "amount" ? amount : percentage}
                                onChange={(e) => {type == "amount" ? setAmount(e.target.value) : setPercentage(e.target.value)}}
                            />
                        </div>
                    </div>
                    
                </div>
                <hr />
                <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="college_id">
                        College
                        </label>
                        <select 
                        name="college_id" id="college_id" required="" className="form-control"
                        value={collegeSearch}
                        onChange={(e) => setCollegeSearch(e.target.value)}
                        >
                        <option value="">All</option>
                        {collegeOpt?.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>
                        })}
                        </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="college_id">
                        Designation
                        </label>
                        <select 
                        name="designation" id="designation" required="" className="form-control"
                        value={designationSearch}
                        onChange={(e) => setDesignationSearch(e.target.value)}
                        >
                        <option value="">All</option>
                        {jobPositionOpt?.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>
                        })}
                        </select>
                    </div>
                </div>
                <div className="col-md-12 mb-3">
                        <label>
                            Search By Employee Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Employee Name"
                          value={searchName}
                          onChange={(e) => {
                            setSearchName(e.target.value);
                          }}
                        />
                </div>
                <div className="col-md-12"><hr /></div>
                <div className='col-md-12'>
                    <label>Assign Selected ({promotes.length}) : </label>
                    <button className="btn btn-success float-right mr-4"  
                    // data-dismiss="modal" aria-label="Close" 
                    onClick={assignAll}>Assign</button>
                </div>
                </div>
                <div className="table-responsive mt-3 fixTableHead">
                        <table className="table table-bordered" style={{}}>
                            <thead className="">
                            <tr>
                                <th>
                                <input
                                    type="checkbox"
                                    id="select-all"
                                    checked={selectAllChecked}
                                    onChange={toggleSelectAll}
                                />
                                </th>
                                <th>Sl No.</th>
                                <th>Name</th>
                                <th>College</th>
                                <th>Designation</th>
                                <th>Deductions Assigned</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {displayData ? displayData?.map((i,k) => {
                                    return (
                                        <tr>
                                            <td>
                                            <input
                                                type="checkbox"
                                                name="checkbox[]"
                                                value={i.id}
                                                checked={promotes?.some((staff) => staff.id == i.id)}
                                                onChange={() => togglePromotion(i.id, i)}
                                                disabled={i.deduction_ids?.split(',').some((eid) => eid == edit?.id)}
                                            />
                                            </td>
                                            <td>{k+1}</td>
                                            <td>{`${i.first_name} ${i.last_name}`}</td>
                                            <td>{collegeOpt.find((s) => s.id == i.college_id)?.name}</td>
                                            <td>{jobPositionOpt?.find((s) => s.id == i.role)?.name}</td>
                                            <td>{i.deductions ? i.deductions : <span className="badge badge-soft-danger">Not Assigned</span>}</td>
                                            <td>{(i.deduction_ids?.split(',')?.some((eid) => eid == edit?.id)) ? 
                                                <button className="btn btn-sm btn-danger ml-2" 
                                                onClick={() => removeAssigned(i.id, i)} 
                                                disabled={(promotes.length > 0)}>
                                                Remove
                                                </button>
                                            : <><button className="btn btn-sm btn-success ml-2"
                                                onClick={() => assign(i.id, i)} 
                                                disabled={(promotes.length > 0) || (i.deduction_ids?.split(',').some((eid) => eid === edit?.id))}>
                                                Assign
                                            </button>
                                            
                                            </>
                                            }
                                            </td>
                                        </tr>
                                    )
                                }) : <>Loading Employees...</>}
                            </tbody>
                        </table>
                        </div>
                    <div className="row d-flex justify-content-between px-2">
                    <button
                        className="btn btn-danger btn-rounded btn-outline ml-3 mt-3"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-success btn-outline mr-3 mt-3"
                        // data-dismiss="modal"
                        // aria-label="Close"
                        onClick={() => {
                        assignAll();
                        }}
                    >
                        Assign
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    );
}

export default ModalDeductionAssign;